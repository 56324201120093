import trkData from "./data.js";
import { trkDataGroups } from "./const.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import { findAssetById, normalizeAssetData } from "./assets.js";
import preferences from "./preferences.js";
import { createListing } from "./item-listing.js";
import { getDisplayFilterForEventType } from "./display-filter.js";
import { createMarkerCluster } from "./marker-cluster.js";
import strings from "./strings.js";
import user, { displayPreferencesAdd, displayPreferencesRemove } from "./user.js";
import domNodes from "./domNodes.js";
import { addPositionMarkerToPoint } from "./marker.js";
import { openDialogPanel } from "./panel-nav.js";
import { findJourneyById } from "./journey.js";
import { isItemIncluded } from "./polyfills.js";
import { createPositionLinesForTrip } from "./geometry-create.js";
import { setMapBounds } from "./map-bounds.js";
import { updateItemListingIcon } from "./item-listing.js";
import { handleWebServiceError } from "./ajax.js";
import state from "./state.js";
import { wrapUrl } from "./wrapurl.js";
import { resizeApp } from "./window-layout.js";
import { updateGroupVisibilityStatus } from "./asset-group.js";
import { toggleLoadingMessage } from "./ajax.js";
import { updateGroupFunctionBadges, updateAssetFunctionBadges } from "./badges.js";
import { updateActiveAssetInformation } from "./assets-active.js";
import { createMarkerPath } from "./marker-path.js";
import options from "./options.js";
import templates from "./templates.js";
import { closeSecondaryPanel } from "./panel.js";

import $ from "jquery";
import $j from "jquery";
import L from "leaflet";
import _ from "lodash";
import moment from "moment"; // https://www.npmjs.com/package/moment

export function findTripById(id) {
	var tripId = parseInt(id);
	for (var i = 0; i < trkData.journeys.length; i++) {
		for (var j = 0; j < trkData.journeys[i].Trips.length; j++) {
			if (trkData.journeys[i].Trips[j].Id === tripId) {
				return trkData.journeys[i].Trips[j];
			}
		}
	}
	return null;
}

function processTripData(journey, trip, data) {
	var asset = findAssetById(journey.AssetId);
	if (asset === null) {
		return;
	}

	_.each(data.Positions, function (position) {
		if (trkData.positionsById[position.Id] === undefined) {
			trkData.positionsById[position.Id] = normalizeAssetData(asset.Id, "position", position);
		}
		//trkData.trips.normalizedPositions.push(trkData.positionsById[position.Id]);
		if (trkData.trips.normalizedPositionsByTripId[trip.Id] === undefined) {
			trkData.trips.normalizedPositionsByTripId[trip.Id] = [];
		}
		trkData.trips.normalizedPositionsByTripId[trip.Id].push(trkData.positionsById[position.Id]);
	});

	if (data.ResultsForSearch) {
		trkData.trips.tripIdsWithResults[trip.Id] = true;
	}

	// trkData.trips.positions.push(data); // needed?
	trkData.trips.positionsByTripId[trip.Id] = data;

	if (data.MessageCounts !== null) {
		//trkData.trips.messageCounts.push(data.MessageCounts); // needed?
		trkData.trips.messageCountsByTripId[trip.Id] = data.MessageCounts;
	}

	var clusterTripMarkers = preferences.PREFERENCE_GROUP_POSITIONS && trip.IncludeAllPositions;
	var visiblePositions = _.filter(data.Positions, function (item) {
		return !item.IsHidden;
	});
	var lastVisiblePositionId = null;
	var firstVisiblePositionId = null;
	if (visiblePositions.length > 0) {
		lastVisiblePositionId = visiblePositions[0].Id;
		firstVisiblePositionId = visiblePositions[visiblePositions.length - 1].Id;
	}
	if (visiblePositions.length > 0) {
		if (clusterTripMarkers && trkData.trips.markerClustersByTripId[trip.Id] === undefined) {
			trkData.trips.markerClustersByTripId[trip.Id] = createMarkerCluster(asset, trip);
		}

		let alpha = 255;
		const alphaIncrement = preferences.PREFERENCE_ALPHA_POSITIONS
			? 185 / visiblePositions.length
			: 0;
		// positions are sorted from newest->last
		var totalTripPositions = visiblePositions.length;
		var clusterMarkers = [];
		for (var j = 0; j < totalTripPositions; j++) {
			// add position to map and position filters
			var position = visiblePositions[j];
			var isFirst = totalTripPositions > 1 && position.Id === firstVisiblePositionId;
			var isLast = totalTripPositions > 1 && position.Id === lastVisiblePositionId;
			if (!position.IsHidden && (trip.IncludeAllPositions || isFirst || isLast)) {
				var marker = addPositionMarkerToPoint(
					[position.Lat, position.Lng],
					false,
					position,
					asset,
					alpha,
					clusterTripMarkers,
					isFirst,
					isLast,
					trkDataGroups.JOURNEY_HISTORY,
					trip
				);
				clusterMarkers.push(marker);
				alpha -= alphaIncrement;
			}
		}

		trkData.trips.markersByTripId = _.groupBy(trkData.trips.markers, function (marker) {
			return marker.data.tripId;
		});

		if (clusterTripMarkers && trkData.trips.markerClustersByTripId[trip.Id] !== undefined) {
			trkData.trips.markerClustersByTripId[trip.Id].empty();
			trkData.trips.markerClustersByTripId[trip.Id].multiAdd(clusterMarkers);
			addItemToMap(trkData.trips.markerClustersByTripId[trip.Id]);
		}

		createPositionLinesForTrip(trip, asset, visiblePositions);
	}
}

export function openEventsForTrip(trip) {
	var tripEvents = _.filter(trkData.trips.normalizedEventsByTripId[trip.Id], getDisplayFilterForEventType("events"));
	createListing(tripEvents, "events");
	openDialogPanel(
		domNodes.dialogs.assetEvents,
		strings.EVENTS,
		trip,
		false,
		undefined,
		"trip",
		"trip-events",
		openEventsForTrip
	);
}

export function toggleTripActive(journeyId, tripId, makeTripActive, updateUI) {
	var journey = findJourneyById(journeyId);
	if (journey === null) {
		return false;
	}
	var trip = null;
	for (var i = 0; i < journey.Trips.length; i++) {
		if (journey.Trips[i].Id === tripId) {
			trip = journey.Trips[i];
			break;
		}
	}
	if (trip === null) {
		return false;
	}
	var asset = findAssetById(journey.AssetId);
	if (asset === null) {
		return false;
	}

	var isCurrentlyActive = isItemIncluded(user.displayPreferences.visibleTrips, tripId);
	if (makeTripActive === null) {
		makeTripActive = !isCurrentlyActive;
	}

	if (isCurrentlyActive === makeTripActive) {
		return;
	}

	var tripPositions = null;
	if (trkData.trips.positionsByTripId !== null && trkData.trips.positionsByTripId[tripId] !== undefined) {
		tripPositions = trkData.trips.positionsByTripId[tripId];
	}

	var setBounds = false;
	if (tripPositions !== null) {
		if (trkData.trips.mapLinesByTripId[trip.Id] !== undefined) {
			if (makeTripActive) {
				addItemToMap(trkData.trips.mapLinesByTripId[trip.Id]);
			} else {
				removeItemFromMap(trkData.trips.mapLinesByTripId[trip.Id]);
			}
		}

		if (makeTripActive) {
			if (preferences.PREFERENCE_GROUP_POSITIONS) {
				if (trkData.trips.markerClustersByTripId[trip.Id] !== undefined) {
					addItemToMap(trkData.trips.markerClustersByTripId[trip.Id]);
				}
			} else {
				_.each(trkData.trips.markersByTripId[trip.Id], function (item) {
					if (!item.data.location.IsHidden) {
						addItemToMap(item);
					}
				});
			}
		} else {
			// remove marker cluster
			if (preferences.PREFERENCE_GROUP_POSITIONS) {
				if (trkData.trips.markerClustersByTripId[trip.Id] !== undefined) {
					removeItemFromMap(trkData.trips.markerClustersByTripId[trip.Id]);
				}
			} else {
				_.each(trkData.trips.markersByTripId[trip.Id], function (item) {
					removeItemFromMap(item);
				});
			}
		}

		setBounds = true;
	}

	if (tripPositions === null && makeTripActive) {
		querySingleTrip(journey, trip);
	}

	updateTripVisibilityStatus(tripId, makeTripActive);

	// grey out / make active
	var index = _.indexOf(trkData.visible.trips, tripId);
	if (makeTripActive) {
		updateTripListingIcon(tripId, asset.Color, true, asset.Class);
		displayPreferencesAdd("visibleTrips", tripId);
		if (index === -1) {
			trkData.visible.trips.push(tripId);
		}
	} else {
		updateTripListingIcon(tripId, asset.Color, false, asset.Class);
		displayPreferencesRemove("visibleTrips", tripId);
		if (index !== -1) {
			trkData.visible.trips.splice(index, 1);
		}
	}

	// these UI updates should really only happen after all assets have been toggled, so for group changes this is rough
	if (updateUI) {
		updateGroupVisibilityStatus("journey-" + journeyId);
		updateActiveAssetInformation();
	}
	if (setBounds) {
		setMapBounds();
	}
	return true;
}

export function queryVisibleTrips() {
	var deferreds = [];
	_.each(trkData.visible.trips, function (tripId) {
		var trip = findTripById(tripId);
		if (trip === null) {
			return;
		}
		var journey = findJourneyById(trip.JourneyId);
		if (journey === null) {
			return;
		}
		deferreds.push(querySingleTrip(journey, trip));
	});
	return deferreds;
}

//var throttledCompareSharedViews = _.throttle(compareSharedViews, 250, { leading: false });

function querySingleTrip(journey, trip) {
	if (trip.IsQueried === true) {
		return null;
	}
	trip.IsQueried = true;
	toggleLoadingMessage(true, "trip" + trip.Id);
	var data = {
		journeyId: journey.Id,
		tripId: trip.Id,
		zoomLevel: null,
		sensitivity: null,
		loadLimitedData: true,
		isMobile: state.isMobile,
	};
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetTripData"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg.d) {
				//state.hasQueriedHistory = true;
				var result = msg.d;

				_.each(result.Assets, function (assetResult) {
					// should only be a single asset returned
					processTripData(journey, trip, assetResult);
				});
				//var events = result.Events;
				//_.each(events, function (item) {
				//    var existingTripEvent = _.find(trkData.trips.events, function (evt) { return evt.Id === item.Id; });
				//    if (existingTripEvent !== undefined) {
				//        return;
				//    }
				//    trkData.trips.events.push(item);
				//});
				trkData.trips.eventsByTripId[trip.Id] = result.Events;

				var normalizedTripEvents = [];
				_.each(result.Events, function (item) {
					if (trkData.eventsById[item.Id] === undefined) {
						trkData.eventsById[item.Id] = normalizeAssetData(item.AssetId, "event", item);
					}
					normalizedTripEvents.push(trkData.eventsById[item.Id]);
					trkData.trips.normalizedEvents.push(trkData.eventsById[item.Id]);
					trkData.trips.normalizedEventIds[item.Id] = true;
				});

				trkData.trips.normalizedEventsByTripId[trip.Id] = normalizedTripEvents;

				updateAssetFunctionBadges(trkDataGroups.JOURNEY_HISTORY, trip.Id);
				updateGroupFunctionBadges(trkDataGroups.JOURNEY_HISTORY, [trip.Id], "trip");
				resizeApp(true);
				setMapBounds();
			}
			toggleLoadingMessage(false, "trip" + trip.Id);
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_SINGLE_ASSET_ERROR);
			toggleLoadingMessage(false, "trip" + trip.Id);
		},
	});
}

function updateTripListingIcon(id, color, isActive, icon) {
	updateItemListingIcon(id, color, isActive, icon, domNodes.trips);
}

function updateTripVisibilityStatus(tripId, makeActive) {
	updateItemVisibilityStatus(tripId, makeActive, trkData.visible.trips, domNodes.trips);
}

function updateItemVisibilityStatus(itemId, makeActive, visibleList, domNodes) {
	var index = _.indexOf(visibleList, itemId);
	if (makeActive) {
		if (index === -1) {
			visibleList.push(itemId);
		}
	} else {
		if (index !== -1) {
			visibleList.splice(index, 1);
		}
	}
	var itemNodes = domNodes[itemId];
	if (!_.isArray(itemNodes)) {
		itemNodes = [itemNodes];
	}

	console.log(itemId);

	_.each(itemNodes, function (itemNode) {
		var visibilityIcon = itemNode.querySelector(".showhide");
		if (makeActive) {
			visibilityIcon
				.querySelector("use")
				.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#visible");
			visibilityIcon.classList.add("active");
		} else {
			visibilityIcon
				.querySelector("use")
				.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#invisible");
			visibilityIcon.classList.remove("active");
		}
	});
}

export function createTripNode(journey, trip, asset) {
	if (trip === null || asset === null || journey === null) {
		return null;
	}
	var isActive = user.isAnonymous ? true : isItemIncluded(user.displayPreferences.visibleTrips, trip.Id);
	if (isActive) {
		if (_.indexOf(trkData.visible.trips, trip.Id) === -1) {
			trkData.visible.trips.push(trip.Id);
		}
	}
	var alpha = isActive ? null : 50;
	var description = "";
	if (trip.FromEpoch !== undefined && trip.FromEpoch !== null) {
		description = moment(trip.FromEpoch).format("L");
	}
	description += " - ";
	if (trip.ToEpoch !== undefined && trip.ToEpoch !== null) {
		description += moment(trip.ToEpoch).format("L");
	}

	var tripDto = {
		id: trip.Id,
		name: trip.Name,
		description: description,
		journeyId: journey.Id,
		color: asset.Color,
		icon: createMarkerPath(asset.Class, asset.Color, null, alpha, asset.Id, true),
		isVisible: isItemIncluded(user.displayPreferences.visibleTrips, trip.Id),
		visibilityClass: isItemIncluded(user.displayPreferences.visibleTrips, trip.Id) ? "active" : "disabled",
		serviceClass: "",
		location: {
			address: null,
			latitude: null,
			longitude: null,
			time: null,
			speed: null,
		},
		notifications: {
			symbol: user.isAnonymous
				? options.allowAnonymousMessaging
					? "notifications-sh"
					: "notifications-sh-nc"
				: "notifications",
			alerts: 0,
			events: 0,
			status: 0,
			messages: 0,
		},
	};

	var fragment = templates.trip(tripDto);
	return fragment.childNodes[0];
}

export function deleteTrip(trip) {
	domNodes.trips[trip.Id].parentNode.removeChild(domNodes.trips[trip.Id]);
	var panel = domNodes.panels.secondary;
	if (panel.getAttribute("data-group-for") === "trips" && parseInt(panel.getAttribute("data-item-id")) === trip.Id) {
		closeSecondaryPanel();
	}

	_.each(trkData.trips.markersByTripId[trip.Id], function (marker) {
		removeItemFromMap(marker);
	});

	delete trkData.trips.markersByTripId[trip.Id];
	delete trkData.trips.eventsByTripId[trip.Id];
	delete trkData.trips.positionsByTripId[trip.Id];
	delete trkData.trips.messagesByTripId[trip.Id];
	delete trkData.trips.messageCountsByTripId[trip.Id];

	var journey = findJourneyById(trip.JourneyId);
	if (journey != null) {
		for (var i = 0; i < journey.Trips.length; i++) {
			if (journey.Trips[i].Id == trip.Id) {
				journey.Trips.splice(i, 1);
			}
		}
	}

	var groupId = "journey-" + trip.JourneyId;
	updateGroupVisibilityStatus(groupId);
}
