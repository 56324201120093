import user from "./user.js";
import { wrapUrl } from "./wrapurl.js";
import { throttles } from "./timers.js";

import $j from "jquery";

export let version = "";

export function overrideVersion(v) {
	version = v;
}

export function checkVersion() {
	$j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetStatus"),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			var resp = msg.d;
			if (version != resp.Version || user.id != resp.UserId) {
				var reloadTime = Math.floor(Math.random() * 300000);
				console.log(
					"User or version mismatch. Version: " +
						version +
						" vs " +
						resp.Version +
						". User: " +
						user.id +
						" vs " +
						resp.UserId +
						". Reloading in " + (reloadTime / 1000) + "s."
				);
				// don't redirect immediately because this browser session could be the one initiating the logout and will naturally redirect
				setTimeout(function () {
					location.reload(true);
				}, reloadTime);
			} else {
				console.log("Portal status check valid.");
			}
		},
		error: function (xhr, status, error) {},
	});
	throttles.updatePositionStatus();
}
