import trkData from "./data.js";
import domNodes from "./domNodes.js";
import { viewModes, mapModes, trkDataGroups } from "./const.js";
import strings from "./strings.js";
import state from "./state.js";
import { findAssetById } from "./assets.js";
import preferences from "./preferences.js";
import { updateActiveAssetInformation } from "./assets-active.js";
import { addItemToMap, addItemsToMap } from "./map-items.js";
import { updateGroupFunctionBadges, updateAssetFunctionBadges } from "./badges.js";
import { normalizeAssetData } from "./assets.js";
import { addPositionMarkerToPoint } from "./marker.js";
import { createMarkerCluster } from "./marker-cluster.js";
import { createFilteredEventsList, EVENTS_EMERGENCY, EVENTS_TEXT_MESSAGE, EVENTS_STATUS, EVENTS_ALERT } from "./asset-events.js";
import { includeRowIfNotNull } from "./dom-util.js";
import {
	convertToLatLngPreference,
	convertSpeedToPreference,
	convertFromMetresToUserDistancePreference,
} from "./preferences.js";
import { createMarkerPath } from "./marker-path.js";
import { createDialogTitleFragment } from "./dom-util.js";
import { getAssetDataGroupForCurrentViewMode } from "./map-viewmode.js";
import { createPositionLinesForAsset, createPositionLinesForTrip } from "./geometry-create.js";
import { toggleAssetActive } from "./asset-select.js";
import { switchMapMode, map } from "./map-base.js";
import { setMapBounds } from "./map-bounds.js";
import { openMarkerForPosition, playbackStart } from "./playback.js";
import {getHighestPriorityEventType} from "./marker.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import L from "leaflet";
import { el, text, setChildren } from "redom"; // https://redom.js.org/
import moment from "moment"; // https://www.npmjs.com/package/moment


export function createHistoryPositionResults() {
	trkData.assets.forEach(function (asset) {
		updateAssetFunctionBadges(getAssetDataGroupForCurrentViewMode(), asset.Id);
	});
	updateGroupFunctionBadges(getAssetDataGroupForCurrentViewMode(), null, "asset");

	_.each(trkData.history.positionsByAssetId, function (assetHistoryPositions, assetId) {
		var isWithinSearchRange = assetHistoryPositions !== undefined && assetHistoryPositions.ResultsForSearch === true;
		if (isWithinSearchRange) {
			domNodes.assets[assetId].forEach(function (assetNode) {
				var timeIndicator = assetNode.querySelector(".notifications");
				timeIndicator.classList.add("recent-positions");
			});
		}
	});

	updateActiveAssetInformation(viewModes.NORMAL);

	createFilteredEventsList();
}

// Spawns clusters for the historic positions of an asset, given the data structure
// for an asset.
// Typically called with either live data, or data from /GPSService.asmx/GetAssetPositionsForDateRange
export function processAssetHistoryPositionsResult(assetResult) {

	// console.log(assetResult);

	// add the results to the bounds
	var asset = findAssetById(assetResult.Id);
	trkData.history.positions.push(assetResult);
	trkData.history.positionsByAssetId[asset.Id] = assetResult;

	assetResult.Positions.forEach(function (position) {
		if (trkData.positionsById[position.Id] === undefined) {
			trkData.positionsById[position.Id] = normalizeAssetData(asset.Id, "position", position);
		}
		trkData.history.normalizedPositions.push(trkData.positionsById[position.Id]);
		if (trkData.history.normalizedPositionsByAssetId[asset.Id] === undefined) {
			trkData.history.normalizedPositionsByAssetId[asset.Id] = [];
		}
		trkData.history.normalizedPositionsByAssetId[asset.Id].push(trkData.positionsById[position.Id]);
	});

	if (assetResult.MessageCounts !== null) {
		trkData.history.messageCounts.push(assetResult.MessageCounts);
		trkData.history.messageCountsByAssetId[asset.Id] = assetResult.MessageCounts;
	}

	if (assetResult.ResultsForSearch) {
		trkData.history.assetIdsWithResults[asset.Id] = true;
	}

	var assetPositions = assetResult.Positions;
	var assetVisiblePositions = _.filter(assetResult.Positions, function (item) {
		return !item.IsHidden;
	});
	var lastVisiblePositionId = null;
	var firstVisiblePositionId = null;
	if (assetVisiblePositions.length > 0) {
		lastVisiblePositionId = assetVisiblePositions[0].Id;
		firstVisiblePositionId = assetVisiblePositions[assetVisiblePositions.length - 1].Id;
	}

	// console.log("visible positions: " + lastVisiblePositionId + ", " + firstVisiblePositionId);
	if (assetPositions.length > 0) {
		//const eventlessFilter = document.querySelector('#form-history-date-range input#filter-eventless')?.checked;

		if (preferences.PREFERENCE_GROUP_POSITIONS && trkData.history.markerClustersByAssetId[asset.Id] === undefined) {
			trkData.history.markerClustersByAssetId[asset.Id] = createMarkerCluster(asset, null);
		}

		// The opacity of the positions will get lower as the positions get older
		// to a minimum of 70/255 opacity.
		// The opacity will always be 1 if the user has not checked the
		// "Fade historic positions by time" preference in their user settings.
		let alpha = 255;
		const alphaIncrement =
			preferences.PREFERENCE_ALPHA_POSITIONS ?
			185 / assetVisiblePositions.length
			: 0;

		// positions are sorted from newest->last
		var totalAssetVisiblePositions = assetVisiblePositions.length;
		var clusterMarkers = [];
		for (var j = 0; j < assetPositions.length; j++) {
			// add position to map and position filters
			var position = assetPositions[j];

			if (trkData.positionsById[position.Id] === undefined) {
				trkData.positionsById[position.Id] = normalizeAssetData(asset.Id, "position", position);
			}

			if (trkData.history.latestPosition == null || trkData.history.latestPosition.Epoch < position.Epoch) {
				trkData.history.latestPosition = position;
			}

			// (Inefficiently) fetch the highest-priority event from each position
			const evtType = getHighestPriorityEventType(position.Events);

			const isFirst = totalAssetVisiblePositions > 1 && firstVisiblePositionId === position.Id;
			const isLast = totalAssetVisiblePositions > 1 && lastVisiblePositionId === position.Id;

			// TODO: Display markers only for "important" events when requested
			// Skip this logic when we only have one position (i.e. on live mode)
			if (
					true || // TODO: !eventlessFilter ||
					assetPositions.length == 1 ||
					isFirst ||
					isLast ||
					EVENTS_STATUS.includes(evtType) ||
					EVENTS_ALERT.includes(evtType) ||
					EVENTS_EMERGENCY.includes(evtType) ||
					EVENTS_TEXT_MESSAGE.includes(evtType)
			) {
				const marker = addPositionMarkerToPoint(
					[position.Lat, position.Lng],
					false,
					position,
					asset,
					alpha,
					preferences.PREFERENCE_GROUP_POSITIONS,
					isFirst,
					isLast,
					trkDataGroups.NORMAL_HISTORY,
					undefined,
				);
				if (!position.IsHidden) {
					clusterMarkers.push(marker);
				}
			}
			//markersAdded++;
			//updateLoadingMarkerProgress(markersAdded, totalMarkers);

			alpha -= alphaIncrement;
		}

		trkData.history.markersByAssetId = _.groupBy(trkData.history.markers, function (marker) {
			return marker.data.assetId;
		});
		trkData.history.markersByPositionId = _.keyBy(trkData.history.markers, function (marker) {
			return marker.data.location.Id;
		});

		// addItemsToMap(clusterMarkers)

		/// TODO: create one clusterer per asset ID
		// if (preferences.PREFERENCE_GROUP_POSITIONS && trkData.history.markerClustersByAssetId[asset.Id] !== undefined) {
		// 	trkData.history.markerClustersByAssetId[asset.Id].clearLayers();
		// 	trkData.history.markerClustersByAssetId[asset.Id].addLayers(clusterMarkers);
		// 	if (state.activeMapMode === mapModes.HISTORY) {
		// 		addItemToMap(trkData.history.markerClustersByAssetId[asset.Id]);
		// 	}
		// }
		if (preferences.PREFERENCE_GROUP_POSITIONS && trkData.history.markerClustersByAssetId[asset.Id] !== undefined) {
			trkData.history.markerClustersByAssetId[asset.Id].empty();
			trkData.history.markerClustersByAssetId[asset.Id].multiAdd(clusterMarkers);
			if (state.activeMapMode === mapModes.HISTORY) {
				addItemToMap(trkData.history.markerClustersByAssetId[asset.Id]);
			}
		}

		createPositionLinesForAsset(asset, assetVisiblePositions, viewModes.NORMAL);
	}
}

function updateLoadingMarkerProgress(current, total) {
	var percentage = Math.ceil((current / total) * 100);
}

export function createPositionHistorySummary(asset, trip, fromId, toId, e) {
	console.log(asset.Id + " - " + fromId + " - " + toId);
	var dialog = $(domNodes.infoDialogs.positionHistory);
	dialog.data("assetId", asset.Id);
	var allPositions = [];

	if (trip !== undefined && trip !== null) {
		dialog.data("tripId", trip.Id);
		allPositions = trkData.trips.positionsByTripId[trip.Id].Positions;
	} else if (state.activeViewMode === viewModes.NORMAL) {
		dialog.removeData("tripId");
		allPositions = trkData.history.positionsByAssetId[asset.Id].Positions;
	} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
		dialog.removeData("tripId");
		allPositions = trkData.sharedView.positionsByAssetId[asset.Id].Positions;
	}

	var endIndex = 0;
	var fromIndex = allPositions.length - 1;
	if (fromId !== undefined && fromId !== null) {
		var from = _.find(allPositions, { Id: fromId });
		if (from !== undefined) {
			fromIndex = _.indexOf(allPositions, from);
		}
	}
	if (toId !== undefined && toId !== null) {
		var to = _.find(allPositions, { Id: toId });
		if (to !== undefined) {
			endIndex = _.indexOf(allPositions, to);
		}
	}

	// swap indexes if user chose a from after the to
	if (endIndex > fromIndex) {
		var fromIndexBefore = fromIndex;
		fromIndex = endIndex;
		endIndex = fromIndexBefore;
	}

	var historyPositions = allPositions.slice(endIndex, fromIndex + 1);

	var distance = 0;
	for (var i = endIndex; i <= fromIndex; i++) {
		if (i + 1 <= fromIndex) {
			distance += L.latLng(allPositions[i].Lat, allPositions[i].Lng).distanceTo(
				L.latLng(allPositions[i + 1].Lat, allPositions[i + 1].Lng)
			);
		}
	}

	var begin = allPositions[fromIndex];
	var end = allPositions[endIndex];
	var beginTime = moment.unix(begin.Epoch);
	var endTime = moment.unix(end.Epoch);
	var duration = moment.duration(endTime.diff(beginTime));
	var durationDays = Math.floor(duration.asDays());
	var durationHours = ("0" + Math.floor(duration.asHours() % 24)).slice(-2);
	var durationDisplay =
		(durationDays > 0 ? durationDays + "." : "") +
		durationHours +
		moment.utc(duration.asMilliseconds()).format(":mm:ss");
	var averageSpeed = duration.asSeconds() > 0 ? convertSpeedToPreference(distance / duration.asSeconds()) : 0;
	var maxSpeed = convertSpeedToPreference(_.max(_.map(historyPositions, "Speed")));

	var beginTimeElements = [text(begin.Time)];
	if (begin.IsAcc === false) {
		beginTimeElements = [el("span.inaccurate", begin.Time), el("sup", { title: strings.TIME_INACCURATE }, "*")];
	}

	var beginAddress = includeRowIfNotNull(strings.ADDRESS, begin.Address, { style: { verticalAlign: "top" } });
	var beginLatLng = includeRowIfNotNull(
		strings.LAT_LNG,
		el(
			"a.location",
			{ href: "#", dataset: { marker: begin.Id } },
			convertToLatLngPreference(begin.DisplayLat, begin.DisplayLng, begin.Grid)
		)
	);

	var endTimeElements = [text(end.Time)];
	if (end.IsAcc === false) {
		beginTimeElements = [el("span.inaccurate", end.Time), el("sup", { title: strings.TIME_INACCURATE }, "*")];
	}

	var endAddress = includeRowIfNotNull(strings.ADDRESS, end.Address, { style: { verticalAlign: "top" } });
	var endLatLng = includeRowIfNotNull(
		strings.LAT_LNG,
		el(
			"a.location",
			{ href: "#", dataset: { marker: end.Id } },
			convertToLatLngPreference(end.DisplayLat, end.DisplayLng, end.Grid)
		)
	);

	const options = [];
	allPositions.forEach(function (position) {
		options.push(el("option", { value: position.Id }, position.Time));
	});
	var positionSelect = el("select.form-control.time-picker", options);

	var positionEndSelect = positionSelect.cloneNode(true);
	var beginOption = positionSelect.querySelector('option[value="' + begin.Id + '"]');
	if (beginOption != null) {
		beginOption.selected = true;
	}
	var endOption = positionEndSelect.querySelector('option[value="' + end.Id + '"]');
	if (endOption != null) {
		endOption.selected = true;
	}

	var beginHeader = el(
		"div#positions-begin-header.input-group",
		el("div.input-group-prepend", el("span.input-group-text", strings.BEGIN))
	);
	var endHeader = el(
		"div#positions-end-header.input-group",
		el("div.input-group-prepend", el("span.input-group-text", strings.END))
	);

	var beginCard = el("div.card.mb-2", [
		el("div.card-header.p-0.d-flex.align-items-center", beginHeader),
		el("div.card-body.p-2", el("table", el("tbody", [beginAddress, beginLatLng]))),
	]);
	var endCard = el("div.card.mb-2", [
		el("div.card-header.p-0.d-flex.align-items-center", endHeader),
		el("div.card-body.p-2", el("table", el("tbody", [endAddress, endLatLng]))),
	]);
	var detailsCard = el("div.card.mb-2", [
		el("div.card-header.p-1.pl-2.pr-2.d-flex.align-items-center", strings.DETAILS),
		el(
			"div.card-body.p-2",
			el(
				"table",
				el("tbody", [
					includeRowIfNotNull(strings.POSITIONS, historyPositions.length),
					includeRowIfNotNull(strings.DISTANCE, convertFromMetresToUserDistancePreference(distance)),
					includeRowIfNotNull(strings.AVERAGE_SPEED, averageSpeed),
					includeRowIfNotNull(strings.MAX_SPEED, maxSpeed),
					includeRowIfNotNull(strings.DURATION, durationDisplay),
				])
			)
		),
	]);
	var content = el("div.markercontent", [beginCard, endCard, detailsCard]);

	setChildren(dialog[0], content);

	document.getElementById("positions-begin-header").appendChild(positionSelect);
	document.getElementById("positions-end-header").appendChild(positionEndSelect);

	if (trip !== undefined && trip !== null) {
		dialog.dialog("option", "title", createDialogTitleFragment(trip.Name, asset.Name));
	} else {
		dialog.dialog(
			"option",
			"title",
			createDialogTitleFragment(asset.Name, options.hideDeviceName ? null : asset.DeviceName)
		);
	}
	var dialogTitleBar = $("div.ui-dialog-titlebar", dialog.parent());
	var titleIcon =
		"url(" + createMarkerPath(asset.Class, asset.Color, null, null, asset.Id, false, null, false, false) + ")";
	dialogTitleBar[0].style.backgroundImage = titleIcon;

	// quick actions
	var historyQuickActions = document.getElementById("position-history-dialog-actions-list");
	var actionAssetOptions = historyQuickActions.querySelector('a[data-action="path-asset-options"]');
	var actionHideAsset = historyQuickActions.querySelector('a[data-action="path-asset-hide"]');
	var actionHideTrip = historyQuickActions.querySelector('a[data-action="path-trip-hide"]');
	actionAssetOptions.setAttribute("data-asset-id", asset.Id);
	actionHideAsset.setAttribute("data-asset-id", asset.Id);
	var actionTripOptions = historyQuickActions.querySelector('a[data-action="path-trip-options"]');
	if (trip !== undefined && trip !== null) {
		actionTripOptions.classList.remove("disabled");
		actionHideTrip.classList.remove("disabled");
		actionTripOptions.setAttribute("data-trip-id", trip.Id);
		actionHideTrip.setAttribute("data-trip-id", trip.Id);
		actionHideTrip.setAttribute("data-journey-id", trip.JourneyId);
		actionHideAsset.classList.remove("disabled");
	} else {
		actionHideAsset.classList.add("disabled");
		actionHideTrip.classList.add("disabled");
		actionTripOptions.classList.add("disabled");
	}
	var actionHistory = historyQuickActions.querySelector('a[data-action="path-history"]');
	actionHistory.setAttribute("data-from", begin.Time);
	actionHistory.setAttribute("data-to", end.Time);
	actionHistory.setAttribute("data-asset-id", asset.Id);

	if (e !== undefined) {
		const mouseEvent = e.originalEvent;
		mouseEvent.preventDefault = true;
		// position dialog if not already opened
		if (!dialog.dialog("isOpen")) {
			dialog.dialog("option", "position", {
				of: mouseEvent,
				my: "left+15 center",
				at: "right center",
				collision: "flipfit",
				within: domNodes.map,
				using: function (param1, param2) {
					$(this).css(param1);
				},
			});
		} else {
			//$('#position-history-dialog').closest('.ui-dialog').position({ of: mouseEvent, my: 'left+15 center', at: 'right center' });
		}
	}
	dialog.dialog("open");
}

export function getPositionInHistoryRelative(assetId, positionId, tripId, shift) {
	let assetPositions;
	if (tripId !== null) {
		assetPositions = trkData.trips.positionsByTripId[tripId].Positions;
	} else if (state.activeViewMode === viewModes.NORMAL) {
		assetPositions = trkData.history.positionsByAssetId[assetId].Positions;
	} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
		assetPositions = trkData.sharedView.positionsByAssetId[assetId].Positions;
	}

	for (var j = 0; j < assetPositions.length; j++) {
		if (assetPositions[j].Id == positionId) {
			// current position is assetPositions[j], get previous one and show it
			var indexShift = j + shift;
			if (indexShift < 0 || indexShift > assetPositions.length) {
				return null;
			}

			var shiftedPosition = assetPositions[indexShift];
			return shiftedPosition;
		}
	}

	return null;
}

export function loadHistory(assetIds, replay) {
	var deferreds = [];
	for (var i = 0; i < trkData.assets.length; i++) {
		var makeActive = $j.inArray(trkData.assets[i].Id, assetIds) !== -1;
		deferreds.push(toggleAssetActive(trkData.assets[i].Id, makeActive, false));
	}

	// todo: this should be a helper function
	if (state.activeMapMode === mapModes.LIVE) {
		// always show the control area if it is hidden
		if ($("#controlarea").is(":hidden")) {
			$("#controlarea").slideDown();
		}
		deferreds.push(switchMapMode(mapModes.HISTORY, null, true));
	}

	Promise.all(deferreds).then(function () {
		setMapBounds();

		if (replay) {
			var assetId = assetIds[0];
			if (trkData.history.positionsByAssetId[assetId] !== undefined) {
				var positionsForAsset = trkData.history.positionsByAssetId[assetId].Positions;
				if (positionsForAsset !== undefined && positionsForAsset.length > 0) {
					var latestPositionForAsset = positionsForAsset[positionsForAsset.length - 1];
					openMarkerForPosition(latestPositionForAsset.Id);
					playbackStart(assetId, latestPositionForAsset.Id, null);
				}
			}
		}
	});
}
