import strings from "./strings.js";
import trkData from "./data.js";
import domNodes from "./domNodes.js";
import state from "./state.js";
import user from "./user.js";
import { devices } from "./devices.js";
import options from "./options.js";
import preferences, { convertFuelEfficiencyToStandard, distanceValueFromMetersToUserPreference, distanceText, globalizeNumberFormat } from "./preferences.js";
import { loadAssetStatus } from "./asset-state.js";
import { populateCheckboxList } from "./dom-util.js";
import { sortAssetGroups, sortItemGroups } from "./item-sorting.js";
import { openDialogPanel } from "./panel-nav.js";
import { findDeviceById } from "./devices.js";
import { changePrimaryButtonLabel } from "./modal.js";
import { loadAssetAlerts } from "./asset-alerts.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import { formShowErrorMessage } from "./ajax.js";
import { addGroupToGroupListInItemEditDialog, addItemGroupToItemGroupListInAssetEditDialog } from "./group-list.js";
import { isMessagingEnabledForAsset } from "./assets.js";
import { getDescriptionForDriver, getGroupNamesForDriver } from "./driver.js";
import { deviceDialogChange } from "./device-dialog.js";
import { hash1, hash2 } from "./device-imei.js";
import {
	loadIDPARCAssetIOConfiguration,
	loadIDPAssetIOConfiguration,
	loadIDPAVLAssetIOConfiguration,
} from "./idp-config.js";

import _ from "lodash";
import $ from "jquery";
import $j from "jquery";

import "../legacy/jquery.maxchar.js"

export function openEditAssetDialog(asset) {
	var dialog = $(domNodes.dialogs.editAsset);
	var dialogTitle;
	state.isAddingAsset = asset === null;

	var buttonText = !state.isAddingAsset ? strings.SAVE_CHANGES : strings.CREATE_ASSET;
	changePrimaryButtonLabel(domNodes.dialogs.editAsset, buttonText);

	if (state.isAddingAsset) {
		if (user.assetLimit !== null && trkData.assets.length >= user.assetLimit) {
			$j("#assetlimit-modal").modal("show");
			return;
		}

		dialogTitle = strings.ADD_ASSET_TITLE;
		//dialogTitle = '<svg><use xlink:href="/content/svg/tracking.svg?v=15#plus-circle"></use></svg> <span>' + strings.ADD_ASSET_TITLE + '</span>';
		$j(".portal-registration,.dnid-download", dialog).hide();
	} else {
		dialogTitle = strings.EDIT_ASSET;
		//dialogTitle = '<svg><use xlink:href="/content/svg/tracking.svg?v=15#edit"></use></svg> <span>' + strings.EDIT_ASSET + ' ' + asset.Name + '</span>';
		$j(".portal-registration,.dnid-download", dialog).show();
	}

	var id = null;
	trkData.validation.editAsset.resetForm(); // reset validation
	trkData.validation.editAsset.currentForm.reset();
	if (asset === null) {
		// make first tab active when adding
		$("#accordion-edit-asset-main-content").collapse("show");
	}
	$j("#SendPortalRegistration", dialog).prop("disabled", false);
	$j("#txtEditAssetUniqueId", dialog).prop("disabled", user.assetControlFederated ? true : false);
	$j("#edit-asset-photo", dialog).hide();
	$j("#edit-asset-icon", dialog).hide();
	$j("#txtEditAssetFuelEfficiency,#txtEditAssetCustomMessage", dialog).val("");
	$j("input[name=EditAssetContactIds]", dialog).prop("checked", false);
	$j("#ddlAssetSRID", dialog).val("4326");
	$j("#ddlEditAssetCannedMessageGroup", dialog).val("");
	$j("#ddlEditAssetGatewayAccount", dialog).val("");
	$j("#ddlEditAssetDriverStatusTemplate,#ddlEditAssetDriverDeviceType", dialog).val("");
	$j("#ddlEditAssetQuickMessageFromMobileTemplate,#ddlEditAssetQuickMessageToMobileTemplate", dialog).val("");
	$j("#ddlEditAssetConfiguration", dialog).val("None");
	$j("label[for=ddlEditAssetDevice]", dialog).parent().show();
	$j("#EditAssetDevice", dialog).parent().show();
	$("#ddlEditAssetDeviceManufacturer").change();
	$j("#AssetContact", dialog).hide();
	var softwarePackage = document.getElementById("ddlEditAssetSoftwarePackage");
	softwarePackage.value = "";
	document.getElementById("edit-asset-remove-photo").value = "false";
	$j("#txtEditAssetOdometerOffsetUnits").text(distanceText());

	if (!user.limitAssetEditing) {
		if (asset != null) {
			// get asset's latest status
			$j("#UpdateAssetStatus", dialog).prop("disabled", false);
			$("#RefreshAssetStatus,#ResetAssetStatus", dialog).removeClass("disabled").prop("disabled", false);
			loadAssetStatus(asset);
			loadAssetAlerts(asset);
			loadAssetAdditional(asset);
			//loadAssetDrivers(asset);

			// query for last contact/ip information
			requestLastContact(asset.Id);

			id = asset.Id;
			var device = findDeviceById(asset.DeviceId);
			$j("#hfEditAssetId").val(id);
			// populate existing values
			$j("#txtEditAssetName").val(asset.Name);
			$j("#txtEditAssetUniqueId").val(asset.UniqueId);
			$j("#txtEditAssetSecondaryUniqueId").val(asset.SecondaryUniqueId);
			$j("#txtEditAssetSensitivity").val(asset.Sensitivity);
			// disallow device selection, output chosen device instead
			$j("#ddlEditAssetDevice").val(asset.DeviceId);

			if (options.hideDeviceName) {
				$j("#EditAssetDevice").parent().hide();
				$j("label[for=ddlEditAssetDevice]", dialog).parent().hide();
			} else {
				$j("#EditAssetDevice")
					.val(device.Manufacturer + ": " + device.Name)
					.show();
			}
			$j("#ddlEditAssetDevice,#ddlEditAssetDeviceManufacturer").hide();

			$j('input[name=rbEditAssetColor][value="' + asset.Color + '"]:radio', dialog[0]).prop("checked", true);
			var iconChosen = $j('input[name=rbEditAssetIcon][value="' + asset.Class + '"]:radio', dialog[0]).prop(
				"checked",
				true
			);
			var chosenSet = iconChosen.parent().parent()[0].getAttribute("data-icon-set");
			if (chosenSet === null) {
				chosenSet = options.defaultIconSet;
			}
			$("#EditAssetIconSet").val(chosenSet);
			$(".icon-set", dialog[0]).removeClass("is-visible");
			$("#icon-set-" + chosenSet).addClass("is-visible");
			if (asset.Class == "Upload") {
				$j("#edit-asset-icon img").attr("src", "/markers/upload?aid=" + asset.Id + "&lm=" + asset.IconModified);
				$j("#edit-asset-icon").show();
				$("#edit-asset-icon-tab-upload").tab("show");
			} else {
				$("#edit-asset-icon-tab-system").tab("show");
				// icon set
			}
			var icons = $("input[name=rbEditAssetIcon]", dialog[0]);
			_.each(icons, function (icon) {
				var iconImage = $(icon)
					.next()
					.children("img")
					.attr("src", "/markers/" + icon.value + "?color=" + asset.Color);
			});

			// attribute fields
			if (asset.Attributes != null) {
				for (var i = 0; i < asset.Attributes.length; i++) {
					var attr = asset.Attributes[i];
					$j("#EditAssetAttribute" + attr.Id).val(attr.Value);
					// todo: special case for file/photo upload?
				}
			}

			// extraneous fields
			$j("#txtEditAssetIMEI").val(asset.IMEI);
			$j("#txtEditAssetSerialNumber").val(asset.SerialNumber);
			$j("#txtEditAssetInmarsatCSerialNumber").val(asset.SerialNumber);
			$j("#txtEditAssetSIMICCID").val(asset.SIMICCID);
			$j("#txtEditAssetSIMPhoneNumber").val(asset.SIMPhoneNumber);
			$j("#txtEditAssetSIMPIN").val(asset.SIMPIN);
			$j("#txtEditAssetSIMPINUnlock").val(asset.SIMPINUnlock);
			$j("#txtEditAssetSIMProvider").val(asset.SIMProvider);
			$j("#txtEditAssetMission").val(asset.Mission);
			$j("#txtEditAssetDriver").val(asset.Driver);
			$j("#txtEditAssetPhoneNumber").val(asset.PhoneNumber);
			$j("#txtEditAssetPlateNumber").val(asset.PlateNumber);
			$j("#txtEditAssetLicenseNumber").val(asset.LicenseNumber);
			$j("#txtEditAssetNationalIdentityCardNumber").val(asset.NationalIdentityCardNumber);
			$j("#txtEditAssetNotes").val(asset.Notes);
			$j("#txtEditAssetSoftwareVersion").val(asset.SoftwareVersion);
			$j("#txtEditAssetVehicleMakeAndModel").val(asset.VehicleMakeAndModel);
			$j("#txtEditAssetVehiclePurchaseDate").val(asset.VehiclePurchaseDate);
			$j("#txtEditAssetVehicleVIN").val(asset.VehicleVIN);
			$j("#txtEditAssetVesselName").val(asset.VesselName);
			$j("#txtEditAssetVesselCallSign").val(asset.VesselCallSign);
			$j("#txtEditAssetVesselIMONumber").val(asset.VesselIMONumber);
			$j("#txtEditAssetVesselFlagRegistry").val(asset.VesselFlagRegistry);
			$j("#txtEditAssetVesselTonnage").val(asset.VesselTonnage);
			$j("#txtEditAssetVesselClass").val(asset.VesselClass);
			$j("#txtEditAssetVesselSkipper").val(asset.VesselSkipper);
			$j("#txtEditAssetVesselMMSI").val(asset.VesselMMSI);
			$j("#txtEditAssetCustomMessage").val(asset.CustomMessage);
			if (asset.ContactIds != null) {
				for (var i = 0; i < asset.ContactIds.length; i++) {
					var contactId = asset.ContactIds[i];
					$j("#EditAssetContactIds" + contactId).prop("checked", true);
				}
			}
			if (asset.FuelEfficiency != "") {
				// convert from L/100 km to user preference
				$j("#txtEditAssetFuelEfficiency").val(
					convertFuelEfficiencyToStandard(asset.FuelEfficiency, preferences.PREFERENCE_FUEL_UNIT)
				);
			}
			$j(
				'input[name=rbDrawLinesBetweenPositions][value="' + asset.DrawLinesBetweenPositions + '"]:radio',
				dialog[0]
			).prop("checked", true);
			$j('input[name=rbSnapLinesToRoads][value="' + asset.SnapLinesToRoads + '"]:radio', dialog[0]).prop(
				"checked",
				true
			);
			$j('input[name=rbMicroUseCorrectedCourse][value="' + asset.MicroUseCorrectedCourse + '"]:radio', dialog[0]).prop(
				"checked",
				true
			);
			$j('input[name=rbSuppressEmergency][value="' + asset.SuppressEmergency + '"]:radio', dialog[0]).prop(
				"checked",
				true
			);
			$j(
				'input[name=rbPrioritizeIncomingMessages][value="' + asset.PrioritizeIncomingMessages + '"]:radio',
				dialog[0]
			).prop("checked", true);
			$j('input[name=rbEditAssetIsOutOfService][value="' + asset.IsOutOfService + '"]:radio', dialog[0]).prop(
				"checked",
				true
			);
			$j("#chkHideInformationAltitude", dialog[0]).prop("checked", asset.HideAltitude);
			$j("#chkHideInformationAddress", dialog[0]).prop("checked", asset.HideAddress);
			$j("#chkHideInformationCourse", dialog[0]).prop("checked", asset.HideCourse);
			$j("#chkHideInformationFlags", dialog[0]).prop("checked", asset.HideFlags);
			$j("#chkHideInformationSpeed", dialog[0]).prop("checked", asset.HideSpeed);
			$j("#chkHideInformationAccuracy", dialog[0]).prop("checked", asset.HideAccuracy);
			$j("#txtEditAssetDestinationId").val(asset.DestinationId);
			var elemOdometerRaw = document.getElementById("txtEditAssetOdometerRaw");
			elemOdometerRaw.value = asset.OdometerRaw == null ? strings.NEVER : globalizeNumberFormat(distanceValueFromMetersToUserPreference(asset.OdometerRaw), "n0");
			var elemOdometerOffset = document.getElementById("txtEditAssetOdometerOffset");
			elemOdometerOffset.value = globalizeNumberFormat(distanceValueFromMetersToUserPreference(asset.OdometerOffset), "n0");
			elemOdometerOffset.setAttribute("data-metric-value", asset.OdometerOffset);
			elemOdometerOffset.setAttribute("data-original-value", elemOdometerOffset.value);
			$(elemOdometerOffset).trigger("input");
			$j("#EditAssetAllowGroupLocationSharing").prop("checked", asset.IsGroupLocationSharingEnabled);
			$j("#txtEditAssetAnalogTolerance").val(asset.AnalogTolerance);
			$j("#txtEditAssetReceiveKey").val(asset.ReceiveEncryptionKey); // either empty string or placeholder (acts like a bit field in the DTO)
			$j("#txtEditAssetTransmitKey").val(asset.TransmitEncryptionKey); // either empty string or placeholder (acts like a bit field in the DTO)
			$j("#txtEditAssetDevicePwrd").val(asset.Password); // either empty string or placeholder (acts like a bit field in the DTO)
			$j("#txtEditAssetMobilePwrd").val(asset.MobilePassword); // either empty string or placeholder (acts like a bit field in the DTO)
			$j("#txtEditAssetMobileUnme").val(asset.MobileUsername);
			if (asset.DisplaySRID != "") {
				$j("#ddlAssetSRID").val(asset.DisplaySRID);
			}
			if (asset.CannedMessageGroupId != "") {
				$j("#ddlEditAssetCannedMessageGroup").val(asset.CannedMessageGroupId);
			}
			if (asset.AddressBookGroupId != "") {
				$j("#ddlEditAssetAddressBookGroup").val(asset.AddressBookGroupId);
			}
			if (asset.GatewayAccountId != "") {
				$j("#ddlEditAssetGatewayAccount").val(asset.GatewayAccountId);
			}
			if (asset.SecondaryGatewayAccountId != "") {
				$j("#ddlEditAssetSecondaryGatewayAccount").val(asset.SecondaryGatewayAccountId);
			}
			if (asset.DriverStatusTemplateId != "") {
				$j("#ddlEditAssetDriverStatusTemplate").val(asset.DriverStatusTemplateId);
			}
			$j("input[name=EditAssetDriverDeviceType]", dialog).prop("checked", false);
			if (asset.DriverDeviceType != null) {
				if ((asset.DriverDeviceType & 1) == 1) {
					$j("#EditAssetDriverDeviceTypeGarmin").prop("checked", true);
				}
				if ((asset.DriverDeviceType & 2) == 2) {
					$j("#EditAssetDriverDeviceTypeIButton").prop("checked", true);
				}
			}
			$j("#ddlEditAssetDriverStatusTemplate").val(asset.DriverStatusTemplateId);
			if (asset.QuickMessageFromMobileTemplateId != "") {
				$j("#ddlEditAssetQuickMessageFromMobileTemplate").val(asset.QuickMessageFromMobileTemplateId);
			}
			if (asset.QuickMessageToMobileTemplateId != "") {
				$j("#ddlEditAssetQuickMessageToMobileTemplate").val(asset.QuickMessageToMobileTemplateId);
			}
			$j("#ddlEditAssetConfiguration").val(asset.Configuration);

			// show photo
			if (asset.PhotoType != null && asset.PhotoType != "") {
				$j("#edit-asset-photo img").attr(
					"src",
					"/uploads/images/assets/" + asset.Id + "_thumb." + asset.PhotoType + "?rnd=" + new Date().getTime()
				);
				$("#edit-asset-photo a").attr("href", "/uploads/images/assets/" + asset.Id + "." + asset.PhotoType);
				$j("#edit-asset-photo").show();
			}

			if (asset.InmarsatC != null) {
				$j("#txtEditAssetInmarsatCDNID").val(asset.InmarsatC.DNID).prop("disabled", true);
				$j("#txtEditAssetInmarsatCMemberNumber").val(asset.InmarsatC.MemberNumber).prop("disabled", true);
				$j("#txtEditAssetInmarsatCSubaddress").val(asset.InmarsatC.Subaddress);
			}
		} else {
			$j("#txtEditAssetInmarsatCDNID,#txtEditAssetInmarsatCMemberNumber").prop("disabled", false);
			$j("#AssetAlerts").DataTable().clear();

			$j("#txtEditAssetUniqueId").val("");
			$j("#txtEditAssetSecondaryUniqueId,#txtEditAssetInmarsatCSerialNumber,#txtEditAssetInmarsatCSubaddress").val("");
			$j("#UpdateAssetStatus").prop("disabled", true);
			$("#RefreshAssetStatus,#ResetAssetStatus").addClass("disabled").prop("disabled", true);

			$j("#EditAssetDevice").hide();
			$j("#ddlEditAssetDevice,#ddlEditAssetDeviceManufacturer").show();

			$j("#txtEditAssetSensitivity").val("50");
			$j("#txtEditAssetAnalogTolerance").val("0" + options.decimalSeparator + "3");
			$j('input[name=rbDrawLinesBetweenPositions][value="true"]:radio', dialog[0]).prop("checked", true); // default to draw lines
			$j('input[name=rbSnapLinesToRoads][value="false"]:radio', dialog[0]).prop("checked", true); // default to not snap
			$j('input[name=rbMicroUseCorrectedCourse][value="false"]:radio', dialog[0]).prop("checked", true); // default to not use
			$j('input[name=rbSuppressEmergency][value="false"]:radio', dialog[0]).prop("checked", true); // default to not use
			$j('input[name=rbPrioritizeIncomingMessages][value="true"]:radio', dialog[0]).prop("checked", true); // default to prioritize
			$j('input[name=rbEditAssetIsOutOfService][value="false"]:radio', dialog[0]).prop("checked", true); // default to in service
			$j("#EditAssetAllowGroupLocationSharing").prop("checked", true);
			// choose default icon and color
			$j('input[name=rbEditAssetIcon][value="Default_Generic"]:radio', dialog[0]).prop("checked", true);
			$("#edit-asset-icon-tab-system").tab("show");
			$("#EditAssetIconSet").val(options.defaultIconSet);
			$('input[name=rbEditAssetColor][value="blue"]:radio', dialog[0]).prop("checked", true);
			$(".icon-set", dialog[0]).removeClass("is-visible");
			$("#icon-set-" + options.defaultIconSet).addClass("is-visible");
			
			$j("#txtEditAssetOdometerOffset").val(0);

			var icons = $("input[name=rbEditAssetIcon]", dialog[0]);
			_.each(icons, function (icon) {
				var iconImage = $(icon)
					.next()
					.children("img")
					.attr("src", "/markers/" + icon.value + "?color=blue");
			});
		}

		// necessary? non-admins cannot edit the config of an asset anyways
		if (user.isAdmin) {
			$j("#EditAssetSIMFields").show();
		} else {
			$j("#EditAssetSIMFields").hide();
		}

		$j('input[name=rbEditAssetFuelEfficiency][value="' + preferences.PREFERENCE_FUEL_UNIT + '"]:radio', dialog[0]).prop(
			"checked",
			true
		);

		// deal with groups that have parents
		// create a globalGroupHierarchy that is sorted properly?

		// populate groups, along with selecting groups this asset currently belongs to
		var cont = $j("#edit-asset-groups-container");
		var groupContainer = document.getElementById("edit-asset-groups-container");
		cont.empty();
		var sortedGroups = sortAssetGroups();
		for (var i = 0; i < sortedGroups.length; i++) {
			var group = sortedGroups[i];
			addGroupToGroupListInItemEditDialog(group, 0, groupContainer, asset);
		}

		var userList = user.isAdmin ? trkData.users : [];

		populateCheckboxList(
			"edit-asset-users-list",
			userList,
			"EditAssetUserIds",
			function (item) {
				if (asset === null) {
					return false;
				}
				var assetUsers = _.find(trkData.assetUsers, { AssetId: asset.Id });
				if (assetUsers === undefined) {
					return false;
				}
				return _.indexOf(assetUsers.UserIds, item.Id) !== -1;
			},
			function (item) {
				return item.Name;
			},
			"users",
			function (item) {
				return item.Username;
			}
		);

		var garminForms = [];
		$("#garmin-forms option").each(function (index, elem) {
			garminForms.push({
				Id: parseInt(this.value),
				Title: this.innerText,
			});
		});
		var garminFormsList = user.isAdmin ? garminForms : [];
		populateCheckboxList(
			"edit-asset-garmin-list",
			garminFormsList,
			"EditAssetGarminIds",
			function (item) {
				if (asset === null) {
					return false;
				}
				var assetForms = _.find(trkData.assetForms, { AssetId: asset.Id });
				if (assetForms === undefined) {
					return false;
				}
				return _.indexOf(assetForms.FormIds, item.Id) !== -1;
			},
			function (item) {
				return item.Title;
			},
			"forms"
		);

		// populate checkboxes and update the list
		// only text-messagable assets

		var assetAssets = _.filter(trkData.assets, function (item) {
			return asset !== null && isMessagingEnabledForAsset(item) && asset.Id !== item.Id;
		});
		populateCheckboxList(
			"edit-asset-assets-list",
			assetAssets,
			"EditAssetAssetIds",
			function (item) {
				if (asset === null) {
					return false;
				}
				return _.indexOf(asset.AssetIds, item.Id) !== -1;
			},
			function (item) {
				return item.Name;
			},
			"assets",
			function (item) {
				return item.UniqueId;
			}
		);

		// driver groups
		var cont = $j("#edit-asset-driver-groups-container");
		var groupContainer = document.getElementById("edit-asset-driver-groups-container");
		cont.empty();
		var sortedGroups = sortItemGroups(trkData.driverGroups);

		for (var i = 0; i < sortedGroups.length; i++) {
			var group = sortedGroups[i];
			var assetDriverGroups = [];
			if (asset !== null && asset !== undefined) {
				var assignedGroups = _.find(trkData.assetDriverGroups, { AssetId: asset.Id });
				if (assignedGroups !== undefined) {
					assetDriverGroups = assignedGroups.DriverGroupIds;
				}
			}
			addItemGroupToItemGroupListInAssetEditDialog(group, 0, groupContainer, assetDriverGroups);
		}

		populateCheckboxList(
			"edit-asset-drivers-list",
			trkData.drivers,
			"EditAssetDriverIds",
			function (item) {
				if (asset === null) {
					return false;
				}
				var assetDrivers = _.find(trkData.assetDrivers, { AssetId: asset.Id });
				if (assetDrivers === undefined) {
					return false;
				}
				return _.indexOf(assetDrivers.DriverIds, item.Id) !== -1 || _.indexOf(assetDrivers.GroupDriverIds, item.Id) !== -1;
			},
			function (item) {
				return item.DriverId;
			},
			"drivers",
			getDescriptionForDriver,
			getGroupNamesForDriver
		);

		if (asset != null) {
			// disable any drivers that are included in assigned groups to the asset
			// so that it is clear which drivers are assigned via group selection
			// and that the group needs to be toggled to enable
			var driverList = document.getElementById("edit-asset-drivers-list");
			var assetDriverGroups = _.find(trkData.assetDriverGroups, { AssetId: asset.Id });
			if (assetDriverGroups !== undefined) {
				_.each(assetDriverGroups.DriverGroupIds, function (groupId) {
					_.each(trkData.driverGroupsById[groupId].ItemIds, function (driverId) {
						var driverItem = driverList.querySelector('input[type="checkbox"][value="' + driverId + '"]');
						if (driverItem !== null) {
							driverItem.setAttribute("disabled", true);
							$(driverItem).trigger("change");
						}
					});
				});
			}
		}

		// select main tab
		$("#edit-asset-tab-main").tab("show");

		// clear file selection - todo: technically should be hidden unless it exists (otherwise triggers a 404)
		$j("#fileEditAssetPhoto").val("").next(".custom-file-label").text(strings.NO_FILE_SELECTED);

		deviceDialogChange($j("#ddlEditAssetDevice"), asset);
		if (asset != null) {
			// iridium extreme
			if (asset.DeviceId == devices.IRIDIUM_EXTREME) {
				$j("#txtEditAssetIMEIVerificationCode").val(hash1(asset.UniqueId));
				$j("#txtEditAssetPortalRegistrationCode").val(hash2(asset.UniqueId));
			}
		}
		if (asset != null) {
			// why did i move this to after the dialog change?... probably because of the input mask issue
			$j("#txtEditAssetUniqueId").val(asset.UniqueId);
			$j("#txtEditAssetSecondaryUniqueId").val(asset.SecondaryUniqueId);
		}
		$j("#txtEditAssetNotes").maxChar("init", 250, { indicator: "txtEditAssetNotesIndicator" });
	} else {
		if (asset != null) {
			id = asset.Id;
			$j("#hfEditAssetId").val(id);
			// populate existing values
			$j("#txtEditAssetName").val(asset.Name);
		}
	}

	//openDialogPanel()
	openDialogPanel(
		domNodes.dialogs.editAsset,
		dialogTitle,
		asset,
		true,
		null,
		"asset",
		asset !== null ? "edit-asset" : null,
		asset !== null ? openEditAssetDialog : null
	);
	//setDialogTitle(dialog, dialogTitle);
	//$j(dialog).dialog('open');
	document.getElementById("txtEditAssetName").focus();

	var selector = dialog.parent().find("div.asset-selector"); // unused?
	if (asset != null) {
		// todo: only for idp assets
		if (!user.limitAssetEditing) {
			loadIDPAssetIOConfiguration(asset);
			loadIDPAVLAssetIOConfiguration(asset);
			loadIDPARCAssetIOConfiguration(asset);
		}
		dialog.data("assetId", asset.Id);
		selector.show();
	} else {
		selector.hide();
	}
}

function loadAssetAdditional(asset) {
	if (asset == null) return;
	var device = findDeviceById(asset.DeviceId);
	if (!device.RequiresPollingInterval) return;
	toggleLoadingMessage(true, "asset-additional");
	var data = {
		assetId: asset.Id,
	};
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetAssetAdditional"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			if (msg.d) {
				var result = msg.d;
				if (result.Success == true) {
					if (result.PollingInterval != "") {
						var days = result.PollingInterval / 86400;
						var hours = result.PollingInterval / 3600;
						var minutes = result.PollingInterval / 60;
						if (days >= 1 && result.PollingInterval % 86400 == 0) {
							$j("#txtEditAssetPollingInterval").val(days);
							$j("#ddlEditAssetPollingInterval").val("d");
						} else if (hours >= 1 && result.PollingInterval % 3600 == 0) {
							$j("#txtEditAssetPollingInterval").val(hours);
							$j("#ddlEditAssetPollingInterval").val("h");
						} else if (minutes >= 1 && result.PollingInterval % 60 == 0) {
							$j("#txtEditAssetPollingInterval").val(minutes);
							$j("#ddlEditAssetPollingInterval").val("m");
						} else {
							$j("#txtEditAssetPollingInterval").val(result.PollingInterval);
							$j("#ddlEditAssetPollingInterval").val("s");
						}
					}
				}
			}
			toggleLoadingMessage(false, "asset-additional");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_GET_STATUS_ERROR);
			toggleLoadingMessage(false, "asset-additional");
			// btn.prop("disabled", false);
		},
	});
}

function requestLastContact(assetId) {
	var data = {
		assetId: assetId,
	};
	var status = document.getElementById("edit-asset-status");
	toggleLoadingMessage(true, "asset-contact");
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetLastContact"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			var result = msg.d;
			if (result) {
				if (result.Success == true) {
					$j("#AssetContact").show();
					if (result.ContactedOn != null) {
						$j("#AssetLastContact").text(result.ContactedOn);
					} else {
						$j("#AssetLastContact").text(strings.NEVER);
					}
					if (result.IPAddress != null) {
						$j("#AssetLastIP")
							.show()
							.text("(" + result.IPAddress + ")");
					} else {
						$j("#AssetLastIP").hide();
					}
				} else {
					// message failure, keep text field to allow retry
					formShowErrorMessage(status, strings.MSG_LAST_CONTACT_ERROR);
					if (result.ErrorMessage != null && result.ErrorMessage != "") {
						formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
					}
				}
			}
			toggleLoadingMessage(false, "asset-contact");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_LAST_CONTACT_ERROR);
			toggleLoadingMessage(false, "asset-contact");
		},
	});
}
