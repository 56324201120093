import strings from "./strings.js";
import trkData from "./data.js";
import domNodes from "./domNodes.js";
import state from "./state.js";
import user from "./user.js";
import { handleWebServiceError } from "./ajax.js";
import { formShowSuccessMessage, formShowErrorMessage } from "./ajax.js";
import { findAssetById } from "./assets.js";
import { viewModes, sortModes } from "./const.js";
import { isItemIncluded } from "./polyfills.js";
import { devices } from "./devices.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { loadDialogButtons } from "./modal-dialog-buttons.js";
import { convertHexToSortable, convertNamedColorToHex } from "./color.js";
import { getAttributesForType } from "./attributes.js";
import { uploadFile } from "./ajax.js";
import { closeSecondaryPanel } from "./panel.js";
import { findDeviceById } from "./devices.js";
import { toggleItemSorting } from "./item-sorting.js";
import { toggleAssetActive, updateAssetListingIcon } from "./asset-select.js";
import {
	addDriverGroupToAsset,
	findAssetDriversByAssetId,
	findAssetDriverGroupIdsByAssetId,
	addDriverToAsset,
	removeDriverFromAsset,
	removeDriverGroupFromAsset,
} from "./driver.js";
import { openEditAssetDialog } from "./asset-edit-dialog.js";
import { convertFuelEfficiencyToStandard, distanceValueFromUserPreferenceToMeters } from "./preferences.js";
import { changeMarkerIcon } from "./marker-click.js";
import { addAssetToGroup, removeAssetFromGroup, indexCustomAttributes } from "./asset-group.js";
import { createMarkerPath } from "./marker-path.js";
import { removeItemFromMap } from "./map-items.js";
import log from "./log.js";
import { throttles } from "./timers.js";
import { querySingleAsset } from "./asset-select.js";
import { updateActiveAssetInformation } from "./assets-active.js";
import { updateAssetPositionLines } from "./asset-positions.js";
import { findDriverById } from "./driver.js";

/*global JsSearch */
// import JsSearch from '../js-search.js';

import _ from "lodash";
import $ from "jquery";
import $j from "jquery";

export function initAssetEdit() {
	// Most data for the dialog buttons is static, but the text strings are
	// initialized late (because they're async, because they depend on user
	// settings which are `fetch()`ed late). They need to be refreshed prior to
	// the dialogs being defined.
	editAssetButtons[0].text = strings.SAVE_CHANGES;
	editAssetButtons[1].text = strings.CANCEL;

	loadDialogButtons(domNodes.dialogs.editAsset, editAssetButtons);

	$('#edit-asset-driver-groups-container').on("change", "input[name=EditAssetDriverGroupIds]", function (e) {
		var isChecked = $(this).prop("checked");
		// check and disable drivers in the listing that are members of this group

		var driverGroupId = $(this).val();
		var driverGroup = trkData.driverGroupsById[driverGroupId];
		var driverList = document.getElementById("edit-asset-drivers-list");

		if (isChecked) {
			// check and disable drivers in this group
			_.each(driverGroup.ItemIds, function (driverId) {
				var driverItem = driverList.querySelector('input[type="checkbox"][value="' + driverId + '"]');
				if (driverItem !== null) {
					driverItem.disabled = true;
					driverItem.checked = true;
					$(driverItem).trigger("change");
				}
			});
		} else {
			// enable and uncheck (if not also individually assigned to the currently edited asset (if any))
			// unless they're in another group assigned to the asset
			var assetDriverGroupIds = _.map(document.querySelectorAll("input[name=EditAssetDriverGroupIds]:checked"), function (item) { return item.value });
			var assetDriverGroupDriverIds = _.flatten(_.map(assetDriverGroupIds, function (groupId) { return trkData.driverGroupsById[groupId].ItemIds; }));
			var assetDriverIds = [];
			if (!state.isAddingAsset) {
				var asset = findAssetById($j("#hfEditAssetId").val());
				if (asset !== null) {
					var assetDrivers = _.find(trkData.assetDrivers, { AssetId: asset.Id });
					if (assetDrivers !== undefined) {
						assetDriverIds = assetDrivers.DriverIds;
					}
				}
			}
			_.each(driverGroup.ItemIds, function (driverId) {
				var driverItem = driverList.querySelector('input[type="checkbox"][value="' + driverId + '"]');
				if (driverItem !== null) {
					var isDriverAssignedByGroup = _.indexOf(assetDriverGroupDriverIds, driverId) !== -1;
					driverItem.disabled = isDriverAssignedByGroup;
					driverItem.checked = isDriverAssignedByGroup || _.indexOf(assetDriverIds, driverId) !== -1;
					$(driverItem).trigger("change");
				}
			});
		}
	});
}

// edit asset dialog
const editAssetButtons = [
	{
		id: "EditAssetSave",
		icons: { primary: "ui-icon-disk" },
		text: strings.SAVE_CHANGES,
		buttonType: "primary",
		click: function () {
			var isFormValid = $(trkData.validation.editAsset.currentForm).valid();
			if (!isFormValid) {
				trkData.validation.editAsset.focusInvalid();
				return;
			}
			var btn = this;
			btn.disabled = true;
			var dialog = $j("#edit-asset-dialog");
			var id = $j("#hfEditAssetId").val();
			var status = document.getElementById("edit-asset-status");
			toggleLoadingMessage(true, "edit-asset");
			var name = $j("#txtEditAssetName").val();
			var removePhoto = false;
			if (!user.limitAssetEditing) {
				var uniqueId = $j("#txtEditAssetUniqueId").val();
				var secondaryUniqueId = $j("#txtEditAssetSecondaryUniqueId").val();
				var deviceId;
				if (state.isAddingAsset) {
					deviceId = $j("#ddlEditAssetDevice").val();
				} else {
					var asset = findAssetById(id);
					if (asset != null) {
						deviceId = asset.DeviceId;
					}
				}
				var color = $j("input[name=rbEditAssetColor]:checked", dialog[0]).val();
				var sensitivity = $j("#txtEditAssetSensitivity").val();
				var type = $j("input[name=rbEditAssetIcon]:checked", dialog[0]).val();
				if (type == null) {
					type = "Upload";
				}

				var inmarsatc = {
					DNID: $j("#txtEditAssetInmarsatCDNID").val(),
					MemberNumber: $j("#txtEditAssetInmarsatCMemberNumber").val(),
				};
				if ($j("#rbInmarsatCActivateTrue").prop("checked")) {
					inmarsatc = null;
				}

				// extra fields
				var driver = $j("#txtEditAssetDriver").val();
				var mission = $j("#txtEditAssetMission").val();
				if (mission === undefined) {
					mission = null;
				}
				var phoneNumber = $j("#txtEditAssetPhoneNumber").val();
				if (phoneNumber === undefined) {
					phoneNumber = null;
				}
				var plateNumber = $j("#txtEditAssetPlateNumber").val();
				if (plateNumber === undefined) {
					plateNumber = null;
				}
				var licenseNumber = $j("#txtEditAssetLicenseNumber").val();
				if (licenseNumber === undefined) {
					licenseNumber = null;
				}
				var nationalIdentityCardNumber = $j("#txtEditAssetNationalIdentityCardNumber").val();
				if (nationalIdentityCardNumber === undefined) {
					nationalIdentityCardNumber = null;
				}
				var softwareVersion = $j("#txtEditAssetSoftwareVersion").val();
				var notes = $j("#txtEditAssetNotes").val();
				var vehicleMakeAndModel = $j("#txtEditAssetVehicleMakeAndModel").val();
				var vehiclePurchaseDate = $j("#txtEditAssetVehiclePurchaseDate").val();
				var vehicleVIN = $j("#txtEditAssetVehicleVIN").val();
				var fuelEfficiency = $j("#txtEditAssetFuelEfficiency").val();
				var fuelEfficiencyType = $j("input[name=rbEditAssetFuelEfficiency]:checked", dialog[0]).val();
				var drawLines = $j("input[name=rbDrawLinesBetweenPositions]:checked", dialog[0]).val();
				drawLines = drawLines === "true"; // string to bool
				var snapLinesToRoads = $j("input[name=rbSnapLinesToRoads]:checked", dialog[0]).val();
				snapLinesToRoads = snapLinesToRoads === "true"; // string to bool
				if (!drawLines) {
					snapLinesToRoads = false;
				}
				var microUseCorrectedCourse = $j("input[name=rbMicroUseCorrectedCourse]:checked", dialog[0]).val();
				microUseCorrectedCourse = microUseCorrectedCourse === "true"; // string to bool
				var suppressEmergency = $j("input[name=rbSuppressEmergency]:checked", dialog[0]).val();
				suppressEmergency = suppressEmergency === "true"; // string to bool
				var prioritizeIncomingMessages = $j("input[name=rbPrioritizeIncomingMessages]:checked", dialog[0]).val();
				prioritizeIncomingMessages = prioritizeIncomingMessages === "true";
				var destinationId = $j("#txtEditAssetDestinationId").val();
				var elemOdometerOffset = document.getElementById("txtEditAssetOdometerOffset");
				var odometerOffset = parseInt(elemOdometerOffset.getAttribute("data-metric-value"));
				if (elemOdometerOffset.hasAttribute("data-updated")) {
					// convert from globalized user distance preference to metric
					var odometerOffset = $j.parseFloat(elemOdometerOffset.value);
					if (Number.isFinite(odometerOffset)) {
						odometerOffset = distanceValueFromUserPreferenceToMeters(odometerOffset);
					} else {
						odometerOffset = 0;
					}
				}
				var isGroupLocationSharingEnabled = $j("#EditAssetAllowGroupLocationSharing").prop("checked");
				var receiveKey = $j("#txtEditAssetReceiveKey").val();
				var transmitKey = $j("#txtEditAssetTransmitKey").val();
				var analogTolerance = $j("#txtEditAssetAnalogTolerance").val();
				var srid = $j("#ddlAssetSRID").val();
				var cannedMessageGroupId = $j("#ddlEditAssetCannedMessageGroup").val();
				var addressBookGroupId = $j("#ddlEditAssetAddressBookGroup").val();
				var driverStatusTemplateId = $j("#ddlEditAssetDriverStatusTemplate").val();
				var quickMessageFromMobileTemplateId = $j("#ddlEditAssetQuickMessageFromMobileTemplate").val();
				var quickMessageToMobileTemplateId = $j("#ddlEditAssetQuickMessageToMobileTemplate").val();
				var ioTemplateId = $j("#EditAssetIOTemplate").val();
				var gatewayAccountId = $j("#ddlEditAssetGatewayAccount").val();
				var secondaryGatewayAccountId = $("#ddlEditAssetSecondaryGatewayAccount").val();
				var pollingInterval = $j("#txtEditAssetPollingInterval").val();
				var pollingIntervalType = $j("#ddlEditAssetPollingInterval").val();
				if (pollingInterval != "") {
					switch (pollingIntervalType) {
						case "d":
							pollingInterval *= 86400;
							break;
						case "h":
							pollingInterval *= 3600;
							break;
						case "m":
							pollingInterval *= 60;
							break;
						default:
							break;
					}
				}

				var idpGatewayBehavior = $j("input[name=EditAssetIDPGatewayBehavior]:checked", dialog[0]).val();
				var idpGatewayTimeout = $j("#EditAssetIDPGatewayBehaviorTimeout").val();
				var idpGatewayRetries = $j("#EditAssetIDPGatewayBehaviorRetries").val();
				if (idpGatewayBehavior == 3) {
					idpGatewayRetries = $j("#EditAssetIDPGatewayBehaviorCellRetries").val();
				}
				var configuration = $j("#ddlEditAssetConfiguration").val();
				var softwarePackageId = $j("#ddlEditAssetSoftwarePackage").val();
				var imei = $j("#txtEditAssetIMEI").val();
				var serialNumber = $j("#txtEditAssetSerialNumber").val();
				var inmarsatSerialNumber = $j("#txtEditAssetInmarsatCSerialNumber").val();
				var inmarsatCSubaddress = $j("#txtEditAssetInmarsatCSubaddress").val();
				if ($j.inArray(deviceId, devices.INMARSAT_C) !== -1 && inmarsatSerialNumber != "") {
					// use for both
					serialNumber = inmarsatSerialNumber;
				}
				var simPINUnlock = $j("#txtEditAssetSIMPINUnlock").val();
				var simICCID = $j("#txtEditAssetSIMICCID").val();
				var simPhoneNumber = $j("#txtEditAssetSIMPhoneNumber").val();
				var simPIN = $j("#txtEditAssetSIMPIN").val();
				var simProvider = $j("#txtEditAssetSIMProvider").val();
				var vesselName = $j("#txtEditAssetVesselName").val();
				var vesselCallSign = $j("#txtEditAssetVesselCallSign").val();
				var vesselFlagRegistry = $j("#txtEditAssetVesselFlagRegistry").val();
				var vesselIMONumber = $j("#txtEditAssetVesselIMONumber").val();
				var vesselTonnage = $j("#txtEditAssetVesselTonnage").val();
				var vesselClass = $j("#txtEditAssetVesselClass").val();
				var vesselSkipper = $j("#txtEditAssetVesselSkipper").val();
				var vesselMMSI = $j("#txtEditAssetVesselMMSI").val();
				var customMessage = $j("#txtEditAssetCustomMessage").val();
				var contactIds = new Array();
				$j("input[name=EditAssetContactIds]:checked", dialog[0]).each(function (index, elem) {
					contactIds.push($j(this).val());
				});

				var isOutOfService = $j("input[name=rbEditAssetIsOutOfService]:checked", dialog[0]).val();
				isOutOfService = isOutOfService === "true"; // string to bool

				var hideAltitude = $j("#chkHideInformationAltitude").prop("checked");
				var hideAddress = $j("#chkHideInformationAddress").prop("checked");
				var hideCourse = $j("#chkHideInformationCourse").prop("checked");
				var hideSpeed = $j("#chkHideInformationSpeed").prop("checked");
				var hideAccuracy = $j("#chkHideInformationAccuracy").prop("checked");
				var hideFlags = $j("#chkHideInformationFlags").prop("checked");
				var sendAddressBookAndCannedMessagesOta = $j("#EditAssetSyncOTA").prop("checked");

				var password = $j("#txtEditAssetDevicePwrd").val();
				var mobileUsername = $j("#txtEditAssetMobileUnme").val();
				var mobilePassword = $j("#txtEditAssetMobilePwrd").val();
				var authCode = $j('#txtEditAssetSpotAuthCode').val();

				var groupIds = new Array();
				$j("input[name=EditGroupIds]:checked", dialog[0]).each(function (index, elem) {
					groupIds.push($j(this).val());
				});
				var assetIds = new Array();
				$j("input[name=EditAssetAssetIds]:checked", dialog[0]).each(function (index, elem) {
					assetIds.push(parseInt($j(this).val()));
				});
				var userIds = new Array();
				$j("input[name=EditAssetUserIds]:checked", dialog[0]).each(function (index, elem) {
					userIds.push($j(this).val());
				});
				var driverGroupIds = new Array();
				$j("input[name=EditAssetDriverGroupIds]:checked").each(function (index, elem) {
					driverGroupIds.push($j(this).val());
				});
				var driverIds = new Array();
				var driverGroupDriverIds = _.flatten(_.map(driverGroupIds, function (driverGroupId) { return trkData.driverGroupsById[driverGroupId].ItemIds }));
				$j("input[name=EditAssetDriverIds]:checked").each(function (index, elem) {
					// exclude driverids included in the groups
					var driverId = parseInt(this.value);
					if (_.indexOf(driverGroupDriverIds, driverId) === -1) {
						driverIds.push(driverId);
					}
				});

				var driverDeviceType = null;
				$j("input[name=EditAssetDriverDeviceType]:checked", dialog[0]).each(function (index, elem) {
					if (driverDeviceType == null) driverDeviceType = parseInt($j(this).val());
					else driverDeviceType += parseInt($j(this).val());
				});

				var garminFormIds = new Array();
				var formIds = new Array();
				$j("input[name=EditAssetGarminIds]:checked").each(function (index, elem) {
					garminFormIds.push({ id: $j(this).val(), position: index + 1 });
					formIds.push(parseInt($j(this).val()));
				});

				if ($("#edit-asset-remove-photo").val() === "true") {
					removePhoto = true;
				}

				var deviceLabel = findDeviceById(deviceId);
				var inputLabels = [];
				// normal skywave 6xx device would have 4 inputs, but in ARC configuration we need to apply 8
				//for (var i = 0; i < deviceLabel.InputPinCount; i++) {
				var num = 0;
				if (deviceLabel.InputConfigurationPinsDigital != null) {
					for (var i = 0; i < deviceLabel.InputConfigurationPinsDigital.length; i++) {
						num++;
						var label = {
							Number: num,
							Label: $j("#EditAssetInputLabel" + num).val(),
							InputPin: $j("#EditAssetInputLabelPin" + num).val(),
							OnLabel: $j("#EditAssetInputOnLabel" + num).val(),
							OnIsDisabled: !$j("#EditAssetInputOnEnabled" + num).prop("checked"),
							OffLabel: $j("#EditAssetInputOffLabel" + num).val(),
							OffIsDisabled: !$j("#EditAssetInputOffEnabled" + num).prop("checked"),
							PulseLabel: "",
							PulseIsDisabled: false,
							IsNormallyOpen: $j("#EditAssetInputLabelNormallyOpen" + num).prop("checked"),
							IsEmergency: $j("#EditAssetInputLabelEmergency" + num).prop("checked"),
							IsFuel: $j("#EditAssetInputLabelFuel" + num).prop("checked"),
							AnalogBehavior: $j("#EditAssetInputAnalogBehavior" + num).val(),
							AnalogUnit: $j("#EditAssetInputAnalogUnit" + num).val(),
							AnalogFactor: $j("#EditAssetInputAnalogFactor" + num).val(),
							MaxVoltage: $j("#EditAssetInputMaxVoltage" + num).val(),
							FuelCapacity: $j("#EditAssetInputFuelCapacity" + num).val(),
							WeightMax: $j("#EditAssetInputWeightMax" + num).val(),
						};
						inputLabels.push(label);
					}
				}
				if (deviceLabel.InputConfigurationPinsAnalog != null) {
					for (var i = 0; i < deviceLabel.InputConfigurationPinsAnalog.length; i++) {
						num++;
						var label = {
							Number: num,
							Label: $j("#EditAssetInputLabel" + num).val(),
							InputPin: $j("#EditAssetInputLabelPin" + num).val(),
							OnLabel: $j("#EditAssetInputOnLabel" + num).val(),
							OnIsDisabled: !$j("#EditAssetInputOnEnabled" + num).prop("checked"),
							OffLabel: $j("#EditAssetInputOffLabel" + num).val(),
							OffIsDisabled: !$j("#EditAssetInputOffEnabled" + num).prop("checked"),
							PulseLabel: "",
							PulseIsDisabled: false,
							IsNormallyOpen: $j("#EditAssetInputLabelNormallyOpen" + num).prop("checked"),
							IsEmergency: $j("#EditAssetInputLabelEmergency" + num).prop("checked"),
							IsFuel: $j("#EditAssetInputLabelFuel" + num).prop("checked"),
							AnalogBehavior: $j("#EditAssetInputAnalogBehavior" + num).val(),
							AnalogUnit: $j("#EditAssetInputAnalogUnit" + num).val(),
							AnalogFactor: $j("#EditAssetInputAnalogFactor" + num).val(),
							MaxVoltage: $j("#EditAssetInputMaxVoltage" + num).val(),
							FuelCapacity: $j("#EditAssetInputFuelCapacity" + num).val(),
							WeightMax: $j("#EditAssetInputWeightMax" + num).val(),
						};
						inputLabels.push(label);
					}
				}

				var outputLabels = [];
				for (var i = 0; i < deviceLabel.OutputPinCount; i++) {
					var num = i + 1;
					var label = {
						Number: num,
						Label: $j("#EditAssetOutputLabel" + num).val(),
						InputPin: "",
						OnLabel: $j("#EditAssetOnLabel" + num).val(),
						OnIsDisabled: !$j("#EditAssetOnEnabled" + num).prop("checked"),
						OffLabel: $j("#EditAssetOffLabel" + num).val(),
						OffIsDisabled: !$j("#EditAssetOffEnabled" + num).prop("checked"),
						PulseLabel: $j("#EditAssetPulseLabel" + num).val(),
						PulseIsDisabled: !$j("#EditAssetPulseEnabled" + num).prop("checked"),
					};
					outputLabels.push(label);
				}

				var sensorLabels = [];
				var sensorLabelsTrs = $j('#SensorLabels tbody tr');
				for (var i = 0; i < sensorLabelsTrs.length; i++) {
					var tr = sensorLabelsTrs[i];
					var sensorId = $j(tr).find('.sensor-input-sensor-id').val();
					var sensorLabel = $j(tr).find('.sensor-input-sensor-label').val();
					var label = {
						SensorId: sensorId,
						Label: sensorLabel,
					};
					sensorLabels.push(label);
				}

				var attributes = getAttributesForType(0, "EditAssetAttribute");
			}
			var dataPostName = {
				assetId: id,
				name: name,
			};
			var dataPost = {
				assetId: id,
				name: name,
				uniqueId: uniqueId,
				secondaryUniqueId: secondaryUniqueId,
				color: color,
				sensitivity: sensitivity,
				type: type,
				deviceId: deviceId,
				mission: mission === undefined ? null : mission,
				driver: driver === undefined ? null : driver,
				phoneNumber: phoneNumber === undefined ? null : phoneNumber,
				plateNumber: plateNumber === undefined ? null : plateNumber,
				groupIds: groupIds,
				userIds: userIds,
				driverIds: driverIds,
				driverGroupIds: driverGroupIds,
				fuelEfficiency: fuelEfficiency,
				fuelEfficiencyType: fuelEfficiencyType,
				drawLines: drawLines,
				snapLinesToRoads: snapLinesToRoads,
				microUseCorrectedCourse: microUseCorrectedCourse,
				suppressEmergency: suppressEmergency,
				prioritizeIncomingMessages: prioritizeIncomingMessages,
				destinationId: destinationId,
				odometerOffset: odometerOffset,
				receiveKey: receiveKey,
				transmitKey: transmitKey,
				srid: srid,
				analogTolerance: analogTolerance,
				cannedMessageGroupId: cannedMessageGroupId,
				addressBookGroupId: addressBookGroupId,
				driverStatusTemplateId: driverStatusTemplateId,
				quickMessageFromMobileTemplateId: quickMessageFromMobileTemplateId,
				quickMessageToMobileTemplateId: quickMessageToMobileTemplateId,
				gatewayAccountId: gatewayAccountId,
				secondaryGatewayAccountId: secondaryGatewayAccountId,
				idpGatewayBehavior: idpGatewayBehavior,
				idpGatewayTimeout: idpGatewayTimeout,
				idpGatewayRetries: idpGatewayRetries,
				ioTemplateId: ioTemplateId,
				configuration: configuration,
				softwarePackageId: softwarePackageId,
				inputLabels: inputLabels,
				outputLabels: outputLabels,
				sensorLabels: sensorLabels,
				simICCID: simICCID === undefined ? null : simICCID,
				simPhoneNumber: simPhoneNumber === undefined ? null : simPhoneNumber,
				simPIN: simPIN === undefined ? null : simPIN,
				simPINUnlock: simPINUnlock === undefined ? null : simPINUnlock,
				simProvider: simProvider === undefined ? null : simProvider,
				imei: imei === undefined ? null : imei,
				serialNumber: serialNumber === undefined ? null : serialNumber,
				licenseNumber: licenseNumber === undefined ? null : licenseNumber,
				nationalIdentityCardNumber: nationalIdentityCardNumber === undefined ? null : nationalIdentityCardNumber,
				softwareVersion: softwareVersion === undefined ? null : softwareVersion,
				vehicleMakeAndModel: vehicleMakeAndModel === undefined ? null : vehicleMakeAndModel,
				vehiclePurchaseDate: vehiclePurchaseDate === undefined ? null : vehiclePurchaseDate,
				vehicleVIN: vehicleVIN === undefined ? null : vehicleVIN,
				notes: notes === undefined ? null : notes,
				vesselName: vesselName === undefined ? null : vesselName,
				vesselFlagRegistry: vesselFlagRegistry === undefined ? null : vesselFlagRegistry,
				vesselCallSign: vesselCallSign === undefined ? null : vesselCallSign,
				vesselIMONumber: vesselIMONumber === undefined ? null : vesselIMONumber,
				vesselClass: vesselClass === undefined ? null : vesselClass,
				vesselTonnage: vesselTonnage === undefined ? null : vesselTonnage,
				vesselSkipper: vesselSkipper === undefined ? null : vesselSkipper,
				vesselMMSI: vesselMMSI === undefined ? null : vesselMMSI,
				customMessage: customMessage,
				contactIds: contactIds,
				isOutOfService: isOutOfService,
				hideAltitude: hideAltitude,
				hideAddress: hideAddress,
				hideSpeed: hideSpeed,
				hideAccuracy: hideAccuracy,
				hideCourse: hideCourse,
				hideFlags: hideFlags,
				garminFormIds: garminFormIds,
				password: password,
				driverDeviceType: driverDeviceType,
				attributes: attributes,
				inmarsatc: inmarsatc,
				pollingInterval: pollingInterval,
				inmarsatCSubaddress: inmarsatCSubaddress,
				mobileUsername: mobileUsername,
				mobilePassword: mobilePassword,
				assetIds: assetIds,
				sendAddressBookAndCannedMessagesOta: sendAddressBookAndCannedMessagesOta,
				removePhoto: removePhoto,
				isGroupLocationSharingEnabled: isGroupLocationSharingEnabled,
				authCode: authCode,
			};

			var url = "/services/GPSService.asmx/UpdateAssetConfiguration";
			if (state.isAddingAsset) {
				url = "/services/GPSService.asmx/AddAsset";
				// exclude parameters not included in adding asset
				delete dataPost.assetId;
				delete dataPost.ioTemplateId; // wha...?
				delete dataPost.removePhoto;
			}
			if (user.limitAssetEditing) {
				url = "/services/GPSService.asmx/UpdateAssetName";
				dataPost = dataPostName;
			}
			$j.ajax({
				type: "POST",
				url: wrapUrl(url),
				data: JSON.stringify(dataPost),
				contentType: "application/json; charset=utf-8",
				dataType: "json",
				success: function (msg) {
					btn.disabled = false;
					var result = msg.d;
					if (result) {
						if (result.Success == true) {
							var asset = null;
							var isAssetAdded = false;
							if (state.isAddingAsset) {
								// create asset and link to groups
								if (result.Asset != null) {
									asset = result.Asset;
									processNewAsset(asset);

									// drivers and driver groups
									var assetDrivers = {
										AssetId: asset.Id,
										DriverIds: []
									}
									if (trkData.assetDrivers == null) {
										trkData.assetDrivers = [];
									}
									trkData.assetDrivers.push(assetDrivers);

									_.each(driverIds, function (driverId) {
										addDriverToAsset(driverId, asset.Id);
									});

									_.each(driverGroupIds, function (driverGroupId) {
										addDriverGroupToAsset(driverGroupId, asset.Id);
									});

									formShowSuccessMessage(status, strings.MSG_ADD_ASSET_SUCCESS);
									isAssetAdded = true;
								}
							} else {
								formShowSuccessMessage(status, strings.MSG_EDIT_ASSET_SUCCESS);
								asset = findAssetById(id);
								var previousSensitivity = asset.Sensitivity;
								var previousGroupIds = asset.GroupIds;
								var previousUserIds = findAssetUsersByAssetId(id);
								var previousDriverIds = findAssetDriversByAssetId(id);
								var previousDriverGroupIds = findAssetDriverGroupIdsByAssetId(parseInt(id));
								var previousColor = asset.Color;
								var previousUniqueId = asset.UniqueId;
								var previousName = asset.Name;
								var previousClass = asset.Class;
								var previousDrawLines = asset.DrawLinesBetweenPositions;
								var previousSnapLinesToRoads = asset.SnapLinesToRoads;
								var previousSRID = asset.DisplaySRID;
								var previousService = asset.IsOutOfService;

								asset.Name = name;
								if (!user.limitAssetEditing) {
									asset.UniqueId = uniqueId;
									asset.SecondaryUniqueId = secondaryUniqueId;
									asset.Color = color;
									asset.ColorSorted = convertHexToSortable(convertNamedColorToHex(asset.Color));
									asset.Class = type;
									asset.Sensitivity = sensitivity;
									asset.Type = type;
									asset.DeviceId = parseInt(deviceId);
									asset.Configuration = configuration;

									asset.IMEI = imei;
									asset.SerialNumber = serialNumber;
									asset.SIMICCID = simICCID;
									asset.SIMPhoneNumber = simPhoneNumber;
									asset.SIMPIN = simPIN;
									asset.SIMPINUnlock = simPINUnlock;
									asset.SIMProvider = simProvider;
									asset.Driver = driver;
									asset.Mission = mission;
									asset.PhoneNumber = phoneNumber;
									asset.PlateNumber = plateNumber;
									asset.LicenseNumber = licenseNumber;
									asset.NationalIdentityCardNumber = nationalIdentityCardNumber;
									asset.Notes = notes;
									asset.CustomMessage = customMessage;
									asset.ContactIds = contactIds;
									asset.SoftwareVersion = softwareVersion;
									asset.VehicleMakeAndModel = vehicleMakeAndModel;
									asset.VehiclePurchaseDate = vehiclePurchaseDate;
									asset.VehicleVIN = vehicleVIN;
									asset.VesselName = vesselName;
									asset.VesselFlagRegistry = vesselFlagRegistry;
									asset.VesselCallSign = vesselCallSign;
									asset.VesselIMONumber = vesselIMONumber;
									asset.VesselTonnage = vesselTonnage;
									asset.VesselClass = vesselClass;
									asset.VesselSkipper = vesselSkipper;
									asset.VesselMMSI = vesselMMSI;
									asset.DriverDeviceType = driverDeviceType;
									asset.Attributes = attributes;
									asset.Tags = indexCustomAttributes(attributes);

									asset.InmarsatC = inmarsatc;

									asset.GroupIds = groupIds;
									asset.AssetIds = assetIds;
									asset.FuelEfficiency = convertFuelEfficiencyToStandard(fuelEfficiency, fuelEfficiencyType);
									asset.DestinationId = destinationId;
									asset.OdometerOffset = odometerOffset;
									asset.Odometer = asset.OdometerRaw == null ? asset.OdometerRaw : (asset.OdometerRaw + odometerOffset);
									asset.DrawLinesBetweenPositions = drawLines;
									asset.SnapLinesToRoads = snapLinesToRoads;
									asset.MicroUseCorrectedCourse = microUseCorrectedCourse;
									asset.SuppressEmergency = suppressEmergency;
									asset.PrioritizeIncomingMessages = prioritizeIncomingMessages;
									asset.IsOutOfService = isOutOfService;
									if (asset.HideAddress !== hideAddress) {
										// expire any cached listings with the address shown
										// this may make more sense by setting a class on the listing to show/hide addresses
										// instead of re-creating dom list items
										console.log("remove listing cache");
										var assetPositions = _.union(
											trkData.live.normalizedPositionsByAssetId[asset.Id],
											trkData.history.normalizedPositionsByAssetId[asset.Id]
										);
										_.each(assetPositions, function (item) {
											if (domNodes.positionListingById[item.Position.Id] !== undefined) {
												delete domNodes.positionListingById[item.Position.Id];
											}
											if (domNodes.sharedView.positionListingById[item.Position.Id] !== undefined) {
												delete domNodes.sharedView.positionListingById[item.Position.Id];
											}
										});
										var assetEvents = _.union(
											trkData.live.normalizedEventsByAssetId[asset.Id],
											trkData.history.normalizedEventsByAssetId[asset.Id]
										);
										_.each(assetEvents, function (item) {
											if (domNodes.eventListingById[item.Event.Id] !== undefined) {
												delete domNodes.eventListingById[item.Event.Id];
											}
											if (domNodes.sharedView.eventListingById[item.Event.Id] !== undefined) {
												delete domNodes.sharedView.eventListingById[item.Event.Id];
											}
										});
										var assetMessages = _.union(
											trkData.live.normalizedMessagesByAssetId[asset.Id],
											trkData.history.normalizedMessagesByAssetId[asset.Id]
										);
										_.each(assetMessages, function (item) {
											if (item.Chat !== undefined) {
												if (domNodes.messageListingById[item.Chat.Id] !== undefined) {
													delete domNodes.messageListingById[item.Chat.Id];
												}
												if (domNodes.sharedView.messageListingById[item.Chat.Id] !== undefined) {
													delete domNodes.sharedView.messageListingById[item.Chat.Id];
												}
											} else if (item.Message !== undefined) {
												if (domNodes.messageListingById[item.Message.Id] !== undefined) {
													delete domNodes.messageListingById[item.Message.Id];
												}
												if (domNodes.sharedView.messageListingById[item.Message.Id] !== undefined) {
													delete domNodes.sharedView.messageListingById[item.Message.Id];
												}
											}
										});
									}
									asset.HideAddress = hideAddress;
									asset.HideAltitude = hideAltitude;
									asset.HideSpeed = hideSpeed;
									asset.HideAccuracy = hideAccuracy;
									asset.HideCourse = hideCourse;
									asset.HideFlags = hideFlags;
									asset.IsGroupLocationSharingEnabled = isGroupLocationSharingEnabled;
									asset.DisplaySRID = srid;
									asset.AnalogTolerance = analogTolerance;
									asset.CannedMessageGroupId = cannedMessageGroupId;
									asset.IOTemplateId = ioTemplateId;
									asset.AddressBookGroupId = addressBookGroupId;
									asset.DriverStatusTemplateId = driverStatusTemplateId;
									asset.QuickMessageFromMobileTemplateId = quickMessageFromMobileTemplateId;
									asset.QuickMessageToMobileTemplateId = quickMessageToMobileTemplateId;
									asset.GatewayAccountId = gatewayAccountId;
									asset.SecondaryGatewayAccountId = secondaryGatewayAccountId;
									asset.PollingInterval = pollingInterval;
									asset.DefaultIDPGateway = idpGatewayBehavior;
									asset.DefaultIDPGatewayTimeout = idpGatewayTimeout;
									asset.DefaultIDPGatewayRetries = idpGatewayRetries;
									asset.InputLabels = inputLabels;
									asset.OutputLabels = outputLabels;
									asset.SensorLabels = sensorLabels;
									asset.MobileUsername = mobileUsername;
									asset.SoftwarePackageId = softwarePackageId;

									if (receiveKey != "") {
										asset.ReceiveEncryptionKey = "placeholder";
									} else {
										asset.ReceiveEncryptionKey = "";
									}
									if (transmitKey != "") {
										asset.TransmitEncryptionKey = "placeholder";
									} else {
										asset.TransmitEncryptionKey = "";
									}
									if (password != "") {
										asset.Password = "placeholder";
									} else {
										asset.Password = "";
									}

									if (mobilePassword != "") {
										asset.MobilePassword = "placeholder";
									} else {
										asset.MobilePassword = "";
									}

									if (removePhoto) {
										asset.PhotoType = null;
									}

									var isThisAssetActive = !isItemIncluded(user.displayPreferences.hiddenAssets, asset.Id);

									// check for changes
									if (previousSensitivity != sensitivity || previousUniqueId != uniqueId || srid != previousSRID) {
										requeryPositionsForAsset(asset);
									}
									if (previousGroupIds.toString() != groupIds.toString()) {
										// changes in group associations
										for (var i = 0; i < previousGroupIds.length; i++) {
											let value = previousGroupIds[i];
											if ($j.inArray(value, groupIds) == -1) {
												removeAssetFromGroup(asset, value);
											}
										}

										$j.each(groupIds, function (index, value) {
											if ($j.inArray(value, previousGroupIds) == -1) {
												addAssetToGroup(asset, value);
											}
										});
										toggleItemSorting("assets", user.displayPreferences.sortMode.assets === sortModes.CUSTOM);
									}

									if (trkData.assetForms != null) {
										for (var i = 0; i < trkData.assetForms.length; i++) {
											if (trkData.assetForms[i].AssetId != id) continue;
											trkData.assetForms[i].FormIds = formIds;
										}
									}

									if (previousUserIds != null) {
										if (previousUserIds.toString() != userIds.toString()) {
											// changes in asset user associations
											for (var i = 0; i < previousUserIds.length; i++) {
												let value = previousUserIds[i];
												if ($j.inArray(value, userIds) == -1) {
													// user no longer associated with asset
													removeUserFromAsset(value, id);
												}
											}

											$j.each(userIds, function (index, value) {
												if ($j.inArray(value, previousUserIds) == -1) {
													// new user association with asset
													addUserToAsset(value, id);
												}
											});
										}
									}

									if (previousDriverIds != null) {
										if (previousDriverIds.toString() != driverIds.toString()) {
											// changes in asset driver associations
											for (var i = 0; i < previousDriverIds.length; i++) {
												let value = previousDriverIds[i];
												if ($j.inArray(value, driverIds) == -1) {
													// driver no longer associated with asset
													removeDriverFromAsset(value, id);
												}
											}
											$j.each(driverIds, function (index, value) {
												if ($j.inArray(value, previousDriverIds) == -1) {
													// new driver association with asset
													addDriverToAsset(value, id);
												}
											});
										}
									} else {
										if (driverIds.length > 0) {
											var assetDrivers = {
												AssetId: asset.Id,
												DriverIds: [],
											};
											if (trkData.assetDrivers == null) {
												trkData.assetDrivers = [];
											}
											trkData.assetDrivers.push(assetDrivers);
											$j.each(driverIds, function (index, value) {
												// new driver association with asset
												addDriverToAsset(value, id);
											});
										}
									}

									if (previousDriverGroupIds != null) {
										if (previousDriverGroupIds.toString() != driverGroupIds.toString()) {
											// changes in asset driver group associations
											_.each(previousDriverGroupIds, function (driverGroupId) {
												if (_.indexOf(driverGroupIds, driverGroupId) === -1) {
													removeDriverGroupFromAsset(driverGroupId, parseInt(id));
												}
											});
											_.each(driverGroupIds, function (driverGroupId) {
												if (_.indexOf(previousDriverGroupIds, driverGroupId) === -1) {
													addDriverGroupToAsset(driverGroupId, parseInt(id));
												}
											});
										}
									} else {
										_.each(driverGroupIds, function (driverGroupId) {
											addDriverGroupToAsset(driverGroupId, parseInt(id));
										});
									}

									if (previousDrawLines != drawLines || previousSnapLinesToRoads != snapLinesToRoads) {
										updateAssetPositionLines(asset, false, viewModes.NORMAL);
									}

									if (previousColor != color || previousClass != type) {
										// update position colors
										updateMarkersForAsset(asset);

										// update marker colors in selection list, todo: fix this duplicate
										updateAssetListingIcon(asset.Id, asset.Color, isThisAssetActive, asset.Class);
									}

									if (previousService != isOutOfService) {
										_.each(domNodes.assets[asset.Id], function (assetNode) {
											if (isOutOfService) {
												assetNode.classList.add("noservice");
											} else {
												assetNode.classList.remove("noservice");
											}
										});
									}
								}
								if (previousName != name) {
									_.each(domNodes.assets[asset.Id], function (assetNode) {
										var nameNode = assetNode.querySelector(".asset-name");
										nameNode.textContent = name;
									});
									// todo: resort each list if alphabetical
								}
								indexAssetsForSearch();
							}

							toggleLoadingMessage(false, "edit-asset"); // IE fix to place before upload plugin

							// upload the photo if it exists
							uploadFile("fileEditAssetPhoto", "/Home/UploadAssetPhoto", { assetId: asset.Id }, function (res) {
								if (res == "" || res == "empty") {
									// plugin does not handle empty result properly
									return;
								}
								res = JSON.parse(res);

								if (res.success == true) {
									// show photo
									if (res.type != null && res.type != "") {
										asset.PhotoType = res.type;
										if (
											domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
											parseInt(domNodes.panels.secondary.getAttribute("data-item-id")) === asset.Id
										) {
											$("#edit-asset-photo")
												.find("img")
												.attr(
													"src",
													"/uploads/images/assets/" + asset.Id + "_thumb." + res.type + "?rnd=" + new Date().getTime()
												);
											$("#edit-asset-photo a").attr("href", "/uploads/images/assets/" + asset.Id + "." + res.type);
											$("#edit-asset-photo").show();
										}

										// clear file selection
										$("#fileEditAssetPhoto").val("").next(".custom-file-label").text(strings.NO_FILE_SELECTED);
									}
								}
							});

							// upload icon
							uploadFile("fileEditAssetIcon", "/Home/UploadAssetIcon", { assetId: asset.Id }, function (res) {
								if (res == "" || res == "empty") {
									// plugin does not handle empty result properly
									return;
								}
								res = JSON.parse(res);
								if (res.success == true) {
									// update asset icon
									asset.Class = "Upload";
									asset.IconModified = Math.round(new Date().getTime() / 1000);

									// update position colors
									updateMarkersForAsset(asset);

									// update marker colors in selection list, todo: fix this duplicate
									updateAssetListingIcon(asset.Id, asset.Color, isThisAssetActive, asset.Class);

									// show icon - only if dialog still open for asset
									if (
										domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
										parseInt(domNodes.panels.secondary.getAttribute("data-item-id")) === asset.Id
									) {
										$j("#edit-asset-icon")
											.find("img")
											.attr("src", "/markers/upload?aid=" + asset.Id + "&lm=" + new Date().getTime());
										$j("#edit-asset-icon").show();
									}

									// clear file selection
									$j("#fileEditAssetIcon").val("").next(".custom-file-label").text(strings.NO_FILE_SELECTED);
								}
							});

							if (isAssetAdded === true) {
								openEditAssetDialog(null);
								formShowSuccessMessage(status, strings.MSG_ADD_ASSET_SUCCESS);
							}
						} else {
							// message failure, keep text field to allow retry
							if (state.isAddingAsset) {
								formShowErrorMessage(status, strings.MSG_ADD_ASSET_ERROR);
							} else {
								formShowErrorMessage(status, strings.MSG_EDIT_ASSET_ERROR);
							}
							if (result.ErrorMessage != null && result.ErrorMessage != "") {
								formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
							}
							toggleLoadingMessage(false, "edit-asset");
						}
					}
				},
				error: function (xhr, status, error) {
					btn.disabled = false;
					handleWebServiceError(strings.MSG_EDIT_ASSET_FAILURE);
					toggleLoadingMessage(false, "edit-asset");
				},
			});
		},
	},
	{
		buttonType: "secondary",
		icons: { primary: "ui-icon-close" },
		text: strings.CANCEL,
		click: function () {
			if (state.isAddingAsset) {
				closeSecondaryPanel();
			} else {
				document.getElementById("panel-dialog-back").click();
			}
		},
	},
];

function findAssetUsersByAssetId(id) {
	if (trkData.assetUsers == null) return null;
	for (var i = 0, len = trkData.assetUsers.length; i < len; i++) {
		if (trkData.assetUsers[i].AssetId == id) {
			return trkData.assetUsers[i].UserIds;
		}
	}
}

function addUserToAsset(userId, assetId) {
	if (trkData.assetUsers == null) return;
	for (var i = 0; i < trkData.assetUsers.length; i++) {
		if (trkData.assetUsers[i].AssetId == assetId) {
			trkData.assetUsers[i].UserIds.push(userId);
			break;
		}
	}
}

function removeUserFromAsset(userId, assetId) {
	if (trkData.assetUsers == null) return;
	for (var i = 0; i < trkData.assetUsers.length; i++) {
		if (trkData.assetUsers[i].AssetId == assetId) {
			for (var j = 0; j < trkData.assetUsers[i].UserIds.length; j++) {
				if (userId == trkData.assetUsers[i].UserIds[j]) {
					trkData.assetUsers[i].UserIds.splice(j, 1);
					break;
				}
			}
			break;
		}
	}
}

function processNewAsset(asset) {
	if (trkData.assets == null) {
		return;
	}

	asset.ColorSorted = convertHexToSortable(convertNamedColorToHex(asset.Color));
	trkData.assets.push(asset);
	trkData.assetsById = _.keyBy(trkData.assets, "Id");
	addAssetToGroup(asset, null);
	for (var i = 0; i < asset.GroupIds.length; i++) {
		addAssetToGroup(asset, asset.GroupIds[i]);
	}
	toggleItemSorting("assets", user.displayPreferences.sortMode.assets === sortModes.CUSTOM);

	// make active
	//addAssetContextMenus(asset.Id);
	toggleAssetActive(asset.Id, true, true);

	indexAssetsForSearch();
}

function updateMarkersForAsset(asset) {
	if (trkData.history.markerClustersByAssetId[asset.Id] !== undefined) {
		trkData.history.markerClustersByAssetId[asset.Id].imageData = {
			assetClass: asset.Class,
			color: asset.Color,
			assetId: asset.Id,
		};
		trkData.history.markerClustersByAssetId[asset.Id].imagePath = createMarkerPath(
			asset.Class,
			asset.Color,
			null,
			null,
			asset.Id,
			false,
			null,
			false,
			false
		);
	}

	var hexColor = convertNamedColorToHex(asset.Color);
	if (trkData.history.mapLinesByAssetId[asset.Id] !== undefined) {
		trkData.history.mapLinesByAssetId[asset.Id].setStyle({ color: hexColor });
	}
	if (trkData.live.mapLinesByAssetId[asset.Id] !== undefined) {
		trkData.live.mapLinesByAssetId[asset.Id].setStyle({ color: hexColor });
	}

	_.each(trkData.history.markersByAssetId[asset.Id], function (marker) {
		// changeMarkerIcon(marker, null, marker.data.alpha, false);
		marker.replaceIcon(asset.Class, asset.Color, marker.data.alpha);
	});

	_.each(trkData.live.markersByAssetId[asset.Id], function (marker) {
		// changeMarkerIcon(marker, null, marker.data.alpha, false);
		marker.replaceIcon(asset.Class, asset.Color, marker.data.alpha);
	});

	var journeys = _.filter(trkData.journeys, { AssetId: asset.Id });
	_.each(journeys, function (journey) {
		_.each(journey.Trips, function (trip) {
			_.each(trkData.trips.markersByTripId[trip.Id], function (marker) {
				changeMarkerIcon(marker, null, marker.data.alpha, false);
			});
		});
	});
}

export function indexAssetsForSearch() {
	trkData.search.assets = new JsSearch.Search("Id");
	trkData.search.assets.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
	// primary attributes
	var primaryAttributes = ["Name", "UniqueId", "SecondaryUniqueId"];
	_.each(primaryAttributes, function (attribute) {
		trkData.search.assets.addIndex(attribute);
	});

	_.each(trkData.assets, function (asset) {
		// add additional metadata fields for searching
		
		var device = findDeviceById(asset.DeviceId);
		asset.DeviceName = device.Manufacturer + ": " + device.Name;
		asset.DriverNames = asset.Drivers.map(d => d.DriverName);
		asset.DriverIButtonIds = asset.Drivers.map(d => {
			var driver = findDriverById(d.DriverId);
			return driver?.IButtonId;
		});
	});

	// secondary attributes (extra fields, custom fields)
	var secondaryAttributes = [
		"DeviceName",
		"Mission",
		"Driver",
		"DriverNames",
		"DriverIButtonIds",
		"PhoneNumber",
		"PlateNumber",
		"PhotoType",
		"FuelEfficiency",
		"DrawLinesBetweenPositions",
		"SnapLinesToRoads",
		"MicroUseCorrectedCourse",
		"PrioritizeIncomingMessages",
		"SoftwareVersion",
		"VehicleMakeAndModel",
		"VehiclePurchaseDate",
		"VehicleVIN",
		"Notes",
		"VesselCallSign",
		"VesselClass",
		"VesselFlagRegistry",
		"VesselIMONumber",
		"VesselName",
		"VesselSkipper",
		"VesselTonnage",
		"VesselMMSI",
		"Configuration",
		"CustomMessage",
		"DestinationId",
		"IMEI",
		"SerialNumber",
		"SIMICCID",
		"SIMPhoneNumber",
		"SIMPIN",
		"SIMPINUnlock",
		"SIMProvider",
		"LicenseNumber",
		"NationalIdentityCardNumber",
		"MobileUsername",
		"Tags", // custom attributes
	];
	_.each(secondaryAttributes, function (attribute) {
		trkData.search.assets.addIndex(attribute);
	});

	trkData.search.assets.addDocuments(trkData.assets);
	log("Assets indexed for search filtering.");
}

export function deleteAsset(asset) {
	if (
		domNodes.infoDialogs.mapItemInformation.data !== undefined &&
		domNodes.infoDialogs.mapItemInformation.data !== null &&
		domNodes.infoDialogs.mapItemInformation.data.assetId === asset.Id &&
		$(domNodes.infoDialogs.mapItemInformation).dialog("isOpen")
	) {
		$(domNodes.infoDialogs.mapItemInformation).dialog("close");
	}

	if ($(domNodes.infoDialogs.positionHistory).data("assetId") == asset.Id) {
		$(domNodes.infoDialogs.positionHistory).dialog("close");
	}

	_.each(asset.GroupIds, function (groupId) {
		removeAssetFromGroup(asset, groupId);
	});
	removeAssetFromGroup(asset, "all-assets");

	var panel = domNodes.panels.secondary;
	if (panel.getAttribute("data-group-for") === "assets" && parseInt(panel.getAttribute("data-item-id")) === asset.Id) {
		closeSecondaryPanel();
	}

	// remove positions and variable definitions

	//remove where trkData.assetUsers[i].AssetId == id
	if (trkData.assetUsers != null) {
		for (var i = trkData.assetUsers.length - 1; i >= 0; i -= 1) {
			if (trkData.assetUsers[i].AssetId == asset.Id) {
				trkData.assetUsers.splice(i, 1);
			}
		}
	}

	//remove where trkData.history.positions[i].Id == id
	if (trkData.history.positions != null) {
		for (var i = trkData.history.positions.length - 1; i >= 0; i -= 1) {
			if (trkData.history.positions[i].Id == asset.Id) trkData.history.positions.splice(i, 1);
		}
	}

	trkData.history.normalizedPositions = _.reject(trkData.history.normalizedPositions, function (item) {
		return item.AssetId === asset.Id;
	});
	delete trkData.history.assetIdsWithResults[asset.Id];

	//remove where trkData.live.positions[i].AssetId == id
	if (trkData.live.positions != null) {
		for (var i = trkData.live.positions.length - 1; i >= 0; i -= 1) {
			if (trkData.live.positions[i].AssetId == asset.Id) trkData.live.positions.splice(i, 1);
		}
	}

	trkData.live.normalizedPositions = _.reject(trkData.live.normalizedPositions, function (item) {
		return item.AssetId === asset.Id;
	});

	if (trkData.live.latestPositions != null) {
		for (var i = trkData.live.latestPositions.length - 1; i >= 0; i -= 1) {
			if (trkData.live.latestPositions[i].AssetId == asset.Id) trkData.live.latestPositions.splice(i, 1);
		}
	}

	//remove where trkData.markers.assetId == id ... setMap(null)
	if (trkData.live.markers != null) {
		for (var i = trkData.live.markers.length - 1; i >= 0; i -= 1) {
			var assetId = trkData.live.markers[i].data.assetId;
			if (assetId == asset.Id) {
				removeItemFromMap(trkData.live.markers[i]);
				trkData.live.markers.splice(i, 1);
			}
		}
	}
	if (trkData.history.markers != null) {
		for (var i = trkData.history.markers.length - 1; i >= 0; i -= 1) {
			var assetId = trkData.history.markers[i].data.assetId;
			if (assetId == asset.Id) {
				removeItemFromMap(trkData.history.markers[i]);
				trkData.history.markers.splice(i, 1);
			}
		}
	}

	if (domNodes.assets[asset.Id] !== undefined) {
		delete domNodes.assets[asset.Id];
	}

	// remove events and messages from live and history
	var assetEvents = _.filter(trkData.live.events, function (evt) {
		return evt.AssetId === asset.Id;
	});
	var assetEventIds = _.map(assetEvents, "Id");
	trkData.live.eventIds = _.without(trkData.live.eventIds, assetEventIds);
	_.each(assetEventIds, function (eventId) {
		delete trkData.live.normalizedEventIds[eventId];
		delete trkData.history.normalizedEventIds[eventId];
	});
	trkData.live.events = _.filter(trkData.live.events, function (evt) {
		return evt.AssetId !== asset.Id;
	});
	trkData.live.normalizedEvents = _.reject(trkData.live.normalizedEvents, function (item) {
		return item.AssetId === asset.Id;
	});
	trkData.history.events = _.filter(trkData.history.events, function (evt) {
		return evt.AssetId !== asset.Id;
	});
	trkData.history.normalizedEvents = _.reject(trkData.history.normalizedEvents, function (item) {
		return item.AssetId === asset.Id;
	});
	trkData.live.messages = _.filter(trkData.live.messages, function (item) {
		return item.AssetId !== asset.Id;
	});
	trkData.history.messages = _.filter(trkData.history.messages, function (item) {
		return item.AssetId !== asset.Id;
	});
	trkData.history.normalizedMessages = _.filter(trkData.history.normalizedMessages, function (item) {
		return item.AssetId !== asset.Id;
	});

	if (trkData.visible.assets != null) {
		var index = _.indexOf(trkData.visible.assets, asset.Id);
		if (index !== -1) {
			trkData.visible.assets.splice(index, 1);
		}
	}

	if (trkData.assets != null) {
		for (var i = trkData.assets.length - 1; i >= 0; i -= 1) {
			if (trkData.assets[i].Id == asset.Id) trkData.assets.splice(i, 1);
		}
	}

	trkData.assetsById = _.keyBy(trkData.assets, "Id");
	if (trkData.live.latestPositionsByAssetId[asset.Id] !== undefined) {
		delete trkData.live.latestPositionsByAssetId[asset.Id];
	}
	if (trkData.live.notificationTimesByAssetId[asset.Id] !== undefined) {
		delete trkData.live.notificationTimesByAssetId[asset.Id];
	}
	trkData.live.positionsByAssetId = _.groupBy(trkData.live.positions, "AssetId");
	trkData.live.normalizedPositionsByAssetId = _.groupBy(trkData.live.normalizedPositions, "AssetId");
	trkData.live.normalizedEventsByAssetId = _.groupBy(trkData.live.normalizedEvents, "AssetId");
	trkData.live.messagesByAssetId = _.groupBy(trkData.live.messages, "AssetId");
	trkData.live.markersByAssetId = _.groupBy(trkData.live.markers, function (marker) {
		return marker.data.assetId;
	});
	trkData.live.markersByPositionId = _.keyBy(trkData.live.markers, function (marker) {
		return marker.data.location.Id;
	});
	trkData.history.positionsByAssetId = _.keyBy(trkData.history.positions, "Id");
	trkData.history.normalizedPositionsByAssetId = _.groupBy(trkData.history.normalizedPositions, "AssetId");
	trkData.history.normalizedEventsByAssetId = _.groupBy(trkData.history.normalizedEvents, "AssetId");
	trkData.history.messagesByAssetId = _.groupBy(trkData.history.messages, "AssetId");
	trkData.history.normalizedMessagesByAssetId = _.groupBy(trkData.history.normalizedMessages, "AssetId");
	trkData.history.markersByAssetId = _.groupBy(trkData.history.markers, function (marker) {
		return marker.data.assetId;
	});
	trkData.history.markersByPositionId = _.keyBy(trkData.history.markers, function (marker) {
		return marker.data.assetId;
	});

	updateActiveAssetInformation(viewModes.NORMAL);

	if (trkData.assets.length === 0) {
		document.getElementById("no-assets").classList.add("is-visible");
		document.getElementById("assets-all").classList.remove("is-visible");
		document.getElementById("filter-assets").querySelector(".filter-box").classList.remove("is-visible");
	}

	indexAssetsForSearch();
}

function requeryPositionsForAsset(asset) {
	var hadLocationHistory = false;

	var assetHistory = trkData.history.normalizedPositionsByAssetId[asset.Id];
	if (assetHistory !== undefined && assetHistory.length > 0) {
		hadLocationHistory = true;

		// remove markers from map
		trkData.history.markers = _.without(trkData.history.markers, trkData.history.markersByAssetId[asset.Id]);
		_.each(trkData.history.markersByAssetId[asset.Id], function (item) {
			removeItemFromMap(item);
			delete trkData.history.markersByPositionId[item.data.location.Id];
		});
		trkData.history.markersByAssetId[asset.Id] = [];

		trkData.history.normalizedPositions = _.reject(trkData.history.normalizedPositions, function (item) {
			return item.AssetId === asset.Id;
		});
		delete trkData.history.assetIdsWithResults[asset.Id];
		trkData.history.normalizedPositionsByAssetId = _.groupBy(trkData.history.normalizedPositions, "AssetId");

		asset.Positions = null; // TODO remove this reference everywhere
		if (trkData.history.mapLinesByAssetId[asset.Id] !== undefined) {
			// remove polyline
			removeItemFromMap(trkData.history.mapLinesByAssetId[asset.Id]);
			delete trkData.history.mapLinesByAssetId[asset.Id];
		}
		if (trkData.history.markerClustersByAssetId[asset.Id] !== undefined) {
			// remove marker cluster
			trkData.history.markerClustersByAssetId[asset.Id].clearLayers();
			// TODO delete?
		}
	}

	if (trkData.history.positions != null) {
		for (var k = 0; k < trkData.history.positions.length; k++) {
			if (trkData.history.positions[k].Id === asset.Id) {
				// remove from position results
				trkData.history.positions = trkData.history.positions.splice(k, 1);
				break;
			}
		}
	}
	trkData.history.positionsByAssetId = _.keyBy(trkData.history.positions, "Id");

	throttles.updateLiveAssets();
	if (hadLocationHistory) {
		querySingleAsset(asset.Id);
	}
}
