import strings from "./strings.js";
import options from "./options.js";
import { findFenceById } from "./fence.js";
import {
	convertToLatLngPreference,
	convertAltitudeToPreference,
	convertSpeedToPreference,
	convertFromMetresToUserDistancePreference,
	fuelText,
} from "./preferences.js";
import user from "./user.js";
import { createMarkerPath } from "./marker-path.js";
import { includeRowIfNotNull, createAccordionCard, formattedTextToDiv } from "./dom-util.js";
import { findWaypointByAsset } from "./waypoint.js";
import { getStatusTextForLocation } from "./location.js";
import { findPlaceByUniqueKey } from "./place.js";
import { createPositionPlaybackForAsset } from "./playback.js";
import { populateCustomAttributes } from "./attributes.js";

import $j from "jquery";
import L from "leaflet";
import _ from "lodash";
import { el, text } from "redom"; // https://redom.js.org/

export function populatePositionInformation(asset, marker) {
	// no strings for html! WHY ME SO DUMB?!
	var location = marker.data.location;
	var message = marker.data.message;
	if (asset === undefined || asset === null) {
		return;
	}

	var isHover = false;
	var isMarkerSelected = true;

	var headingRow = null;
	if (location.Course != null && !asset.HideCourse) {
		headingRow = includeRowIfNotNull(strings.HEADING, location.Course + "°");
	}

	var elevationRow = null;
	if (location.Altitude != null && location.Altitude != "" && !asset.HideAltitude) {
		elevationRow = includeRowIfNotNull(strings.ALTITUDE, convertAltitudeToPreference(location.Altitude));
	}

	var playbackContent = createPositionPlaybackForAsset(asset, marker);

	var waypointRow = null;
	var waypoint = findWaypointByAsset(asset);
	if (waypoint != null) {
		var distance = waypoint.DistanceTo;
		if (distance == null) {
			var destination = L.latLng(waypoint.Lat, waypoint.Lng);
			distance = convertFromMetresToUserDistancePreference(
				destination.distanceTo(L.latLng(location.Lat, location.Lng))
			);
		}
		waypointRow = includeRowIfNotNull(strings.WAYPOINT, [text(waypoint.Name), el("br"), text(distance)]);
	}

	var messageRow = null;
	if (message != null && message != "") {
		messageRow = includeRowIfNotNull(strings.MESSAGE, messageRow);
	}

	var garminFormElement = null; // can't have more than one form submission per position
	var eventInformationElements = [];
	var shockLogRow = null;
	if (location.Events != null) {
		for (var i = 0; i < location.Events.length; i++) {
			var evtclass = i % 2 === 0 ? "odd" : "even";
			var evt = location.Events[i];
			// todo: actually filter this out in the data feed -- maybe not, would it break alerts tab?
			if (
				user.isAnonymous &&
				(evt.Type === 14 ||
					evt.Type === 16 ||
					evt.Type === 9 ||
					evt.Type === 124 ||
					evt.Type === 126 ||
					evt.Type === 29)
			) {
				continue;
			}
			if (
				(options.hideEmergencyEvents || evt.Hide) &&
				(evt.Type === 9 || evt.Type === 124 || evt.Type === 126 || evt.Type === 29)
			) {
				continue;
			}
			if ((options.hideAlertTriggeredEvents || evt.Hide) && (evt.Type === 14 || evt.Type === 16)) {
				continue;
			}

			if (evt.Type == 268) {
				// SL Summary
				shockLogRow = includeRowIfNotNull("ShockLog", evt.Details);
				continue;
			}
			var eventicon = createMarkerPath(asset.Class, asset.Color, null, null, asset.Id, false, evt.Type);
			var eventInformationDetails = [];
			switch (evt.Type) {
				case 14: // alert triggered
				case 16: // alert no longer triggered
					if (evt.Details != null) {
						eventInformationDetails.push(
							el(
								"button.event-information.btn.btn-primary",
								{ dataset: { position: location.Id, event: evt.Id } },
								strings.EVENT_VIEW_DETAILS
							)
						);
					} else {
						eventInformationDetails.push(text(strings.DELETED));
					}
					break;
				case 127: // garmin form submission
					if (evt.Details != null) {
						var formDetails = evt.Details.split("|");
						var formId = formDetails[0];
						var formName = strings.VIEW;
						if (formDetails.length > 1) {
							formName = formDetails[1];
						}
						eventInformationDetails.push(text(formName));
						garminFormElement = createAccordionCard(
							"garmin",
							"asset-information-accordion",
							strings.GARMIN_FORM + " - " + formName,
							el("ul.GarminSubmissionItems", { "data-form-id": formId })
						);
					} else {
						eventInformationDetails.push(text(strings.DELETED));
					}
					break;
				case 270: // beacon read
					if (evt.Details != null) {
						var beacons = evt.Details.split("\n");
						for (var k = 0; k < beacons.length; k++) {
							var beacon = beacons[k].split("|");
							if (beacon.length > 1) {
								var beaconUniqueKey = beacon[0];
								var beaconRSSI = beacon[1];
								if (beaconUniqueKey == null) {
									continue;
								}
								var beaconPlace = findPlaceByUniqueKey(beaconUniqueKey);
								if (beaconPlace != null) {
									eventInformationDetails.push(
										el("a.beacon-place", { href: "#", dataset: { placeId: beaconPlace.Id } }, beaconPlace.Name)
									);
									eventInformationDetails.push(text(" @ " + beaconRSSI + " RSSI"));
								} else {
									eventInformationDetails.push(text(beaconUniqueKey + " @ " + beaconRSSI + " RSSI"));
								}
								eventInformationDetails.push(el("br"));
							}
						}
					}
					break;
				case 272:
				case 273:
				case 274:
					// driver fatigue
					if (evt.Details != null) {
						eventInformationDetails.push(el("img.fatigue-image", { src: "data:image/jpeg;base64, " + evt.Details }));
					}
					break;
				case 275:
					break;
				default:
					eventInformationDetails.push(formattedTextToDiv(evt.Details));
					break;
			}
			// add row for event information
			// TODO: remove inline styles except background image
			eventInformationElements.push(
				el("tr." + evtclass, [
					el(
						"td",
						el("div", {
							style: {
								backgroundImage: "url(" + eventicon + ")",
								width: "36px",
								height: "27px",
								display: "block",
								backgroundPosition: "0 0",
							},
						})
					),
					el("td", { style: { verticalAlign: "middle" } }, evt.TypeName),
					el("td.break-text", { style: { verticalAlign: "middle" } }, _.compact(eventInformationDetails)),
				])
			);

			if (evt.Alert != null && isMarkerSelected && evt.Type === 14) {
				// todo: use DOM editing here to prevent script/html injection
				var includeFields = evt.Alert.Acknowledged === 0;

				var alertHeaderElements = [];
				if (evt.Alert.Color != null) {
					alertHeaderElements.push(el("span.alert-color", { style: { backgroundColor: evt.Alert.Color } }));
				}
				if (evt.Alert.Name != null) {
					alertHeaderElements.push(text(evt.Alert.Name + ": "));
				}
				alertHeaderElements.push(text(evt.Alert.Type));

				var alertFormElements = [];
				if (evt.Alert.Description != null) {
					alertFormElements.push(
						el("div.form-group", [
							el("label", { for: "PositionAlertDescription" + evt.Id }, strings.DESCRIPTION),
							el("input#PositionAlertDescription" + evt.Id + ".form-control-plaintext", {
								type: "text",
								value: evt.Alert.Description,
								readOnly: true,
							}),
						])
					);
				}
				if (evt.Alert.ResolutionProcedure != null) {
					alertFormElements.push(
						el("div.form-group", [
							el("label", { for: "PositionAlertResolutionProcedure" + evt.Id }, strings.RESOLUTION_PROCEDURE),
							el("input#PositionAlertResolutionProcedure" + evt.Id + ".form-control-plaintext", {
								type: "text",
								value: evt.Alert.ResolutionProcedure,
								readOnly: true,
							}),
						])
					);
				}
				var alertResolutionElements = [];
				if (includeFields) {
					alertResolutionElements.push(
						el("div.form-group", [
							el("label", { for: "AlertResolution" + evt.Id }, strings.RESOLUTION),
							// TODO: remove inline style
							el(
								"textarea#AlertResolution" + evt.Id + ".required.form-control",
								{ style: { height: "4em", width: "100%" }, name: "AlertResolution" },
								evt.Alert.AcknowledgedText
							),
						])
					);
				} else if (evt.Alert.AcknowledgedText != null) {
					alertResolutionElements.push(
						el("div.form-group", [
							el("label", { for: "PositionAlertResolution" + evt.Id }, strings.RESOLUTION),
							el("input#PositionAlertResolution" + evt.Id + ".form-control-plaintext", {
								type: "text",
								value: evt.Alert.AcknowledgedText,
								readOnly: true,
							}),
						])
					);
				}
				alertFormElements.push(el("div.alert-resolution", alertResolutionElements));

				var ack = strings.ACKNOWLEDGE;
				var ackalt = strings.ACKNOWLEDGE_ALT;
				if (evt.Alert.LabelAcknowledge != null) {
					ack = evt.Alert.LabelAcknowledge;
				}
				if (evt.Alert.LabelAcknowledgeAlt != null) {
					ackalt = evt.Alert.LabelAcknowledgeAlt;
				}
				if (includeFields) {
					alertFormElements.push(
						el("div.alert-acknowledge", [
							el("button.AlertAcknowledge.btn.btn-primary.alert-acknowledge", ack),
							text(" " + strings.OR + " "),
							el("button.AlertAcknowledgeAlt.btn.btn-secondary.alert-acknowledge-alt", ackalt),
						])
					);
				} else {
					alertFormElements.push(
						el("div.form-group", [
							el("label", { for: "PositionAlertStatus" + evt.Id }, strings.STATUS),
							el("input#PositionAlertStatus" + evt.Id + ".form-control-plaintext", {
								type: "text",
								value: strings.ACKNOWLEDGE_STATUS.replace("{Status}", evt.Alert.AcknowledgedStatus)
									.replace("{Time}", evt.Alert.AcknowledgedOn)
									.replace("{User}", evt.Alert.AcknowledgedBy),
								readOnly: true,
							}),
						])
					);
				}
				alertFormElements.push(el("input", { type: "hidden", value: evt.Id }));
				var alertForm = el(
					"form#AcknowledgeAlert" + evt.Id,
					{ dataset: { assetId: asset.Id, eventId: evt.Id } },
					alertFormElements
				);

				// add row for alert information
				eventInformationElements.push(
					el("tr." + evtclass, [
						el(
							"td",
							{ colspan: 3 },
							el(
								"div#alert-information-accordion" + evt.Id + ".alert-information-accordion.position-accordion",
								createAccordionCard(
									"alert-information" + evt.Id,
									"alert-information-accordion" + evt.Id,
									alertHeaderElements,
									alertForm
								)
							)
						),
					])
				);
			}
		}
	}
	var eventInformationTable = null;
	if (eventInformationElements.length > 0) {
		eventInformationTable = el("table.table.table-striped", [
			el("thead", el("tr", [el("th", " "), el("th", strings.EVENT_EVENT), el("th", strings.EVENT_DETAILS)])),
			el("tbody", eventInformationElements),
		]);
	}

	var ioInformationElement = null;
	if (location.GPIO != null) {
		var ioRows = createIOOutput(asset, location.GPIO, true);
		ioRows.push(includeRowIfNotNull(strings.CHANGED, location.GPIO.CreatedOn));
		var ioInformationTable = el("table", el("tbody", _.compact(ioRows)));
		ioInformationElement = createAccordionCard(
			"io",
			"asset-information-accordion",
			strings.IO_FIELDS,
			ioInformationTable
		);
	}

	var obdInformationElement = null;
	if (location.OBD != null) {
		var obdInformationTable = el("table", el("tbody", _.compact(createOBDOutput(location.OBD))));
		obdInformationElement = createAccordionCard(
			"obd",
			"asset-information-accordion",
			strings.OBD_FIELDS,
			obdInformationTable
		);
	}

	// asset photo should be shown in either the general information accordion or alongside the position information
	// depending on the user's preferences
	var assetPhotoElement = null;
	if (asset.PhotoType !== null && asset.PhotoType !== "") {
		assetPhotoElement = el(
			"a",
			{ href: "/uploads/images/assets/" + asset.Id + "." + asset.PhotoType, target: "_blank" },
			el("img.asset-photo", { src: "/uploads/images/assets/" + asset.Id + "_thumb." + asset.PhotoType })
		);
	}

	var generalInformationElements = [
		includeRowIfNotNull(strings.IMEI, asset.IMEI),
		includeRowIfNotNull(strings.SERIAL_NUMBER, asset.SerialNumber),
		includeRowIfNotNull(strings.SOFTWARE_VERSION, asset.SoftwareVersion),
		includeRowIfNotNull(strings.NOTES, asset.Notes),
	];
	if (!options.placeAssetPhotosInPositionDialog) {
		generalInformationElements.push(includeRowIfNotNull(strings.PHOTO, assetPhotoElement));
	}
	generalInformationElements = _.compact(generalInformationElements);

	var generalInformationElement = null;
	if (generalInformationElements.length > 0) {
		var generalInformationTable = el("table", el("tbody", generalInformationElements));
		generalInformationElement = createAccordionCard(
			"general",
			"asset-information-accordion",
			strings.GENERAL_FIELDS,
			generalInformationTable
		);
	}

	var simInformationElements = _.compact([
		includeRowIfNotNull(strings.SIM_ICCID, asset.SIMICCID),
		includeRowIfNotNull(strings.SIM_PHONE_NUMBER, asset.SIMPhoneNumber),
		includeRowIfNotNull(strings.SIM_PIN, asset.SIMPIN),
		includeRowIfNotNull(strings.SIM_PIN_UNLOCK, asset.SIMPINUnlock),
		includeRowIfNotNull(strings.SIM_PROVIDER, asset.SIMProvider),
	]);
	var simInformationElement = null;
	if (simInformationElements.length > 0) {
		var simInformationTable = el("table", el("tbody", simInformationElements));
		simInformationElement = createAccordionCard(
			"sim",
			"asset-information-accordion",
			strings.SIM_FIELDS,
			simInformationTable
		);
	}

	// custom attributes
	var attributeElements = populateCustomAttributes(asset.Attributes, 0, "asset-information-accordion");

	var driverInformationElements = [];
	if (location.Drivers != null && location.Drivers.length > 0) {
		// show driver for position instead of assigned driver
		// todo: cross-check DriverId to trkData.drivers for this information so the position information can be much less? (findDriverById)
		for (var i = 0; i < location.Drivers.length; i++) {
			var driver = location.Drivers[i].Driver;
			var driverStatus = location.Drivers[i].DriverStatus;
			var driverSummary = driver.DriverId;
			if (driverStatus != null) {
				driverSummary += " - " + driverStatus.Status;
			}
			var assetDriverFields = [
				includeRowIfNotNull(strings.DRIVER_ID, driver.DriverId),
				includeRowIfNotNull(strings.IBUTTON_ID, driver.IButtonId),
				includeRowIfNotNull(strings.GARMIN_ID, driver.GarminId),
				includeRowIfNotNull(strings.REGION, driver.Region),
				includeRowIfNotNull(strings.PHONE_NUMBER, driver.Phone),
				includeRowIfNotNull(strings.BLOOD_TYPE, driver.BloodType),
				includeRowIfNotNull(strings.LICENSE_NUMBER, driver.LicenseNumber),
				includeRowIfNotNull(strings.LICENSE_EXPIRATION, driver.LicenseExpiration),
				includeRowIfNotNull(strings.LICENSE_RESTRICTION, driver.LicenseRestriction),
				includeRowIfNotNull(strings.MANAGER, driver.Manager),
				includeRowIfNotNull(strings.EMERGENCY_CONTACT, driver.EmergencyContact),
				includeRowIfNotNull(strings.EMERGENCY_CONTACT_NUMBER, driver.EmergencyContactNumber),
			];
			var assetDriverInfo = [];
			if (driver.PhotoType !== null && driver.PhotoType !== "") {
				assetDriverInfo.push(
					el(
						"a",
						{ href: "/uploads/images/drivers/" + driver.Id + "." + driver.PhotoType, target: "_blank" },
						el("img", {
							align: "right",
							src: "/uploads/images/drivers/" + driver.Id + "_thumb." + driver.PhotoType,
							alt: driver.DriverId,
						})
					)
				);
			}

			if (driverStatus != null) {
				assetDriverFields.push(includeRowIfNotNull(strings.STATUS, driverStatus.Status));
			}
			assetDriverFields = _.compact(assetDriverFields);
			if (assetDriverFields.length > 0) {
				assetDriverInfo.push(el("table", el("tbody", assetDriverFields)));
			}

			assetDriverInfo = _.compact(assetDriverInfo);
			if (assetDriverInfo.length > 0) {
				driverInformationElements.push(
					createAccordionCard(
						"driver-" + i,
						"asset-information-accordion",
						strings.DRIVER_FIELDS + ": " + driverSummary,
						assetDriverInfo
					)
				);
			}
		}
	} else {
		// asset's driver attributes
		var driverFields = _.compact([
			includeRowIfNotNull(strings.MISSION, asset.Mission),
			includeRowIfNotNull(strings.DRIVER, asset.Driver),
			includeRowIfNotNull(strings.PHONE_NUMBER, asset.PhoneNumber),
			includeRowIfNotNull(strings.LICENSE_NUMBER, asset.LicenseNumber),
			includeRowIfNotNull(strings.NATIONAL_IDENTITY_CARD_NUMBER, asset.NationalIdentityCardNumber),
		]);
		if (driverFields.length > 0) {
			driverInformationElements.push(
				createAccordionCard(
					"driver",
					"asset-information-accordion",
					strings.DRIVER_FIELDS,
					el("table", el("tbody", driverFields))
				)
			);
		}
	}

	var vehicleInformationElement = null;
	var vehicleFields = _.compact([
		includeRowIfNotNull(strings.VEHICLE_MAKE_AND_MODEL, asset.VehicleMakeAndModel),
		includeRowIfNotNull(strings.VEHICLE_PURCHASE_DATE, asset.VehiclePurchaseDate),
		includeRowIfNotNull(strings.VEHICLE_VIN, asset.VehicleVIN),
		includeRowIfNotNull(strings.PLATE_NUMBER, asset.PlateNumber),
		includeRowIfNotNull(strings.FUEL_EFFICIENCY, asset.FuelEfficiency),
	]);
	if (vehicleFields.length > 0) {
		vehicleInformationElement = createAccordionCard(
			"vehicle",
			"asset-information-accordion",
			strings.VEHICLE_FIELDS,
			el("table", el("tbody", vehicleFields))
		);
	}
	var vesselInformationElement = null;
	var vesselFields = _.compact([
		includeRowIfNotNull(strings.VESSEL_NAME, asset.VesselName),
		includeRowIfNotNull(strings.VESSEL_CALL_SIGN, asset.VesselCallSign),
		includeRowIfNotNull(strings.VESSEL_IMO_NUMBER, asset.VesselIMONumber),
		includeRowIfNotNull(strings.VESSEL_FLAG_REGISTRY, asset.VesselFlagRegistry),
		includeRowIfNotNull(strings.VESSEL_TONNAGE, asset.VesselTonnage),
		includeRowIfNotNull(strings.VESSEL_CLASS, asset.VesselClass),
		includeRowIfNotNull(strings.VESSEL_SKIPPER, asset.VesselSkipper),
		includeRowIfNotNull(strings.VESSEL_MMSI, asset.VesselMMSI),
	]);
	if (vesselFields.length > 0) {
		vesselInformationElement = createAccordionCard(
			"vessel",
			"asset-information-accordion",
			strings.VESSEL_FIELDS,
			el("table", el("tbody", vesselFields))
		);
	}

	var assetInformationElement = null;
	var assetFields = [obdInformationElement, ioInformationElement, generalInformationElement, simInformationElement];
	assetFields = assetFields.concat(driverInformationElements);
	assetFields = assetFields.concat([vehicleInformationElement, vesselInformationElement, garminFormElement]);
	assetFields = assetFields.concat(attributeElements);
	assetFields = _.compact(assetFields);

	if (assetFields.length > 0) {
		assetInformationElement = el(
			"div#asset-information-accordion.asset-information-accordion.position-accordion",
			assetFields
		);
	}

	var extraRow = null;
	if (location.Extra != null && location.Extra != "") {
		extraRow = includeRowIfNotNull(strings.EXTRA, formattedTextToDiv(location.Extra), {
			style: { verticalAlign: "top" },
		}); // TODO: remove inline style
	}
	var addressRow = null;
	if (location.Address != null && location.Address != "" && !asset.HideAddress) {
		addressRow = includeRowIfNotNull(strings.ADDRESS, location.Address, { style: { verticalAlign: "top" } }); // TODO: remove inline style
	}
	var odometerRow = null;
	if (location.Odometer != null) {
		odometerRow = includeRowIfNotNull(strings.ODOMETER, convertFromMetresToUserDistancePreference(location.Odometer));
	}
	var sourceRow = null;
	if (location.Source != null) {
		sourceRow = includeRowIfNotNull(strings.DATA_SOURCE, location.Source);
	}

	var statusRow = includeRowIfNotNull(strings.STATUS, getStatusTextForLocation(location));

	var fencesRow = null;
	if (location.InsideFences != null) {
		var fences = "";
		for (var i = 0; i < location.InsideFences.length; i++) {
			var fenceId = location.InsideFences[i];
			var fence = findFenceById(fenceId);
			if (fence != null) {
				if (fences !== "") {
					fences += ", ";
				}
				fences += fence.Name;
			}
		}
		fencesRow = includeRowIfNotNull(strings.GEOFENCES, fences);
	}

	var speedRow = null;
	if (!asset.HideSpeed) {
		var speed = convertSpeedToPreference(location.Speed);
		if (location.IsEst === true) {
			speed = el("span.estimated", { title: strings.SPEED_ESTIMATED }, [text(speed), el("span.tooltip-circle", "?")]);
		}
		speedRow = includeRowIfNotNull(strings.SPEED, speed);
	}

	var accuracyRows = [];
	if (!asset.HideAccuracy) {
		if (location.Accuracy != null && location.Accuracy !== "") {
			accuracyRows.push(includeRowIfNotNull(strings.ACCURACY, location.Accuracy));
		}
		if (location.HDOP != null) {
			accuracyRows.push(includeRowIfNotNull(strings.HDOP, location.HDOP));
		}
		if (location.NumSats != null) {
			accuracyRows.push(includeRowIfNotNull(strings.SATELLITES, location.NumSats));
		}
	}
	
	var timeElement = el(
		"span.mr-auto",
		location.IsAcc === false
			? el("span.inaccurate", [text(location.Time), el("sup", { title: strings.TIME_INACCURATE }, "*")])
			: text(location.Time)
	);
	var timeContainerElement = el("div.d-flex.align-items-center", timeElement);
	var latLngRow = includeRowIfNotNull(
		strings.LAT_LNG,
		convertToLatLngPreference(location.DisplayLat, location.DisplayLng, location.Grid)
	);

	var mapItemDetailsRows = _.compact([
		messageRow,
		addressRow,
		latLngRow,
		speedRow,
		elevationRow,
		headingRow,
		...accuracyRows,
		odometerRow,
		sourceRow,
		extraRow,
		statusRow,
		fencesRow,
		shockLogRow,
		waypointRow,
	]);
	var mapItemsDetailsElement = null;
	if (mapItemDetailsRows.length > 0) {
		mapItemsDetailsElement = el("table.map-item-details", el("tbody", mapItemDetailsRows));
	}

	var infoElements = [
		options.placeAssetPhotosInPositionDialog ? assetPhotoElement : null,
		timeContainerElement,
		mapItemsDetailsElement,
	];
	if (isMarkerSelected) {
		infoElements = infoElements.concat(playbackContent);
	}
	infoElements.push(eventInformationTable);
	if (isMarkerSelected) {
		infoElements.push(assetInformationElement);
	}
	infoElements = _.compact(infoElements);
	return el("div.markercontent", infoElements);
}

function createOBDOutput(item) {
	var info = [];
	if (item.PowerVoltage != null) {
		info = addToOBDOutput("Power Voltage", item.PowerVoltage, info, "V");
	}
	if (item.VehicleSpeed != null) {
		info = addToOBDOutput("Vehicle Speed", item.VehicleSpeed, info, "mps");
	}
	if (item.ThrottlePosition != null) {
		info = addToOBDOutput("Throttle Position", item.ThrottlePosition, info, "%");
	}
	if (item.EngineRPM != null) {
		info = addToOBDOutput("Engine RPM", item.EngineRPM, info);
	}
	if (item.EngineLoad != null) {
		info = addToOBDOutput("Engine Load", item.EngineLoad, info, "%");
	}
	if (item.EngineCoolantTemperature != null) {
		info = addToOBDOutput("Engine Coolant Temp.", item.EngineCoolantTemperature, info, "°C");
	}
	if (item.FuelLevelPercentage != null) {
		info = addToOBDOutput("Fuel Level", item.FuelLevelPercentage, info, "%");
	}
	if (item.FuelLevelRemaining != null) {
		info = addToOBDOutput("Fuel Level Remaining", item.FuelLevelRemaining, info);
	}
	if (item.FuelConsumption != null) {
		info = addToOBDOutput("Fuel Consumption", item.FuelConsumption, info, "L/100km");
	}
	if (item.FuelRate != null) {
		info = addToOBDOutput("Fuel Rate", item.FuelRate, info);
	}
	if (item.IsMILActive != null) {
		info = addToOBDOutput("MIL Active", item.IsMILActive, info);
	}
	if (item.DTCCount != null) {
		info = addToOBDOutput("DTC Count", item.DTCCount, info);
	}
	if (item.DTCs != null) {
		info = addToOBDOutput("DTCs", item.DTCs.split(",").join(", "), info);
	}
	if (item.DTCsClearedDistance != null) {
		info = addToOBDOutput("DTCs Cleared Distance", item.DTCsClearedDistance, info, "km");
	}
	if (item.MILActivatedDistance != null) {
		info = addToOBDOutput("MIL Activated Distance", item.MILActivatedDistance, info, "km");
	}
	if (item.Odometer != null) {
		info = addToOBDOutput("Odometer", item.Odometer, info, "km");
	}
	if (item.MaintenanceRequired != null) {
		info = addToOBDOutput("Maintenance Required", item.MaintenanceRequired, info);
	}
	return info;
}

function addToOBDOutput(label, value, output, unit) {
	if (unit == null) {
		unit = "";
	}
	return includeRowIfNotNull(label, value + unit);
}

export function createIOOutput(asset, item, isTable) {
	var settings = [];
	settings = addToIOOutput(asset, item.AnalogPin1, "a1", strings.ANALOG_PIN + " 1", settings, isTable);
	settings = addToIOOutput(asset, item.AnalogPin2, "a2", strings.ANALOG_PIN + " 2", settings, isTable);
	settings = addToIOOutput(asset, item.AnalogPin3, "a3", strings.ANALOG_PIN + " 3", settings, isTable);
	settings = addToIOOutput(asset, item.AnalogPin4, "a4", strings.ANALOG_PIN + " 4", settings, isTable);
	settings = addToIOOutput(asset, item.AnalogPin5, "a5", strings.ANALOG_PIN + " 5", settings, isTable);
	settings = addToIOOutput(asset, item.AnalogPin6, "a6", strings.ANALOG_PIN + " 6", settings, isTable);
	settings = addToIOOutput(asset, item.AnalogPin7, "a7", strings.ANALOG_PIN + " 7", settings, isTable);
	settings = addToIOOutput(asset, item.AnalogPin8, "a8", strings.ANALOG_PIN + " 8", settings, isTable);
	settings = addToIOOutput(asset, item.AnalogPin9, "a9", strings.ANALOG_PIN + " 9", settings, isTable);
	settings = addToIOOutput(asset, item.AnalogPin10, "a10", strings.ANALOG_PIN + " 10", settings, isTable);
	settings = addToIOOutput(asset, item.DigitalPin1, "d1", strings.DIGITAL_PIN + " 1", settings, isTable);
	settings = addToIOOutput(asset, item.DigitalPin2, "d2", strings.DIGITAL_PIN + " 2", settings, isTable);
	settings = addToIOOutput(asset, item.DigitalPin3, "d3", strings.DIGITAL_PIN + " 3", settings, isTable);
	settings = addToIOOutput(asset, item.DigitalPin4, "d4", strings.DIGITAL_PIN + " 4", settings, isTable);
	settings = addToIOOutput(asset, item.DigitalPin5, "d5", strings.DIGITAL_PIN + " 5", settings, isTable);
	settings = addToIOOutput(asset, item.DigitalPin6, "d6", strings.DIGITAL_PIN + " 6", settings, isTable);
	settings = addToIOOutput(asset, item.DigitalPin7, "d7", strings.DIGITAL_PIN + " 7", settings, isTable);
	settings = addToIOOutput(asset, item.DigitalPin8, "d8", strings.DIGITAL_PIN + " 8", settings, isTable);
	settings = addToIOOutput(asset, item.DigitalPin9, "d9", strings.DIGITAL_PIN + " 9", settings, isTable);
	settings = addToIOOutput(asset, item.DigitalPin10, "d10", strings.DIGITAL_PIN + " 10", settings, isTable);
	settings = addToIOOutput(asset, item.DigitalPin11, "d11", strings.DIGITAL_PIN + " 11", settings, isTable);
	settings = addToIOOutput(asset, item.DigitalPin12, "d12", strings.DIGITAL_PIN + " 12", settings, isTable);
	settings = addToIOOutput(asset, item.DigitalIngition, "d98", strings.DIGITAL_IGNITION, settings, isTable);
	settings = addToIOOutput(asset, item.DigitalSOS, "d99", strings.DIGITAL_SOS, settings, isTable);
	if (!isTable) {
		return settings.join("");
	}
	return settings;
}

function addToIOOutput(asset, pin, id, defaultLabel, output, isTable) {
	if (pin == null) {
		return output;
	}
	var label = getInputLabelById(asset, id);
	if (label != null && label != "") {
		if (label.IsNormallyOpen) {
			pin = !pin;
		}
		if (label.InputPin.indexOf("d") != -1) {
			// we may have a label override
			var onLabel = strings.ON;
			if (!label.OnIsDisabled && label.OnLabel != null && label.OnLabel != "") {
				onLabel = label.OnLabel;
			}
			var offLabel = strings.OFF;
			if (!label.OffIsDisabled && label.OffLabel != null && label.OffLabel != "") {
				offLabel = label.OffLabel;
			}
			pin = pin ? onLabel : offLabel;
		} else {
			// analog, we may need to factor pin
			if (label.AnalogFactor != null && label.AnalogFactor != "") {
				pin = pin / $j.parseFloat(label.AnalogFactor);
			}
			if (label.AnalogBehavior == 1) {
				//IsFuel
				var level = pin / $j.parseFloat(label.MaxVoltage);
				pin =
					(level * 100).toFixed(2) +
					"% (" +
					(level * $j.parseFloat(label.FuelCapacity)).toFixed(2) +
					" " +
					fuelText() +
					")";
			} else if (label.AnalogUnit != null) {
				pin += " " + label.AnalogUnit;
			}
		}
		var pinLabel = label.Label;
		if (pinLabel == null || pinLabel == "") {
			pinLabel = defaultLabel;
		}
		if (isTable) {
			output.push(includeRowIfNotNull(pinLabel, pin));
		} else {
			output.push(padRight(pinLabel, 15) + ": " + pin + "\n");
		}
	} else {
		if (isTable) {
			output.push(includeRowIfNotNull(defaultLabel, pin));
		} else {
			output.push(padRight(defaultLabel, 15) + ": " + pin + "\n");
		}
	}
	return output;
}

/// TODO: Replace with String.prototype.padEnd
function padRight(input, length) {
	if (input == null) return input;
	if (input == "") return input;
	if (input.length >= length) return input;
	var pad = length - input.length;
	return pad > 0 ? input + new Array(pad).join(" ") : input;
}

function getInputLabelById(asset, id) {
	if (asset == null) return "";
	if (asset.InputLabels == null) return "";
	for (var i = 0; i < asset.InputLabels.length; i++) {
		var label = asset.InputLabels[i];
		if (label.InputPin == id) return label;
	}
	return "";
}
