// Legacy code that runs at init time (**not** function definitions)

import strings from "./strings.js";
import trkData from "./data.js";
import state from "./state.js";
import options from "./options.js";
import preferences, { globalizeNumberFormat } from "./preferences.js";
import { devices } from "./devices.js";
import user, { saveDisplayPreferences } from "./user.js";
import domNodes from "./domNodes.js";
import log from "./log.js";
import { viewModes, mapModes, sortModes } from "./const.js";
import { initRouting } from "./routing.js";
import {
	initPanels,
	closePrimaryPanel,
	closeSecondaryPanel,
	openPrimaryPanel,
	changeActivePanel,
	runReportOptionChanged,
} from "./panel.js";
import { findDeviceById } from "./devices.js";
import { findAssetById } from "./assets.js";
import {
	findFenceById,
	addFencePaths,
	populateGeofenceDocuments,
	indexFencesForSearch,
	updateFence,
	processNewFence,
	cleanupFenceEditing,
	deleteFence,
} from "./fence.js";
import { editFenceSegments } from "./fence-edit.js";
import { map, initMap, enableDragZoom, switchMapMode, disableDragZoom } from "./map-base.js";
import { removeItemFromMap } from "./map-items.js";
import { updateChosenLocation } from "./map-chooselocation.js";
import { openRuler, closeRuler, removeRulerPoint /*, updateRuler*/ } from "./ruler.js";
import {
	findGroupById,
	indexAssetGroupsForSearch,
	queryGroupsAndAssets,
	createGroupColorStyles,
	removeItemFromGroup,
	addItemToGroup,
} from "./asset-group.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";
import { findTripById, deleteTrip } from "./trips.js";
import { convertHexToSortable } from "./color.js";
import { intervals, throttles } from "./timers.js";
import { findPlaceById, deletePlace, processNewPlace } from "./place.js";
import { initializeMouseTooltip } from "./mouse-tooltip.js";
import { findWaypointById, addWaypointMarker } from "./waypoint.js";
import { resizeApp } from "./window-layout.js";
import { querySharedViewData } from "./shared-view.js";
import { initPlaces } from "./place.js";
import { includeRowIfNotNull, svgPath, copyTextToClipboard } from "./dom-util.js";
import { findSharedViewById } from "./shared-view.js";
import { updateAssetState } from "./asset-state.js";
import { initSharedView } from "./shared-view.js";
import { loadDialogButtons, closeButton } from "./modal-dialog-buttons.js";
import { initDialogs } from "./modal-dialog.js";
import { setMapBounds } from "./map-bounds.js";
import { toggleItemSorting, sortModeUpdated, sortGroups } from "./item-sorting.js";
import { findJourneyById } from "./journey.js";
import { queryActiveAssets } from "./assets-active.js";
import { handleAjaxFormSubmission, formShowErrorMessage, formShowSuccessMessage } from "./ajax.js";
import { initDatatables } from "./datatables.js";
import { initIDPCommand } from "./idp-init.js";
import walkthrough from "./walkthrough.js";
import { initAssetEdit, indexAssetsForSearch, deleteAsset } from "./asset-edit.js";
import { getValueIfCheckboxSelected } from "./dom-util.js";
import { initAlerts } from "./alert.js";
import { populateGroupList } from "./group-list.js";
import { initPlayback } from "./playback.js";
import { initAssets } from "./asset-init.js";
import { getAttributesForType } from "./attributes.js";
import { uploadFile } from "./ajax.js";
import { loadHistory } from "./position-history.js";
import { openSharedViewDialog } from "./shared-view-dialog.js";
import { setupSignalR } from "./signalr.js";
import { highlightPosition } from "./positions.js";
import { openActionDialog } from "./modal.js";
import { initGarmin } from "./garmin.js";
import { showOrLoadAssetPosition, clearAssetPositions, updatePositionStatus } from "./asset-positions.js";
import { filterDateClick } from "./item-listing.js";
import {
	findAssetGroupUsersByAssetGroupId,
	addUserToAssetGroup,
	removeUserFromAssetGroup,
	deleteSharedView,
	toggleEventContainer,
	findFenceUsersByFenceId,
	addUserToFence,
	removeUserFromFence,
	handleNotificationItemAction,
	updateUserTime,
	queryUsersForAssets,
	cleanupExpiredLiveData,
	initializeFormValidation,
} from "./legacy.js";
import { populateCustomAttributesEditable } from "./attributes.js";
import { indexSharedViewsForSearch } from "./shared-view.js";
import { openAssetGroupDialog } from "./asset-group.js";
import { indexPlacesForSearch, openPlaceDialog } from "./place.js";
import { openSendMessageDialog } from "./messages.js";
import { openSendMessageToAssetDialog } from "./messages.js";
import { openEditAssetDialog } from "./asset-edit-dialog.js";
import { openGeofenceDialog, findAssetIdsInGeofence } from "./fence.js";
import { initWindowLoad } from "./window-load.js";
import { filterAssets } from "./item-filter.js";
import { validateIMEI, validateIMEIVerificationCode } from "./device-imei.js";
import { loadAssetStatus } from "./asset-state.js";
import { findDriverById } from "./driver.js";
import { initSecondaryPanel, openSendCommandDialog } from "./panel-secondary.js";
import { addOrUpdateWaypoint, updateWaypointListing } from "./waypoint.js";
import { addPlaceMarker } from "./place-marker.js";
import { setLabelsByTemplate, toggleAnalogIOBehavior, deviceDialogChange, addSensorLabel } from "./device-dialog.js";
import { toggleEdgeSolarVisibleElements } from "./edgesolar.js";
import { loadWaypointHistory, loadFillupHistory } from "./asset-history.js";
import { requestInmarsatCInformation, queclinkCreateAPNCommand } from "./queclink.js";
import { populateSidePanel, showSidePanel, hideSidePanel } from "./panel-side.js";
import {
	loadAssetDrivers,
	loadDriverHistory,
	loginAssetDriver,
	updateAssetDriverStatus,
	openCurrentDriversDialog,
} from "./driver.js";
import { filterFences, filterPlaces, filterSharedViews } from "./item-filter.js";
import {
	queryLatestNotifications,
	showSystemNotification,
	checkTextNotifications,
	checkEmergencyNotifications,
	alertUserOfEmergency,
	initEmergencyAudio,
} from "./notifications-system.js";
import { queryAlertsRequiringAcknowledgement, openAcknowledgeAlertDialog } from "./alert.js";
import { userLocationUpdated, userLocationErrorManual, zoomToUserLocation } from "./user-location.js";
import { loadMessageHistory } from "./messages-history.js";
import { toggleAssetActive } from "./asset-select.js";
import {
	addAssetToGroup,
	removeAssetFromGroup,
	addGroup,
	deleteAssetGroup,
	updateGroup,
} from "./asset-group.js";
import { loadAssetAlerts } from "./asset-alerts.js";
import { formattedTextToDiv } from "./dom-util.js";
import { checkVersion } from "./version.js";
import { togglePositionVisibility } from "./positions.js";
import { queryLatestEvents, populateEvents } from "./asset-events.js";
import { initLiveAssets, updateLiveAssets } from "./asset-live.js";
import { waypointGetRoute, waypointMarkComplete, waypointClearRoute } from "./routing-waypoint.js";
import { addressSearch } from "./geocode.js";
import { deleteJourney } from "./journey.js";
import { dialogs } from "./panel-dialog.js";
import { updateItemGroup, deleteItemGroup, openItemGroupDialog } from "./common-group.js";
import { setUrlHistoryForActivePanel } from "./panel-browser-history.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import { el, svg, text, setChildren } from "redom"; // https://redom.js.org/
import moment from "moment"; // https://www.npmjs.com/package/moment
import SimpleBar from "simplebar"; // https://www.npmjs.com/package/simplebar
const Cookies = window.Cookies; // from 'js.cookie.js', v2.2.0

/*global MapToolbar */
// import MapToolbar from '../MapToolbar.js';

export function init() {
	log("Initialize Tracking.");

	// initialize dom references
	domNodes.map = document.getElementById("map");
	domNodes.panels.primary = document.getElementById("panel-primary");
	domNodes.panels.secondary = document.getElementById("panel-secondary");
	domNodes.simpleBars.primary = new SimpleBar(document.getElementById("panel-content-wrapper"));
	domNodes.simpleBars.secondary = new SimpleBar(document.getElementById("panel-secondary-content-wrapper"));
	domNodes.nav.utility = document.getElementById("nav-utility");
	domNodes.nav.primary = document.getElementById("nav-primary");
	domNodes.nav.toggle = document.getElementById("nav-toggle");
	domNodes.content.base = document.getElementById("base-content");
	domNodes.filter.assetResults = document.getElementById("filter-asset-results");
	domNodes.filter.fenceResults = document.getElementById("filter-fence-results");
	domNodes.filter.placeResults = document.getElementById("filter-place-results");
	domNodes.filter.sharedViewResults = document.getElementById("filter-shared-view-results");
	domNodes.mapTools.container = document.getElementById("map-functions");
	domNodes.mapTools.currentTime = document.getElementById("map-time");
	domNodes.mapTools.currentTimeCompact = document.getElementById("map-time-compact");
	domNodes.moduleName = document.getElementById("module-name");
	domNodes.assetsModeLive = document.getElementById("assets-panel-mode-live");
	domNodes.assetsModeHistory = document.getElementById("assets-panel-mode-history");
	domNodes.mapMode.container = document.getElementById("map-mode");
	domNodes.mapMode.show = document.getElementById("btnGo");
	domNodes.mapMode.from = document.getElementById("map-mode-details-history-from");
	domNodes.mapMode.to = document.getElementById("map-mode-details-history-to");
	domNodes.mapMode.liveLoaded = document.getElementById("map-mode-details-live-from");
	domNodes.mapMode.visibleAssetsLive = document.getElementById("visible-assets-live");
	domNodes.mapMode.visibleAssetsHistory = document.getElementById("visible-assets-history");
	domNodes.mapMode.visiblePositionsHistory = document.getElementById("visible-positions-history");
	domNodes.mapMode.modeLiveButton = document.getElementById("history-live");
	domNodes.mapMode.excessData = document.getElementById("map-mode-excess-data");
	domNodes.mapMode.dateRange = document.getElementById("form-history-date-range");
	domNodes.mapTools.visibleSummary = document.getElementById("map-visible-summary");
	domNodes.assetNotificationMenu = document.getElementById("asset-notification-menu");
	domNodes.assetNotificationMenu.parentNode.removeChild(domNodes.assetNotificationMenu);
	domNodes.assetNotificationMenu.classList.remove("toggle-content");
	domNodes.tripNotificationMenu = domNodes.assetNotificationMenu.cloneNode(true);
	domNodes.followLiveStatus = document.getElementById("live-follow-status");
	domNodes.infoDialogs.eventDetails = document.getElementById("event-details-dialog");
	domNodes.infoDialogs.garminSubmission = document.getElementById("garmin-submission-dialog");
	domNodes.infoDialogs.mapItemInformation = document.getElementById("map-item-information-dialog");
	domNodes.infoDialogs.positionHistory = document.getElementById("position-history-dialog");
	domNodes.infoDialogs.ruler = document.getElementById("ruler-dialog");
	domNodes.infoDialogs.sharedViewInformation = document.getElementById("shared-view-information-dialog");

	domNodes.modals.clearAssetHistory = document.getElementById("clear-history-modal");
	domNodes.modals.confirmAssetSetting = document.getElementById("confirm-asset-setting-modal");
	domNodes.modals.eventFilter = document.getElementById("event-filter-modal");
	domNodes.modals.idpActivate = document.getElementById("idp-activate-modal");
	domNodes.modals.idpDeactivate = document.getElementById("idp-deactivate-modal");
	domNodes.modals.idpDisableEncryption = document.getElementById("idp-encryption-disable-modal");
	domNodes.modals.mapLegend = document.getElementById("map-legend-modal");
	domNodes.modals.messageAction = document.getElementById("message-action-modal");
	domNodes.modals.runReport = document.getElementById("run-report-modal");
	domNodes.modals.uploadFile = document.getElementById("upload-file-modal");

	domNodes.dialogs.acknowledgeAlert = document.getElementById("acknowledge-alert-dialog");
	domNodes.dialogs.addPlace = document.getElementById("add-place-dialog");
	domNodes.dialogs.addPosition = document.getElementById("add-position-dialog");
	domNodes.dialogs.assetActivity = document.getElementById("asset-activity-dialog");
	domNodes.dialogs.assetAlerts = document.getElementById("asset-alerts-dialog");
	domNodes.dialogs.assetChat = document.getElementById("asset-chat-dialog");
	domNodes.dialogs.assetEvents = document.getElementById("asset-events-dialog");
	domNodes.dialogs.assetMessages = document.getElementById("asset-messages-dialog");
	domNodes.dialogs.assetPositions = document.getElementById("asset-positions-dialog");
	domNodes.dialogs.assetStatus = document.getElementById("asset-status-dialog");
	domNodes.dialogs.calamp = document.getElementById("calamp-dialog");
	domNodes.dialogs.currentDrivers = document.getElementById("current-drivers-dialog");
	domNodes.dialogs.dPlus = document.getElementById("dplus-dialog");
	domNodes.dialogs.driverHistory = document.getElementById("drivers-dialog");
	domNodes.dialogs.edge = document.getElementById("edge-dialog");
	domNodes.dialogs.edgeSolar = document.getElementById("edge-solar-dialog");
	domNodes.dialogs.editAsset = document.getElementById("edit-asset-dialog");
	domNodes.dialogs.editGroup = document.getElementById("add-group-dialog");
	domNodes.dialogs.extreme = document.getElementById("extreme-dialog");
	domNodes.dialogs.fbb = document.getElementById("fbb-dialog");
	domNodes.dialogs.flightcell = document.getElementById("flightcell-dialog");
	domNodes.dialogs.garminFormsHistory = document.getElementById("garmin-forms-dialog");
	domNodes.dialogs.geofence = document.getElementById("geofence-dialog");
	domNodes.dialogs.geopro = document.getElementById("geopro-dialog");
	domNodes.dialogs.gtts = document.getElementById("gtts-dialog");
	domNodes.dialogs.hughes = document.getElementById("hughes-dialog");
	domNodes.dialogs.idpOutput = document.getElementById("idp-output-dialog");
	domNodes.dialogs.idpSendCommand = document.getElementById("idp-dialog");
	domNodes.dialogs.inmarsatC = document.getElementById("inmarsatc-dialog");
	domNodes.dialogs.inreach = document.getElementById("inreach-dialog");
	domNodes.dialogs.ioHistory = document.getElementById("io-dialog");
	domNodes.dialogs.itrac = document.getElementById("itrac-dialog");
	domNodes.dialogs.lt100 = document.getElementById("lt100-dialog");
	domNodes.dialogs.lt501 = document.getElementById("lt501-dialog");
	domNodes.dialogs.messageHistory = document.getElementById("message-dialog");
	domNodes.dialogs.nal = document.getElementById("nal-dialog");
	domNodes.dialogs.quake = document.getElementById("quake-dialog");
	domNodes.dialogs.queclink = document.getElementById("queclink-dialog");
	domNodes.dialogs.refuelHistory = document.getElementById("refuel-dialog");
	domNodes.dialogs.routing = document.getElementById("routing-dialog");
	domNodes.dialogs.sendMessage = document.getElementById("send-message-dialog");
	domNodes.dialogs.sendPosition = document.getElementById("send-position-dialog");
	domNodes.dialogs.serviceMeterHistory = document.getElementById("service-meter-dialog");
	domNodes.dialogs.sharedView = document.getElementById("shared-view-dialog");
	domNodes.dialogs.sharedViewShare = document.getElementById("shared-view-share-dialog");
	domNodes.dialogs.sharedViewStatistics = document.getElementById("shared-view-statistics-dialog");
	domNodes.dialogs.tm3000 = document.getElementById("tm3000-dialog");
	domNodes.dialogs.waypointHistory = document.getElementById("waypoint-history-dialog");

	options.defaultColor = window.getComputedStyle(document.getElementById("nav-toggle")).backgroundColor;
	state.openPanels.primary =
		(window.screen.width > 768 && !domNodes.panels.primary.classList.contains("is-closed")) ||
		domNodes.panels.primary.classList.contains("is-opened");

	updateUserTime();
	walkthrough.initialize();

	var button = document.getElementById("toggle-nav-primary");
	if (domNodes.panels.primary.style.display !== "flex") {
		button.classList.remove("active");
		button
			.querySelector("use")
			.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#expand");
	}

	if (options.disableMapTypeSelection) {
		var mapType = document.getElementById("map-type-select");
		mapType.classList.add("d-none");
	}

	if (options.disableLayerSelection) {
		var mapType = document.getElementById("map-layers-select");
		mapType.classList.add("d-none");
	}

	if (options.disablePanels) {
		var eventControl = document.getElementById("event-control-btn");
		var alertsControl = document.getElementById("alerts-control-btn");
		eventControl.classList.add("d-none");
		alertsControl.classList.add("d-none");
	}

	if (user.isAnonymous && !options.showSidePanel) {
		closePrimaryPanel();
	}
	if (options.disableHeader) {
		$j("#head").addClass("anonymous");
	}

	if (options.isCompact) {
		// this need to be done after all data load or history view change needs to check for first load
		domNodes.mapTools.container.classList.add("is-closed");
		domNodes.mapMode.container.classList.add("is-minimized");
		domNodes.mapMode.dateRange.classList.remove("is-visible");
	}

	var layerList = document.getElementById("map-layers-list");
	if (layerList.querySelectorAll("a").length === 0) {
		layerList.classList.add("disabled");
	}

	dialogs.closeButton = {
		buttonType: "secondary",
		icons: { primary: "ui-icon-close" },
		text: strings.CANCEL,
		click: function () {
			document.getElementById("panel-dialog-back").click();
		},
	};

	dialogs.closePanelButton = {
		buttonType: "secondary",
		icons: { primary: "ui-icon-close" },
		text: strings.CANCEL,
		click: function () {
			closeSecondaryPanel();
		},
	};

	state.promises.getGroupsAndAssets = queryGroupsAndAssets();
	state.promises.getAssetUsers = queryUsersForAssets();

	$.fn.dataTable.moment(user.dateFormat);
	$.datepicker.parseDate = function (format, value) {
		if (value === "" || value === null) {
			return moment().toDate();
		}
		return moment(value, format).toDate();
	};
	$.datepicker.formatDate = function (format, value) {
		if (value === "" || value === null) {
			return moment().format(format);
		}
		return moment(value).format(format);
	};

	throttles.queryLatestNotifications = _.throttle(queryLatestNotifications, 5000);
	throttles.updateLiveAssets = _.throttle(updateLiveAssets, 5000);
	throttles.queryLatestEvents = _.throttle(queryLatestEvents, 5000);
	throttles.updatePositionStatus = _.throttle(updatePositionStatus, 3000);
	throttles.resizeApp = _.throttle(resizeApp, 100);
	throttles.emergencyAlert = _.throttle(alertUserOfEmergency, 2000, { trailing: false });

	//$j(".multiselect").multiselect({ sortable: true, searchable: false });

	intervals.version = setInterval(checkVersion, 1000 * 60 * 10);
	intervals.emergency = setInterval(checkEmergencyNotifications, 1000 * 10 * 1);
	intervals.text = setInterval(checkTextNotifications, 1000 * 60 * 1);
	intervals.currentTime = setInterval(updateUserTime, 1000 * 5 * 1);
	intervals.cleanupExpiredData = setInterval(cleanupExpiredLiveData, 1000 * 60 * 30);

	$j("#fence-tooltip").tooltip({
		track: true,
		content: "Nice!",
		position: {
			my: "left+15 bottom+15",
			//                at: 'right center',
			of: "#panel",
		},
	});

	if (preferences.PREFERENCE_EMERGENCY_AUDIO) {
		initEmergencyAudio();
	}

	$j("#event-panel-container")
		.resizable({ handles: "n", minHeight: 100 })
		.on("resize", function (e, ui) {
			$j(this).css("top", "auto");
			$j("#event-panel-events,#event-panel-alerts", this).css("max-height", $j(this).height() - 45);
			throttles.resizeApp(true);
		});
	$j("#event-control-btn").on("click", "a", function (e) {
		e.preventDefault();
		toggleEventContainer(false);
	});
	$j("#alerts-control-btn").on("click", "a", function (e) {
		e.preventDefault();
		toggleEventContainer(true);
	});

	initializeFormValidation();
	//$('#event-panel-tabs').on('tabsbeforeactivate', function (event, ui) {
	//    if (ui.newTab[0].id === 'event-panel-close')
	//        toggleEventContainer();
	//});
	//$j('#event-panel-tabs').on('click', 'li', function (e) {
	//    e.stopPropagation();
	//});
	//$j('#event-panel-tabs').on('click', 'ul', function (e) {
	//    e.preventDefault();
	//    toggleEventContainer();
	//});

	$("#dialog-functions").on("click", ".list-container .alert-acknowledge-icon", function (e) {
		var listing = this.parentNode.parentNode.parentNode;
		var location = listing.querySelector(".event-location");
		if (location !== null) {
			if (!location.classList.contains("is-visible")) {
				location.classList.add("is-visible");
			}
		}
		var details = listing.querySelector(".event-details");
		$(details).collapse("show");

		var textarea = listing.querySelector("textarea");
		if (textarea !== undefined && textarea !== null) {
			textarea.focus();
		}

		domNodes.simpleBars.secondary.recalculate();
	});

	$(".list-container").on("hide.bs.collapse", function (e) {
		e.target.previousElementSibling
			.querySelector(".list-item-details use")
			.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#expand");
	});
	$(".list-container").on("show.bs.collapse", function (e) {
		e.target.previousElementSibling
			.querySelector(".list-item-details use")
			.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#collapse");
	});

	$("#dialog-functions").on(
		"click",
		".list-container .alert-details, .list-container .event-details-header a",
		function (e) {
			// TODO handle multiple in listing
			e.preventDefault();
			var header = this.parentNode;
			var details = header.nextElementSibling;
			var listing = this.parentNode.parentNode;
			var icon = header.querySelector(".alert-details");
			// what is this
			var location = listing.querySelector(".event-location");
			if (location !== null) {
				if (location.classList.contains("is-visible")) {
					location.classList.remove("is-visible");
				} else {
					location.classList.add("is-visible");
				}
			}
			//var details = listing.querySelector('.event-details');
			if (details !== null) {
				if (details.classList.contains("is-visible")) {
					details.classList.remove("is-visible");
					icon.classList.remove("active");
					icon
						.querySelector("use")
						.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#expand");
				} else {
					details.classList.add("is-visible");
					icon.classList.add("active");
					icon
						.querySelector("use")
						.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#collapse");
				}
			}
			domNodes.simpleBars.secondary.recalculate();
		}
	);

	$j("#event-panel-container").on("click", "#event-panel-close", function (e) {
		e.preventDefault();
		toggleEventContainer();
	});
	$j("#event-panel-container").on("click", ".panel-header a", function (e) {
		e.preventDefault();
		toggleEventContainer();
	});
	$j("#event-panel-container").on("click", ".panel-header a", function (e) {
		e.preventDefault();
		toggleEventContainer();
	});

	$j("#event-panel-container").on("click", "#ClearEvents", function (e) {
		e.preventDefault();
		populateEvents([]);
	});

	$(window).on("resize", resizeApp);

	// label enabling/disabling
	$j("#labels-output,#labels-input-digital").on("click", "input[type=checkbox]", function (e) {
		var isChecked = $j(this).prop("checked");
		$j(this).parent().parent().parent().next("input").prop("disabled", !isChecked);
	});

	$j("#labels-input-analog").on("change", "select.behavior", function (e) {
		toggleAnalogIOBehavior(this);
	});

	$(domNodes.modals.uploadFile).on("change", "#UploadGeofencesMerge", function (e) {
		if (this.checked) {
			$("#UploadGeofenceMergeSingle").show();
			$("#MergeGeofenceName").prop("disabled", false);
		} else {
			$("#UploadGeofenceMergeSingle").hide();
			$("#MergeGeofenceName").prop("disabled", true);
		}
	});
	$(domNodes.modals.uploadFile).on("click", "#UploadKML", function (e) {
		e.preventDefault();

		toggleLoadingMessage(true, "upload-kml");
		var status = document.getElementById("upload-file-status");
		var button = this;
		button.disabled = true;
		if (
			!uploadFile("fileKML", "/KML/UploadKML", {}, function (res) {
				button.disabled = false;
				toggleLoadingMessage(false, "upload-kml");
				if (res === "" || res === "empty") {
					// plugin does not handle empty result properly
					return;
				}
				if (res.Success === true) {
					$("#UploadFileGeofencesMerge").hide();
					$("#UploadFilePreview").show();
					var places = $("#UploadFilePlaces ul").empty();
					var geofences = $("#UploadFileGeofences ul").eq(0).empty();
					var hasItems = false;
					var i;
					var li;
					if (res.Places !== null) {
						if (res.Places.length === 0) {
							places.append($("<li>").text(strings.UPLOAD_FILE_NO_PLACES));
						}
						for (i = 0; i < res.Places.length; i++) {
							hasItems = true;
							var place = res.Places[i];
							li = $("<li>").append(
								$('<div class="custom-control custom-checkbox">')
									.append(
										$(
											'<input class="custom-control-input" type="checkbox" checked="checked" id="Place' + i + '">'
										).data("place", place)
									)
									.append($('<label class="custom-control-label" for="Place' + i + '">').text(place.Name))
							);
							places.append(li);
						}
					}
					if (res.Fences !== null) {
						if (res.Fences.length === 0) {
							geofences.append($("<li>").text(strings.UPLOAD_FILE_NO_GEOFENCES));
						}

						for (i = 0; i < res.Fences.length; i++) {
							hasItems = true;
							var geofence = res.Fences[i];
							li = $("<li>").append(
								$('<div class="custom-control custom-checkbox">')
									.append(
										$(
											'<input class="custom-control-input" type="checkbox" checked="checked" id="Geofence' + i + '">'
										).data("geofence", geofence)
									)
									.append($('<label class="custom-control-label" for="Geofence' + i + '">').text(geofence.Name))
							);
							geofences.append(li);
						}
						if (res.Fences.length > 1) {
							$("#UploadFileGeofencesMerge").show();
						}
					}
					if (hasItems) {
						$("#ImportPlacesAndFences")[0].disabled = false;
					} else {
						$("#ImportPlacesAndFences")[0].disabled = true;
					}
				} else {
					formShowErrorMessage(status, res.ErrorMessage);
				}
			})
		) {
			button.disabled = false;
			toggleLoadingMessage(false, "upload-kml");
			formShowErrorMessage(status, strings.MSG_UPLOAD_FILE_CHOOSE);
		}
	});
	$(domNodes.modals.uploadFile).on("click", "#ImportPlacesAndFences", function (e) {
		e.preventDefault();

		var modal = $(domNodes.modals.uploadFile);
		var status = document.getElementById("upload-file-status");
		var button = this;

		var places = [];
		$j("#UploadFilePlaces input:checked").each(function (index, elem) {
			var place = $j(this).data("place");
			if (place !== undefined) {
				places.push(place);
			}
		});

		var fences = [];
		$j("#UploadFileGeofences input:checked").each(function (index, elem) {
			var fence = $j(this).data("geofence");
			if (fence !== undefined) {
				fences.push(fence);
			}
		});

		var request = {
			Places: places,
			Fences: fences,
			MergeFences: $("#UploadGeofencesMerge").prop("checked"),
			FenceName: $("#MergeGeofenceName").val(),
		};
		var data = request;

		handleAjaxFormSubmission(
			"UploadPlacesAndGeofences",
			data,
			button,
			status,
			null,
			strings.MSG_IMPORT_ERROR,
			function (result) {
				try {
					if (result.Fences !== null) {
						for (let i = 0; i < result.Fences.length; i++) {
							var fence = result.Fences[i];
							processNewFence(fence);
							if (trkData.fenceUsers !== null) {
								trkData.fenceUsers.push({ FenceId: fence.Id, UserIds: [] });
							}
						}
					}
					if (result.Places !== null) {
						for (let i = 0; i < result.Places.length; i++) {
							var place = result.Places[i];
							processNewPlace(place);
							if (trkData.placeUsers !== null) {
								trkData.placeUsers.push({ PlaceId: place.Id, UserIds: [] });
							}
						}
					}
					modal.modal("hide");
				} catch (e) {
					console.error(e);
					throw e;
				}
			}
		);
	});
	if (user.isAnonymous) {
		$("#upload-file").prop("disabled", true);
		$('.dropdown-item[data-action="upload-file"]').addClass("disabled");
	}
	//$j('#map-functions').on('click', '#upload-file', function (e) {
	//    e.preventDefault();

	//    document.getElementById('ImportPlacesAndFences').disabled = true;
	//    var status = document.getElementById('upload-file-status');
	//    clearStatusMessage(status);
	//    $(domNodes.modals.uploadFile).modal('show');
	//    $('#UploadGeofencesMerge').prop('checked', false);
	//    $('#MergeGeofenceName').val('');
	//    $j('#UploadFilePreview,#UploadFileGeofencesMerge').hide();
	//});

	$(domNodes.modals.eventFilter).on("click", "#event-filter-filter", function (e) {
		var eventFilters = [];
		var allChecked = $("#event-filter-all").prop("checked");
		if (allChecked) {
			eventFilters.push("all");
		} else {
			var items = $("#event-filter-modal .custom-control-input").not("#event-filter-all");
			items.each(function () {
				if ($(this).prop("checked")) {
					eventFilters.push(parseInt($(this).val()));
				}
			});
		}

		if (state.activeMapMode === mapModes.LIVE) {
			trkData.live.eventFilters = eventFilters;
		} else if (state.activeMapMode === mapModes.HISTORY) {
			trkData.history.eventFilters = eventFilters;
		}

		$(domNodes.modals.eventFilter).modal("hide");

		$("#event-data").DataTable().draw();
	});

	$("#event-filter-modal").on("click", "#event-filter-none", function (e) {
		e.preventDefault();
		$("#event-filter-modal input").prop("checked", false).prop("disabled", false);
	});

	$("#event-filter-modal").on("change", "#event-filter-all", function (e) {
		var checked = $(this).prop("checked");
		if (checked) {
			$("#event-filter-modal input").prop("checked", true).prop("disabled", true);
			$(this).prop("disabled", false);
		} else {
			$("#event-filter-modal input").prop("disabled", false);
		}
	});

	$("#event-panel-container").on("click", "#FilterEvents", function (e) {
		e.preventDefault();
		$("#event-filter-modal").modal("show");
		// populate li items appopriately...
	});

	$(domNodes.infoDialogs.mapItemInformation).on("click", "#WaypointMarkComplete", function (e) {
		e.preventDefault();

		var waypoint = findWaypointById(parseInt(this.getAttribute("data-waypoint-id")));
		waypointMarkComplete(waypoint);
	});

	$(domNodes.infoDialogs.mapItemInformation).on("click", "#WaypointRequestETAUpdate", function (e) {
		e.preventDefault();
		var button = $(this).prop("disabled", true);
		var waypointId = $(this).data("waypointId");
		var waypoint = findWaypointById(waypointId);

		if (waypoint == null) {
			return;
		}
		var asset = findAssetById(waypoint.AssetId);

		if (asset == null) {
			return;
		}
		toggleLoadingMessage(true, "waypoint-eta");
		var dataPost = {
			assetId: asset.Id,
		};
		$.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/SendGarminStopETARequest"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						$("#WaypointRequestETASuccess").show().fadeOut(5000);
					} else {
						handleWebServiceError(strings.WAYPOINT_ETA_ERROR);
					}
				}
				toggleLoadingMessage(false, "waypoint-eta");
				button.prop("disabled", false);
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.WAYPOINT_ETA_ERROR);
				toggleLoadingMessage(false, "waypoint-eta");
				button.prop("disabled", false);
			},
		});
	});

	$(domNodes.infoDialogs.mapItemInformation).on("click", "#WaypointRemoveRoute", function (e) {
		e.preventDefault();

		var waypoint = findWaypointById(parseInt(this.getAttribute("data-waypoint-id")));
		waypointClearRoute(waypoint);
	});

	$(domNodes.infoDialogs.mapItemInformation).on("click", "#WaypointGetRoute", function (e) {
		e.preventDefault();

		var waypoint = findWaypointById(parseInt(this.getAttribute("data-waypoint-id")));
		waypointGetRoute(waypoint);
	});

	$j("#alert-data,#message-alerts").on("click", "button.AlertAcknowledge", function (e) {
		e.preventDefault();
		var eventId = parseInt($j(this).data("alert"));
		var event = _.find(trkData.alerts, function (alert) {
			return alert.Id === eventId;
		});
		if (event !== undefined) {
			openAcknowledgeAlertDialog(null, event);
			//openAlertsRequiringAcknowledgementDialog(event);
		}
	});

	$j("#map-item-information-dialog,#event-panel-events").on("click", "a.beacon-place", function (e) {
		e.preventDefault();
		var placeId = $j(this).data("placeId");
		var place = findPlaceById(placeId);
		if (place != null) {
			// take to place
			for (var i = 0; i < trkData.placeMarkers.length; i++) {
				// var markerPlaceId = $j.data(trkData.placeMarkers[i], 'placeId');
				var markerPlaceId = trkData.placeMarkers[i].data.placeId;
				if (markerPlaceId == placeId) {
					trkData.placeMarkers[i].fire("click");
					break;
				}
			}
		}
	});
	initPlaces();

	$j("#event-panel-container,#acknowledge-alert-dialog,#message-alerts").on("click", "a.location", function (e) {
		e.preventDefault();

		var positionId = $j(this).attr("data-marker");
		var assetId = parseInt($j(this).attr("data-asset"));
		showOrLoadAssetPosition(positionId, assetId);
	});

	$j("#acknowledge-alert-dialog").on("click", ".alert-ack-type", function (e) {
		//#AcknowledgeDetails
		e.preventDefault();
		$j(this).parent().parent().find("a.location").trigger("click");
	});

	$j("#garmin-forms-dialog,#drivers-dialog,#position-history-dialog").on("click", "a.location", function (e) {
		e.preventDefault();

		var positionId = $j(this).attr("data-marker");
		var assetId = $j(this).parents("div.dialog").data("assetId");
		showOrLoadAssetPosition(positionId, assetId);
	});

	$j("#event-data").on("click", "button.ViewPhoto", function (e) {
		e.preventDefault();

		var photoData = $j(this).data("photo");
		var assetId = $j(this).data("assetId");
		var positionId = $j(this).parent().parent().find("a.location").attr("data-marker");
		if (positionId != null) {
			$j.when(showOrLoadAssetPosition(positionId, assetId)).done(function () {
				// photo should be displayed in event detail
			});
		} else {
			// tooltip with photo above the button?
			$j(this)
				.tooltip()
				.tooltip("close")
				.tooltip("option", "content", el("img.fatigue-image", { src: "data:image/jpeg;base64, " + photoData }))
				.tooltip("open");
		}
	});

	initGarmin();

	$("#form-add-geofence").on("click", "button.add-alert", function (e) {
		e.preventDefault();
		var link = this.getAttribute("data-action");
		window.location = link;
	});

	$j("#form-edit-asset").on("click", "a.portal-registration-help", function (e) {
		e.preventDefault();
		$("#iridium-verification-modal").modal("show");
	});

	$j("body").on("click", "#jGrowl button.acknowledge", function (e) {
		e.preventDefault();
		var event = null;
		var eventId = $j(this).data("eventId");
		for (var i = 0; i < trkData.alerts.length; i++) {
			if (trkData.alerts[i].Id == eventId) {
				event = trkData.alerts[i];
				break;
			}
		}
		if (event != null) {
			var asset = findAssetById(event.AssetId);
			openAcknowledgeAlertDialog(asset, event);
			//openAlertsRequiringAcknowledgementDialog(event);
		}
	});

	$("body").on("click", "#jGrowl button.notification-position", function (e) {
		e.preventDefault();

		var assetId = this.getAttribute("data-asset-id");
		var positionId = this.getAttribute("data-position-id");
		var text = $(this).parent().prev().find("div.message").text();

		showOrLoadAssetPosition(positionId, assetId, text);
	});

	// message notification acknowledgements
	$("body").on("click", "#jGrowl .reply-to-text", function (e) {
		e.preventDefault();
		if (user.isAnonymous && !options.allowAnonymousMessaging) {
			return;
		}
		var id = this.getAttribute("data-asset-id");
		var asset = findAssetById(id);
		openSendMessageToAssetDialog(asset);
	});

	$("body").on("click", "#jGrowl button.confirm", function (e) {
		e.preventDefault();
		if (user.isAnonymous && !options.allowAnonymousMessaging) {
			return;
		}
		var btn = this;
		var id = btn.getAttribute("data-notification-id");
		if (id !== undefined) {
			id = id.toString();
			if (id.indexOf("notify") !== -1) {
				var notify = Cookies.get("notif");
				var confirmedNotifications = [];
				if (notify !== undefined) {
					confirmedNotifications = notify.split(",");
				}
				confirmedNotifications.push(id.substring(7));
				Cookies.set("notif", confirmedNotifications.join(","), { expires: 365, path: "/", secure: true });
				$("#jGrowl div[data-notification-id=" + id + "]")
					.trigger("jGrowl.close")
					.find("button")
					.prop("disabled", true);
				return;
			}
		}
		var assetId = this.getAttribute("data-asset-id");
		var asset = findAssetById(assetId);
		btn.disabled = true;
		var dataPost = { id: id };
		$.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/ConfirmNotification"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				if (msg.d && asset != null && asset.UnconfirmedMessages != null) {
					var index = _.indexOf(asset.UnconfirmedMessages, id.toString());
					if (index !== -1) {
						asset.UnconfirmedMessages.splice(index, 1);
						throttles.updatePositionStatus();

						// remove confirmed message from unread indexes
						if (trkData.live.messageCountsByAssetId[asset.Id] !== undefined) {
							var unreadIndex = _.indexOf(trkData.live.messageCountsByAssetId[asset.Id].FromMobileUnread, id);
							if (unreadIndex !== -1) {
								trkData.live.messageCountsByAssetId[asset.Id].FromMobileUnread.splice(unreadIndex, 1);
							}
						}
					}
				}
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_CONFIRM_NOTIFICATION_ERROR);
				btn.disabled = false;
			},
		});
	});

	$j("#edit-asset-inmarsatc").on("click", "input[name=rbInmarsatCActivate]", function (e) {
		if ($j(this).val() == "false") {
			$j("#inmarsatc-gateway-details").show();
			$j("#txtEditAssetInmarsatCDNID").rules("add", { required: true });
			$j("#txtEditAssetInmarsatCMemberNumber").rules("add", { required: true });
		} else {
			$j("#inmarsatc-gateway-details").hide();
			$j("#txtEditAssetInmarsatCDNID").rules("remove", "required");
			$j("#txtEditAssetInmarsatCMemberNumber").rules("remove", "required");
		}
	});

	//$j('#edit-asset-inmarsatc').on('click', '#InmarsatCDNIDDownload', function (e) {
	//    e.preventDefault();

	//    // ajax portal registration
	//    var id = $j('#hfEditAssetId').val();
	//    var status = $j('#edit-asset-status');
	//    status.text('').hide(); // clear previous status
	//    toggleLoadingMessage(true, 'dnid-download');
	//    var button = $j(this).prop('disabled', true);
	//    var dataPost = { assetId: id };
	//    $j.ajax({
	//        type: 'POST',
	//        url: wrapUrl('/Services/GPSService.asmx/InmarsatCDNIDDownload'),
	//        data: JSON.stringify(dataPost),
	//        contentType: 'application/json; charset=utf-8',
	//        dataType: 'json',
	//        success: function (msg) {
	//            button.prop('disabled', false);
	//            toggleLoadingMessage(false, 'dnid-download');
	//            var result = msg.d;
	//            if (result) {
	//                if (result.Success == true) {
	//                    status.show().text(strings.DNID_DOWNLOAD_SUCCESS).addClass('success');
	//                }
	//            }
	//        },
	//        error: function (xhr, status, error) {
	//            button.prop('disabled', false);
	//            handleWebServiceError(strings.DNID_DOWNLOAD_ERROR);
	//            toggleLoadingMessage(false, 'dnid-download');
	//        }
	//    });
	//});

	$j("#PortalRegistrationCode").on("click", "#SendPortalRegistration", function (e) {
		e.preventDefault();

		// ajax portal registration
		var id = $j("#hfEditAssetId").val();
		var status = $j("#edit-asset-status");
		status.text("").hide(); // clear previous status
		toggleLoadingMessage(true, "portal-registration");
		$j(this).prop("disabled", true);
		var dataPost = { assetId: id };
		$j.ajax({
			type: "POST",
			url: wrapUrl("/Services/GPSService.asmx/SendPortalRegistration"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				toggleLoadingMessage(false, "portal-registration");
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						status.show().text("Portal registration successfully sent.").addClass("success");
					}
				}
			},
			error: function (xhr, status, error) {
				handleWebServiceError("An error occurred while sending the portal registration.");
				toggleLoadingMessage(false, "portal-registration");
			},
		});
	});

	$j("#form-edit-asset").on("keyup", "#txtEditAssetUniqueId", function (e) {
		validateIMEI(this);
	});

	$j("#form-edit-asset").on("keyup", "#txtEditAssetIMEIVerificationCode", function (e) {
		validateIMEIVerificationCode(this);
	});

	$j("#form-edit-asset").on("change", "#ddlEditAssetAddressBookGroup,#ddlEditAssetCannedMessageGroup", function (e) {
		if (state.isAddingAsset) return;
		if (!options.enableShoutConfigOta) return;

		var asset = findAssetById($j("#hfEditAssetId").val());
		if (asset != null) {
			if (_.indexOf(devices.NAL, asset.DeviceId) !== -1) {
				$j("#edit-asset-sync-ota").show();
			}
		}
	});

	$j("#form-edit-asset").on("change", "#ddlEditAssetDevice", function (e) {
		deviceDialogChange(this, null);
	});

	$("#form-edit-asset").on("change", "#ddlEditAssetDeviceManufacturer", function (e) {
		var mfr = $(this).val();
		// filter device list by manufacturer
		var isFiltered = mfr == "";
		var filteredDevices = isFiltered
			? _.filter(trkData.devices, function (item) {
					return item.IsActive === true;
			  })
			: _.filter(trkData.devices, function (item) {
					return item.Manufacturer === mfr && item.IsActive === true;
			  });
		filteredDevices = filteredDevices
			.sort(function (a, b) {
				return a.Name.localeCompare(b.Name);
			})
			.sort(function (a, b) {
				return a.Manufacturer.localeCompare(b.Manufacturer);
			});
		//filteredDevices = _.chain(filteredDevices).sortBy('Name').sortBy('Manufacturer').value();
		var deviceList = document.getElementById("ddlEditAssetDevice");
		var priorValue = deviceList.value;
		$(deviceList).find("option").not("[value='']").remove();

		filteredDevices.forEach(function (device) {
			var option = document.createElement("option");
			option.value = device.Id;
			option.textContent = isFiltered ? device.Manufacturer + ": " + device.Name : device.Name;
			option.selected = filteredDevices.length === 1 || option.value === priorValue; // auto-select option if it is the only one
			deviceList.appendChild(option);
		});
		deviceDialogChange(deviceList, null);
	});

	$j("#edit-asset-dialog").on("click", "#RefreshAssetAlerts", function (e) {
		e.preventDefault();
		var assetId = $j("#hfEditAssetId").val();
		var asset = findAssetById(assetId);
		if (asset != null) loadAssetAlerts(asset);
	});

	$(document).on("click", ".dialog-titlebar .item-close", function (e) {
		var dialog = document.getElementById(this.getAttribute("data-for-dialog"));
		if (dialog.getAttribute("data-manual-close") !== "false") {
			$(dialog).dialog("close");
		} else {
			if (dialog.manualCloseCallback !== undefined && dialog.manualCloseCallback !== null) {
				dialog.manualCloseCallback();
			}
		}
	});

	$(document).on("click", ".dialog-titlebar .item-collapse", function (e) {
		var dialog = document.getElementById(this.getAttribute("data-for-dialog"));
		if (dialog.getAttribute("data-collapsed") === "true") {
			// expand
			//dialog.style.display = 'block';
			dialog.setAttribute("data-collapsed", "false");
			this.querySelector("use").setAttributeNS("http://www.w3.org/1999/xlink", "href", svgPath("angle-up"));
		} else {
			//dialog.style.display = 'none';
			dialog.setAttribute("data-collapsed", "true");
			this.querySelector("use").setAttributeNS("http://www.w3.org/1999/xlink", "href", svgPath("angle-down"));
		}
	});

	$j("body").on("click", "button.delete-refuel", function (e) {
		e.preventDefault();

		// open confirm dialog
		$j("#hfDeleteFillupId").val($(this).data("refuelId"));
		$j("#hfDeleteFillupAssetId").val($("#hfRefuelAssetId").val());
		var deleteFillupModal = $("#delete-fillup-modal");
		deleteFillupModal.modal("show");
	});

	$j("#AssetAlerts").on("click", "button.alert-remove", function (e) {
		e.preventDefault();

		// open confirm dialog
		var asset = findAssetById($j("#hfEditAssetId").val());
		$j("#hfDeleteAlertId").val($j(this).data("alertId"));
		$j("#hfDeleteAlertAssetId").val($j("#hfEditAssetId").val());
		var removeAlertModal = $("#delete-alert-modal");
		$(".modal-body", removeAlertModal).text(
			strings.REMOVE_FROM_ALERT_CONFIRM.replace("{0}", asset.Name).replace("{1}", $j(this).data("alertType"))
		);
		removeAlertModal.modal("show");
	});

	var assetContainer = $j("#assets-all");

	$j("#geofence-dialog").on("click", "span.highlight-segment", function (e) {
		e.preventDefault();
		// highlight the selected segment
		var seg = $j(this).data("segment");
		MapToolbar.setMapCenter(seg);
	});

	$("#panel-primary").on("click", ".leftnav-add-group", function (e) {
		e.preventDefault();
		if (this.classList.contains("disabled-feature")) {
			return;
		}
		openAssetGroupDialog(null);
		// by default, add currently selected assets to group if not all assets are selected
		trkData.assets.forEach(function (asset) {
			var isChecked = false;
			if (trkData.assets.length !== trkData.visible.assets.length) {
				isChecked = trkData.visible.assets.indexOf(asset.Id) !== -1;
			}
			$("#edit-asset-group-assets-list input[value=" + asset.Id + "]").prop("checked", isChecked);
		});
	});

	$("#panel-primary").on("click", ".leftnav-add-fencegroup", function (e) {
		e.preventDefault();
		if (this.classList.contains("disabled-feature")) {
			return;
		}
		openItemGroupDialog(null, "fences");
	});

	$("#panel-primary").on("click", ".leftnav-add-placegroup", function (e) {
		e.preventDefault();
		if (this.classList.contains("disabled-feature")) {
			return;
		}
		openItemGroupDialog(null, "places");
	});

	$("#panel-primary").on("click", ".leftnav-add-place", function (e) {
		e.preventDefault();
		openPlaceDialog(null);
	});

	$("#panel-primary").on("click", ".leftnav-add-fence", function (e) {
		e.preventDefault();
		openGeofenceDialog(null);
	});

	$("#panel-primary").on("click", ".leftnav-add-asset", function (e) {
		e.preventDefault();
		openEditAssetDialog(null);
	});

	$("#panel-primary").on("click", ".leftnav-add-shared-view", function (e) {
		e.preventDefault();
		openSharedViewDialog(null);
	});

	$("#panel-secondary-content-wrapper").on("hidden.bs.collapse", function () {
		// handle accordion resizes that affect the scrollbar calculations for some reason
		domNodes.simpleBars.secondary.recalculate();
	});
	$("#panel-secondary-content-wrapper").on("shown.bs.collapse", function () {
		// handle accordion resizes that affect the scrollbar calculations for some reason
		domNodes.simpleBars.secondary.recalculate();
	});
	$("#panel-secondary-content-wrapper").on("shown.bs.tab", function () {
		// handle accordion resizes that affect the scrollbar calculations for some reason
		domNodes.simpleBars.secondary.recalculate();
	});
	$("#panel-secondary-content-wrapper").on("hidden.bs.tab", function () {
		// handle accordion resizes that affect the scrollbar calculations for some reason
		domNodes.simpleBars.secondary.recalculate();
	});

	$("#panel-secondary-nav-tabs").on("click", ".nav-item", function (e) {
		e.preventDefault();

		var action = this.getAttribute("data-action");
		var type = domNodes.panels.secondary.getAttribute("data-item-type");
		var id = domNodes.panels.secondary.getAttribute("data-item-id");
		handleNotificationItemAction(action, type, id);
	});

	$("#panel-content-assets,#panel-content-journeys,#panel-content-shared-views").on(
		"click",
		".item-action",
		function (e) {
			e.preventDefault();
			if (this.classList.contains("disabled")) {
				return;
			}
			var rootNode = this.parentNode.parentNode.parentNode;
			var assetId = rootNode.getAttribute("data-asset-id");
			var tripId = rootNode.getAttribute("data-trip-id");
			var sharedViewId = rootNode.getAttribute("data-shared-view-id");
			var action = this.getAttribute("data-action");
			if (assetId !== null) {
				handleNotificationItemAction(action, "assets", assetId);
			} else if (tripId !== null) {
				handleNotificationItemAction(action, "trips", tripId);
			} else if (sharedViewId !== null) {
				handleNotificationItemAction(action, "shared-views", sharedViewId);
			}
		}
	);

	initSecondaryPanel();

	assetContainer.on("click", "svg", function (e) {
		e.preventDefault();
		//console.log('svg click');
	});

	$("#panel-primary").on("click", ".item-toggle", function (e) {
		e.preventDefault();
		// determine if shown/hidden
		// attach/detach based on view mode and
		// var itemNode = this.parentNode.parentNode;
		//toggleItemNotificationList(itemNode);
	});

	$j("#topbar-divider").on("click", "#panel-contract", function (e) {
		// todo: remove anon function
		e.preventDefault();
		hideSidePanel();
	});

	$j("#topbar-divider").on("click", "#panel-expand", function (e) {
		// todo: remove anon function
		e.preventDefault();
		showSidePanel();
	});

	$j("#controlarea").on("click", "#controls-close a", function (e) {
		$j("#controlarea").slideUp();
		e.preventDefault();
	});
	initLiveAssets();

	$j("#map-functions").on("click", "#map-reveal-all", function (e) {
		e.preventDefault();
		resizeApp(true);
		setMapBounds();
	});

	$("#map-functions").on("click", "#map-current-location-hide", function (e) {
		e.preventDefault();
		if (state.userLocation.marker === null) {
			return;
		}

		removeItemFromMap(state.userLocation.marker, trkData.mapLayers.other);
		if (state.userLocation.circle !== null) {
			removeItemFromMap(state.userLocation.circle, trkData.mapLayers.other);
		}
		if (state.userLocation.watch !== null) {
			navigator.geolocation.clearWatch(state.userLocation.watch);
		}
		state.userLocation.position = null;
		state.userLocation.marker = null;
		state.userLocation.circle = null;
		state.userLocation.watch = null;
		state.userLocation.zoomToPosition = false;
	});

	$("#map-functions").on("click", "#map-current-location-zoom", function (e) {
		e.preventDefault();
		zoomToUserLocation();
	});

	$("#map-functions").on("click", "#map-current-location", function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (navigator.geolocation) {
			if (state.userLocation.marker != null) {
				$("#map-current-location-toggle").dropdown("toggle");
			} else {
				// request current location
				state.userLocation.zoomToPosition = true;
				if (state.userLocation.watch === null) {
					state.userLocation.watch = navigator.geolocation.watchPosition(
						userLocationUpdated,
						userLocationErrorManual,
						state.userLocation.options
					);
				} else {
					navigator.geolocation.getCurrentPosition(
						userLocationUpdated,
						userLocationErrorManual,
						state.userLocation.options
					);
				}
			}
		} else {
			$("#map-current-location").addClass("disabled");
			handleWebServiceError(strings.CURRENT_LOCATION_UNSUPPORTED);
		}
	});

	$j("#map-functions").on("click", "#map-ruler", function (e) {
		e.preventDefault();
		if (trkData.ruler.isOpen) {
			closeRuler();
		} else {
			openRuler();
		}
	});

	initRouting();

	$j(domNodes.infoDialogs.mapItemInformation).on("click", "button.event-information", function (e) {
		// handles view details button for an event
		e.preventDefault();

		var eventId = parseInt(this.getAttribute("data-event"));
		var location = $(domNodes.infoDialogs.mapItemInformation).data("location");

		for (var i = 0; i < location.Events.length; i++) {
			var evt = location.Events[i];
			if (evt.Id === eventId) {
				setChildren(domNodes.infoDialogs.eventDetails, formattedTextToDiv(evt.Details));
				$(domNodes.infoDialogs.eventDetails).dialog("open");
				break;
			}
		}
	});

	$j("#map-functions").on("click", "#map-zoom-region", function (e) {
		e.preventDefault();
		if (!trkData.zoom.isActive) {
			enableDragZoom();
		} else {
			disableDragZoom();
		}
	});

	assetContainer.on("click", "a.edit,a.edit-group,a.edit-fence,a.edit-place", function (e) {
		e.preventDefault();
	});

	// duplicate of callbacks for fence context menu -- todo: should merge/refactor
	$j(document).on("click", "#FenceReplay", function (e) {
		var id = $j(this).parent().parent().find(".item-settings").data("fenceId");
		var fence = findFenceById(id);
		var assetIds = findAssetIdsInGeofence(fence);
		loadHistory(assetIds);
	});
	$j(document).on("click", "#FenceSendMessage", function (e) {
		console.log("fence send click");
		var id = $j(this).parent().parent().find(".item-settings").data("fenceId");
		var fence = findFenceById(id);
		openSendMessageDialog(null, null, fence);
	});
	$j(document).on("click", "#FenceGroup", function (e) {
		var id = $j(this).parent().parent().find(".item-settings").data("fenceId");
		var fence = findFenceById(id);
		openAssetGroupDialog(null);
		var assetIds = findAssetIdsInGeofence(fence);
		var assets = new Array();
		for (var i = 0; i < assetIds.length; i++) {
			var asset = findAssetById(assetIds[i]);
			if (asset == null) {
				continue;
			}
			assets.push(asset);
			$("#edit-asset-group-assets-list input[value=" + asset.Id + "]").prop("checked", true);
		}
	});
	$j(document).on("click", "#FenceEdit", function (e) {
		var id = $j(this).parent().parent().find(".item-settings").data("fenceId");
		var fence = findFenceById(id);
		openGeofenceDialog(fence);
	});
	$j(document).on("click", "#FenceAlert", function (e) {
		var id = $j(this).parent().parent().find(".item-settings").data("fenceId");
		var fence = findFenceById(id);
		var assetIds = findAssetIdsInGeofence(fence);
		window.location = "/Alerts/CreateAlert?assetIds=" + assetIds.join() + "&fenceId=" + fence.Id;
	});
	$j(document).on("click", "#FenceReport", function (e) {
		var id = $j(this).parent().parent().find(".item-settings").data("fenceId");
		var fence = findFenceById(id);
		window.location = "/Reports/Location?fenceId=" + fence.Id;
	});
	$j(document).on("click", "#FenceDelete", function (e) {
		var id = $j(this).parent().parent().find(".item-settings").data("fenceId");
		var fence = findFenceById(id);
		//var dialog = $j('#delete-geofence-dialog');
		//$j('div', dialog[0]).text(strings.MSG_DELETE_FENCE_CONFIRM.replace('{0}', fence.Name));
		//$j('#hfDeleteFenceId').val(fence.Id);
		//$j(dialog).dialog('open');

		var deleteFenceModal = $("#delete-geofence-modal");
		$(".modal-body", deleteFenceModal).text(strings.MSG_DELETE_FENCE_CONFIRM.replace("{0}", fence.Name));
		$j("#hfDeleteFenceId").val(fence.Id);
		deleteFenceModal.modal("show");
	});

	// select this search result on the map via the info window to send a position
	$j("#search-position-results").on("click", "a.highlight-position", function (e) {
		e.preventDefault();

		if (trkData.searchPositionResults == null) {
			return;
		}

		var numRes = $j(this).data("resultNumber");
		if (trkData.searchPositionResults[numRes] == null) {
			return;
		}

		var loc = trkData.searchPositionResults[numRes];
		updateChosenLocation([loc.address.lat, loc.address.lon], state.mapClickHandlers.POSITION);
		map.panTo([loc.address.lat, loc.address.lon]);
		$("#SendPositionName").val(loc.formatted_address);
	});

	$("#send-position-dialog").on("click", "#SendPositionCancel", function (e) {
		e.preventDefault();
		trkData.validation.sendPositionSend.resetForm();
		trkData.validation.sendPositionSend.currentForm.reset();
		removeItemFromMap(state.chosenLocations[state.mapClickHandlers.POSITION]);
		$("#send-position-search").addClass("is-visible");
		$("#form-send-position-send").removeClass("is-visible");
	});

	$("#send-position-dialog").on("click", "#SendPositionSend", function (e) {
		e.preventDefault();
		var isFormValid = $(trkData.validation.sendPositionSend.currentForm).valid();
		if (!isFormValid) {
			trkData.validation.sendPositionSend.focusInvalid();
			return;
		}

		var id = $("#hfPositionAssetId").val();
		var asset = findAssetById(id);
		if (asset === null) {
			return;
		}

		// submit position to pending
		var text = $("#SendPositionMessage");
		var name = $("#SendPositionName");
		var id = $("#hfPositionAssetId").val();
		var lat = $("#hfPositionLat").val();
		var lng = $("#hfPositionLng").val();
		var isServerSide = $("#send-position-dialog").data("isServerSide") === true;

		var data = {
			assetId: id,
			name: name.val(),
			message: text.val(),
			lat: lat,
			lng: lng,
			notifyStatus: true,
			notifyETA: true,
			isServerSide: isServerSide,
		};

		var status = document.getElementById("send-position-status");
		$.when(
			handleAjaxFormSubmission(
				"SendWaypointToAsset",
				data,
				this,
				status,
				strings.SEND_WAYPOINT_SUCCESS,
				strings.SEND_WAYPOINT_ERROR
			)
		).done(function () {
			trkData.validation.sendPositionSend.resetForm();
			trkData.validation.sendPositionSend.currentForm.reset();
			removeItemFromMap(state.chosenLocations[state.mapClickHandlers.POSITION]);
			$("#send-position-search").addClass("is-visible");
			$("#form-send-position-send").removeClass("is-visible");
		});
		//toggleLoadingMessage(true, 'send-position');

		//$j.ajax({
		//    type: 'POST',
		//    url: wrapUrl('/services/GPSService.asmx/SendWaypointToAsset'),
		//    data: JSON.stringify(dataPost),
		//    contentType: 'application/json; charset=utf-8',
		//    dataType: 'json',
		//    success: function (msg) {
		//        if (msg.d) {
		//            toggleLoadingMessage(false, 'send-position');
		//            if (state.openWindow != null) {
		//                var status = $j('#send-position-status');
		//                status.text(strings.SEND_WAYPOINT_SUCCESS).addClass('alert-success').show();
		//            }
		//        }
		//    },
		//    error: function (xhr, status, error) {
		//        handleWebServiceError(strings.SEND_WAYPOINT_ERROR);
		//        toggleLoadingMessage(false, 'send-position');
		//    }
		//});
	});

	$j("#send-position-dialog").on("click", "#btnSendPositionPlace", function (e) {
		e.preventDefault();

		if (!state.isChoosingPosition) {
			return;
		}

		var isFormValid = $(trkData.validation.sendPositionShow.currentForm).valid();
		if (!isFormValid) {
			trkData.validation.sendPositionShow.focusInvalid();
			return;
		}

		var placeId = $j("#ddlSendPositionPlace").val();
		if (placeId == "") {
			return;
		}
		var place = findPlaceById(placeId);
		if (place == null) {
			return;
		}

		updateChosenLocation([place.Location.Lat, place.Location.Lng], state.mapClickHandlers.POSITION);
		map.panTo([place.Location.Lat, place.Location.Lng]);
		$("#SendPositionName").val(place.Name);
		//$('#SendPositionLatLng').text(latLng.lat.toFixed(6) + ', ' + latLng.lng.toFixed(6));
		//$('#form-send-position-search').removeClass('is-visible');
		//$('#form-send-position-send').addClass('is-visible');
	});

	$j("#send-position-dialog").on("click", "#btnSendPositionLatLng", function (e) {
		e.preventDefault();

		if (!state.isChoosingPosition) {
			return;
		}

		var isFormValid = $(trkData.validation.sendPositionFind.currentForm).valid();
		if (!isFormValid) {
			trkData.validation.sendPositionFind.focusInvalid();
			return;
		}

		var lat = $("#txtSendPositionLat").val();
		var lng = $("#txtSendPositionLng").val();

		if (isNaN(lat) || isNaN(lng)) {
			return;
		}

		updateChosenLocation([lat, lng], state.mapClickHandlers.POSITION);
		map.panTo([lat, lng]);
	});

	$j("#send-position-dialog").on("click", "#btnPositionSearch", function (e) {
		e.preventDefault();

		var btn = this;
		if (!options.enableGeocoding) {
			return;
		}

		var isFormValid = $(trkData.validation.sendPositionSearch.currentForm).valid();
		if (!isFormValid) {
			trkData.validation.sendPositionSearch.focusInvalid();
			return;
		}

		var search = document.getElementById("txtPositionSearch").value;
		var loading = document.getElementById("search-position-loading");
		loading.classList.add("is-visible");

		btn.disabled = true;

		var status = $("#search-position-status").hide();

		var searchResults = document.getElementById("search-position-results");
		searchResults.classList.remove("is-visible");
		var noResults = document.getElementById("search-position-no-results");
		noResults.classList.remove("is-visible");

		var resultList = document.getElementById("search-position-results-list");
		setChildren(resultList, []);

		trkData.searchPositionResults = null;

		// search for address via external service
		addressSearch(search, function (success, resultData) {
			btn.disabled = false;
			loading.classList.remove("is-visible");
			searchResults.classList.add("is-visible");
			if (!success) {
				status.text(strings.MSG_SEARCH_POSITION_ERROR).show();
			} else {
				if (resultData.length == 0) {
					noResults.classList.add("is-visible");
				} else {
					trkData.searchPositionResults = resultData;
					var resultItems = [];
					for (var i = 0; i < resultData.length; i++) {
						resultItems.push(
							el(
								"li",
								el(
									"a#HighlightPosition" + i + ".highlight-position",
									{ href: "#", dataset: { resultNumber: i } },
									resultData[i].formatted_address
								)
							)
						);
					}
					setChildren(resultList, resultItems);
				}
			}
		});
	});

	// from/to filters
	$j("#txtDateFrom,#RunReportFrom").datetimepicker({
		dateFormat: user.shortDateFormat,
		minDate: options.dateRangeMin === null ? null : new Date(options.dateRangeMin),
		maxDate: options.dateRangeMax === null ? null : new Date(options.dateRangeMax),
	});
	$j("#txtDateTo,#RunReportTo").datetimepicker({
		dateFormat: user.shortDateFormat,
		hour: 23,
		minute: 59,
		minDate: options.dateRangeMin === null ? null : new Date(options.dateRangeMin),
		maxDate: options.dateRangeMax === null ? null : new Date(options.dateRangeMax),
	});

	$("#delete-waypoint-modal").on("click", "#delete-waypoint-button", function (e) {
		var modal = $("#delete-waypoint-modal");

		var btn = this;
		btn.disabled = true;
		var id = $j("#hfDeleteWaypointId").val();
		var assetId = $j("#hfDeleteWaypointAssetId").val();
		toggleLoadingMessage(true, "delete-waypoint");
		var dataPost = {
			id: id,
			assetId: assetId,
		};
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/WaypointDelete"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					modal.modal("hide");
					if (result.Success == true) {
						var data = $j("#WaypointHistory").DataTable();
						if (data != null) {
							var rows = data.data();
							for (var i = 0; i < rows.length; i++) {
								if (rows[i][9] == id) {
									data.row(i).remove();
								}
							}
							data.draw();
						}
					}
				}
				btn.disabled = false;
				toggleLoadingMessage(false, "delete-waypoint");
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_DELETE_WAYPOINT_ERROR);
				toggleLoadingMessage(false, "delete-waypoint");
				btn.disabled = false;
			},
		});
	});

	$("#delete-fillup-modal").on("click", "#delete-fillup-button", function (e) {
		// delete the fillup and reload the history
		var id = $j("#hfDeleteFillupId").val();
		var assetId = $j("#hfDeleteFillupAssetId").val();
		var data = {
			id: id,
			assetId: assetId,
		};
		var status = document.getElementById("add-refuel-status");
		$.when(
			handleAjaxFormSubmission(
				"DeleteFillup",
				data,
				this,
				status,
				strings.MSG_DELETE_FILLUP_SUCCESS,
				strings.MSG_DELETE_FILLUP_ERROR
			)
		).done(function () {
			$("#delete-fillup-modal").modal("hide");
			var asset = findAssetById(assetId);
			loadFillupHistory(asset);
		});
	});

	$("#delete-message-modal").on("click", "#delete-message-button", function (e) {
		var modal = $("#delete-message-modal");
		var btn = this;
		btn.disabled = true;

		var id = modal.data("messageId");
		var assetId = modal.data("assetId");

		toggleLoadingMessage(true, "delete-message");
		var dataPost = {
			id: id,
			assetId: assetId,
		};
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/DeleteMessage"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					modal.modal("hide");
					btn.disabled = false;
					if (result.Success == true) {
						var asset = findAssetById(assetId);
						loadMessageHistory(asset);
					}
				}
				toggleLoadingMessage(false, "delete-message");
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_DELETE_MESSAGE_ERROR);
				toggleLoadingMessage(false, "delete-message");
				btn.disabled = false;
			},
		});
	});

	$("#delete-group-modal").on("click", "#delete-group-button", function (e) {
		var modal = $("#delete-group-modal");
		var btn = this;
		btn.disabled = true;
		var id = $j("#hfDeleteGroupId").val();
		var groupFor = $j("#hfDeleteGroupFor").val() || "assets";
		const Params = {
			assets: { url: "DeleteAssetGroup", errorMsg: strings.MSG_DELETE_GROUP_ERROR, deleteHandler: deleteAssetGroup },
			fences: { url: "DeleteGeofenceGroup", errorMsg: strings.MSG_DELETE_GEOFENCE_GROUP_ERROR, deleteHandler: deleteItemGroup },
			places: { url: "DeletePlaceGroup", errorMsg: strings.MSG_DELETE_PLACE_GROUP_ERROR, deleteHandler: deleteItemGroup },
		};
		const params = Params[groupFor];
		toggleLoadingMessage(true, "delete-group");
		var dataPost = {
			id: id,
		};
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/" + params.url),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						var group = findGroupById(id, groupFor);
						deleteAssetGroup(group);
						closeSecondaryPanel();
					}
				}
				modal.modal("hide");
				btn.disabled = false;
				toggleLoadingMessage(false, "delete-group");
			},
			error: function (xhr, status, error) {
				handleWebServiceError(params.errorMsg);
				toggleLoadingMessage(false, "delete-group");
				btn.disabled = false;
			},
		});
	});

	$("#delete-shared-view-modal").on("click", "#delete-shared-view-button", function (e) {
		var modal = $("#delete-shared-view-modal");
		var btn = this;
		btn.disabled = true;
		var id = $("#hfDeleteSharedViewId").val();
		toggleLoadingMessage(true, "delete-shared-view");
		var dataPost = {
			id: id,
		};
		$.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/DeleteSharedView"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						var sharedView = findSharedViewById(id);
						deleteSharedView(sharedView);
					}
				}
				modal.modal("hide");
				btn.disabled = false;
				toggleLoadingMessage(false, "delete-shared-view");
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_DELETE_SHARED_VIEW_ERROR);
				toggleLoadingMessage(false, "delete-shared-view");
				btn.disabled = false;
			},
		});
	});

	$("#delete-place-modal").on("click", "#delete-place-button", function (e) {
		var modal = $("#delete-place-modal");
		var btn = this;
		btn.disabled = true;
		var id = $j("#hfDeletePlaceId").val();
		toggleLoadingMessage(true, "delete-place");
		var dataPost = {
			id: id,
		};
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/DeletePlace"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				try {
					var result = msg.d;
					if (result) {
						if (result.Success == true) {
							var place = findPlaceById(id);
							deletePlace(place);
						}
					}
					modal.modal("hide");
					btn.disabled = false;
					toggleLoadingMessage(false, "delete-place");
				} catch (e) {
					console.error(e);
					throw e;
				}
			},
			error: function (xhr, status, error) {
				try {
					handleWebServiceError(strings.MSG_DELETE_PLACE_ERROR);
					toggleLoadingMessage(false, "delete-place");
					btn.disabled = false;
				} catch (e) {
					console.error(e);
					throw e;
				}
			},
		});
	});

	$("#delete-trip-modal").on("click", "#delete-trip-button", function (e) {
		var modal = $("#delete-trip-modal");
		var btn = this;
		btn.disabled = true;
		var id = $j("#hfDeleteTripId").val();
		toggleLoadingMessage(true, "delete-trip");
		var dataPost = {
			id: id,
		};
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/DeleteTrip"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						var trip = findTripById(id);
						deleteTrip(trip);
					}
				}
				modal.modal("hide");
				btn.disabled = false;
				toggleLoadingMessage(false, "delete-trip");
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_DELETE_TRIP_ERROR);
				toggleLoadingMessage(false, "delete-trip");
				btn.disabled = false;
			},
		});
	});

	$("#delete-journey-modal").on("click", "#delete-journey-button", function (e) {
		var modal = $("#delete-journey-modal");
		var btn = this;
		btn.disabled = true;
		var id = $j("#hfDeleteJourneyId").val();
		toggleLoadingMessage(true, "delete-journey");
		var dataPost = {
			id: id,
		};
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/DeleteJourney"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						var journey = findJourneyById(id);
						deleteJourney(journey);
					}
				}
				modal.modal("hide");
				btn.disabled = false;
				toggleLoadingMessage(false, "delete-journey");
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_DELETE_JOURNEY_ERROR);
				toggleLoadingMessage(false, "delete-journey");
				btn.disabled = false;
			},
		});
	});

	$("#delete-geofence-document-modal").on("click", "#delete-geofence-button", function (e) {
		var modal = $("#delete-geofence-document-modal");
		var btn = this;
		btn.disabled = true;
		var id = $j("#hfDeleteDocumentId").val();
		var fenceId = $j("#hfDeleteFenceId").val();
		toggleLoadingMessage(true, "delete-document");
		var dataPost = {
			id: id,
			fenceId: fenceId,
		};
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/DeleteGeofenceDocument"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						updateFence(result.Fence);
						populateGeofenceDocuments(result.Fence);
					}
				}
				modal.modal("hide");
				btn.disabled = false;
				toggleLoadingMessage(false, "delete-document");
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_DELETE_DOCUMENT_ERROR);
				toggleLoadingMessage(false, "delete-document");
				btn.disabled = false;
			},
		});
	});

	$("#delete-geofence-modal").on("click", "#delete-geofence-button", function (e) {
		e.preventDefault();
		var modal = $("#delete-geofence-modal");
		var btn = this;
		btn.disabled = true;
		// ajax post for delete
		var id = $j("#hfDeleteFenceId").val();
		toggleLoadingMessage(true, "delete-fence");
		var dataPost = {
			id: id,
		};
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/DeleteGeofence"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						var fence = findFenceById(id);
						deleteFence(fence);
					}
				}
				modal.modal("hide");
				btn.disabled = false;
				toggleLoadingMessage(false, "delete-fence");
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_DELETE_GEOFENCE_ERROR);
				toggleLoadingMessage(false, "delete-fence");
				btn.disabled = false;
			},
		});
	});

	$("#delete-asset-modal").on("click", "#delete-asset-button", function (e) {
		e.preventDefault();
		var modal = $("#delete-asset-modal");
		var btn = this;
		btn.disabled = true;
		var id = $j("#hfDeleteAssetId").val();
		toggleLoadingMessage(true, "delete-asset");
		var dataPost = {
			id: id,
		};
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/DeleteAsset"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						var asset = findAssetById(id);
						deleteAsset(asset);
					}
				}
				modal.modal("hide");
				btn.disabled = false;
				toggleLoadingMessage(false, "delete-asset");
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_DELETE_ASSET_ERROR);
				toggleLoadingMessage(false, "delete-asset");
				btn.disabled = false;
			},
		});
	});

	$j("input.datepick").datepicker();

	$("#delete-alert-modal").on("click", "#delete-alert-button", function (e) {
		var id = $j("#hfDeleteAlertId").val();
		var btn = this;
		btn.disabled = true;
		var assetId = $j("#hfDeleteAlertAssetId").val();
		toggleLoadingMessage(true, "delete-alert");
		var dataPost = {
			alertId: id,
			assetId: assetId,
		};
		var status = document.getElementById("edit-asset-status");
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/RemoveAssetFromAlert"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						formShowSuccessMessage(status, strings.MSG_REMOVE_FROM_ALERT_SUCCESS);
						var asset = findAssetById(assetId);
						loadAssetAlerts(asset);
					} else {
						formShowErrorMessage(status, strings.MSG_REMOVE_FROM_ALERT_ERROR);
						if (result.ErrorMessage != null && result.ErrorMessage != "") {
							formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
						}
					}
					$("#delete-alert-modal").modal("hide");
				}
				btn.disabled = false;
				toggleLoadingMessage(false, "delete-alert");
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_REMOVE_FROM_ALERT_ERROR);
				toggleLoadingMessage(false, "delete-alert");
				btn.disabled = false;
			},
		});
	});

	// add geofence
	var geofenceButtons = [
		{
			buttonType: "primary",
			text: strings.CREATE_GEOFENCE,
			id: "EditGeofenceButton",
			click: function () {
				var isFormValid = $(trkData.validation.geofence.currentForm).valid();
				if (!isFormValid) {
					trkData.validation.geofence.focusInvalid();
					return;
				}

				// must have at least one segment/shape
				// todo: return custom validation message if not

				var dialog = domNodes.dialogs.geofence;
				var name = $j("#txtGeofenceName", dialog).val();
				var color = $j("#txtGeofenceColor", dialog).val();
				var id = $j("#hfEditFenceId", dialog).val();
				var description = $j("#txtGeofenceDescription").val();
				var groupIds = new Array();
				$j("#edit-fence-groups-container input[name=EditGroupIds]:checked").each(function (index, elem) {
					groupIds.push($j(this).val());
				});
				var userIds = new Array();
				$j("input[name=EditFenceUserIds]:checked", dialog).each(function (index, elem) {
					userIds.push($j(this).val());
				});
				var removePhoto = $("#edit-geofence-remove-photo").val() === "true";

				var segs = $j("#add-geofence-sections").find("span.highlight-segment");
				var status = document.getElementById("add-geofence-status");
				var segments = [];
				var circles = [],
					shapes = [],
					lines = [];
				segs.each(function (index, elem) {
					var segment = MapToolbar.getSegmentInfo($j(elem).data("segment"));
					var segmentId = $j(elem).data("segmentid");
					if (segmentId != "undefined") {
						segment.Id = segmentId;
					}

					if (segment.Shape == "circle") {
						circles.push(segment);
					} else if (segment.Shape == "shape") {
						shapes.push(segment);
					} else if (segment.Shape == "line") {
						lines.push(segment);
					}
					segments.push(segment);
				});

				if (color == "") {
					color = "#fc6355";
				}

				if (segments.length == 0) {
					formShowErrorMessage(status, strings.MSG_ADD_GEOFENCE_NO_SEGMENTS);
					return;
				}

				var attributes = getAttributesForType(1, "EditGeofenceAttribute");
				var contactId = $j("#ddlFenceContactId").val();
				var isServerSideOnly = $j("#chkGeofenceServerSide").prop("checked");
				var data = {
					Id: id,
					Name: name,
					Description: description,
					Color: color,
					Circles: circles,
					Shapes: shapes,
					Lines: lines,
					UserIds: userIds,
					Attributes: attributes,
					ContactId: contactId,
					IsServerSideOnly: isServerSideOnly,
					RemovePhoto: removePhoto,
					GroupIds: groupIds,
				};
				var url = "/services/GPSService.asmx/AddGeofence";
				if (state.isEditingGeofence) {
					url = "/services/GPSService.asmx/UpdateGeofence";
				} else {
					delete data.Id;
					delete data.RemovePhoto;
				}
				var btn = this;
				btn.disabled = true;
				toggleLoadingMessage(true, "add-geofence");
				$j.ajax({
					type: "POST",
					url: wrapUrl(url),
					data: JSON.stringify(data),
					contentType: "application/json; charset=utf-8",
					dataType: "json",
					success: function (msg) {
						try {
							btn.disabled = false;
							var result = msg.d;
							if (result) {
								if (result.Success == true) {
									if (state.isEditingGeofence) {
										formShowSuccessMessage(status, strings.MSG_EDIT_GEOFENCE_SUCCESS);

										updateFence(result.Fence);
										var previousUserIds = findFenceUsersByFenceId(id);
										if (previousUserIds != null) {
											if (previousUserIds.toString() != userIds.toString()) {
												for (var i = 0; i < previousUserIds.length; i++) {
													let value = previousUserIds[i];
													if (_.indexOf(userIds, value) == -1) {
														removeUserFromFence(value, id);
														i--;
													}
												}

												$j.each(userIds, function (index, value) {
													if (_.indexOf(previousUserIds, value) == -1) {
														addUserToFence(value, id);
													}
												});
											}
										}

										if (removePhoto) {
											var fence = findFenceById(result.Fence.Id);
											if (fence !== null) {
												fence.PhotoType = null;
											}
										}
									} else {
										processNewFence(result.Fence);
										if (trkData.fenceUsers !== null) {
											trkData.fenceUsers.push({ FenceId: result.Fence.Id, UserIds: [] });
										}
										$j.each(userIds, function (index, value) {
											addUserToFence(value, result.Fence.Id);
										});

										// quick link to add alert
										var assetIds = findAssetIdsInGeofence(findFenceById(result.Fence.Id));
										var addAlertLink = "/Alerts/CreateAlert?assetIds=" + assetIds.join() + "&fenceId=" + result.Fence.Id;
										setChildren(status, [
											text(status.textContent + " "),
											el(
												"button.add-alert.btn.btn-primary.btn-small.ml-auto",
												{ type: "button", dataset: { action: addAlertLink } },
												[
													svg(
														"svg",
														svg("use", { xlink: { href: "/content/svg/tracking.svg?v=15#exclamation-triangle" } })
													),
													text(" "),
													el("span", strings.ADD_ALERT),
												]
											),
										]);

										cleanupFenceEditing();
									}

									uploadFile(
										"fileEditGeofencePhoto",
										"/Home/UploadGeofencePhoto",
										{ fenceId: result.Fence.Id },
										function (res) {
											if (res == "" || res == "empty") {
												// plugin does not handle empty result properly
												return;
											}
											res = JSON.parse(res);

											if (res.success == true) {
												if (res.type != null && res.type != "") {
													var fence = findFenceById(result.Fence.Id);
													fence.PhotoType = res.type;
													if (
														domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
														domNodes.panels.secondary.getAttribute("data-item-id") === fence.Id
													) {
														$j("#edit-geofence-photo")
															.find("img")
															.attr(
																"src",
																"/uploads/images/fences/" +
																	fence.Id +
																	"_thumb." +
																	res.type +
																	"?rnd=" +
																	new Date().getTime()
															);
														$j("#edit-geofence-photo a").attr(
															"href",
															"/uploads/images/fences/" + fence.Id + "." + res.type
														);
														$j("#edit-geofence-photo").show();
													}
													$("#fileEditGeofencePhoto").val("").next(".custom-file-label").text(strings.NO_FILE_SELECTED);
												}
											}
										}
									);

									if (!state.isEditingGeofence) {
										openGeofenceDialog(null);
										formShowSuccessMessage(status, strings.MSG_ADD_GEOFENCE_SUCCESS);
									}
									//dialog.dialog('close');
								} else {
									// show error
									var stat = strings.MSG_ADD_GEOFENCE_ERROR;
									if (state.isEditingGeofence) {
										stat = strings.MSG_EDIT_GEOFENCE_ERROR;
									}
									formShowErrorMessage(status, stat);
									if (result.ErrorMessage != null && result.ErrorMessage != "") {
										formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
									}
								}
							}

							toggleLoadingMessage(false, "add-geofence");
						} catch (e) {
							console.error(e);
							throw e;
						}
					},
					error: function (xhr, status, error) {
						try{
							btn.disabled = false;
							if (state.isEditingGeofence) {
								handleWebServiceError(strings.MSG_EDIT_GEOFENCE_ERROR);
							} else {
								handleWebServiceError(strings.MSG_ADD_GEOFENCE_ERROR);
							}
							toggleLoadingMessage(false, "add-geofence");
						} catch (e) {
							console.error(e);
							throw e;
						}
					},
				});
			},
		},
		{
			buttonType: "secondary",
			icons: { primary: "ui-icon-close" },
			text: strings.CANCEL,
			click: function () {
				if (state.isEditingGeofence) {
					document.getElementById("panel-dialog-back").click();
				} else {
					closeSecondaryPanel();
				}
			},
		},
	];

	loadDialogButtons(domNodes.dialogs.geofence, geofenceButtons);
	$(domNodes.dialogs.geofence).on("click", "a.remove-feature", function (e) {
		e.preventDefault();
		MapToolbar.removeFeature(this.dataset.segment);
	});

	$(domNodes.dialogs.geofence).on("click", "div.tool", function (e) {
		e.preventDefault();
		MapToolbar.initFeature(this.dataset.tool);
	});

	var deleteFenceModal = $("#delete-geofence-modal");
	$j("#geofence-documents").on("click", "button.delete", function (e) {
		e.preventDefault();
		var button = $j(this);
		var fence = findFenceById(button.data("fenceId"));
		var deleteFenceDocumentModal = $("#delete-geofence-document-modal");
		var docId = button.data("docId");
		if (fence.Documents != null) {
			for (var i = 0; i < fence.Documents.length; i++) {
				var doc = fence.Documents[i];
				if (doc.Id === docId) {
					$(".modal-body", deleteFenceDocumentModal).text(strings.MSG_DELETE_DOCUMENT_CONFIRM.replace("{0}", doc.Name));
					break;
				}
			}
		}

		$j("#hfDeleteFenceId").val(fence.Id);
		$j("#hfDeleteDocumentId").val(docId);
		deleteFenceModal.modal("show");
	});
	$(domNodes.dialogs.geofence).on("click", "#RemoveGeofencePhoto", function (e) {
		e.preventDefault();
		$(this).next().val("true");
		$(this).parent().parent().hide();
	});
	$j(domNodes.dialogs.geofence).on("click", "#AddFenceDocument", function (e) {
		e.preventDefault();
		var button = $j(this)[0];
		var dialog = $j(domNodes.dialogs.geofence);
		var status = $j("#add-document-status").text("").hide();
		var startDate = $j("#txtFenceDocumentStartDate").val();
		var endDate = $j("#txtFenceDocumentExpiryDate").val();
		var name = $j("#txtFenceDocumentName").val();
		var fence = findFenceById(dialog.data("fenceId"));
		var data = {
			FenceId: fence.Id,
			StartDate: startDate,
			EndDate: endDate,
			Name: name,
		};
		button.disabled = true;
		toggleLoadingMessage(true, "add-geofence-document");
		uploadFile("FileFenceDocument", "/Home/AddDocumentToFence", data, function (res) {
			console.log(res);
			button.disabled = false;
			toggleLoadingMessage(false, "add-geofence-document");
			if (res == "" || res == "empty")
				// plugin does not handle empty result properly (html)
				return;
			res = JSON.parse(res);

			if (res.Success == true) {
				updateFence(res.Fence);
				populateGeofenceDocuments(res.Fence);
				$j("#edit-geofence-documents input").val("");
			} else {
				status.text(res.ErrorMessage).addClass("error").show();
			}
		});
	});

	const routeAsGeofenceButton = {
		id: "RouteGeofenceCreate",
		text: strings.ADD_GEOFENCE,
		disabled: true,
		click: function () {
			// original dialog
			var dialog = $j(this);
			if (options.enabledFeatures.indexOf("UI_GEOFENCE_FROM_ROUTE") === -1) {
				return;
			}
			var btn = $j("#RouteGeofenceCreate").prop("disabled", true);
			var latlngs = [];
			if (trkData.routeLine != null) {
				trkData.routeLine.getLatLngs().forEach(function (elem, index) {
					latlngs.push({ Lat: elem.lat, Lng: elem.lng });
				});
			}
			var data = {
				encodedPolyline: trkData.routeLineEncoded,
				encodedPolylines: trkData.routeLinesEncoded,
				latlngs: latlngs,
				buffer: $j("#RouteGeofenceBuffer").slider("value"),
			};
			toggleLoadingMessage(false, "polyline-geofence");
			// submit encoded polyline to server, have it create and return a buffered polygon to populate add geofence
			$j.ajax({
				type: "POST",
				url: wrapUrl("/services/GPSService.asmx/BufferPolyline"),
				data: JSON.stringify(data),
				contentType: "application/json; charset=utf-8",
				dataType: "json",
				success: function (msg) {
					var result = msg.d;
					if (result) {
						if (result.Success != true) {
							handleWebServiceError(strings.MSG_ADD_GEOFENCE_ERROR);
						} else {
							dialog.dialog("close");
							dialog.data("dialog").dialog("close"); // close original dialog
							var points = result.Points;

							var fence = {
								Id: 1,
								Segments: [{ Points: points, Type: 1 }],
							};

							openGeofenceDialog(null);
							editFenceSegments(fence);
							btn.prop("disabled", true);
						}
					}
					toggleLoadingMessage(false, "polyline-geofence");
				},
				error: function (xhr, status, error) {
					handleWebServiceError(strings.MSG_ADD_GEOFENCE_ERROR);
					toggleLoadingMessage(false, "polyline-geofence");
					btn.prop("disabled", false);
				},
			});
		},
	};

	$j(domNodes.infoDialogs.ruler).on("click", "a.delete", function (e) {
		e.preventDefault();
		var index = $j("a.delete", domNodes.infoDialogs.ruler).index($j(this));
		removeRulerPoint(index);
	});

	initDialogs();
	initPlayback();
	initSharedView();

	$(document).on("click", ".copy-link", function (e) {
		e.preventDefault();
		var btn = this;
		var existingText = btn.querySelector("span").textContent;
		$.when(copyTextToClipboard(btn.getAttribute("data-link")))
			.done(function () {
				btn.querySelector("span").textContent = strings.LINK_COPIED;
				setTimeout(function () {
					btn.querySelector("span").textContent = existingText;
				}, 3000);
				console.log("copied successfully.");
			})
			.fail(function () {
				console.log("failed to copy");
			});
	});

	$j("#search-place-results").on("click", "a.highlight-place", function (e) {
		e.preventDefault();

		if (trkData.searchPlaceResults == null) {
			return;
		}

		var numRes = $j(this).data("resultNumber");
		if (trkData.searchPlaceResults[numRes] == null) {
			return;
		}

		var loc = trkData.searchPlaceResults[numRes];
		updateChosenLocation([loc.address.lat, loc.address.lon], state.mapClickHandlers.PLACE);
		map.panTo([loc.address.lat, loc.address.lon]);
		$("#txtPlaceName").val(loc.formatted_address);
	});

	$(domNodes.dialogs.addPlace).on("click", "#btnPlaceSearch", function (e) {
		e.preventDefault();

		var btn = this;
		if (!options.enableGeocoding) {
			return;
		}

		var isFormValid = $(trkData.validation.placeSearch.currentForm).valid();
		if (!isFormValid) {
			trkData.validation.placeSearch.focusInvalid();
			return;
		}

		var search = document.getElementById("txtPlaceSearch").value;
		var loading = document.getElementById("search-place-loading");
		loading.classList.add("is-visible");

		btn.disabled = true;

		var status = $("#search-place-status").hide();

		var searchResults = document.getElementById("search-place-results");
		searchResults.classList.remove("is-visible");
		var noResults = document.getElementById("search-place-no-results");
		noResults.classList.remove("is-visible");

		var resultList = document.getElementById("search-place-results-list");
		setChildren(resultList, []);

		trkData.searchPlaceResults = null;

		// search for address via external service
		addressSearch(search, function (success, resultData) {
			btn.disabled = false;
			loading.classList.remove("is-visible");
			searchResults.classList.add("is-visible");
			if (!success) {
				status.text(strings.MSG_SEARCH_POSITION_ERROR).show();
			} else {
				if (resultData.length == 0) {
					noResults.classList.add("is-visible");
				} else {
					trkData.searchPlaceResults = resultData;
					var resultItems = [];
					for (var i = 0; i < resultData.length; i++) {
						resultItems.push(
							el(
								"li",
								el(
									"a#HighlightPlace" + i + ".highlight-place",
									{ dataset: { resultNumber: i } },
									resultData[i].formatted_address
								)
							)
						);
					}
					setChildren(resultList, resultItems);
				}
			}
		});
	});

	if (!options.enableGeocoding) {
		$j("#search-place-address,#searchPositionAddress").hide();
	}

	$(domNodes.dialogs.driverHistory).on("click", "a.history", function (e) {
		e.preventDefault();

		// this loads the asset's history and selects the first position of the trip the driver took
		var assetId = $(domNodes.dialogs.driverHistory).data("assetId");
		var from = moment(this.getAttribute("data-from"), user.dateFormat);
		var to = moment(this.getAttribute("data-to"), user.dateFormat);
		$("#txtDateFrom").datetimepicker("setDate", from.toDate());
		if (to == null) {
			$("#txtDateTo").val("");
		} else {
			$("#txtDateTo").datetimepicker("setDate", to.toDate());
		}

		// make all other assets inactive
		// todo: this should be a helper function
		trkData.assets.forEach(function (asset) {
			var makeActive = asset.Id === assetId;
			toggleAssetActive(asset.Id, makeActive, false);
		});
		var callback = function () {
			// highlight asset's first position
			var lastPosition = _.last(trkData.history.normalizedPositionsByAssetId[assetId]);
			if (lastPosition !== undefined) {
				highlightPosition(lastPosition.Position.Id, null);
			}
		};
		// todo: this should be a helper function
		// turn to history mode for the trip's date range
		if (state.activeMapMode === mapModes.LIVE) {
			switchMapMode(mapModes.HISTORY, null, true).then(callback);
		} else {
			queryActiveAssets(null).then(callback);
		}
	});
	$j(domNodes.dialogs.driverHistory).on("click", "#RefreshDrivers", function (e) {
		e.preventDefault();
		var assetId = $j(domNodes.dialogs.driverHistory).data("assetId");
		var asset = findAssetById(assetId);
		if (asset !== null) {
			loadDriverHistory(asset);
		}
	});

	$("#AssetDrivers").on("click", "button.update-driver", function (e) {
		e.preventDefault();
		var assetId = $(domNodes.dialogs.currentDrivers).data("assetId");
		var asset = findAssetById(assetId);
		var sel = $(this).parent().prev().find("select");
		var driverId = sel.data("driverId");
		var statusId = sel.val();
		updateAssetDriverStatus(asset, driverId, statusId, this, null);
	});
	$(domNodes.dialogs.currentDrivers).on("click", "#LoginDriver", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.currentDriver.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var assetId = $(domNodes.dialogs.currentDrivers).data("assetId");
		var asset = findAssetById(assetId);
		var driverId = $("#LoginDrivers").val();
		if (driverId == null) return;
		var statusId = $("#LoginDriversStatus").val();
		var notes = $("#LoginDriversNotes").val();

		loginAssetDriver(asset, driverId, notes, this, statusId);
	});
	$(domNodes.dialogs.currentDrivers).on("click", "#RefreshAssetDrivers", function (e) {
		e.preventDefault();
		var assetId = $(domNodes.dialogs.currentDrivers).data("assetId");
		var asset = findAssetById(assetId);
		loadAssetDrivers(asset);
	});

	$(domNodes.dialogs.waypointHistory).on("click", "#RefreshWaypoints", function (e) {
		e.preventDefault();
		var assetId = $(domNodes.dialogs.waypointHistory).data("assetId");
		var asset = findAssetById(assetId);
		if (asset != null) {
			loadWaypointHistory(asset);
		}
	});
	$j("#WaypointHistory").on("click", "button.delete-waypoint", function (e) {
		e.preventDefault();

		$("#hfDeleteWaypointId").val($j(this).data("waypointId"));
		$("#hfDeleteWaypointAssetId").val($(domNodes.dialogs.waypointHistory).data("assetId"));
		$("#delete-waypoint-modal").modal("show");
	});

	$(domNodes.dialogs.refuelHistory).on("click", "a.location", function (e) {
		e.preventDefault();

		var positionId = this.getAttribute("data-marker");
		var assetId = parseInt(document.getElementById("hfRefuelAssetId").value);
		showOrLoadAssetPosition(positionId, assetId);
	});
	$("#form-add-refuel").on("click", "#btnAddRefuel", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.refuelAdd.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var fuel = $j("#txtRefuelAmount").val();
		var time = $j("#txtRefuelDate").val();
		var odometer = $j("#txtRefuelOdometer").val();
		var assetId = $j("#hfRefuelAssetId").val();
		var data = {
			assetId: assetId,
			fuel: fuel,
			time: time,
			odometer: odometer,
		};
		var status = document.getElementById("add-refuel-status");

		$.when(
			handleAjaxFormSubmission(
				"AddFillupToAsset",
				data,
				this,
				status,
				strings.MSG_ADD_FILLUP_SUCCESS,
				strings.MSG_ADD_FILLUP_ERROR
			)
		).done(function () {
			$j("#txtRefuelAmount").val("");
			var asset = findAssetById(assetId);
			loadFillupHistory(asset);
		});
	});

	$(domNodes.dialogs.messageHistory).on("click", "#RefreshMessages", function (e) {
		e.preventDefault();

		var assetId = $(domNodes.dialogs.messageHistory).data("assetId");
		var asset = findAssetById(assetId);
		if (asset == null) {
			return;
		}

		loadMessageHistory(asset);
	});
	$(domNodes.dialogs.messageHistory).on("click", "#MessagesSendCommand", function (e) {
		e.preventDefault();

		var assetId = $(domNodes.dialogs.messageHistory).data("assetId");
		var asset = findAssetById(assetId);
		if (asset == null) {
			return;
		}

		openSendCommandDialog(asset);
	});
	$(domNodes.dialogs.messageHistory).on("click", "button.delete-message", function (e) {
		e.preventDefault();

		var modal = $("#delete-message-modal").modal("show");
		modal.data("messageId", $(this).data("messageId"));
		modal.data("assetId", $(domNodes.dialogs.messageHistory).data("assetId"));
	});
	$("#MessageHistoryOutbox").on("click", "a.error", function (e) {
		e.preventDefault();
		var messageId = $j(this).data("messageId");
		var assetId = $(domNodes.dialogs.messageHistory).data("assetId");
		var asset = findAssetById(assetId);
		if (asset === null) return;
		if (
			_.indexOf(devices.SKYWAVE_IDP_DUAL_MODE, asset.DeviceId) === -1 &&
			_.indexOf(devices.SKYWAVE_IDP, asset.DeviceId) === -1
		)
			return;

		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			// ajax post
			var data = {
				assetId: id,
				messageId: messageId,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
			};
			toggleLoadingMessage(true, "idp-resend-message");
			$j.ajax({
				type: "POST",
				url: wrapUrl("/services/GPSService.asmx/IDPResendOutgoingMessage"),
				data: JSON.stringify(data),
				contentType: "application/json; charset=utf-8",
				dataType: "json",
				success: function (msg) {
					var result = msg.d;
					if (result) {
						if (result.Success === true) {
							// refresh message log
							loadMessageHistory(asset);
						} else {
							handleWebServiceError("");
						}
					}
					toggleLoadingMessage(false, "idp-resend-message");
					$(domNodes.modals.messageAction).modal("hide");
				},
				error: function (xhr, status, error) {
					handleWebServiceError("");
					toggleLoadingMessage(false, "idp-resend-message");
					$(domNodes.modals.messageAction).modal("hide");
				},
			});
		};

		openActionDialog(strings.RESEND_MESSAGE, strings.RESEND_MESSAGE, callback, asset.Id, false);
	});
	$("#MessageHistoryInbox").on("click", "a.location", function (e) {
		e.preventDefault();

		for (var i = 0; i < trkData.messaging.inbox.length; i++) {
			var message = trkData.messaging.inbox[i];
			if (message.Position == null) continue;

			if (message.Position.Id == $(this).attr("data-marker")) {
				var positionDate = moment(message.Position.Time, user.dateFormat);
				positionDate.add({ hours: -12 });
				// set from date
				$("#txtDateFrom").val(positionDate.format(user.dateFormat));
				positionDate.add({ hours: 24 });
				$("#txtDateTo").val(positionDate.format(user.dateFormat));

				// make sure asset is highlighted
				toggleAssetActive(message.AssetId, true, true);

				// if not in history, switch to it
				var self = this;
				if (state.activeMapMode === mapModes.LIVE) {
					// always show the control area if it is hidden
					if ($("#controlarea").is(":hidden")) {
						$("#controlarea").slideDown();
					}
					// switch view to history with zero sensitivity so the position is not consolidated
					switchMapMode(mapModes.HISTORY, 0, true).then(function () {
						highlightPosition($(self).attr("data-marker"), null);
					});
				} else {
					// query history with zero sensitivity so the position is not consolidated
					queryActiveAssets(0).then(function () {
						highlightPosition($j(self).attr("data-marker"), null);
					});
				}
				return;
			}
		}
	});

	$j("#edit-asset-dialog").on("click", "#ResetAssetStatus", function (e) {
		e.preventDefault();
		var assetId = $j("#hfEditAssetId").val();
		var asset = findAssetById(assetId);
		if (asset == null) return;

		var data = {
			assetId: asset.Id,
		};

		var btn = $j(this);
		btn.addClass("disabled").prop("disabled", true);

		toggleLoadingMessage(true, "reset-status");
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/ResetAssetStatus"),
			data: JSON.stringify(data),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					if (result.Success != true) {
						handleWebServiceError(strings.MSG_UPDATE_STATUS_ERROR);
					} else {
						loadAssetStatus(asset);
					}
				}
				toggleLoadingMessage(false, "reset-status");
				btn.removeClass("disabled").prop("disabled", false);
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_UPDATE_STATUS_ERROR);
				toggleLoadingMessage(false, "reset-status");
				btn.removeClass("disabled").prop("disabled", false);
			},
		});
	});

	$j("#edit-asset-dialog").on("click", "#UpdateAssetStatus", function (e) {
		e.preventDefault();
		var assetId = $j("#hfEditAssetId").val();
		var asset = findAssetById(assetId);
		if (asset == null) return;

		// not currently used... if added back must be updated with new states
		var isIdling = $j("input[name=rbEditAssetIdling]:checked").val();
		var isDwelling = $j("input[name=rbEditAssetDwelling]:checked").val();
		var isIgnitionOn = $j("input[name=rbEditAssetIgnitionOn]:checked").val();
		var isMoving = $j("input[name=rbEditAssetMoving]:checked").val();
		var isSpeeding = $j("input[name=rbEditAssetSpeeding]:checked").val();
		//var isTowing = $j('input[name=rbEditAssetTowing]:checked').val();
		var state = {
			IsIdling: isIdling == "" ? null : isIdling === "true",
			IsDwelling: isDwelling == "" ? null : isDwelling === "true",
			IsIgnitionOn: isIgnitionOn == "" ? null : isIgnitionOn === "true",
			IsMoving: isMoving == "" ? null : isMoving === "true",
			IsSpeeding: isSpeeding == "" ? null : isSpeeding === "true",
		};

		var data = {
			assetId: asset.Id,
			state: state,
		};

		var btn = $j(this);
		btn.prop("disabled", true);

		toggleLoadingMessage(true, "update-status");
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/UpdateAssetStatus"),
			data: JSON.stringify(data),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				var result = msg.d;
				if (result) {
					if (result.Success != true) {
						handleWebServiceError(strings.MSG_UPDATE_STATUS_ERROR);
					} else {
						updateAssetState(asset, state);
					}
				}
				toggleLoadingMessage(false, "update-status");
				btn.prop("disabled", false);
			},
			error: function (xhr, status, error) {
				handleWebServiceError(strings.MSG_UPDATE_STATUS_ERROR);
				toggleLoadingMessage(false, "update-status");
				btn.prop("disabled", false);
			},
		});
	});

	$j("#edit-asset-dialog").on("click", "#RefreshAssetStatus", function (e) {
		e.preventDefault();
		var assetId = $j("#hfEditAssetId").val();
		var asset = findAssetById(assetId);
		loadAssetStatus(asset);
	});

	// add position dialog
	var addPositionButtons = [
		{
			svgIcon: "plus",
			buttonType: "primary",
			icons: { primary: "ui-icon-plus" },
			text: strings.BUTTON_ADD_POSITION,
			click: function () {
				var isFormValid = $j("#form-add-position").valid();
				if (!isFormValid) {
					return;
				}
				var lat = $j("#txtAddPositionLat");
				var lng = $j("#txtAddPositionLng");
				var time = $j("#txtAddPositionDate");
				var speed = $j("#txtAddPositionSpeed");
				var assetId = $j("#hfAddPositionAssetId").val();
				var status = document.getElementById("add-position-status");
				toggleLoadingMessage(true, "add-position");
				var dataPost = {
					assetId: assetId,
					lat: lat.val(),
					lng: lng.val(),
					time: time.val(),
					speed: speed.val(),
				};

				$j.ajax({
					type: "POST",
					url: wrapUrl("/services/GPSService.asmx/AddPositionToAsset"),
					data: JSON.stringify(dataPost),
					contentType: "application/json; charset=utf-8",
					dataType: "json",
					success: function (msg) {
						var result = msg.d;
						if (result) {
							if (result.Success == true) {
								//status.text(strings.MSG_ADD_POSITION_SUCCESS).addClass('success').removeClass('error').show();
								//lat.val(''); // clear text field
								//lng.val('');
								//speed.val('');
								//updateLiveAssets();
								throttles.updateLiveAssets();
								formShowSuccessMessage(status, strings.MSG_ADD_POSITION_SUCCESS);
								//closeSecondaryPanel();
							} else {
								formShowErrorMessage(status, strings.MSG_ADD_POSITION_ERROR);
								if (result.ErrorMessage != null && result.ErrorMessage !== "") {
									formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
								}
							}
						}
						toggleLoadingMessage(false, "add-position");
					},
					error: function (xhr, status, error) {
						handleWebServiceError(strings.MSG_ADD_POSITION_ERROR);
						toggleLoadingMessage(false, "add-position");
					},
				});
			},
		},
		closeButton,
	];

	loadDialogButtons(domNodes.dialogs.addPosition, addPositionButtons);

	// send message dialog
	var sendMessageButtons = [
		{
			id: "SendMessageToAsset",
			svgIcon: "envelope",
			icons: { primary: "ui-icon-mail-closed" },
			text: strings.BUTTON_SEND_MESSAGE,
			click: function () {
				var dialog = domNodes.dialogs.sendMessage;
				var text = $j("#txtMessageText");
				var assetId = $j(dialog).data("assetId");
				var groupId = $j(dialog).data("groupId");
				var fenceId = $j(dialog).data("fenceId");
				var isFormValid = $(trkData.validation.sendMessage.currentForm).valid();
				if (!isFormValid) {
					return;
				}
				var notifyOnRead = $j("#chkMessageNotifyOnRead").prop("checked");
				var quickMessageId = $j("#quick-messages").val();

				var dataPost = {};
				var url = "SendTextMessageToAsset";

				if (assetId !== undefined) {
					dataPost = {
						assetId: assetId,
						text: text.val(),
						notifyOnRead: notifyOnRead,
						quickMessageId: quickMessageId,
					};
				}
				if (groupId !== undefined) {
					url = "SendTextMessageToGroup";
					dataPost = {
						id: groupId === "all-assets" ? null : groupId,
						text: text.val(),
						isForAllAssets: groupId === "all-assets",
					};
				}
				if (fenceId !== undefined) {
					url = "SendTextMessageToAssets";
					var assetIds = new Array();
					$j("input[name=SendMessageAssetIds]:checked", dialog[0]).each(function (index, elem) {
						assetIds.push($j(this).val());
					});
					dataPost = {
						assetIds: assetIds,
						text: text.val(),
					};
				}

				var status = document.getElementById("send-message-status");
				var btn = this;
				handleAjaxFormSubmission(
					url,
					dataPost,
					btn,
					status,
					strings.MSG_MESSAGE_SENT_SUCCESS,
					strings.MSG_MESSAGE_SENT_ERROR,
					function () {
						trkData.validation.sendMessage.resetForm();
						trkData.validation.sendMessage.currentForm.reset();
					}
				);
			},
		},
		closeButton,
	];

	loadDialogButtons(domNodes.dialogs.sendMessage, sendMessageButtons);

	$(domNodes.dialogs.sendMessage).on("change", "#quick-messages", function (e) {
		e.preventDefault();
		if ($j(this).val() == "") {
			$j("#message-text-label").text(strings.TEXT + ":");
			$j("#txtMessageText").addClass("required");
		} else {
			$j("#message-text-label").text(strings.ADDITIONAL_TEXT + ":");
			$j("#txtMessageText").removeClass("required");
		}
	});

	$j(domNodes.dialogs.sendMessage).on("click", "#beam-set-message", function (e) {
		e.preventDefault();

		$j("#txtMessageText").val($j("#beam-message").val());
	});

	$("#edit-asset-dialog").on("change", "#EditAssetIconSet", function (e) {
		var iconSet = this.value;
		$(".icon-set").removeClass("is-visible");
		var chosenSet = $("#icon-set-" + iconSet).addClass("is-visible");
		//chosenSet.find('.custom-control-input')[0].checked = true;
	});

	// color change for asset icon in edit asset dialog
	$j("#edit-asset-dialog").on("click", "input[name=rbEditAssetColor]", function () {
		// change the color to the selected value
		var val = $j("#edit-asset-dialog").find("input[name=rbEditAssetColor]:checked").val();
		var icons = $("#edit-asset-dialog input[name=rbEditAssetIcon]");
		_.each(icons, function (icon) {
			$(icon)
				.next()
				.children("img")
				.attr("src", "/markers/" + icon.value + "?color=" + val);
		});
	});

	$j("#edit-asset-address-book-group").on("click", "#SendAddressBookGroup", function (e) {
		e.preventDefault();
		// todo
	});

	$("#edit-asset-drivers-list").on("click", "label", function (e) {
		var val = $("#" + this.getAttribute("for")).val();
		var driver = findDriverById(parseInt(val));
		if (driver == null) {
			return;
		}
		var info = _.compact([
			includeRowIfNotNull(strings.DRIVER_ID, driver.DriverId),
			includeRowIfNotNull(strings.IBUTTON_ID, driver.IButtonId),
			includeRowIfNotNull(strings.GARMIN_ID, driver.GarminId),
			includeRowIfNotNull(strings.REGION, driver.Region),
			includeRowIfNotNull(strings.PHONE_NUMBER, driver.Phone),
			includeRowIfNotNull(strings.BLOOD_TYPE, driver.BloodType),
			includeRowIfNotNull(strings.LICENSE_NUMBER, driver.LicenseNumber),
			includeRowIfNotNull(strings.LICENSE_EXPIRATION, driver.LicenseExpiration),
			includeRowIfNotNull(strings.LICENSE_RESTRICTION, driver.LicenseRestriction),
			includeRowIfNotNull(strings.MANAGER, driver.Manager),
			includeRowIfNotNull(strings.EMERGENCY_CONTACT, driver.EmergencyContact),
			includeRowIfNotNull(strings.EMERGENCY_CONTACT_NUMBER, driver.EmergencyContactNumber),
		]);
		setChildren(document.getElementById("driver-information-current").querySelector("tbody"), info);
		if (driver.PhotoType != null && driver.PhotoType != "") {
			$("#driver-information-photo")
				.attr("src", "/uploads/images/drivers/" + driver.Id + "_thumb." + driver.PhotoType)
				.attr("alt", driver.DriverId)
				.show();
		} else {
			$("#driver-information-photo").hide();
		}
	});

	$j("#edit-asset-dialog").on("input", "#txtEditAssetOdometerRaw, #txtEditAssetOdometerOffset", function (e) {
		// values are in local format (maybe commas for decimals, oh my!)
		var offsetInput = document.getElementById("txtEditAssetOdometerOffset");
		let v1 = $j.parseFloat(document.getElementById("txtEditAssetOdometerRaw").value);
		if (!Number.isFinite(v1)) {
			document.getElementById("txtEditAssetOdometerResult").value = strings.UNKNOWN;
		} else {
			let v2 = $j.parseFloat(offsetInput.value);
			v2 = Number.isFinite(v2) ? v2 : 0;
			document.getElementById("txtEditAssetOdometerResult").value = globalizeNumberFormat(v1 + v2, "n0");
		}
		if (offsetInput.value != offsetInput.getAttribute("data-original-value")) {
			offsetInput.setAttribute("data-updated", true);
		}
	});

	$j("#edit-asset-dialog").on("change", "#EditAssetIOTemplate", function (e) {
		var templateId = $j(this).val();
		var device = findDeviceById($j("#ddlEditAssetDevice").val());
		var template = null;
		var asset = findAssetById($j("#hfEditAssetId").val());
		if (templateId == "") {
			// no template, use the current selected asset
			$j(
				"#labels-input-analog input, #labels-input-analog select, #labels-input-digital input, #labels-output input, #edit-asset-labels-sensor button, #edit-asset-labels-sensor input"
			).prop("disabled", false); // , #labels-input select
			setLabelsByTemplate(device, asset, template);
		} else {
			for (var i = 0; i < device.IOTemplates.length; i++) {
				if (device.IOTemplates[i].Id == templateId) {
					template = device.IOTemplates[i];
					break;
				}
			}
			setLabelsByTemplate(device, asset, template);
			// disable all inputs
			$j(
				"#labels-input-analog input, #labels-input-analog select, #labels-input-digital input, #labels-output input, #edit-asset-labels-sensor button, #edit-asset-labels-sensor input"
			).prop("disabled", true); // , #labels-input select
		}
		// enable/disable overrides
		$j("#labels-input-analog select.behavior").each(function (index, elem) {
			toggleAnalogIOBehavior($j(this));
		});
	});
	$j("#edit-asset-labels-sensor-button-add").on("click", addSensorLabel);

	$j("#edit-asset-dialog").on("click", "#EditAssetCurrentDrivers", function (e) {
		e.preventDefault();
		var asset = findAssetById($j("#hfEditAssetId").val());
		openCurrentDriversDialog(asset);
	});

	// this default button is necessary because we also have buttons within the form itself that come before submit
	// todo: ensure there is only one button with role=submit for the form
	// the rest should be role=button
	$j("#edit-asset-dialog").on("click", "#EditAssetDefaultButton", function (e) {
		e.preventDefault();
		$j("#EditAssetSave").trigger("click");
	});

	$j("#edit-asset-canned-message-group").on("click", "#SendCannedMessageGroup", function (e) {
		e.preventDefault();

		var id = $j("#hfEditAssetId").val();
		var status = $j("#edit-asset-status");
		status.text("").hide(); // clear previous status
		var cannedMessageGroupId = $j("#ddlEditAssetCannedMessageGroup").val();
		var dataPost = {
			assetId: id,
			cannedMessageGroupId: cannedMessageGroupId,
		};
		$j(this).prop("disabled", true);
		toggleLoadingMessage(true, "send-canned-message");
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/SendCannedMessageGroupToAsset"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				$j("#SendCannedMessageGroup").prop("disabled", false);
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						status.text("Canned message group sent to asset.").addClass("success").removeClass("error").show();
					}
				}
				toggleLoadingMessage(false, "send-canned-message");
			},
			error: function (xhr, status, error) {
				handleWebServiceError("An error occurred while sending the canned message group.");
				toggleLoadingMessage(false, "send-canned-message");
				$j("#SendCannedMessageGroup").prop("disabled", false);
			},
		});
	});

	$j("#edit-asset-address-book-group").on("click", "#SendAddressBookGroup", function (e) {
		e.preventDefault();

		var id = $j("#hfEditAssetId").val();
		var status = $j("#edit-asset-status");
		status.text("").hide(); // clear previous status
		var addressBookGroupId = $j("#ddlEditAssetAddressBookGroup").val();
		var dataPost = {
			assetId: id,
			addressBookGroupId: addressBookGroupId,
		};
		$j(this).prop("disabled", true);
		toggleLoadingMessage(true, "send-address-book");
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/SendAddressBookGroupToAsset"),
			data: JSON.stringify(dataPost),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				$j("#SendAddressBookGroup").prop("disabled", false);
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						status.text("Address book group sent to asset.").addClass("success").removeClass("error").show();
					}
				}
				toggleLoadingMessage(false, "send-address-book");
			},
			error: function (xhr, status, error) {
				handleWebServiceError("An error occurred while sending the address book group.");
				toggleLoadingMessage(false, "send-address-book");
				$j("#SendAddressBookGroup").prop("disabled", false);
			},
		});
	});

	initAssetEdit();

	$(domNodes.dialogs.editAsset).on("change", "#fileEditAssetIcon", function (e) {
		$("#rbEditAssetIcon-Upload").prop("checked", true);
	});
	$(domNodes.dialogs.editAsset).on("click", "#RemoveAssetPhoto", function (e) {
		e.preventDefault();
		$(this).next().val("true");
		$(this).parent().parent().hide();
	});

	$(domNodes.dialogs.editAsset).on("change", "#chkHideInformationAddress", function (e) {
		e.preventDefault();
		var isChecked = this.checked;
		if (!isChecked && user.displayPreferences.warnings.addresses === true) {
			this.checked = true;
			var $modal = $(domNodes.modals.confirmAssetSetting);
			$(".toggle-content", $modal).removeClass("is-visible");
			$("#ConfirmAssetSettingAddress").addClass("is-visible");
			// confirm they want to uncheck it (enable)
			$modal.data("setting", $(this));
			$modal.modal("show");
		}
	});

	$(domNodes.dialogs.editAsset).on("change", "#chkHideInformationSpeed", function (e) {
		e.preventDefault();
		var isChecked = this.checked;
		if (!isChecked && user.displayPreferences.warnings.speed === true) {
			// should be devices with estimated speed only
			var deviceId = null;
			if (state.isAddingAsset) {
				deviceId = parseInt(document.getElementById("ddlEditAssetDevice").value);
			} else {
				var asset = findAssetById(document.getElementById("hfEditAssetId").value);
				if (asset !== null) {
					deviceId = asset.DeviceId;
				}
			}
			if (deviceId !== null && devices.SPOT.indexOf(deviceId) !== -1) {
				this.checked = true;
				var $modal = $(domNodes.modals.confirmAssetSetting);
				$(".toggle-content", $modal).removeClass("is-visible");
				$("#ConfirmAssetSettingSpeed").addClass("is-visible");
				// confirm they want to uncheck it (enable)
				$modal.data("setting", $(this));
				$modal.modal("show");
			}
		}
	});

	$("#edit-asset-extra-accordion,#edit-asset-accordion,.menu-accordion").on("show.bs.collapse", function (e) {
		var chevron = e.target.previousElementSibling.querySelector("svg.list-item-action use");
		if (chevron !== null) {
			chevron.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#angle-up");
		}
		var icon = e.target.previousElementSibling.querySelector("svg.list-item-icon use");
		if (icon !== null) {
			icon.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#folder-open-solid");
		}
	});
	$("#edit-asset-extra-accordion,#edit-asset-accordion,.menu-accordion").on("hide.bs.collapse", function (e) {
		var chevron = e.target.previousElementSibling.querySelector("svg.list-item-action use");
		if (chevron !== null) {
			e.target.previousElementSibling
				.querySelector("svg.list-item-action use")
				.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#angle-down");
		}
		var icon = e.target.previousElementSibling.querySelector("svg.list-item-icon use");
		if (icon !== null) {
			e.target.previousElementSibling
				.querySelector("svg.list-item-icon use")
				.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#folder-solid");
		}
	});
	$("#edit-asset-extra-accordion,#edit-asset-accordion,.menu-accordion").on(
		"shown.bs.collapse,hidden.bs.collapse",
		function (e) {
			domNodes.simpleBars.primary.recalculate();
		}
	);

	initDatatables();

	$(domNodes.modals.confirmAssetSetting).on("click", "#ConfirmAssetSetting", function (e) {
		e.preventDefault();

		$("#DontShowSettingWarning").prop("checked", false);
		$(domNodes.modals.confirmAssetSetting).data("setting").prop("checked", false);
		$(domNodes.modals.confirmAssetSetting).modal("hide");
		$(domNodes.modals.confirmAssetSetting).removeData("setting");
	});

	$(domNodes.modals.confirmAssetSetting).on("change", "#DontShowSettingWarning", function (e) {
		var setting = $(domNodes.modals.confirmAssetSetting).data("setting")[0];
		if (setting.id === "chkHideInformationSpeed") {
			user.displayPreferences.warnings.speed = !this.checked;
		} else if (setting.id === "chkHideInformationAddress") {
			user.displayPreferences.warnings.addresses = !this.checked;
		}
		saveDisplayPreferences();
	});

	$(domNodes.modals.clearAssetHistory).on("click", "#ClearAssetHistoryConfirm", function (e) {
		e.preventDefault();

		var btn = this;
		var statusError = document.getElementById("clear-history-status");
		var statusSuccess = document.getElementById("edit-asset-status");
		var id = $j("#hfClearHistoryAssetId").val();
		var data = {
			assetId: id,
		};
		btn.disabled = true;
		toggleLoadingMessage(true, "clear-history");
		$j.ajax({
			type: "POST",
			url: wrapUrl("/services/GPSService.asmx/ClearAssetHistory"),
			data: JSON.stringify(data),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (msg) {
				btn.disabled = false;
				var result = msg.d;
				if (result) {
					if (result.Success == true) {
						$(domNodes.modals.clearAssetHistory).modal("hide");
						formShowSuccessMessage(statusSuccess, strings.MSG_CLEAR_HISTORY_SUCCESS);
						var asset = findAssetById(id);
						if (asset != null) {
							clearAssetPositions(asset);
						}
					} else {
						formShowErrorMessage(statusError, strings.MSG_CLEAR_HISTORY_ERROR);
						if (result.ErrorMessage != null && result.ErrorMessage != "") {
							formShowErrorMessage(statusError, statusError.textContent + " " + result.ErrorMessage);
						}
					}
				}
				toggleLoadingMessage(false, "clear-history");
			},
			error: function (xhr, status, error) {
				btn.disabled = false;
				handleWebServiceError(strings.MSG_CLEAR_HISTORY_ERROR);
				toggleLoadingMessage(false, "clear-history");
			},
		});
	});
	$("#edit-asset-dialog").on("click", "#ClearAssetHistory", function (e) {
		e.preventDefault();
		// open confirmation dialog
		var asset = findAssetById($j("#hfEditAssetId").val());
		$("#hfClearHistoryAssetId").val(asset.Id);
		// customize confirmation message
		$(".modal-body div.confirm", domNodes.modals.clearAssetHistory).text(
			strings.MSG_CLEAR_HISTORY_CONFIRM.replace("{0}", asset.Name)
		);
		$(domNodes.modals.clearAssetHistory).modal("show");
	});

	var tm3000Buttons = [
		{
			buttonType: "primary",
			text: strings.BUTTON_SET_OUTPUT,
			click: function () {
				var isFormValid = $(trkData.validation.tm3000.currentForm).valid();
				if (!isFormValid) {
					return;
				}

				var id = $(domNodes.dialogs.tm3000).data("assetId");
				var pin = $j("#ddlTM3000Pin").val();
				var vals = pin.split(",");
				var data = {
					assetId: id,
					pin: vals[0],
					value: vals[1],
					time: null,
					gateway: null,
					gatewayTimeout: null,
					gatewayRetries: null,
					groupIds: [],
					assetIds: [],
				};

				var status = document.getElementById("tm3000-query-status");
				handleAjaxFormSubmission(
					"SendOutputPinRequest",
					data,
					this,
					status,
					strings.MSG_SET_OUTPUT_SUCCESS,
					strings.MSG_SET_OUTPUT_ERROR
				);
			},
		},
		closeButton,
	];
	loadDialogButtons(domNodes.dialogs.tm3000, tm3000Buttons);

	loadDialogButtons(domNodes.dialogs.calamp, [closeButton]);
	$(domNodes.dialogs.calamp).on("click", "#CalampUpdateAPN", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.calampApn.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.calamp).data("assetId");
		var phoneNumber = $j("#ddlCalampPhoneNumberCountry").val() + $j("#txtCalampPhoneNumber").val();
		var apn = $j("#txtCalampAPN").val();
		var username = $j("#txtCalampAPNUsername").val();
		var password = $j("#txtCalampAPNPassword").val();
		var data = {
			assetId: id,
			phoneNumber: phoneNumber,
			apn: apn,
			apnUsername: username,
			apnPassword: password,
		};

		var status = document.getElementById("calamp-query-status");
		handleAjaxFormSubmission(
			"CalampUpdateAPN",
			data,
			this,
			status,
			strings.MSG_SET_APN_SUCCESS,
			strings.MSG_SET_APN_ERROR
		);
	});
	$(domNodes.dialogs.calamp).on("click", "#CalampSetOutput", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.calampOutput.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.calamp).data("assetId");
		var pin = $j("#ddlCalampPin").val();
		var vals = pin.split(",");
		var data = {
			assetId: id,
			pin: vals[0],
			value: vals[1],
			time: null,
			gateway: null,
			gatewayTimeout: null,
			gatewayRetries: null,
			groupIds: [],
			assetIds: [],
		};

		var status = document.getElementById("calamp-query-status");
		handleAjaxFormSubmission(
			"SendOutputPinRequest",
			data,
			this,
			status,
			strings.MSG_SET_OUTPUT_SUCCESS,
			strings.MSG_SET_OUTPUT_ERROR
		);
	});

	loadDialogButtons(domNodes.dialogs.inreach, [closeButton]);
	$(domNodes.dialogs.inreach).on("click", "#InReachRequestLocation", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.inreach).data("assetId");
		var data = {
			assetId: id,
			pollType: null,
		};

		var status = document.getElementById("inreach-query-status");
		handleAjaxFormSubmission(
			"SendLocationRequest",
			data,
			this,
			status,
			strings.MSG_LOCATION_REQUEST_SUCCESS,
			strings.MSG_LOCATION_REQUEST_ERROR
		);
	});
	$(domNodes.dialogs.inreach).on("click", "#InReachSetReportingInterval", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.inreachInterval.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.inreach).data("assetId");
		var data = {
			assetId: id,
			interval: $j("#ddlInReachReportingInterval").val(),
		};

		var status = document.getElementById("inreach-query-status");
		handleAjaxFormSubmission(
			"SendReportingIntervalRequest",
			data,
			this,
			status,
			strings.MSG_INTERVAL_SUCCESS,
			strings.MSG_INTERVAL_ERROR
		);
	});

	loadDialogButtons(domNodes.dialogs.geopro, [closeButton]);
	$(domNodes.dialogs.geopro).on("click", "#GeoProSetReportingInterval", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.geoproInterval.currentForm).valid();
		if (!isFormValid) return;

		var id = $(domNodes.dialogs.geopro).data("assetId");
		var data = {
			assetId: id,
			interval: $j("#txtGeoProReportingInterval").val(),
		};

		var status = document.getElementById("geopro-query-status");
		handleAjaxFormSubmission(
			"SendReportingIntervalRequest",
			data,
			this,
			status,
			strings.MSG_INTERVAL_SUCCESS,
			strings.MSG_INTERVAL_ERROR
		);
	});

	loadDialogButtons(domNodes.dialogs.nal, [closeButton]);
	$(domNodes.dialogs.nal).on("click", "#NALSetParameters", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.nal.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.nal).data("assetId");
		var properties = {
			ReportingInterval: getValueIfCheckboxSelected("#txtNALReportingInterval"),
			EmergencyReportingInterval: getValueIfCheckboxSelected("#txtNALEmergencyReportingInterval"),
			RetryInterval: getValueIfCheckboxSelected("#ddlNALRetryInterval"),
			EmergencyRetryInterval: getValueIfCheckboxSelected("#ddlNALEmergencyRetryInterval"),
			ReportFormat: getValueIfCheckboxSelected("#ddlNALReportFormat"),
			EmergencyEnabled: getValueIfCheckboxSelected("input[name=rbNALEmergencyEnabled]:checked"),
			TrackingEnabled: getValueIfCheckboxSelected("input[name=rbNALTrackingEnabled]:checked"),
			IncludeGPSInMessages: getValueIfCheckboxSelected("input[name=rbNALIncludeGPSInMessages]:checked"),
			MailboxChecks: getValueIfCheckboxSelected("#txtNALMailboxChecks"),
			EmergencyMailboxChecks: getValueIfCheckboxSelected("#txtNALEmergencyMailboxChecks"),
		};

		var status = document.getElementById("nal-query-status");
		var btn = this;

		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				properties: properties,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
				groupIds: groupIds,
				assetIds: assetIds,
			};

			$.when(
				handleAjaxFormSubmission(
					"SetNALProperties",
					data,
					btn,
					status,
					strings.MSG_PARAMETERS_SUCCESS,
					strings.MSG_PARAMETERS_ERROR
				)
			).done(function () {
				$(domNodes.modals.messageAction).modal("hide");
			});
		};
		var asset = findAssetById(id);
		var satelliteOnly = false;
		if (asset != null) {
			if (_.indexOf(devices.NAL_GSM, asset.DeviceId) === -1) {
				satelliteOnly = true;
			}
		}
		openActionDialog(strings.SET_PARAMETERS, strings.SET_PARAMETERS, callback, id, null, satelliteOnly, devices.NAL);
	});
	$(domNodes.dialogs.nal).on("click", "#NALRequestLocation", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.nal).data("assetId");
		var properties = {
			PollReport: true,
		};

		var status = document.getElementById("nal-query-status");
		var btn = this;
		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				properties: properties,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
				groupIds: groupIds,
				assetIds: assetIds,
			};

			$.when(
				handleAjaxFormSubmission(
					"SetNALProperties",
					data,
					btn,
					status,
					strings.MSG_LOCATION_REQUEST_SUCCESS,
					strings.MSG_LOCATION_REQUEST_ERROR
				)
			).done(function () {
				$(domNodes.modals.messageAction).modal("hide");
			});
		};
		var asset = findAssetById(id);
		var satelliteOnly = false;
		if (asset != null) {
			if (_.indexOf(devices.NAL_GSM, asset.DeviceId) === -1) {
				satelliteOnly = true;
			}
		}
		openActionDialog(
			strings.BUTTON_REQUEST_LOCATION,
			strings.BUTTON_REQUEST_LOCATION,
			callback,
			id,
			null,
			satelliteOnly,
			devices.NAL
		);
	});

	$j("#edit-asset-assets").on("click", "a.select-all", function (e) {
		e.preventDefault();
		$j("input[name=EditAssetAssetIds]").prop("checked", true);
	});

	$j("#edit-asset-assets").on("click", "a.select-none", function (e) {
		e.preventDefault();
		$j("input[name=EditAssetAssetIds]").prop("checked", false);
	});

	$("#dialog-functions").on("click", "a.select-all", function (e) {
		e.preventDefault();
		var container = $(this).closest(".parameters-form");
		$(".parameter-toggle input:checkbox", container).prop("checked", true);
		$(".parameter-toggle input:checkbox", container)
			.parents(".parameter-toggle")
			.next(".parameter-options")
			.find("input,select,label")
			.prop("disabled", false)
			.removeClass("disabled");
	});
	$("#dialog-functions").on("click", "a.select-none", function (e) {
		e.preventDefault();
		var container = $(this).closest(".parameters-form");
		$(".parameter-toggle input:checkbox", container).prop("checked", false);
		$(".parameter-toggle input:checkbox", container)
			.parents(".parameter-toggle")
			.next(".parameter-options")
			.find("input,select,label")
			.prop("disabled", true)
			.addClass("disabled");
	});
	$("#dialog-functions").on("click", ".parameters-form label", function (e) {
		var col = $(this).next("div");
		col.find(".parameter-toggle input:checkbox").prop("checked", true);
		col
			.find(".parameter-options")
			.find("input,select,label")
			.not(":checkbox")
			.prop("disabled", false)
			.removeClass("disabled");
		// todo: check .next-cell?
	});
	$("#dialog-functions").on("change", ".parameter-toggle input:checkbox", function (e) {
		var isDisabled = !$(this).prop("checked");
		var col = $(this).parents(".parameter-toggle").next(".parameter-options");
		if (isDisabled) {
			col.find("input,select,label").not(":checkbox").prop("disabled", isDisabled).addClass("disabled");
		} else {
			col.find("input,select,label").not(":checkbox").prop("disabled", isDisabled).removeClass("disabled");
		}
		// todo: check .next-cell?
	});

	$j("#idp-dialog,#queclink-dialog,#quake-dialog").on("click", "tr.collapse,tr.collapse a", function (e) {
		e.preventDefault();
		$j(this).nextUntil("tr.collapse").toggle();
		$j(this).nextUntil("tr.collapse").filter(".stayhidden").hide();
	});

	$(domNodes.modals.runReport).on("click", "#RunReportConfirm", function (e) {
		var isFormValid = $(trkData.validation.runReport.currentForm).valid();
		if (!isFormValid) {
			return;
		}
		$("#run-report-form").submit();
	});

	$(domNodes.modals.runReport).on("change", "#RunReportReport", function (e) {
		runReportOptionChanged();
	});
	$(domNodes.modals.messageAction).on("click", "a.select-all", function (e) {
		e.preventDefault();
		$("input[name=AssetIds]", domNodes.modals.messageAction).prop("checked", true);
	});
	$(domNodes.modals.messageAction).on("click", "a.select-non", function (e) {
		e.preventDefault();
		$("input[name=AssetIds]", domNodes.modals.messageAction).prop("checked", false);
	});

	$(domNodes.modals.messageAction).on("hidden.bs.modal", function (e) {
		if (state.nextFocus !== undefined && state.nextFocus !== null) {
			state.nextFocus.focus();
			state.nextFocus = null;
		}
	});
	$(domNodes.modals.messageAction).on("shown.bs.modal", function (e) {
		document.getElementById("message-action-button").focus();
	});
	$(domNodes.modals.messageAction).on("click", "#message-action-button", function (e) {
		var btn = this;
		btn.disabled = true;
		var dialog = domNodes.modals.messageAction;
		var isFormValid = $("#message-action-form").valid();
		if (!isFormValid) {
			return;
		}
		var id = $(dialog).data("assetId");
		var groupIds = [];
		$j(".GroupsContainer input:checked", dialog).each(function () {
			groupIds.push($j(this).val());
		});
		var assetIds = [];
		$j(".AssetsIncluded option", dialog).each(function () {
			assetIds.push($j(this).val());
		});

		var gateway = $j("input[name=IDPGateway]:checked", dialog).val();
		var gatewayTimeout = $j("#IDPGatewayTimeout").val();

		var gatewayRetries = $j("#IDPGatewayRetries").val();
		if (gateway == 3) {
			gatewayRetries = $j("#IDPGatewayCellRetries").val();
		}

		var callback = $j(dialog).data("message-action-callback");
		$.when(callback(id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries)).done(function () {
			btn.disabled = false;
		});
	});

	loadDialogButtons(domNodes.dialogs.lt100, [closeButton]);
	$(domNodes.dialogs.lt100).on("click", "#LT100RequestLocation,#LT100DismissHelp,#LT100DismissFall", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.lt100).data("assetId");
		var command = $j(this).data("command");
		var data = {
			assetId: id,
			command: {
				Type: command,
			},
		};

		var status = document.getElementById("lt100-query-status");
		handleAjaxFormSubmission(
			"LT100SendCommand",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});
	$(domNodes.dialogs.lt100).on("click", "#LT100VibrateDevice", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.lt100Vibrate.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.lt100).data("assetId");
		var type = $j(this).data("command");
		var vibrateInterval = $j("#LT100VibrationInterval").val();
		var beepInterval = $j("#LT100BeepInterval").val();
		var data = {
			assetId: id,
			command: {
				Type: type,
				VibrationInterval: vibrateInterval,
				BeepInterval: beepInterval,
			},
		};

		var status = document.getElementById("lt100-query-status");
		handleAjaxFormSubmission(
			"LT100SendCommand",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});
	$(domNodes.dialogs.lt100).on("click", "#LT100SetPeriodicMode", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.lt100Periodic.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.lt100).data("assetId");
		var type = $j(this).data("command");
		var interval = $j("#LT100PeriodicInterval").val();
		var data = {
			assetId: id,
			command: {
				Type: type,
				StationaryReportingInterval: interval,
			},
		};

		var status = document.getElementById("lt100-query-status");
		handleAjaxFormSubmission(
			"LT100SendCommand",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});
	$(domNodes.dialogs.lt100).on("click", "#LT100SetMotionMode", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.lt100Motion.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.lt100).data("assetId");
		var type = $j(this).data("command");
		var stationaryInterval = $j("#LT100StationaryInterval").val();
		var movingInterval = $j("#LT100MovingInterval").val();
		var data = {
			assetId: id,
			command: {
				Type: type,
				StationaryReportingInterval: stationaryInterval,
				MovingReportingInterval: movingInterval,
			},
		};

		var status = document.getElementById("lt100-query-status");
		handleAjaxFormSubmission(
			"LT100SendCommand",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});
	$(domNodes.dialogs.lt100).on("click", "#LT100SetStandbyMode", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.lt100).data("assetId");
		var type = $j(this).data("command");
		var data = {
			assetId: id,
			command: {
				Type: type,
			},
		};

		var status = document.getElementById("lt100-query-status");
		handleAjaxFormSubmission(
			"LT100SendCommand",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});

	loadDialogButtons(domNodes.dialogs.lt501, [closeButton]);
	$(domNodes.dialogs.lt501).on("click", "#LT501DismissHelp", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.lt501).data("assetId");
		var command = $j(this).data("command");

		var data = {
			assetId: id,
			command: {
				Type: command,
			},
		};

		var status = document.getElementById("lt501-query-status");
		handleAjaxFormSubmission(
			"LT501SendCommand",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});
	$(domNodes.dialogs.lt501).on("click", "#LT501RequestLocation", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.lt501Location.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.lt501).data("assetId");
		var type = $j(this).data("command");
		var interval = $j("#LT501GpsTime").val();
		var data = {
			assetId: id,
			command: {
				Type: type,
				GpsTime: interval,
			},
		};

		var status = document.getElementById("lt501-query-status");
		handleAjaxFormSubmission(
			"LT501SendCommand",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});
	$(domNodes.dialogs.lt501).on("click", "#LT501SetPeriodicMode", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.lt501Periodic.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.lt501).data("assetId");
		var type = $j(this).data("command");
		var interval = $j("#LT501PeriodicInterval").val();
		var data = {
			assetId: id,
			command: {
				Type: type,
				StationaryReportingInterval: interval,
			},
		};

		var status = document.getElementById("lt501-query-status");
		handleAjaxFormSubmission(
			"LT501SendCommand",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});
	$(domNodes.dialogs.lt501).on("click", "#LT501SetMotionMode", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.lt501Motion.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.lt501).data("assetId");
		var type = $j(this).data("command");
		var stationaryInterval = $j("#LT501StationaryInterval").val();
		var movingInterval = $j("#LT501MovingInterval").val();
		var gSensorSensitivity = $j("#LT501GSensorSensitivity").val();
		var data = {
			assetId: id,
			command: {
				Type: type,
				StationaryReportingInterval: stationaryInterval,
				MovingReportingInterval: movingInterval,
				GSensorSensitivity: gSensorSensitivity,
			},
		};

		var status = document.getElementById("lt501-query-status");
		handleAjaxFormSubmission(
			"LT501SendCommand",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});

	loadDialogButtons(domNodes.dialogs.itrac, [closeButton]);
	$(domNodes.dialogs.itrac).on(
		"click",
		"#ITracRequestLocation,#ITracResetDevice,#ITracSetReportingInterval",
		function (e) {
			e.preventDefault();

			var id = $(domNodes.dialogs.itrac).data("assetId");
			var command = $(this).data("command");
			var data = {
				assetId: id,
				command: command,
				reportingInterval: document.getElementById("ddlITracReportingInterval").value
			};

			var status = document.getElementById("itrac-query-status");
			handleAjaxFormSubmission(
				"ITracSendCommand",
				data,
				this,
				status,
				strings.MSG_COMMAND_SUCCESS,
				strings.MSG_COMMAND_ERROR
			);
		}
	);

	loadDialogButtons(domNodes.dialogs.hughes, [closeButton]);
	$(domNodes.dialogs.hughes).on(
		"click",
		"#HughesRequestLocation,#HughesResetDevice,#HughesUpdateFirmware,#HughesRequestUsage",
		function (e) {
			e.preventDefault();

			var id = $(domNodes.dialogs.hughes).data("assetId");
			var command = $j(this).data("command");

			var data = {
				assetId: id,
				command: command,
			};

			var status = document.getElementById("hughes-query-status");
			handleAjaxFormSubmission(
				"HughesSendCommand",
				data,
				this,
				status,
				strings.MSG_COMMAND_SUCCESS,
				strings.MSG_COMMAND_ERROR
			);
		}
	);
	$(domNodes.dialogs.hughes).on("click", "#HughesSendCommand", function (e) {
		e.preventDefault();
		var isFormValid = $(trkData.validation.hughesCommands.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.hughes).data("assetId");
		var command = $j("#HughesCommand").val();
		var data = {
			assetId: id,
			command: command,
		};

		var status = document.getElementById("hughes-query-status");
		handleAjaxFormSubmission(
			"HughesSendRawCommand",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});
	$(domNodes.dialogs.hughes).on("click", "#HughesDownloadFile", function (e) {
		e.preventDefault();
		var isFormValid = $(trkData.validation.hughesDownloadFile.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.hughes).data("assetId");
		var data = {
			assetId: id,
			localDirectory: $("#txtHughesLocalDirectory").val(),
			filename: $("#txtHughesFilename").val(),
			ftpDirectory: $("#txtHughesFTPDirectory").val(),
			ftpServer: $("#txtHughesFTPServer").val(),
			ftpUsername: $("#txtHughesFTPUsername").val(),
			ftpPassword: $("#txtHughesFTPPassword").val(),
			apn: $("#txtHughesAPN").val(),
			apnUsername: $("#txtHughesAPNUsername").val(),
			apnPassword: $("#txtHughesAPNPassword").val(),
		};

		var status = document.getElementById("hughes-query-status");
		handleAjaxFormSubmission(
			"HughesDownloadFile",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});

	loadDialogButtons(domNodes.dialogs.flightcell, [closeButton]);
	$(domNodes.dialogs.flightcell).on("click", "#FlightcellSendPayload", function (e) {
		e.preventDefault();
		var isFormValid = $(trkData.validation.flightcellPayload.currentForm).valid();
		if (!isFormValid) {
			return;
		}
		var id = $(domNodes.dialogs.flightcell).data("assetId");
		var data = {
			assetId: id,
			payload: {
				Sender: $("#FlightcellPayloadSender").val(),
				Remote: $("#FlightcellPayloadRemote").val(),
				ContentType: $("#FlightcellPayloadContentType").val(),
				Subject: $("#FlightcellPayloadSubject").val(),
				Payload: $("#FlightcellPayloadPayload").val(),
			},
		};
		var status = document.getElementById("flightcell-query-status");
		handleAjaxFormSubmission(
			"FlightcellSendPayload",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});

	loadDialogButtons(domNodes.dialogs.edgeSolar, [closeButton]);
	$(domNodes.dialogs.edgeSolar).on("click", "#QueryEdgeSolarConfig", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.edgeSolar).data("assetId");
		var data = {
			assetId: id,
		};

		var status = document.getElementById("edge-solar-query-status");
		handleAjaxFormSubmission(
			"EdgeSolarSendGetConfigurationRequest",
			data,
			this,
			status,
			strings.MSG_GET_PARAMETERS_SUCCESS,
			strings.MSG_GET_PARAMETERS_ERROR
		);
	});

	$(domNodes.dialogs.edgeSolar).on("click", "#EdgeSolarSetParameters", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.edgeSolarSettings.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.edgeSolar).data("assetId");
		var config = {
			Interval1: {
				IsEnabled: !document.getElementById("EdgeSolarReportingInterval1Disabled").checked,
				IsTimeOfDay: document.getElementById("EdgeSolarReportingInterval1TimeOfDay").checked,
				TimeMinutesPastUtc:
					parseInt(document.getElementById("EdgeSolarReportingInterval1TimeHour").value) * 60 +
					parseInt(document.getElementById("EdgeSolarReportingInterval1TimeMinute").value),
				IntervalMinutes: document.getElementById("EdgeSolarReportingInterval1IntervalMinutes").value,
			},
			Interval2: {
				IsEnabled: !document.getElementById("EdgeSolarReportingInterval2Disabled").checked,
				IsTimeOfDay: document.getElementById("EdgeSolarReportingInterval2TimeOfDay").checked,
				TimeMinutesPastUtc:
					parseInt(document.getElementById("EdgeSolarReportingInterval2TimeHour").value) * 60 +
					parseInt(document.getElementById("EdgeSolarReportingInterval2TimeMinute").value),
				IntervalMinutes: document.getElementById("EdgeSolarReportingInterval2IntervalMinutes").value,
			},
			Interval3: {
				IsEnabled: !document.getElementById("EdgeSolarReportingInterval3Disabled").checked,
				IsTimeOfDay: document.getElementById("EdgeSolarReportingInterval3TimeOfDay").checked,
				TimeMinutesPastUtc:
					parseInt(document.getElementById("EdgeSolarReportingInterval3TimeHour").value) * 60 +
					parseInt(document.getElementById("EdgeSolarReportingInterval3TimeMinute").value),
				IntervalMinutes: document.getElementById("EdgeSolarReportingInterval3IntervalMinutes").value,
			},
			Interval4: {
				IsEnabled: !document.getElementById("EdgeSolarReportingInterval4Disabled").checked,
				IsTimeOfDay: document.getElementById("EdgeSolarReportingInterval4TimeOfDay").checked,
				TimeMinutesPastUtc:
					parseInt(document.getElementById("EdgeSolarReportingInterval4TimeHour").value) * 60 +
					parseInt(document.getElementById("EdgeSolarReportingInterval4TimeMinute").value),
				IntervalMinutes: document.getElementById("EdgeSolarReportingInterval4IntervalMinutes").value,
			},
			Interval5: {
				IsEnabled: !document.getElementById("EdgeSolarReportingInterval5Disabled").checked,
				IsTimeOfDay: document.getElementById("EdgeSolarReportingInterval5TimeOfDay").checked,
				TimeMinutesPastUtc:
					parseInt(document.getElementById("EdgeSolarReportingInterval5TimeHour").value) * 60 +
					parseInt(document.getElementById("EdgeSolarReportingInterval5TimeMinute").value),
				IntervalMinutes: document.getElementById("EdgeSolarReportingInterval5IntervalMinutes").value,
			},
			Interval6: {
				IsEnabled: !document.getElementById("EdgeSolarReportingInterval6Disabled").checked,
				IsTimeOfDay: document.getElementById("EdgeSolarReportingInterval6TimeOfDay").checked,
				TimeMinutesPastUtc:
					parseInt(document.getElementById("EdgeSolarReportingInterval6TimeHour").value) * 60 +
					parseInt(document.getElementById("EdgeSolarReportingInterval6TimeMinute").value),
				IntervalMinutes: document.getElementById("EdgeSolarReportingInterval6IntervalMinutes").value,
			},
			Interval7: {
				IsEnabled: !document.getElementById("EdgeSolarReportingInterval7Disabled").checked,
				IsTimeOfDay: document.getElementById("EdgeSolarReportingInterval7TimeOfDay").checked,
				TimeMinutesPastUtc:
					parseInt(document.getElementById("EdgeSolarReportingInterval7TimeHour").value) * 60 +
					parseInt(document.getElementById("EdgeSolarReportingInterval7TimeMinute").value),
				IntervalMinutes: document.getElementById("EdgeSolarReportingInterval7IntervalMinutes").value,
			},
			Interval8: {
				IsEnabled: !document.getElementById("EdgeSolarReportingInterval8Disabled").checked,
				IsTimeOfDay: document.getElementById("EdgeSolarReportingInterval8TimeOfDay").checked,
				TimeMinutesPastUtc:
					parseInt(document.getElementById("EdgeSolarReportingInterval8TimeHour").value) * 60 +
					parseInt(document.getElementById("EdgeSolarReportingInterval8TimeMinute").value),
				IntervalMinutes: document.getElementById("EdgeSolarReportingInterval8IntervalMinutes").value,
			},
			EngineeringMessageIntervalHours: document.getElementById("EdgeSolarEngineeringMessageInterval").value,
			MailboxCheckIntervalHours: document.getElementById("EdgeSolarMailboxCheckIntervalHours").value,
			MailboxCheckIsEnabled: document.getElementById("EdgeSolarMailboxCheckIsEnabledTrue").checked,
			IridiumRetryCount: document.getElementById("EdgeSolarIridiumRetryCount").value,
			VbmrMode: document.getElementById("EdgeSolarVbmrMode").value,
			VbmrIntervalMinutes: document.getElementById("EdgeSolarVbmrIntervalMinutes").value,
			VbmrIsEnabledDuringPowerSave: document.getElementById("EdgeSolarVbmrIsEnabledDuringPowerSaveYes").checked,
			PowerSaveIsEnabled: document.getElementById("EdgeSolarPowerSaveIsEnabledYes").checked,
			PowerSaveIsDays: document.getElementById("EdgeSolarPowerSaveIsDays").value === "1",
			PowerSaveCount: document.getElementById("EdgeSolarPowerSaveCount").value,
			PowerSaveIntervalHours: document.getElementById("EdgeSolarPowerSaveIntervalHours").value,
			GbmrIsEnabled: document.getElementById("EdgeSolarGbmrIsEnabledYes").checked,
			GbmrCheckIntervalMinutes: document.getElementById("EdgeSolarGbmrCheckIntervalMinutes").value,
			GbmrHomeRatio: document.getElementById("EdgeSolarGbmrHomeRatio").value,
			GbmrAwayRatio: document.getElementById("EdgeSolarGbmrAwayRatio").value,
		};
		var data = {
			assetId: id,
			config: config,
		};

		var status = document.getElementById("edge-solar-query-status");
		handleAjaxFormSubmission(
			"EdgeSolarSendSetConfigurationRequest",
			data,
			this,
			status,
			strings.MSG_PARAMETERS_SUCCESS,
			strings.MSG_PARAMETERS_ERROR
		);
	});
	$(domNodes.dialogs.edgeSolar).on(
		"change",
		"input[name=EdgeSolarGbmrIsEnabled],input[name=EdgeSolarPowerSaveIsEnabled],#EdgeSolarVbmrMode",
		function (e) {
			e.preventDefault();
			toggleEdgeSolarVisibleElements();
		}
	);
	$(domNodes.dialogs.edgeSolar).on("click", "#EdgeSolarRequestLocation", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.edgeSolar).data("assetId");
		var data = {
			assetId: id,
			pollType: null,
		};

		var status = document.getElementById("edge-solar-query-status");
		handleAjaxFormSubmission(
			"SendLocationRequest",
			data,
			this,
			status,
			strings.MSG_LOCATION_REQUEST_SUCCESS,
			strings.MSG_LOCATION_REQUEST_ERROR
		);
	});
	$(domNodes.dialogs.edgeSolar).on("click", "#EdgeSolarRequestEngineeringData", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.edgeSolar).data("assetId");
		var data = {
			assetId: id,
		};

		var status = document.getElementById("edge-solar-query-status");
		handleAjaxFormSubmission(
			"EdgeSolarSendEngineeringRequest",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});
	$(domNodes.dialogs.edgeSolar).on("click", "#EdgeSolarSetScratchpad", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.edgeSolar).data("assetId");
		var data = {
			assetId: id,
			data: document.getElementById("EdgeSolarUserScratchpad").value,
		};

		var status = document.getElementById("edge-solar-query-status");
		handleAjaxFormSubmission(
			"EdgeSolarSendSetScratchpadRequest",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});

	loadDialogButtons(domNodes.dialogs.queclink, [closeButton]);
	$(domNodes.dialogs.queclink).on("click", "#QueryQueclinkConfig", function (e) {
		e.preventDefault();

		var id = $j(domNodes.dialogs.queclink).data("assetId");
		var data = {
			assetId: id,
		};

		var status = document.getElementById("queclink-query-status");
		handleAjaxFormSubmission(
			"QueclinkSendGetConfigurationRequest",
			data,
			this,
			status,
			strings.MSG_GET_PARAMETERS_SUCCESS,
			strings.MSG_GET_PARAMETERS_ERROR
		);
	});
	$(domNodes.dialogs.queclink).on("keyup", "#queclink-apn-form", function (e) {
		// recreate apn/server command based on new values
		queclinkCreateAPNCommand($(domNodes.dialogs.queclink).data("assetId"));
	});
	$(domNodes.dialogs.queclink).on("click", "#QueclinkUpdateAPN", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.queclinkApn.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $j(domNodes.dialogs.queclink).data("assetId");
		var phoneNumber = $j("#ddlQueclinkPhoneNumberCountry").val() + $j("#txtQueclinkPhoneNumber").val();
		var apn = $j("#txtQueclinkAPN").val();
		var username = $j("#txtQueclinkAPNUsername").val();
		var password = $j("#txtQueclinkAPNPassword").val();
		var data = {
			assetId: id,
			phoneNumber: phoneNumber,
			apn: apn,
			apnUsername: username,
			apnPassword: password,
		};

		var status = document.getElementById("queclink-query-status");
		handleAjaxFormSubmission(
			"QueclinkUpdateAPN",
			data,
			this,
			status,
			strings.MSG_SET_APN_SUCCESS,
			strings.MSG_SET_APN_ERROR
		);
	});
	$(domNodes.dialogs.queclink).on("click", "#QueclinkSetParameters", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.queclinkSettings.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var fri = null;
		if ($j("#QueclinkUpdateFRI").is(":checked")) {
			fri = {
				ReportInclude: {
					Speed: $j("#chkQueclinkReportIncludeSpeed").is(":checked"),
					GSMTowerData: $j("#chkQueclinkReportIncludeGSMTowerData").is(":checked"),
					Azimuth: $j("#chkQueclinkReportIncludeAzimuth").is(":checked"),
					SendTime: $j("#chkQueclinkReportIncludeSendTime").is(":checked"),
					Altitude: $j("#chkQueclinkReportIncludeAltitude").is(":checked"),
				},
				ReportMode: $j("#ddlQueclinkReportMode").val(),
				CheckInterval: $j("#txtQueclinkGPSCheckInterval").val(),
				SendInterval: $j("#txtQueclinkSendInterval").val(),
				IgnitionCheckInterval: $j("#txtQueclinkIgnitionOnGPSCheckInterval").val(),
				IgnitionSendInterval: $j("#txtQueclinkIgnitionOnSendInterval").val(),
				BeginTime: $j("#txtQueclinkStartTime").val(),
				EndTime: $j("#txtQueclinkEndTime").val(),
				Distance: $j("#txtQueclinkDistance").val(),
				Mileage: $j("#txtQueclinkMileage").val(),
				CheckInterval: $j("#txtQueclinkGPSCheckInterval").val(),
				MovementDetectMode: $j("input:radio[name=rbQueclinkMovementDetectMode]:checked").val(),
				MovementSpeed: $j("#txtQueclinkMovementSpeed").val(),
				MovementDistance: $j("#txtQueclinkMovementDistance").val(),
				Corner: $j("#txtQueclinkCorner").val(),
				DiscardNoFix: $j("input:radio[name=rbQueclinkDiscardNoFix]:checked").val(),
			};
		}
		var parameters = {
			UniqueId: "test",
			ProtocolVersion: "whatever",
			DeviceName: "testing",
			FRI: fri,
		};

		var data = {
			assetId: $j(domNodes.dialogs.queclink).data("assetId"),
			parameters: parameters,
		};

		var status = document.getElementById("queclink-query-status");
		handleAjaxFormSubmission(
			"QueclinkSendSetParametersRequest",
			data,
			this,
			status,
			strings.MSG_PARAMETERS_SUCCESS,
			strings.MSG_PARAMETERS_ERROR
		);
	});
	$(domNodes.dialogs.queclink).on("click", "#QueclinkSendCommand", function (e) {
		e.preventDefault();
		var isFormValid = $(trkData.validation.queclinkCommands.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.queclink).data("assetId");
		var command = $j("#QueclinkCommand").val();
		var data = {
			assetId: id,
			command: command,
		};

		var status = document.getElementById("queclink-query-status");
		handleAjaxFormSubmission(
			"QueclinkSendRawCommand",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});
	$(domNodes.dialogs.queclink).on(
		"click",
		"#QueclinkRequestLocation,#QueclinkFactoryReset,#QueclinkPowerOff,#QueclinkResetDevice",
		function (e) {
			e.preventDefault();

			var id = $(domNodes.dialogs.queclink).data("assetId");
			var command = $j(this).data("command");

			var data = {
				assetId: id,
				command: command,
			};

			var status = document.getElementById("queclink-query-status");
			handleAjaxFormSubmission(
				"QueclinkSendCommand",
				data,
				this,
				status,
				strings.MSG_COMMAND_SUCCESS,
				strings.MSG_COMMAND_ERROR
			);
		}
	);

	loadDialogButtons(domNodes.dialogs.quake, [closeButton]);
	$(domNodes.dialogs.quake).on("click", "#QuakeSetParameters", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.quake.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.quake).data("assetId");
		var ignition = null;
		//if ($j('#QuakeAICIgnition').is(':checked')) {
		ignition = {
			ReportIgnitionOff: $j("input:radio[name=rbQuakeIgnitionReportOff]:checked").val(),
			ReportIgnitionOn: $j("input:radio[name=rbQuakeIgnitionReportOn]:checked").val(),
			IgnitionOffDebounce: $j("#txtQuakeIgnitionOffDebounce").val(),
			IgnitionOnDebounce: $j("#txtQuakeIgnitionOnDebounce").val(),
		};
		//}
		var parameters = {
			Ignition: ignition,
		};

		var status = document.getElementById("quake-query-status");
		var btn = this;

		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				properties: parameters,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
				groupIds: groupIds,
				assetIds: assetIds,
			};

			$.when(
				handleAjaxFormSubmission(
					"QuakeSendSetParametersRequest",
					data,
					btn,
					status,
					strings.MSG_PARAMETERS_SUCCESS,
					strings.MSG_PARAMETERS_ERROR
				)
			).done(function () {
				$(domNodes.modals.messageAction).modal("hide");
			});
		};

		openActionDialog(strings.SET_PARAMETERS, strings.SET_PARAMETERS, callback, id, null, false, devices.QUAKE_AIC);
	});
	// todo: move this to appropriate place
	function quakeCommand(parameters, label) {
		var id = $(domNodes.dialogs.quake).data("assetId");

		var status = document.getElementById("quake-query-status");
		var btn = this;
		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: id,
				properties: parameters,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
				groupIds: groupIds,
				assetIds: assetIds,
			};

			$.when(
				handleAjaxFormSubmission(
					"QuakeSendSetParametersRequest",
					data,
					btn,
					status,
					strings.MSG_COMMAND_SUCCESS,
					strings.MSG_COMMAND_ERROR
				)
			).done(function () {
				$(domNodes.modals.messageAction).modal("hide");
			});
		};

		openActionDialog(label, label, callback, id, null, false, devices.QUAKE_AIC);
	}
	$(domNodes.dialogs.quake).on("click", "#QuakeRebootDevice", function (e) {
		e.preventDefault();

		var parameters = {
			Reboot: true,
		};
		quakeCommand(parameters, strings.RESET_DEVICE);
	});
	$(domNodes.dialogs.quake).on("click", "#QuakeRequestLocation", function (e) {
		e.preventDefault();
		var parameters = {
			RequestLocation: true,
		};
		quakeCommand(parameters, strings.BUTTON_REQUEST_LOCATION);
	});
	$(domNodes.dialogs.quake).on("click", "#QueryQuakeConfig", function (e) {
		e.preventDefault();
	});

	loadDialogButtons(domNodes.dialogs.gtts, [closeButton]);
	$(domNodes.dialogs.gtts).on("click", "#GTTSSetParameters", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.gttsSettings.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var asset = findAssetById($(domNodes.dialogs.gtts).data("assetId"));
		var parameters = {
			GTTS3000: null,
			GTTS2000BI: null,
		};

		if (asset.DeviceId == devices.GTTS_3000) {
			parameters.GTTS3000 = {
				PositionInterval: $j("#txtGTTSReportingInterval").val(),
				SecondaryInterval: $j("#txtGTTSSecondaryInterval").val(),
				MessageBufferSize: $("#txtGTTSMessageBufferSize").val(),
			};
		} else if (asset.DeviceId == devices.GTTS_2000BI) {
			parameters.GTTS2000BI = {
				PositionInterval: $j("#txtGTTSReportingInterval").val(),
				SecondaryInterval: $j("#txtGTTSSecondaryInterval").val(),
				MessageBufferSize: $("#txtGTTSMessageBufferSize").val(),
				MotionDetection: $j("input:radio[name=rbGTTSMotionDetection]:checked").val(),
			};
		}

		var data = {
			assetId: asset.Id,
			parameters: parameters,
		};

		var status = document.getElementById("gtts-query-status");
		handleAjaxFormSubmission(
			"GTTSSendSetParametersRequest",
			data,
			this,
			status,
			strings.MSG_PARAMETERS_SUCCESS,
			strings.MSG_PARAMETERS_ERROR
		);
	});
	$(domNodes.dialogs.gtts).on("click", "#GTTSRequestLocation", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.gtts).data("assetId");
		var command = $j(this).data("command");
		var data = {
			assetId: id,
			command: command,
		};

		var status = document.getElementById("gtts-query-status");
		handleAjaxFormSubmission(
			"GTTSSendCommand",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});

	initIDPCommand();

	loadDialogButtons(domNodes.dialogs.extreme, [closeButton]);
	$(domNodes.dialogs.extreme).on("click", "#btn9575LocationRequest", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.extreme).data("assetId");
		var data = {
			assetId: id,
			pollType: null,
		};

		var status = document.getElementById("extreme-query-status");
		handleAjaxFormSubmission(
			"SendLocationRequest",
			data,
			this,
			status,
			strings.MSG_LOCATION_REQUEST_SUCCESS,
			strings.MSG_LOCATION_REQUEST_ERROR
		);
	});
	$(domNodes.dialogs.extreme).on("click", "#btn9575DiagnosticRequest", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.extreme).data("assetId");
		var data = {
			assetId: id,
		};

		var status = document.getElementById("extreme-query-status");
		handleAjaxFormSubmission(
			"SendDiagnosticsRequest",
			data,
			this,
			status,
			strings.MSG_DIAGNOSTIC_REQUEST_SUCCESS,
			strings.MSG_DIAGNOSTIC_REQUEST_ERROR
		);
	});
	$(domNodes.dialogs.extreme).on("click", "#btn9575EmergencyCancel", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.extreme).data("assetId");
		var data = {
			assetId: id,
		};

		var status = document.getElementById("extreme-query-status");
		handleAjaxFormSubmission(
			"EmergencyCancel",
			data,
			this,
			status,
			strings.MSG_CANCEL_EMERGENCY_SUCCESS,
			strings.MSG_CANCEL_EMERGENCY_ERROR
		);
	});
	$(domNodes.dialogs.extreme).on("click", "#btn9575SetEmergencyRecipient", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.extremeEmergencyRecipient.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.extreme).data("assetId");
		var recipient = $j("#txt9575EmergencyRecipient").val();
		var data = {
			assetId: id,
			recipient: recipient,
		};

		var status = document.getElementById("extreme-query-status");
		handleAjaxFormSubmission(
			"SetEmergencyRecipient",
			data,
			this,
			status,
			strings.MSG_SET_EMERGENCY_RECIPIENT_SUCCESS,
			strings.MSG_SET_EMERGENCY_RECIPIENT_ERROR
		);
	});
	$(domNodes.dialogs.extreme).on("click", "#btn9575SetEmergencyDestination", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.extremeEmergencyDestination.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.extreme).data("assetId");
		var destination = $j("#ddl9575EmergencyDestination").val();
		var data = {
			assetId: id,
			destination: destination,
		};

		var status = document.getElementById("extreme-query-status");
		handleAjaxFormSubmission(
			"SetEmergencyDestination",
			data,
			this,
			status,
			strings.MSG_SET_EMERGENCY_DESTINATION_SUCCESS,
			strings.MSG_SET_EMERGENCY_DESTINATION_ERROR
		);
	});
	$(domNodes.dialogs.extreme).on("click", "#btn9575SetInterval", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.extremeInterval.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.extreme).data("assetId");
		var interval = $j("#ddl9575ScheduleInterval").val();
		var data = {
			assetId: id,
			interval: interval,
		};

		var status = document.getElementById("extreme-query-status");
		handleAjaxFormSubmission(
			"SendReportingIntervalRequest",
			data,
			this,
			status,
			strings.MSG_INTERVAL_SUCCESS,
			strings.MSG_INTERVAL_ERROR
		);
	});

	var fbbButtons = [
		{
			buttonType: "primary",
			text: strings.BUTTON_SET_INTERVAL,
			click: function () {
				var isFormValid = $(trkData.validation.fbbInterval.currentForm).valid();
				if (!isFormValid) {
					return;
				}
				var id = $(domNodes.dialogs.fbb).data("assetId");
				var interval = $j("#ddlFBBScheduleInterval").val();
				var data = {
					assetId: id,
					interval: interval,
				};

				var status = document.getElementById("fbb-query-status");
				handleAjaxFormSubmission(
					"SendReportingIntervalRequest",
					data,
					this,
					status,
					strings.MSG_DPLUS_INTERVAL_SUCCESS,
					strings.MSG_INTERVAL_ERROR
				);
			},
		},
		closeButton,
	];
	loadDialogButtons(domNodes.dialogs.fbb, fbbButtons);

	loadDialogButtons(domNodes.dialogs.dPlus, [closeButton]);
	$j(domNodes.dialogs.dPlus).on("click", "#btnDPlusSendRequest", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.dPlusQuery.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $j(domNodes.dialogs.dPlus).data("assetId");
		var ocean = $j("#ddlDPlusRegion").val();
		var report = $j("#ddlDPlusReportType").val();
		var data = {
			assetId: id,
			ocean: ocean,
			reportId: report,
		};

		var status = document.getElementById("dplus-query-status");
		handleAjaxFormSubmission(
			"SendDPlusReportRequest",
			data,
			this,
			status,
			strings.MSG_DPLUS_REPORT_SUCCESS,
			strings.MSG_DPLUS_REPORT_ERROR
		);
	});
	$j(domNodes.dialogs.dPlus).on("click", "#btnDPlusSubmitInterval", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.dPlusInterval.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $j(domNodes.dialogs.dPlus).data("assetId");
		var ocean = $j("#ddlDPlusIntervalRegion").val();
		var reportType = $j("input[name=rblDPlusOption]:checked", domNodes.dialogs.dPlus).val();
		var reportId = $j("#ddlDPlusScheduleReportType").val();
		var interval = $j("#ddlDPlusScheduleInterval").val();
		var data = {
			assetId: id,
			ocean: ocean,
			reportType: reportType,
			reportId: reportId,
			interval: interval,
		};

		var status = document.getElementById("dplus-query-status");
		handleAjaxFormSubmission(
			"SendDPlusIntervalRequest",
			data,
			this,
			status,
			strings.MSG_DPLUS_INTERVAL_SUCCESS,
			strings.MSG_DPLUS_INTERVAL_ERROR
		);
	});

	loadDialogButtons(domNodes.dialogs.inmarsatC, [closeButton]);
	$(domNodes.dialogs.inmarsatC).on("click", "button.dnid-download", function (e) {
		e.preventDefault();

		var id = $(domNodes.dialogs.inmarsatC).data("assetId");
		var ocean = $(this).data("region");
		var data = {
			assetId: id,
			oceanRegion: ocean,
		};

		var status = document.getElementById("inmarsatc-query-status");
		var asset = findAssetById(id);
		handleAjaxFormSubmission(
			"InmarsatCDownloadDNID",
			data,
			this,
			status,
			strings.DNID_DOWNLOAD_SUCCESS,
			strings.DNID_DOWNLOAD_ERROR,
			function () {
				requestInmarsatCInformation(asset);
			}
		);
	});
	$(domNodes.dialogs.inmarsatC).on("click", "#InmarsatCDeleteDnid", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.inmarsatCDelete.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.inmarsatC).data("assetId");
		var ocean = $("#ddlInmarsatCDeleteDnidRegion").val();
		var data = {
			assetId: id,
			oceanRegion: ocean,
		};

		var status = document.getElementById("inmarsatc-query-status");
		var asset = findAssetById(id);
		handleAjaxFormSubmission(
			"InmarsatCDeleteDNID",
			data,
			this,
			status,
			strings.DNID_DELETE_SUCCESS,
			strings.DNID_DELETE_ERROR,
			function () {
				requestInmarsatCInformation(asset);
			}
		);
	});
	$(domNodes.dialogs.inmarsatC).on("click", "#InmarsatCRequestLocation", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.inmarsatC.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $(domNodes.dialogs.inmarsatC).data("assetId");
		var ocean = $("#ddlInmarsatCRequestLocationRegion").val();
		var data = {
			assetId: id,
			oceanRegion: ocean,
		};

		var status = document.getElementById("inmarsatc-query-status");
		handleAjaxFormSubmission(
			"InmarsatCSendPollCommand",
			data,
			this,
			status,
			strings.MSG_LOCATION_REQUEST_SUCCESS,
			strings.MSG_LOCATION_REQUEST_ERROR
		);
	});

	$(domNodes.dialogs.inmarsatC).on("click", "#InmarsatCScheduleReports", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.inmarsatCSchedule.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var id = $j(domNodes.dialogs.inmarsatC).data("assetId");
		var commandType = document.getElementById("ddlInmarsatCPollType").value;
		var startHour = document.getElementById("ddlInmarsatCStartTimeHour").value;
		var startMinute = document.getElementById("ddlInmarsatCStartTimeMinute").value;
		var reportsPerDay = document.getElementById("txtInmarsatCReportsPerDay").value;
		var ocean = document.getElementById("ddlInmarsatCPollRegion").value;
		var data = {
			assetId: id,
			commandType: commandType,
			hour: startHour,
			minute: startMinute,
			reportsPerDay: reportsPerDay,
			oceanRegion: ocean,
		};

		var status = document.getElementById("inmarsatc-query-status");
		handleAjaxFormSubmission(
			"InmarsatCSendScheduledPollCommand",
			data,
			this,
			status,
			strings.MSG_COMMAND_SUCCESS,
			strings.MSG_COMMAND_ERROR
		);
	});
	$(domNodes.dialogs.inmarsatC).on("change", "#ddlInmarsatCPollType", function (e) {
		e.preventDefault();
		const options = document.getElementById("InmarsatCScheduleOptions");
		if (parseInt(this.value) === 4) {
			options.classList.add("is-visible");
		} else {
			options.classList.remove("is-visible");
		}
	});

	loadDialogButtons(domNodes.dialogs.edge, [closeButton]);
	$(domNodes.dialogs.edge).on("click", "#EdgeRequestLocation", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.iridiumEdge.currentForm).valid();
		if (!isFormValid) {
			return;
		}

		var assetId = $j(domNodes.dialogs.edge).data("assetId");
		var status = document.getElementById("edge-query-status");

		var btn = this;
		var callback = function (id, groupIds, assetIds, gateway, gatewayTimeout, gatewayRetries) {
			var data = {
				assetId: assetId,
				gateway: gateway,
				gatewayTimeout: gatewayTimeout,
				gatewayRetries: gatewayRetries,
				groupIds: groupIds,
				assetIds: assetIds,
			};

			$.when(
				handleAjaxFormSubmission(
					"GSatMicroSendGetLocationRequest",
					data,
					btn,
					status,
					strings.MSG_LOCATION_REQUEST_SUCCESS,
					strings.MSG_LOCATION_REQUEST_ERROR
				)
			).done(function () {
				$(domNodes.modals.messageAction).modal("hide");
			});
		};

		var asset = findAssetById(assetId);
		var satelliteOnly = false;
		if (asset != null) {
			if (_.indexOf(devices.GSATMICRO_GSM, asset.DeviceId) === -1) {
				satelliteOnly = true;
			}
		}

		openActionDialog(
			strings.BUTTON_REQUEST_LOCATION,
			strings.BUTTON_REQUEST_LOCATION,
			callback,
			assetId,
			null,
			satelliteOnly,
			devices.IRIDIUM_EDGE
		);
	});

	function handleAssetGroupEdittedOrAdded(isEditing, resultGroup) {
		resultGroup.Type = "assets";

		if (isEditing) {
			var group = findGroupById(resultGroup.Id);
			group.Name = resultGroup.Name;
			group.Color = resultGroup.Color;
			group.DestinationId = resultGroup.DestinationId;
			group.IsChatEnabled = resultGroup.IsChatEnabled;
			group.IsLocationSharingEnabled = resultGroup.IsLocationSharingEnabled;
			group.ColorSorted = convertHexToSortable(group.Color);
			updateGroup(group);

			// update assets in group
			for (var i = 0; i < trkData.assets.length; i++) {
				var asset = trkData.assets[i];
				var assetGroupIndex = $j.inArray(group.Id, asset.GroupIds);
				var wasInGroup = assetGroupIndex != -1;
				var isNowInGroup = $j.inArray(asset.Id, resultGroup.AssetIds) != -1;
				if (wasInGroup && !isNowInGroup) {
					asset.GroupIds.splice(assetGroupIndex, 1);
					removeAssetFromGroup(asset, group.Id);
				} else if (!wasInGroup && isNowInGroup) {
					asset.GroupIds.push(group.Id);
					addAssetToGroup(asset, group.Id);
					// toggleItemSorting("assets", user.displayPreferences.sortMode.assets === sortModes.CUSTOM);
				}
			}

			var previousUserIds = findAssetGroupUsersByAssetGroupId(resultGroup.Id);
			if (previousUserIds != null) {
				if (previousUserIds.toString() != resultGroup.UserIds.toString()) {
					for (var i = 0; i < previousUserIds.length; i++) {
						let value = previousUserIds[i];
						if ($j.inArray(value, resultGroup.UserIds) == -1) {
							removeUserFromAssetGroup(value, resultGroup.Id);
							i--;
						}
					}

					$j.each(resultGroup.UserIds, function (index, value) {
						if ($j.inArray(value, previousUserIds) == -1) {
							addUserToAssetGroup(value, resultGroup.Id);
						}
					});
				}
			}
		} else {
			resultGroup.Groups = [];
			resultGroup.GroupIds = [];
			resultGroup.ColorSorted = convertHexToSortable(resultGroup.Color);
			trkData.groups.push(resultGroup);
			trkData.groupsById = _.keyBy(trkData.groups, "Id");
			addGroup(resultGroup, resultGroup.AssetIds);

			if (trkData.assetGroupUsers != null) {
				trkData.assetGroupUsers.push({ AssetGroupId: resultGroup.Id, UserIds: [] });
			}
			$j.each(resultGroup.UserIds, function (index, value) {
				addUserToAssetGroup(value, resultGroup.Id);
			});
		}

		populateGroupList();
		indexAssetGroupsForSearch();
	}

	function handleGroupEdittedOrAdded(isEditing, resultGroup) {
		resultGroup.Groups = [];
		resultGroup.GroupIds = [];
		resultGroup.ColorSorted = convertHexToSortable(resultGroup.Color);

		const [items, groups, groupUsers] = 
			resultGroup.Type === "places" ? [trkData.places, trkData.placeGroups, trkData.placeGroupUsers]
			: resultGroup.Type === "fences" ? [trkData.fences, trkData.fenceGroups, trkData.fenceGroupUsers]
			: [[], [], []];
		const [ ItemIdsKey, GroupIdKey ] =
			resultGroup.Type === "places" ? ["PlaceIds", "PlaceGroupId"]
			: resultGroup.Type === "fences" ? ["FenceIds", "FenceGroupId"]
			: [];

		if (isEditing) {
			var group = findGroupById(resultGroup.Id, resultGroup.Type);
			group.Name = resultGroup.Name;
			group.Color = resultGroup.Color;
			group.ColorSorted = convertHexToSortable(group.Color);
			updateItemGroup(group);

			// update items in group
			for (let item of items) {
				var itemGroupIndex = $j.inArray(group.Id, item.GroupIds);
				var wasInGroup = itemGroupIndex != -1;
				var isNowInGroup = $j.inArray(item.Id, resultGroup[ItemIdsKey]) != -1;
				if (wasInGroup && !isNowInGroup) {
					item.GroupIds.splice(itemGroupIndex, 1);
					removeItemFromGroup(item, group.Id, resultGroup.Type);
				} else if (!wasInGroup && isNowInGroup) {
					item.GroupIds.push(group.Id);
					addItemToGroup(item, group.Id, resultGroup.Type);
					// toggleItemSorting(resultGroup.Type, user.displayPreferences.sortMode[resultGroup.Type] === sortModes.CUSTOM);
				}
			}

			groupUsers.find(g => g[GroupIdKey] === resultGroup.Id).UserIds = resultGroup.UserIds;
		} else {
			groups.push(resultGroup);
			groupUsers.push({ [GroupIdKey]: resultGroup.Id, UserIds: resultGroup.UserIds });
			addGroup(resultGroup, resultGroup[ItemIdsKey]);
		}

		populateGroupList(sortGroups(groups));
	}

	// add/edit group dialog
	var addGroupButtons = [
		{
			buttonType: "primary",
			text: strings.CREATE_GROUP,
			id: "AssetGroupEditButton",
			click: function () {
				var isFormValid = $j(trkData.validation.addGroup.currentForm).valid();
				if (!isFormValid) {
					trkData.validation.addGroup.focusInvalid();
					return;
				}
				var btn = this;
				btn.disabled = true;
				var status = document.getElementById("add-group-status");
				var dialog = $j(domNodes.dialogs.editGroup);
				var id = dialog.data("groupId");
				var name = $j("#txtGroupName").val();
				var color = $j("#txtColor").val();
				var parentId = $j("#ddlGroupParent").val();
				var destinationId = $j("#txtGroupDestinationId").val();
				var isChatEnabled = $j("#EditAssetGroupAllowChat").prop("checked");
				var isLocationSharingEnabled = $j("#EditAssetGroupAllowLocationSharing").prop("checked");
				var itemIds = new Array();
				$j("input[name=EditAssetGroupAssetIds]:checked", dialog).each(function (index, elem) {
					const val = $j(this).val();
					itemIds.push(isFinite(val) ? parseInt(val) : val);
				});
				var userIds = new Array();
				$j("input[name=EditAssetGroupUserIds]:checked", dialog).each(function (index, elem) {
					userIds.push($j(this).val());
				});

				var data = {
					Id: id,
					Name: name,
					Color: color,
					ParentId: parentId,
					DestinationId: destinationId,
					AssetIds: itemIds,
					PlaceIds: itemIds,
					FenceIds: itemIds,
					UserIds: userIds,
					IsChatEnabled: isChatEnabled,
					IsLocationSharingEnabled: isLocationSharingEnabled,
					Type: state.groupDialog.type + "s", // assets, places, fences
				};

				const operation = state.groupDialog.type + (state.groupDialog.isEditing ? "_update" : "_add");

				const endpoints = {
					asset_add: "AddAssetGroup",
					asset_update: "UpdateAssetGroup",
					place_add: "AddPlaceGroup",
					place_update: "UpdatePlaceGroup",
					fence_add: "AddGeofenceGroup",
					fence_update: "UpdateGeofenceGroup",
				};
				const url = "/services/GPSService.asmx/" + endpoints[operation];

				const messages = {
					asset_add: { success: strings.MSG_ADD_GROUP_SUCCESS, error: strings.MSG_ADD_GROUP_ERROR },
					asset_update: { success: strings.MSG_EDIT_GROUP_SUCCESS, error: strings.MSG_EDIT_GROUP_ERROR },
					place_add: { success: strings.MSG_ADD_PLACE_GROUP_SUCCESS, error: strings.MSG_ADD_PLACE_GROUP_ERROR },
					place_update: { success: strings.MSG_EDIT_PLACE_GROUP_SUCCESS, error: strings.MSG_EDIT_PLACE_GROUP_ERROR },
					fence_add: { success: strings.MSG_ADD_GEOFENCE_GROUP_SUCCESS, error: strings.MSG_ADD_GEOFENCE_GROUP_ERROR },
					fence_update: { success: strings.MSG_EDIT_GEOFENCE_GROUP_SUCCESS, error: strings.MSG_EDIT_GEOFENCE_GROUP_ERROR },
				};
				const messageStrings = messages[operation];

				toggleLoadingMessage(true, url);
				$j.ajax({
					type: "POST",
					url: wrapUrl(url),
					data: JSON.stringify(data),
					contentType: "application/json; charset=utf-8",
					dataType: "json",
					success: function (msg) {
						try {
							toggleLoadingMessage(false, url);
							btn.disabled = false;
							var result = msg.d;
							if (result?.Success === true) {
								formShowSuccessMessage(status, messageStrings.success);
								if (!state.groupDialog.isEditing) {
									// reset the form to add another
									trkData.validation.addGroup.resetForm();
									trkData.validation.addGroup.currentForm.reset();
									data.Id = result.Group.Id;
								}
								data.ParentGroupId = data.ParentId? data.ParentId : null;
								if(state.groupDialog.type === "asset") {
									handleAssetGroupEdittedOrAdded(state.groupDialog.isEditing, data);
								} else {
									handleGroupEdittedOrAdded(state.groupDialog.isEditing, data);
								}
							} else if (result?.ErrorMessage) {
								formShowErrorMessage(status, status.textContent + " " + result.ErrorMessage);
							} else {
								formShowErrorMessage(messageStrings.error);
							}
						} catch (e) {
							console.error(e);
							throw e;
						}
					},
					error: function (xhr, status, error) {
						try {
							btn.disabled = false;
							toggleLoadingMessage(false, url);
							handleWebServiceError(messageStrings.error);
						} catch (e) {
							console.error(e);
							throw e;
						}
					},
				});
			},
		},
		{
			buttonType: "secondary",
			icons: { primary: "ui-icon-close" },
			text: strings.CANCEL,
			click: function () {
				if (!state.groupDialog.isEditing) {
					closeSecondaryPanel();
				} else {
					document.getElementById("panel-dialog-back").click();
				}
			},
		},
	];

	loadDialogButtons(domNodes.dialogs.editGroup, addGroupButtons);

	$(document).on("change", "#panel-secondary-item-options", function (e) {
		var id = this.value;
		var selection = document.getElementById("panel-secondary-header");
		var callbackItem = null;
		var itemType = selection.getAttribute("data-group-for");
		switch (itemType) {
			case "groups":
				callbackItem = findGroupById(id);
				break;
			case "assets":
				callbackItem = findAssetById(id);
				break;
			case "places":
				callbackItem = findPlaceById(id);
				break;
			case "place-groups":
				callbackItem = findGroupById(id, "places");
				break;
			case "fences":
				callbackItem = findFenceById(id);
				break;
			case "fence-groups":
				callbackItem = findGroupById(id, "fences");
				break;
			case "trips":
				callbackItem = findTripById(id);
				break;
			case "journeys":
				callbackItem = findJourneyById(id);
				break;
			case "shared-views":
				callbackItem = findSharedViewById(id);
				break;
			default:
				console.warn("Unknown item type for navigation:" + itemType);
				break;
		}
		var callback = domNodes.panels.secondary.nextItemCallback;
		if (callback !== undefined && callback !== null) {
			callback(callbackItem);
		} else {
			console.warn("No asset selection callback defined.");
		}
	});

	$(document).on("click", "a.item-prev,button.item-prev", function (e) {
		e.preventDefault();
		if ($(this).hasClass("disabled")) {
			return;
		}
		var selection = document.getElementById("panel-secondary-header");
		var option = selection.querySelector("select").querySelector("option:checked");
		if (option === null) {
			return;
		}
		var id = null;
		// get previous option not disabled
		while (option.previousElementSibling !== null) {
			option = option.previousElementSibling;
			if (!option.disabled) {
				id = option.value;
				break;
			}
		}

		if (id === null) {
			return;
		}

		var callbackItem = null;
		var itemType = selection.getAttribute("data-group-for");
		switch (itemType) {
			case "groups":
				callbackItem = findGroupById(id);
				break;
			case "assets":
				callbackItem = findAssetById(id);
				break;
			case "places":
				callbackItem = findPlaceById(id);
				break;
			case "place-groups":
				callbackItem = findGroupById(id, "places");
				break;
			case "fences":
				callbackItem = findFenceById(id);
				break;
			case "fence-groups":
				callbackItem = findGroupById(id, "fences");
				break;
			case "trips":
				callbackItem = findTripById(id);
				break;
			case "journeys":
				callbackItem = findJourneyById(id);
				break;
			case "shared-views":
				callbackItem = findSharedViewById(id);
				break;
			default:
				console.warn("Unknown item type for navigation:" + itemType);
				break;
		}
		var callback = domNodes.panels.secondary.nextItemCallback;
		if (callback !== undefined && callback !== null) {
			callback(callbackItem);
		} else {
			console.warn("No asset selection callback defined.");
		}
	});

	$(document).on("click", "a.item-next,button.item-next", function (e) {
		e.preventDefault();
		if ($(this).hasClass("disabled")) {
			return;
		}
		var selection = document.getElementById("panel-secondary-header");
		var option = selection.querySelector("select").querySelector("option:checked");
		if (option === null) {
			return;
		}
		var id = null;

		// get next option not disabled
		while (option.nextElementSibling !== null) {
			option = option.nextElementSibling;
			if (!option.disabled) {
				id = option.value;
				break;
			}
		}

		var callbackItem = null;
		var itemType = selection.getAttribute("data-group-for");
		switch (itemType) {
			case "groups":
				callbackItem = findGroupById(id);
				break;
			case "assets":
				callbackItem = findAssetById(id);
				break;
			case "places":
				callbackItem = findPlaceById(id);
				break;
			case "place-groups":
				callbackItem = findGroupById(id, "places");
				break;
			case "fences":
				callbackItem = findFenceById(id);
				break;
			case "fence-groups":
				callbackItem = findGroupById(id, "fences");
				break;
			case "trips":
				callbackItem = findTripById(id);
				break;
			case "journeys":
				callbackItem = findJourneyById(id);
				break;
			case "shared-views":
				callbackItem = findSharedViewById(id);
				break;
			default:
				console.warn("Unknown item type for navigation:" + itemType);
				break;
		}
		var callback = domNodes.panels.secondary.nextItemCallback;
		if (callback !== undefined && callback !== null) {
			callback(callbackItem);
		} else {
			console.warn("No asset selection callback defined.");
		}
	});

	initPanels();

	$("#iconApp").on("click", "#nav-toggle", function (e) {
		e.preventDefault();
		if (domNodes.nav.toggle.classList.contains("is-active")) {
			domNodes.nav.toggle.classList.remove("is-active");
			domNodes.nav.primary.classList.add("is-closed");
		} else {
			openPrimaryPanel();
			domNodes.nav.toggle.classList.add("is-active");
			domNodes.nav.primary.classList.remove("is-closed");
		}
		closeSecondaryPanel();
	});

	$(".filter-box").on("keyup", ".filter-input", function (e) {
		switch (this.id) {
			case "filter-asset-text":
				filterAssets(this.value);
				break;
			case "filter-fence-text":
				filterFences(this.value);
				break;
			case "filter-place-text":
				filterPlaces(this.value);
				break;
			case "filter-shared-view-text":
				filterSharedViews(this.value);
				break;
		}
		if (this.value === "") {
			this.parentElement.nextElementSibling.classList.remove("is-visible");
		} else {
			this.parentElement.nextElementSibling.classList.add("is-visible");
		}
	});

	$(document).on("click", ".input-clear", function (e) {
		var input = this.parentNode.querySelector(".filter-input");
		input.value = "";
		$(input).trigger("keyup");

		// jquery .trigger doesn't work in notifying listjs bound event for some reason
		var e = document.createEvent("HTMLEvents");
		e.initEvent("keyup", false, true);
		input.dispatchEvent(e);
	});

	$("#nav-primary").on("click", "a", function (e) {
		e.preventDefault();
		$(this).bsTooltip("hide");
		if (this.parentNode.classList.contains("disabled")) {
			return;
		}
		openPrimaryPanel();
		var toPanel = parseInt(this.parentNode.getAttribute("data-panel"));
		//switch (toPanel) {
		//    case panels.LIVE:
		//        switchView(true);
		//        break;
		//    case panels.HISTORY:
		//        switchView(false);
		//        break;
		//    default:
		//        break;
		//}
		changeActivePanel(toPanel, false);
	});

	$("#filter-history-range").on("click", "button", function (e) {
		e.preventDefault();
		filterDateClick(this);
	});

	$("#dialog-functions").on("click", "a.map-toggle", function (e) {
		e.preventDefault();

		if (!user.canEditAssets) {
			return;
		}

		var markerId = this.getAttribute("data-marker");
		var assetId = this.getAttribute("data-asset");
		var isNowHidden = this.getAttribute("data-hidden") !== "true";
		var sharedViewId = null;
		if (state.activeViewMode === viewModes.SHARED_VIEW && trkData.sharedView.current !== null) {
			sharedViewId = trkData.sharedView.current.Id;
		}

		togglePositionVisibility(assetId, markerId, isNowHidden, sharedViewId);

		// any map-toggles with this positionId need updated
		var icon = this;
		icon.setAttribute("data-hidden", isNowHidden);
		icon
			.querySelector("use")
			.setAttributeNS(
				"http://www.w3.org/1999/xlink",
				"href",
				"/content/svg/tracking.svg?v=15#" + (isNowHidden ? "invisible" : "visible")
			);
	});

	$("#dialog-functions").on("click", "a.location", function (e) {
		e.preventDefault();
		highlightPosition(this.getAttribute("data-marker"), null);
	});

	initAssets();

	$("#form-history-date-range").on("click", "#HistoryChangeDates", function (e) {
		e.preventDefault();
		document.getElementById("txtDateFrom").focus();
	});

	$("#form-history-date-range").on("click", "#LoadLimitedData", function (e) {
		e.preventDefault();

		var btn = this;
		btn.disabled = true;
		if (state.activeViewMode === viewModes.NORMAL && state.activeMapMode === mapModes.HISTORY) {
			queryActiveAssets(undefined, undefined, true).then(function () {
				btn.disabled = false;
			});
		} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
			// requery shared view with dates entered in panel
			// user may have chosen different dates
			var dateFilter = {
				// TODO UTC
				fromUtc: document.getElementById("txtDateFrom").value,
				toUtc: document.getElementById("txtDateTo").value,
			};
			if (trkData.sharedView.temp !== null) {
				querySharedViewData(trkData.sharedView.temp, dateFilter, true);
			} else if (trkData.sharedView.current !== null) {
				querySharedViewData(trkData.sharedView.current, dateFilter, true);
			}
			btn.disabled = false;
		}
	});

	$("#map-mode-details-limited-nav").on("click", "#limited-data-prev", function (e) {
		e.preventDefault();
		var source = null;
		if (state.activeViewMode === viewModes.NORMAL) {
			source = trkData.history;
		} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
			source = trkData.sharedView;
		}

		var data = source.limitedData;
		data.currentPage++;
		var newFromUtc = null;
		var newToUtc = null;
		var newFromEpoch = null;
		var newToEpoch = null;
		var newFromLocal = null;
		var newToLocal = null;
		if (data.pageDates[data.currentPage] !== undefined) {
			var existingPage = data.pageDates[data.currentPage];
			newFromUtc = existingPage.fromUtc;
			newToUtc = existingPage.toUtc;
		} else {
			// one second prior to current from date - must use epoch
			newToUtc = moment.utc(data.visibleFromDateEpoch - 1000).format(user.dateWithStandardTimeFormat);
			newFromUtc = data.fromDateUtc;
		}

		if (newFromUtc !== null) {
			newFromEpoch = moment.utc(newFromUtc, user.dateWithStandardTimeFormat).valueOf();
		}
		if (newToUtc !== null) {
			newToEpoch = moment.utc(newToUtc, user.dateWithStandardTimeFormat).valueOf();
		}
		// requery shared view with new page of data
		var dateFilter = {
			fromLocal: newFromLocal,
			toLocal: newToLocal,
			fromUtc: newFromUtc,
			toUtc: newToUtc,
			fromEpoch: newFromEpoch,
			toEpoch: newToEpoch,
		};

		// console.log("dateFilter", dateFilter);
		if (state.activeViewMode === viewModes.NORMAL) {
			// requery history with limited date range (page of data)
			queryActiveAssets(undefined, undefined, true, dateFilter);
		} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
			if (trkData.sharedView.temp !== null) {
				querySharedViewData(trkData.sharedView.temp, dateFilter, true);
			} else if (trkData.sharedView.current !== null) {
				querySharedViewData(trkData.sharedView.current, dateFilter, true);
			}
		}
	});

	$("#map-mode-details-limited-nav").on("click", "#limited-data-next", function (e) {
		e.preventDefault();
		var source = null;
		if (state.activeViewMode === viewModes.NORMAL) {
			source = trkData.history;
		} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
			source = trkData.sharedView;
		}

		var data = source.limitedData;
		data.currentPage--;
		if (data.pageDates[data.currentPage] !== undefined) {
			var existingPage = data.pageDates[data.currentPage];
			var newFromEpoch = null;
			var newToEpoch = null;
			if (existingPage.fromUtc !== null) {
				newFromEpoch = moment.utc(existingPage.fromUtc, user.dateWithStandardTimeFormat).valueOf();
			}
			if (existingPage.toUtc !== null) {
				newToEpoch = moment.utc(existingPage.toUtc, user.dateWithStandardTimeFormat).valueOf();
			}
			var dateFilter = {
				fromUtc: existingPage.fromUtc,
				toUtc: existingPage.toUtc,
				fromEpoch: newFromEpoch,
				toEpoch: newToEpoch,
			};

			if (state.activeViewMode === viewModes.NORMAL) {
				// requery history with limited date range (page of data)
				queryActiveAssets(undefined, undefined, true, dateFilter);
			} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
				if (trkData.sharedView.temp !== null) {
					querySharedViewData(trkData.sharedView.temp, dateFilter, true);
				} else if (trkData.sharedView.current !== null) {
					querySharedViewData(trkData.sharedView.current, dateFilter, true);
				}
			}
		}
	});

	$("#form-history-date-range").on("click", "#btnGo", function (e) {
		e.preventDefault();
		if (state.activeViewMode === viewModes.NORMAL) {
			if (state.activeMapMode === mapModes.LIVE) {
				switchMapMode(mapModes.HISTORY, null, true);
			} else {
				queryActiveAssets();
			}
		} else if (state.activeViewMode === viewModes.SHARED_VIEW) {
			// this is disabled currently
			//// requery shared view with dates entered in panel, limits apply
			//var dateFilter = {
			//    from: document.getElementById('txtDateFrom').value,
			//    to: document.getElementById('txtDateTo').value
			//};
			//if (trkData.sharedView.temp !== null) {
			//    querySharedViewData(trkData.sharedView.temp, dateFilter, false);
			//} else if (trkData.sharedView.current !== null) {
			//    querySharedViewData(trkData.sharedView.current, dateFilter, false);
			//}
			//// TODO this is a mess - move to proper spot
			//var historyCustom = document.getElementById('history-custom');
			//var historyButtons = document.getElementById('filter-history-range').querySelectorAll('button');
			//historyButtons.forEach( function (button) {
			//    button.classList.remove('active');
			//});
			//historyCustom.classList.add('active');
			//historyCustom.classList.add('btn-primary');
		}
	});

	$(document).on("click", "a.add-first-item", function (e) {
		e.preventDefault();
		var itemType = this.getAttribute("data-item-type");
		switch (itemType) {
			case "shared-views":
				openSharedViewDialog(null);
				setUrlHistoryForActivePanel("add");
				break;
			case "fences":
				openGeofenceDialog(null);
				setUrlHistoryForActivePanel("add");
				break;
			case "assets":
				openEditAssetDialog(null);
				setUrlHistoryForActivePanel("add");
				break;
			case "places":
				openPlaceDialog(null);
				setUrlHistoryForActivePanel("add");
				break;
			default:
				console.warn("No add first item handler for: " + itemType);
				break;
		}
	});
	if (!user.canEditGeofences) {
		document.getElementById("no-fences").querySelector(".add-first-item").classList.add("toggle-content");
	}
	if (!user.canEditPlaces) {
		document.getElementById("no-places").querySelector(".add-first-item").classList.add("toggle-content");
	}
	if (!user.canEditAssets) {
		document.getElementById("no-assets").querySelector(".add-first-item").classList.add("toggle-content");
	}
	// end init

	$("#nav-primary a").bsTooltip({ delay: { show: 500, hide: 100 } });
	$("#map-tools button").bsTooltip({ delay: { show: 500, hide: 100 }, placement: "left" });
	$("#panel-content-journeys").bsTooltip({ selector: ".notifications", placement: "right" });
	$("#panel-content-assets").bsTooltip({ selector: ".notifications", placement: "right" });
	$("#shared-view-banner a").bsTooltip({ placement: "top" });
	$("#panel-secondary-nav-tabs a").bsTooltip({ delay: { show: 500, hide: 100 } });

	initializeMouseTooltip();
	log("Initialize Map.");
	initMap();

	$.when(state.promises.getGroupsAndAssets, state.promises.getAssetUsers).done(function () {
		populateSidePanel();
		createGroupColorStyles();
		indexAssetsForSearch();
		indexAssetGroupsForSearch();
		indexPlacesForSearch();
		indexFencesForSearch();
		indexSharedViewsForSearch();
		sortModeUpdated();

		$("#loading-groups").removeClass("is-visible");

		// add places and geofences to the map
		// assets will be added to the map via their live/historical positions
		trkData.places.forEach(function (place, index, list) {
			addPlaceMarker([place.Location.Lat, place.Location.Lng], place.Location, place);
		});

		addFencePaths();

		// add waypoints to map/UI
		trkData.waypoints.forEach(function (waypoint) {
			addOrUpdateWaypoint(waypoint);
			addWaypointMarker([waypoint.Location.Lat, waypoint.Location.Lng], waypoint.Location, waypoint);
		});
		updateWaypointListing();

		// update asset/place/geofence custom attributes
		populateCustomAttributesEditable(document.getElementById("edit-asset-extra-accordion"), 0, "EditAssetAttribute");
		populateCustomAttributesEditable(
			document.getElementById("edit-geofence-extra-accordion"),
			1,
			"EditGeofenceAttribute"
		);
		populateCustomAttributesEditable(
			document.getElementById("edit-place-attributes-accordion"),
			2,
			"EditPlaceAttribute"
		);

		// query positions
		// make live view the default unless otherwise specified

		queryLatestNotifications();

		//queryUsersForAssets();

		trkData.alerts = [];
		queryAlertsRequiringAcknowledgement(null);
		initAlerts();

		setupSignalR();

		for (var i = 0; i < trkData.systemNotifications.length; i++) {
			showSystemNotification(trkData.systemNotifications[i]);
		}

		state.promises.getGroupsAndAssets = null;
		state.promises.getAssetUsers = null;

		if (user.showWalkthrough) {
			walkthrough.intro.start();
		}

		initWindowLoad();
	});

	var authCodeInput = document.getElementById('txtEditAssetSpotAuthCode');
	$(authCodeInput).inputmask({ 'mask': '99999999' });
	$(authCodeInput).rules('add', { regex: '^[0-9]{8}$' });
}
