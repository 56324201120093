import strings from "./strings.js";
import trkData from "./data.js";
import options from "./options.js";
import domNodes from "./domNodes.js";
import { closeSecondaryPanel } from "./panel.js";
import { findAssetById, findAssetIdsUnderGroup } from "./assets.js";
import { findTripById } from "./trips.js";
import { findPlaceById } from "./place.js";
import { findJourneyById } from "./journey.js";
import { openSharedViewDialog } from "./shared-view-dialog.js";
import { openPlaceDialog } from "./place.js";
import { loadHistory } from "./position-history.js";
import { openPlaceRoutingForAsset } from "./place-routing.js";
import { loadMessageHistoryDialog } from "./messages-history.js";
import { loadGarminFormsHistoryDialog } from "./garmin.js";
import { openEditAssetDialog } from "./asset-edit-dialog.js";
import { findFenceById, openGeofenceDialog, findAssetIdsInGeofence } from "./fence.js";
import { openPlaceRoutingForPlace } from "./place-routing.js";
import { openSharedViewShareDialog } from "./shared-view-dialog.js";
import { openSendMessageDialog } from "./messages.js";
import { openAssetLatestPosition } from "./asset-live.js";
import { openSendMessageToAssetDialog } from "./messages.js";
import { toggleFollowAsset, toggleFollowGroup } from "./asset-live-follow.js";
import { devices } from "./devices.js";
import { openDialogPanel } from "./panel-nav.js";
import state from "./state.js";
import { findDeviceById } from "./devices.js";
import { getOutputLabelByIndex } from "./device-dialog.js";
import { startChoosingMapLocation, stopChoosingMapLocation } from "./map-chooselocation.js";
import { sortAssetGroups } from "./item-sorting.js";
import { requestAVLInformation } from "./avl.js";
import { handleAjaxFormSubmission } from "./ajax.js";
import log from "./log.js";
import preferences, { speedText, fuelText, distanceText } from "./preferences.js";
import user from "./user.js";
import { openCurrentDriversDialog, loadDriverHistoryDialog } from "./driver.js";
import { addGroupToGroupListWithName } from "./group-list.js";
import { findGroupById, openAssetGroupDialog, populateAssetGroupDialog } from "./asset-group.js";
import { checkForShareViewChange, findSharedViewById, changeSharedViewStatus } from "./shared-view.js";
import { loadWaypointHistory, loadIOHistory, loadServiceMeterHistory, loadFillupHistory } from "./asset-history.js";
import { fuelEfficiencyType, convertFuelEfficiencyToStandard } from "./preferences.js";
import { requestEdgeSolarInformation } from "./edgesolar.js";
import { requestInmarsatCInformation, queclinkCreateAPNCommand, requestQueclinkInformation } from "./queclink.js";
import { loadDialog, dialogs } from "./panel-dialog.js";
import { openItemGroupDialog } from "./common-group.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";
import moment from "moment"; // https://www.npmjs.com/package/moment
import {Chart} from "chart.js";

export function initSecondaryPanel() {
	$("#panel-secondary").on("click", ".item-action", function (e) {
		if (this.classList.contains("disabled")) {
			e.preventDefault();
			return;
		}
		var panel = domNodes.panels.secondary;
		var actionGroup = panel.getAttribute("data-group-for");
		var action = this.getAttribute("data-action");
		var id = panel.getAttribute("data-item-id");
		handleSecondaryPanelItemAction(action, actionGroup, id, e);
	});
}

function handleSecondaryPanelItemAction(action, actionGroup, id, e) {
	var preventDefault = true;
	if (actionGroup === "dialog") {
		actionGroup = domNodes.panels.secondary.getAttribute("data-item-type");
	}
	switch (actionGroup) {
		case "assets":
			var asset = findAssetById(id);
			switch (action) {
				case "alerts":
					break;
				case "status":
					break;
				case "positions":
					break;
				case "events":
					break;
				case "messages":
					break;
				case "route-asset":
					openPlaceRoutingForAsset(asset);
					break;
				case "current-position":
					closeSecondaryPanel();
					openAssetLatestPosition(asset);
					break;
				case "follow-asset":
					closeSecondaryPanel();
					toggleFollowAsset(asset);
					break;
				case "history-replay":
					closeSecondaryPanel();
					loadHistory([asset.Id], true);
					break;
				case "send-waypoint":
					openSendWaypointDialog(asset);
					break;
				case "send-message":
					openSendMessageToAssetDialog(asset);
					break;
				case "set-waypoint":
					openSetWaypointDialog(asset);
					break;
				case "add-position":
					openAddPositionDialog(asset);
					break;
				case "send-command":
					openSendCommandDialog(asset);
					break;
				case "set-output":
					openSetOutputDialog(asset);
					break;
				case "set-driver":
					openCurrentDriversDialog(asset);
					break;
				case "view-logs":
					var logItems = document.getElementById("asset-function-log-container");
					var logToggle = document.getElementById("asset-function-view-logs");
					if (logItems.classList.contains("is-visible")) {
						logToggle.classList.remove("expanded");
						logItems.classList.remove("is-visible");
					} else {
						logItems.classList.add("is-visible");
						logToggle.classList.add("expanded");
					}
					domNodes.simpleBars.secondary.recalculate();
					break;
				case "view-logs-message":
					loadMessageHistoryDialog(asset);
					break;
				case "view-logs-io":
					loadIOHistoryDialog(asset);
					break;
				case "view-logs-driver":
					loadDriverHistoryDialog(asset);
					break;
				case "view-logs-waypoint":
					loadWaypointHistoryDialog(asset);
					break;
				case "view-logs-refuel":
					loadFillupHistoryDialog(asset);
					break;
				case "view-logs-service-meters":
					loadServiceMeterHistoryDialog(asset);
					break;
				case "view-logs-garmin-forms":
					loadGarminFormsHistoryDialog(asset);
					break;
				case "edit-asset":
					openEditAssetDialog(asset);
					break;
				case "delete-asset":
					var deleteAssetModal = $("#delete-asset-modal");
					$(".modal-body", deleteAssetModal).text(strings.MSG_DELETE_ASSET_CONFIRM.replace("{0}", asset.Name));
					$j("#hfDeleteAssetId").val(asset.Id);
					deleteAssetModal.modal("show");
					break;
				default:
					console.warn("Unhandled asset action: " + action);
					break;
			}
			break;
		case "fences":
			var fence = findFenceById(id);
			switch (action) {
				case "send-message":
					openSendMessageDialog(null, null, fence);
					break;
				case "history-replay":
					var assetIds = findAssetIdsInGeofence(fence);
					loadHistory(assetIds);
					break;
				case "group-assets":
					var assetIds = findAssetIdsInGeofence(fence);
					openAssetGroupDialog(null);
					var assets = new Array();
					for (var i = 0; i < assetIds.length; i++) {
						var asset = findAssetById(assetIds[i]);
						if (asset == null) {
							continue;
						}
						assets.push(asset);
						$("#edit-asset-group-assets-list input[value=" + asset.Id + "]").prop("checked", true);
					}
					break;
				case "add-alert":
					var assetIds = findAssetIdsInGeofence(fence);
					window.location = "/Alerts/CreateAlert?assetIds=" + assetIds.join() + "&fenceId=" + fence.Id;
					break;
				case "location-report":
					window.location = "/Reports/Location?fenceId=" + fence.Id;
					break;
				case "edit-fence":
					openGeofenceDialog(fence);
					break;
				case "delete-fence":
					var deleteFenceModal = $("#delete-geofence-modal");
					$(".modal-body", deleteFenceModal).text(strings.MSG_DELETE_FENCE_CONFIRM.replace("{0}", fence.Name));
					$j("#hfDeleteFenceId").val(fence.Id);
					deleteFenceModal.modal("show");
					break;
				default:
					console.warn("Unhandled fence action: " + action);
					break;
			}
			break;
		case "groups":
			var group = findGroupById(id);
			switch (action) {
				case "send-message":
					openSendMessageDialog(null, group);
					break;
				case "history-replay":
					var assetIds = findAssetIdsUnderGroup(group);
					loadHistory(assetIds);
					break;
				case "follow-group":
					closeSecondaryPanel();
					toggleFollowGroup(group);
					break;
				case "edit-group":
					openAssetGroupDialog(group);
					break;
				case "copy-group":
					if (options.enabledFeatures.indexOf("UI_DUPLICATE_GROUP") === -1) {
						return;
					}
					openAssetGroupDialog(null);
					populateAssetGroupDialog(group);
					_.each(trkData.assets, function (asset) {
						var isChecked = _.indexOf(asset.GroupIds, group.Id) !== -1 || group.IsDefault;
						$("#edit-asset-group-assets-list input[value=" + asset.Id + "]").prop("checked", isChecked);
					});
					var itemUsers = _.find(trkData.assetGroupUsers, { AssetGroupId: group.Id });
					_.each(trkData.users, function (user) {
						var isChecked = itemUsers !== undefined && _.indexOf(itemUsers.UserIds, user.Id) !== -1;
						$("#edit-asset-group-users-list input[value=" + user.Id + "]").prop("checked", isChecked);
					});
					break;
				case "delete-group":
					var deleteGroupModal = $("#delete-group-modal");
					$(".modal-body", deleteGroupModal).text(strings.MSG_DELETE_GROUP_CONFIRM.replace("{0}", group.Name));
					$j("#hfDeleteGroupId").val(group.Id);
					deleteGroupModal.modal("show");
					break;
				default:
					console.warn("Unhandled asset group action: " + action);
					break;
			}
			break;
		case "places":
			var place = findPlaceById(id);
			switch (action) {
				case "route-asset":
					openPlaceRoutingForPlace(place);
					break;
				case "edit-place":
					openPlaceDialog(place);
					break;
				case "delete-place":
					var deletePlaceModal = $("#delete-place-modal");
					$(".modal-body", deletePlaceModal).text(strings.MSG_DELETE_PLACE_CONFIRM.replace("{0}", place.Name));
					$j("#hfDeletePlaceId").val(place.Id);
					deletePlaceModal.modal("show");
					break;
				default:
					console.warn("Unhandled place action: " + action);
					break;
			}
			break;
		case "fence-groups":
		case "place-groups":
			const groupFor = actionGroup === "fence-groups" ? "fences" : "places";
			const deleteStrings = groupFor === "fences" ? { msg: strings.MSG_DELETE_GEOFENCE_GROUP_CONFIRM, title: strings.TITLE_DELETE_GEOFENCE_GROUP } : { msg: strings.MSG_DELETE_PLACE_GROUP_CONFIRM, title: strings.TITLE_DELETE_PLACE_GROUP };
			var group = findGroupById(id, groupFor);
			switch (action) {
				case "edit-group":
					openItemGroupDialog(group);
					break;
				case "delete-group":
					var deleteGroupModal = $("#delete-group-modal");
					$(".modal-body", deleteGroupModal).text(deleteStrings.msg.replace("{0}", group.Name));
					$("#delete-group-modal-title", deleteGroupModal).text(deleteStrings.title);
					$j("#hfDeleteGroupId").val(group.Id);
					$j("#hfDeleteGroupFor").val(groupFor);
					deleteGroupModal.modal("show");
					break;
				default:
					console.warn("Unhandled asset group action: " + action);
					break;
			}
			break;
		case "trips":
			var trip = findTripById(id);
			switch (action) {
				case "edit-trip":
					window.location = "/settings/editjourney/" + trip.JourneyId + "?tripId=" + trip.Id + "#modify-trip";
					break;
				case "delete-trip":
					var deleteTripModal = $("#delete-trip-modal");
					$(".modal-body", deleteTripModal).text(strings.MSG_DELETE_TRIP_CONFIRM.replace("{0}", trip.Name));
					$j("#hfDeleteTripId").val(trip.Id);
					deleteTripModal.modal("show");
					break;
				default:
					console.warn("Unhandled trip action: " + action);
					break;
			}
			break;
		case "journeys":
			var journey = findJourneyById(id);
			switch (action) {
				case "edit-journey":
					window.location = "/settings/editjourney/" + journey.Id;
					break;
				case "delete-journey":
					var deleteJourneyModal = $("#delete-journey-modal");
					$(".modal-body", deleteJourneyModal).text(strings.MSG_DELETE_JOURNEY_CONFIRM.replace("{0}", journey.Name));
					$j("#hfDeleteJourneyId").val(journey.Id);
					deleteJourneyModal.modal("show");
					break;
				default:
					console.warn("Unhandled journey action: " + action);
					break;
			}
			break;
		case "shared-views":
			var sharedView = findSharedViewById(id);
			switch (action) {
				case "access-controls":
				case "data-visualization":
				case "details":
				case "map-settings":
				case "permissions":
				case "preferences":
					openSharedViewDialog(sharedView, action);
					break;
				case "share":
				case "share-email":
					openSharedViewShareDialog(sharedView);
					break;
				case "statistics":
					openSharedViewStatisticsDialog(sharedView);
					break;
				case "delete-shared-view":
					var deleteSharedViewModal = $("#delete-shared-view-modal");
					$(".modal-body", deleteSharedViewModal).text(
						strings.MSG_DELETE_SHARED_VIEW_CONFIRM.replace("{0}", sharedView.Name)
					);
					$j("#hfDeleteSharedViewId").val(sharedView.Id);
					deleteSharedViewModal.modal("show");
					break;
				case "disable-shared-view":
					changeSharedViewStatus(sharedView);
					break;
				case "share-facebook":
				case "share-twitter":
				case "share-linkedin":
					// don't prevent default
					preventDefault = false;
					break;
				default:
					console.warn("Unhandled shared view action:", action);
					break;
			}
			break;
		default:
			console.log("No action handler for: " + actionGroup + ", " + action);
			break;
	}
	if (preventDefault) {
		e.preventDefault();
	}
}

function openSendWaypointDialog(asset) {
	// send asset a position update (garmin)
	var dialog = $(domNodes.dialogs.sendPosition);
	dialog.data("isServerSide", false);

	// populate places
	var sel = $("#ddlSendPositionPlace").empty();
	sel.append($("<option>"));
	for (var i = 0; i < trkData.places.length; i++) {
		var place = trkData.places[i];
		sel.append($("<option>").val(place.Id).text(place.Name));
	}

	$("#txtPositionSearch").val("");
	$("#hfPositionAssetId").val(asset.Id);
	$("#hfPositionAssetName").val(asset.Name + " (" + asset.UniqueId + ")");

	openDialogPanel(
		domNodes.dialogs.sendPosition,
		strings.SEND_POSITION,
		asset,
		false,
		function () {
			state.isChoosingPosition = false;
			stopChoosingMapLocation(state.mapClickHandlers.POSITION);
			if (state.openWindow != null) {
				state.openWindow.remove();
			}
		},
		"asset",
		"send-waypoint",
		openSendWaypointDialog
	);

	state.isChoosingPosition = true;
	startChoosingMapLocation(state.mapClickHandlers.POSITION);
}

function openSetOutputDialog(asset) {
	$("#hfOutputAssetId").val(asset.Id);
	var activeContainerId = "";

	if ($.inArray(asset.DeviceId, devices.NAL) != -1) {
		activeContainerId = "nal-output-fields";

		var device = findDeviceById(asset.DeviceId);
		for (var i = 0; i < device.OutputPinCount; i++) {
			var pinNum = i + 1;
			var label = getOutputLabelByIndex(asset, pinNum);
			var output = strings.OUTPUT_NUMBER.replace("{0}", pinNum);
			var labelElem = document.getElementById("nal-output-" + pinNum + "-label");
			if (label == null) {
				labelElem.textContent = output;
			} else {
				labelElem.textContent = labelOrDefault(label.Label, output);
			}
		}
	} else {
		activeContainerId = "idp-output-fields";

		// different options depending on asset device type
		var outputTime = document.getElementById("set-output-time");
		outputTime.classList.remove("is-visible");

		trkData.validation.idpOutput.resetForm();
		trkData.validation.idpOutput.currentForm.reset();

		var device = findDeviceById(asset.DeviceId);
		var list = $("#ddlIDPOutputPin");
		list.empty();

		var includeTimes =
			$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP_DUAL_MODE) != -1 ||
			$j.inArray(asset.DeviceId, devices.SKYWAVE_IDP) != -1 ||
			asset.DeviceId == devices.QUECLINK_GV200 ||
			asset.DeviceId == devices.QUECLINK_GV300 ||
			asset.DeviceId == devices.QUECLINK_GV600W ||
			asset.DeviceId == devices.QUECLINK_GV620MG;
		var pulseEnabled = !includeTimes && asset.DeviceId !== devices.QUECLINK_GL200;

		for (var i = 0; i < device.OutputPinCount; i++) {
			var pinNum = i + 1;
			var label = getOutputLabelByIndex(asset, pinNum);
			// 3 output types: on, off, pulse
			var onOption = createLabel(pinNum, 1, label);
			var offOption = createLabel(pinNum, 0, label);
			if (onOption !== null) {
				list.append(onOption);
			}
			if (offOption !== null) {
				list.append(offOption);
			}
			if (pulseEnabled) {
				var pulseOption = createLabel(pinNum, 2, label);
				if (pulseOption !== null) {
					list.append(pulseOption);
				}
			}
		}

		if (includeTimes) {
			outputTime.classList.add("is-visible");
		}
	}

	var outputFieldContainers = document.querySelectorAll(".output-fields");
	_.each(outputFieldContainers, function (item) {
		if (item.id !== activeContainerId) {
			item.classList.remove("is-visible");
		} else {
			item.classList.add("is-visible");
		}
	});
	openDialogPanel(
		domNodes.dialogs.idpOutput,
		strings.SET_OUTPUT,
		asset,
		false,
		null,
		"asset",
		"set-output",
		openSetOutputDialog
	);
}

function openSetWaypointDialog(asset) {
	var dialogWaypoint = $(domNodes.dialogs.sendPosition);
	dialogWaypoint.data("isServerSide", true);
	// populate places
	var selWaypoint = $("#ddlSendPositionPlace").empty();
	selWaypoint.append($("<option>"));
	for (var j = 0; j < trkData.places.length; j++) {
		var placeWaypoint = trkData.places[j];
		selWaypoint.append($("<option>").val(placeWaypoint.Id).text(placeWaypoint.Name));
	}

	$("#txtPositionSearch").val("");
	$("#hfPositionAssetId").val(asset.Id);
	$("#hfPositionAssetName").val(asset.Name + " (" + asset.UniqueId + ")");
	openDialogPanel(
		domNodes.dialogs.sendPosition,
		strings.SET_WAYPOINT,
		asset,
		false,
		function () {
			state.isChoosingPosition = false;
			stopChoosingMapLocation(state.mapClickHandlers.POSITION);
			if (state.openWindow != null) {
				state.openWindow.remove();
			}
		},
		"asset",
		"set-waypoint",
		openSetWaypointDialog
	);

	state.isChoosingPosition = true;
	startChoosingMapLocation(state.mapClickHandlers.POSITION);
}

export function openSendCommandDialog(asset) {
	var id = asset.Id;
	//var dialogTitle = strings.SEND_COMMAND_TITLE.replace('{0}', asset.Name);
	var dialogTitle = strings.SEND_COMMAND;
	var dialog = null;

	var device = findDeviceById(asset.DeviceId);
	if (asset.DeviceId === devices.DPLUS) {
		trkData.validation.dPlusInterval.resetForm();
		trkData.validation.dPlusInterval.currentForm.reset();
		trkData.validation.dPlusQuery.resetForm();
		trkData.validation.dPlusQuery.currentForm.reset();
		dialog = $(domNodes.dialogs.dPlus);
	} else if (_.indexOf(devices.GSE_FBB, asset.DeviceId) !== -1) {
		trkData.validation.fbbInterval.resetForm();
		trkData.validation.fbbInterval.currentForm.reset();
		dialog = $(domNodes.dialogs.fbb);
	} else if ($j.inArray(device.Id, devices.INMARSAT_C) !== -1) {
		trkData.validation.inmarsatC.resetForm();
		trkData.validation.inmarsatC.currentForm.reset();
		trkData.validation.inmarsatCSchedule.resetForm();
		trkData.validation.inmarsatCSchedule.currentForm.reset();
		trkData.validation.inmarsatCDelete.resetForm();
		trkData.validation.inmarsatCDelete.currentForm.reset();
		dialog = $(domNodes.dialogs.inmarsatC);
		requestInmarsatCInformation(asset); // lookup ocean region status
		var tenMinutesUtc = moment.utc().add(10, "minutes");
		document.getElementById("ddlInmarsatCStartTimeHour").value = tenMinutesUtc.hour();
		document.getElementById("ddlInmarsatCStartTimeMinute").value = tenMinutesUtc.format("mm");
		document.getElementById("InmarsatCScheduleOptions").classList.add("is-visible");
		document.getElementById("ddlInmarsatCDeleteDnidRegion").value = "9";
		document.getElementById("ddlInmarsatCRequestLocationRegion").value = "9";
		document.getElementById("ddlInmarsatCPollRegion").value = "9";
	} else if (asset.DeviceId === devices.IRIDIUM_EDGE) {
		trkData.validation.iridiumEdge.resetForm();
		trkData.validation.iridiumEdge.currentForm.reset();
		dialog = $(domNodes.dialogs.edge);
	} else if (asset.DeviceId === devices.TM3000) {
		trkData.validation.tm3000.resetForm();
		trkData.validation.tm3000.currentForm.reset();

		// set output pin dropdown items based on asset.Device and asset.OutputLabels
		var list = $("#ddlTM3000Pin");
		list.empty();
		for (var i = 0; i < device.OutputPinCount; i++) {
			var pinNum = i + 1;
			var label = getOutputLabelByIndex(asset, pinNum);
			// 3 output types: on, off, pulse
			var onOption = createLabel(pinNum, 1, label);
			var offOption = createLabel(pinNum, 0, label);
			var pulseOption = createLabel(pinNum, 2, label);
			if (onOption != null) list.append(onOption);
			if (offOption != null) list.append(offOption);
			if (pulseOption != null) list.append(pulseOption);
		}

		dialog = $(domNodes.dialogs.tm3000);
	} else if (_.indexOf(devices.CALAMP, asset.DeviceId) !== -1) {
		trkData.validation.calampApn.resetForm();
		trkData.validation.calampApn.currentForm.reset();
		trkData.validation.calampOutput.resetForm();
		trkData.validation.calampOutput.currentForm.reset();
		dialog = $(domNodes.dialogs.calamp);

		// set output pin dropdown items based on asset.Device and asset.OutputLabels
		var list = $j("#ddlCalampPin");
		list.empty();
		for (var i = 0; i < device.OutputPinCount; i++) {
			var pinNum = i + 1;
			var label = getOutputLabelByIndex(asset, pinNum);
			// 3 output types: on, off, pulse
			var onOption = createLabel(pinNum, 1, label);
			var offOption = createLabel(pinNum, 0, label);
			var pulseOption = createLabel(pinNum, 2, label);
			if (onOption != null) list.append(onOption);
			if (offOption != null) list.append(offOption);
			if (pulseOption != null) list.append(pulseOption);
		}
	} else if (asset.DeviceId === devices.IRIDIUM_EXTREME) {
		trkData.validation.extremeInterval.resetForm();
		trkData.validation.extremeInterval.currentForm.reset();
		trkData.validation.extremeEmergencyDestination.resetForm();
		trkData.validation.extremeEmergencyDestination.currentForm.reset();
		trkData.validation.extremeEmergencyRecipient.resetForm();
		trkData.validation.extremeEmergencyRecipient.currentForm.reset();
		dialog = $(domNodes.dialogs.extreme);
	} else if (_.indexOf(devices.NAL, asset.DeviceId) !== -1) {
		trkData.validation.nal.resetForm();
		trkData.validation.nal.currentForm.reset();
		dialog = $(domNodes.dialogs.nal);
	} else if (_.indexOf(devices.INREACH, asset.DeviceId) !== -1) {
		trkData.validation.inreachInterval.resetForm();
		trkData.validation.inreachInterval.currentForm.reset();
		dialog = $(domNodes.dialogs.inreach);
	} else if (asset.DeviceId === devices.GEOPRO) {
		trkData.validation.geoproInterval.resetForm();
		trkData.validation.geoproInterval.currentForm.reset();
		dialog = $(domNodes.dialogs.geopro);
	} else if (_.indexOf(devices.GSAT_MICROS, asset.DeviceId) !== -1) {
		$.when(loadDialog("gsatmicro", true)).done(function () {
			trkData.validation.gsatmicroSettings.resetForm();
			trkData.validation.gsatmicroSettings.currentForm.reset();
			trkData.validation.gsatmicroCommands.resetForm();
			trkData.validation.gsatmicroCommands.currentForm.reset();
			openDialogPanel(
				dialogs.gsatmicro.domNode,
				dialogTitle,
				asset,
				true,
				null,
				"asset",
				"send-command",
				openSendCommandDialog
			);
			$(dialogs.gsatmicro.domNode).data("assetId", asset.Id);
			dialogs.gsatmicro.requestGSatMicroInformation(asset.Id);
		});
	} else if (_.indexOf(devices.GTTS, asset.DeviceId) !== -1) {
		$j(".gtts3000,.gtts2000bi", dialog).hide();
		if (asset.DeviceId === devices.GTTS_2000BI || asset.DeviceId === devices.GTTS_2000B) {
			$j(".gtts2000bi").show();
		} else if (asset.DeviceId === devices.GTTS_3000) {
			$j(".gtts3000").show();
		}
		dialog = $(domNodes.dialogs.gtts);
	} else if (_.indexOf(devices.FLIGHTCELL, asset.DeviceId) !== -1) {
		trkData.validation.flightcellPayload.resetForm();
		trkData.validation.flightcellPayload.currentForm.reset();
		dialog = $(domNodes.dialogs.flightcell);
	} else if (_.indexOf(devices.QUECLINK, asset.DeviceId) !== -1) {
		trkData.validation.queclinkApn.resetForm();
		trkData.validation.queclinkApn.currentForm.reset();
		trkData.validation.queclinkSettings.resetForm();
		trkData.validation.queclinkSettings.currentForm.reset();
		trkData.validation.queclinkCommands.resetForm();
		trkData.validation.queclinkCommands.currentForm.reset();
		dialog = $(domNodes.dialogs.queclink);
		//$('#edit-queclink-tab-apn').tab('show');
		queclinkCreateAPNCommand(asset.Id);
		requestQueclinkInformation(asset.Id);
	} else if (_.indexOf(devices.HUGHES, asset.DeviceId) !== -1) {
		trkData.validation.hughesCommands.resetForm();
		trkData.validation.hughesCommands.currentForm.reset();
		trkData.validation.hughesDownloadFile.resetForm();
		trkData.validation.hughesDownloadFile.currentForm.reset();
		dialog = $(domNodes.dialogs.hughes);
	} else if (asset.DeviceId === devices.EDGE_SOLAR) {
		trkData.validation.edgeSolarSettings.resetForm();
		trkData.validation.edgeSolarSettings.currentForm.reset();
		dialog = $(domNodes.dialogs.edgeSolar);
		requestEdgeSolarInformation(asset.Id);
	} else if (asset.DeviceId === devices.LT100) {
		trkData.validation.lt100Periodic.resetForm();
		trkData.validation.lt100Periodic.currentForm.reset();
		trkData.validation.lt100Motion.resetForm();
		trkData.validation.lt100Motion.currentForm.reset();
		trkData.validation.lt100Vibrate.resetForm();
		trkData.validation.lt100Vibrate.currentForm.reset();
		dialog = $(domNodes.dialogs.lt100);
		//$('#edit-lt100-tab-diagnostics').tab('show');
	} else if (asset.DeviceId === devices.LT501) {
		trkData.validation.lt501Periodic.resetForm();
		trkData.validation.lt501Periodic.currentForm.reset();
		trkData.validation.lt501Motion.resetForm();
		trkData.validation.lt501Motion.currentForm.reset();
		trkData.validation.lt501Location.resetForm();
		trkData.validation.lt501Location.currentForm.reset();
		dialog = $(domNodes.dialogs.lt501);
		//$('#edit-lt501-tab-diagnostics').tab('show');
	} else if (_.indexOf(devices.QUAKE_AIC, asset.DeviceId) !== -1) {
		trkData.validation.quake.resetForm();
		trkData.validation.quake.currentForm.reset();
		dialog = $(domNodes.dialogs.quake);
		// $('#edit-quake-settings').tab('show');
		//requestQuakeInformation(asset.Id); // todo
	} else if (asset.DeviceId === devices.METOCEAN_ITRAC) {
		trkData.validation.itracCommands.resetForm();
		trkData.validation.itracCommands.currentForm.reset();
		dialog = $(domNodes.dialogs.itrac);
	} else if (
		_.indexOf(devices.SKYWAVE_IDP_DUAL_MODE, asset.DeviceId) !== -1 ||
		_.indexOf(devices.SKYWAVE_IDP, asset.DeviceId) !== -1
	) {
		trkData.validation.idpAvlGeofence.resetForm();
		trkData.validation.idpAvlGeofence.currentForm.reset();
		trkData.validation.idpAvlParameters.resetForm();
		trkData.validation.idpAvlParameters.currentForm.reset();
		trkData.validation.idpCoreParameters.resetForm();
		trkData.validation.idpCoreParameters.currentForm.reset();
		trkData.validation.idpIoParameters.resetForm();
		trkData.validation.idpIoParameters.currentForm.reset();
		trkData.validation.idpMetersSet.resetForm();
		trkData.validation.idpMetersSet.currentForm.reset();
		dialog = $(domNodes.dialogs.idpSendCommand);

		//$j('#IDPAgentVersions button').button({ text: null, icons: { primary: 'ui-icon-info' } });
		// check asset configuration for AVL properties
		// move to default tab
		//$('#query-idp-tab-diagnostics').tab('show');
		$("#query-idp-accordion .primary-card button").removeClass("disabled");

		if (_.indexOf(trkData.avlConfigurations, asset.Configuration) === -1) {
			// disable AVL properties commands
			$("#accordion-query-idp-avl-parameters-head button").addClass("disabled");
			$("#accordion-query-idp-io-parameters-head button").addClass("disabled");
			$("#accordion-query-idp-geofence-head button").addClass("disabled");
			$("#accordion-query-idp-meters-head button").addClass("disabled");
		}

		$j(".hidden", dialog).hide();

		$j.when(requestAVLInformation(asset.Id)).then(function () {
			if (!asset.IDP.Garmin) {
				$("#accordion-query-idp-garmin-head button").addClass("disabled");
			}
			if (!asset.IDP.EyeAlert) {
				$("#accordion-query-idp-eyealert-head button").addClass("disabled");
			}
			if (!asset.IDP.Immobilizer && (!asset.IDP.AVL || asset.Configuration !== "GSEAgentsV2")) {
				$("#accordion-query-idp-immobilizer-head button").addClass("disabled");
			}

			if (!asset.IDP.Updater) {
				$("#accordion-query-idp-updater-head button").addClass("disabled");
			}

			// show/hide basic report section based on Report agent enabled
			if (asset.IDP.Report) {
				$j(".idp-report", dialog).show();
			} else {
				$j(".idp-report", dialog).hide();
			}

			if ($j.inArray(asset.Configuration, trkData.arcConfigurations) == -1) {
				// disable ARC properties commands
				$("#accordion-query-idp-arc-head button").addClass("disabled");
			}

			if (asset.DeviceId == devices.SKYWAVE_SG7100) {
				// hide pins not available in the SG7100
				$j(".sg7100", dialog).hide();
			} else {
				$j(".sg7100", dialog).show();
			}

			$(".avl17", dialog).hide(); // .avl17 is for features no longer in past 1.7
			$(".avl20", dialog).show(); // .avl20 and .avl30 are for features that were added after those versions
			$(".avl30", dialog).show();

			if (asset.Configuration == "AVL") {
				$(".avl17", dialog).show();
				$(".avl20", dialog).hide();
				$(".avl30", dialog).hide();
				$("#accordion-query-io-parameters-head button").addClass("disabled");
			} else if (asset.Configuration == "AVL2") {
				$(".avl17", dialog).hide();
				$(".avl20", dialog).show();
				$(".avl30", dialog).hide();
			} else if (
				asset.Configuration == "AVL3" ||
				asset.Configuration == "ATS" ||
				asset.Configuration == "GSEAgentsV1" ||
				asset.Configuration == "GSEAgentsV2"
			) {
				// hide deprecated pins? Accel?
			}
		});

		// if not dual-mode, hide gprs options
		if (_.indexOf(devices.SKYWAVE_IDP_DUAL_MODE, asset.DeviceId) === -1) {
			// satellite only, disable/hide gprs options
			$j("input[name=IDPGateway][value!=1]")
				.prop("disabled", true)
				.prop("checked", false)
				.next("label")
				.children("input:text")
				.prop("disabled", true);
			$j("input[name=IDPGateway][value=1]").prop("checked", true);
			$j(".dual-mode-delivery", dialog).not("td").hide();
			$j("td.dual-mode-delivery div", dialog).hide();
		} else {
			$j(".dual-mode-delivery", dialog).not("td").show();
			$j("td.dual-mode-delivery div", dialog).show();
			$j("input[name=IDPGateway]")
				.prop("disabled", false)
				.prop("checked", false)
				.next("label")
				.children("input:text")
				.prop("disabled", false);
			$j("input[name=IDPGateway][value=3]").prop("checked", true);
		}
		$j(".collapse.idp-accel", dialog).show();
		if (asset.DeviceId === devices.SKYWAVE_7XX) {
			$j(".idp-eio", dialog).hide();
			$j(".collapse.idp-eeio", dialog).show();
		} else if (asset.DeviceId === devices.SKYWAVE_SG7100) {
			$j(".idp-eio, .idp-eeio, .idp-accel", dialog).hide();
		} else {
			$j(".collapse.idp-eio", dialog).show();
			$j(".idp-eeio", dialog).hide();
		}

		// PINS specific to the IDP-782
		if (
			asset.DeviceId !== devices.SKYWAVE_782 &&
			asset.DeviceId !== devices.SKYWAVE_782_CELL &&
			asset.DeviceId !== devices.SKYWAVE_ST9100 &&
			asset.DeviceId !== devices.ORBCOMM_ST9101
		) {
			$j(".idp-782", dialog).addClass("stayhidden").hide();
		} else {
			$j(".idp-782", dialog).removeClass("stayhidden");
			$j("td.idp-782", dialog).show();
		}

		// populate groups and assets, should this just be static?
		var cont = $j(".GroupsContainer", dialog).empty();
		if (cont.length > 0) {
			var sortedGroups = sortAssetGroups();
			for (var i = 0; i < sortedGroups.length; i++) {
				var group = sortedGroups[i];
				addGroupToGroupListWithName(group, 0, cont[0], "IDPGroups");
			}
		}
		// assets
		$j("select.AssetsIncluded", dialog).empty();
		var cont = $j("select.AssetsNotIncluded", dialog).empty();
		if (cont.length > 0) {
			for (var i = 0; i < trkData.assets.length; i++) {
				var incasset = trkData.assets[i];
				if ($j.inArray(incasset.Configuration, trkData.avlConfigurations) != -1)
					cont.append(
						$j("<option>")
							.val(incasset.Id)
							.text(incasset.Name + " (" + incasset.UniqueId + ")")
					);
			}
		}
		// uncheck all avl properties
		// TODO: is this necessary? just reset form?
		//$j('#IDPAVLParameters input:checkbox,#IDPAVLIOParameters input:checkbox,#IDPCoreParameters input:checkbox,#IDPGarminParameters input:checkbox,#IDPServiceMeters input:checkbox,#IDPARCProperties input:checkbox').prop('checked', false);
		//$j('#IDPAVLParameters input,#IDPAVLParameters select,#IDPAVLIOParameters input,#IDPAVLIOParameters select,#IDPCoreParameters input,#IDPCoreParameters select,#IDPGarminParameters input,#IDPGarminParameters select,#IDPServiceMeters input,#IDPServiceMeters select,#IDPARCProperties input,#IDPARCProperties select').not(':checkbox').prop('disabled', true);
	} else {
		console.log("Unknown Device for Command: " + asset.DeviceId);
	}
	if (dialog != null) {
		var dialogDom = dialog[0];
		var isLarge = dialogDom.getAttribute("data-is-large") !== null ? true : false;
		openDialogPanel(dialog[0], dialogTitle, asset, isLarge, null, "asset", "send-command", openSendCommandDialog);
		dialog.data("assetId", asset.Id);
	}
}

function openSharedViewStatisticsDialog(sharedView) {
	if (sharedView === undefined || sharedView === null) {
		return;
	}
	trkData.sharedView.current = sharedView;
	var data = {
		id: sharedView.Id,
	};

	// TODO clear previous statistics?
	handleAjaxFormSubmission(
		"GetSharedViewStatistics",
		data,
		null,
		null,
		null,
		strings.MSG_GET_SHARED_VIEW_STATISTICS_ERROR,
		function (result) {
			// recreate chart
			var visitCounts = result.VisitCounts;
            if(visitCounts) {
				Chart.defaults.color = "#fff";
                new Chart($('#chart-shared-view-visits'), {
                    type: "line",
                    options: {
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                type: 'time',
                                time: {
                                    unit: 'day'
                                },
                            },
                            y: {
                                title: {
                                    display: true,
                                    text: strings.NUMBER_OF_VISITS,
                                },
                            },
                        },
                        plugins: {
                            title: {
                                display: true,
                                text: strings.VISITOR_DETAILS,
                                font: {
                                    size: 18
                                }
                            },
							legend: {
								display: false,
							},
                        }
                    },
                    data: {
                        datasets: [{
                            data: visitCounts.map(p => ({ x: new Date(p.Date), y: p.Visits })),
                            borderColor: '#7AA937',
    						backgroundColor: '#7AA93730',
                            fill: true,
                            label: strings.DAILY_VISITS,
                        }]
                    }
                });
            }

			var table = $("#shared-view-visitor-activity").DataTable();
			table.clear().draw();
			var rows = [[strings.ANONYMOUS, result.AnonymousVisitCount, result.AnonymousLastVisit]];
			_.each(result.Invites, function (invite) {
				rows.push([invite.Email, invite.TotalVisits, invite.LastVisit]);
			});
			table.rows.add(rows).draw();
		}
	);

	var dialog = domNodes.dialogs.sharedViewStatistics;
	openDialogPanel(
		dialog,
		strings.VISITOR_DETAILS,
		sharedView,
		false,
		checkForShareViewChange(sharedView),
		"shared-view",
		"statistics",
		openSharedViewStatisticsDialog
	);
	$(domNodes.infoDialogs.sharedViewInformation).dialog("close");
}

function createLabel(index, value, label) {
	var output = strings.OUTPUT_NUMBER.replace("{0}", index);
	var option = $j("<option></option>");
	option.val(index + "," + value);
	var defaultText = strings.SET_IO_COMMAND;
	if (label == null) {
		// defaults
		var text = defaultText.replace("{0}", output);
		switch (value) {
			case 0: // Off
				text = text.replace("{1}", strings.OFF);
				break;
			case 1: // On
				text = text.replace("{1}", strings.ON);
				break;
			case 2: // Pulse
				text = text.replace("{1}", strings.ONE_SECOND_PULSE);
				break;
		}
		option.text(text);
	} else {
		// user preferences
		var text = defaultText.replace("{0}", labelOrDefault(label.Label, output));
		switch (value) {
			case 0: // Off
				if (label.OffIsDisabled) return null;
				text = text.replace("{1}", labelOrDefault(label.OffLabel, strings.OFF));
				break;
			case 1: // On
				if (label.OnIsDisabled) return null;
				text = text.replace("{1}", labelOrDefault(label.OnLabel, strings.ON));
				break;
			case 2: // Pulse
				if (label.PulseIsDisabled) return null;
				text = text.replace("{1}", labelOrDefault(label.PulseLabel, strings.ONE_SECOND_PULSE));
				break;
		}
		option.text(text);
	}
	return option;
}

function labelOrDefault(label, orDefault) {
	if (label == null) return orDefault;
	if (label == "") return orDefault;
	return label;
}

function openAddPositionDialog(asset) {
	trkData.validation.addPosition.resetForm();
	trkData.validation.addPosition.currentForm.reset();
	$("#txtAddPositionAsset").val(asset.Name + " (" + asset.UniqueId + ")");
	$("#hfAddPositionAssetId").val(asset.Id);
	$("#txtAddPositionDate").val(moment(new Date().getTime() - user.tickOffset).format(user.dateFormat));
	$("#txtAddPositionSpeedType").text(speedText());
	document.getElementById("txtAddPositionLat").focus();
	openDialogPanel(
		domNodes.dialogs.addPosition,
		strings.ADD_POSITION,
		asset,
		false,
		function () {
			state.isAddingPosition = false;
			stopChoosingMapLocation(state.mapClickHandlers.POSITION_ADD);
		},
		"asset",
		"add-position",
		openAddPositionDialog
	);

	state.isAddingPosition = true;
	startChoosingMapLocation(state.mapClickHandlers.POSITION_ADD);
}

function loadIOHistoryDialog(asset) {
	$(domNodes.dialogs.ioHistory).data("assetId", asset.Id);
	openDialogPanel(
		domNodes.dialogs.ioHistory,
		strings.IO_LOG,
		asset,
		true,
		function () {
			trkData.io = null;
		},
		"asset",
		"view-logs-io",
		loadIOHistoryDialog
	);

	// clear table rows
	loadIOHistory(asset);
}

function loadFillupHistoryDialog(asset) {
	// refuel history/add a fillup
	$(domNodes.dialogs.refuelHistory).data("assetId", asset.Id);
	openDialogPanel(
		domNodes.dialogs.refuelHistory,
		strings.REFUEL_LOG,
		asset,
		true,
		null,
		"asset",
		"view-logs-refuel",
		loadFillupHistoryDialog
	);

	trkData.validation.refuelAdd.resetForm();
	trkData.validation.refuelAdd.currentForm.reset();

	$j("#txtRefuelDate").val(moment().format(user.dateFormat));
	$j("#txtRefuelAsset").text(asset.Name);
	if (asset.FuelEfficiency != "") {
		$j("#RefuelEfficiency").show();
		$j("#txtRefuelFuelEfficiency").text(
			convertFuelEfficiencyToStandard(asset.FuelEfficiency, preferences.PREFERENCE_FUEL_UNIT) +
				" " +
				fuelEfficiencyType(preferences.PREFERENCE_FUEL_UNIT)
		);
	} else {
		$j("#RefuelEfficiency").hide();
	}
	$j("#txtRefuelAmountType").text(fuelText()); // litres/gallons/imp gallons
	$j("#txtRefuelOdometerType").text(distanceText()); // litres/gallons/imp gallons
	$j("#hfRefuelAssetId").val(asset.Id);

	loadFillupHistory(asset);
}

function loadWaypointHistoryDialog(asset) {
	$(domNodes.dialogs.waypointHistory).data("assetId", asset.Id);
	openDialogPanel(
		domNodes.dialogs.waypointHistory,
		strings.WAYPOINT_LOG,
		asset,
		true,
		null,
		"asset",
		"view-logs-waypoint",
		loadWaypointHistoryDialog
	);

	loadWaypointHistory(asset);
}

function loadServiceMeterHistoryDialog(asset) {
	$(domNodes.dialogs.serviceMeterHistory).data("assetId", asset.Id);
	openDialogPanel(
		domNodes.dialogs.serviceMeterHistory,
		strings.SERVICE_METERS,
		asset,
		true,
		null,
		"asset",
		"view-logs-service-meters",
		loadServiceMeterHistoryDialog
	);

	loadServiceMeterHistory(asset);
}
