// Panel navigation. Not just opening/closing, but populating contents of the panel.

import trkData from "./data.js";
import user from "./user.js";
import strings from "./strings.js";
import options from "./options.js";
import domNodes from "./domNodes.js";
import { openSettingsPanel, itemSettingsClick } from "./panel-settings.js";
import { getAssetDataGroupForCurrentViewMode } from "./map-viewmode.js";
import {
	isSendCommandEnabledForAsset,
	isWaypointEnabledForAsset,
	isMessagingEnabledForAsset,
	isServiceMeterEnabledForAsset,
	isGarminFormsEnabledForAsset,
	isOutputEnabledForAsset,
	isMessagingEnabledForAssetGroup,
} from "./assets.js";
import { trkDataGroups } from "./const.js";
import { findDeviceById } from "./devices.js";
import {
	updateNotificationsInSecondaryPanel,
	getNotificationCounts,
	getNotificationCountsForGroup,
} from "./notifications.js";
import { productTitle } from "./entry_point.js";

import _ from "lodash";
import $ from "jquery";
import moment from "moment"; // https://www.npmjs.com/package/moment

// open a dialog for an action - this should be called as early as possible so that previous dialog cleanup does not interfere with no dialog intialization
export function openDialogPanel(domNode, title, item, isLargeDialog, closeCallback, type, action, nextItemCallback) {
	// don't do anything if the dialog is already open for this exact item

	var panel = domNodes.panels.secondary;

	if (type === undefined) {
		type = "asset";
	}
	var id = null;
	if (item !== null) {
		id = item.Id;
	}
	openSettingsPanel(type + "s", item, domNode !== null); // hack/lazy alert
	if (isLargeDialog) {
		panel.classList.add("is-full-width");
	}

	panel.closeCallback = closeCallback;
	panel.nextItemCallback = nextItemCallback;

	var showMetaPanel = false;
	var showNotificationItems = false;
	var notificationTabs = document.getElementById("panel-secondary-nav-tabs");
	var isNotificationItem = true;

	var panelItemType = panel.getAttribute("data-item-type");
	var notificationTabItemTypes = ["groups", "assets", "trips", "journeys", "shared-views"]; // todo: only asset groups
	if (panelItemType !== undefined && _.indexOf(notificationTabItemTypes, panelItemType) !== -1) {
		showNotificationItems = true;
	}

	var metaPanel = document.getElementById("panel-secondary-meta");
	// item selection dropdown (action determines which items are disabled)
	console.log(domNode, type, item, action);
	if (action !== undefined && action !== null) {
		// showing of selector handled above by openSettingsPanel
		populateItemSelector(action, item, type + "s");

		populateMetaInformation(type, item);
		showMetaPanel = true;

		// select notification tab based on action here?
		var actionsPositions = [
			"asset-positions",
			"group-positions",
			"trip-positions",
			"journey-positions",
			"shared-view-positions",
		];
		var actionsEvents = ["asset-events", "group-events", "trip-events", "journey-events", "shared-view-events"];
		var actionsAlerts = ["asset-alerts", "group-alerts", "trip-alerts", "journey-alerts", "shared-view-alerts"];
		var actionsStatus = ["asset-status", "group-status", "trip-status", "journey-status", "shared-view-status"];
		var actionsMessages = ["asset-messages", "group-messages", "trip-messages", "journey-messages"];
		var actionsChat = ["asset-chat", "group-chat", "trip-chat", "journey-chat", "shared-view-chat"];
		var actionsActivity = [
			"asset-activity",
			"group-activity",
			"trip-activity",
			"journey-activity",
			"shared-view-activity",
		];
		var optionsActions = [
			"shared-view-options",
			"asset-options",
			"group-options",
			"trip-options",
			"journey-options",
			"fence-options",
			"place-options",
		];
		notificationTabs.querySelectorAll(".nav-item").forEach(function (nav) {
			nav.classList.remove("active");
		});

		metaPanel.querySelectorAll(".meta-left,.meta-options").forEach(function (meta) {
			meta.classList.remove("is-visible");
		});

		//var metaTypes = ['positions', 'events', 'alerts', 'status', 'messages', 'chat', 'activity'];
		var metaType = null;
		if (_.indexOf(actionsPositions, action) !== -1) {
			document.getElementById("nav-positions-tab").classList.add("active");
			metaType = "positions";
		} else if (_.indexOf(actionsEvents, action) !== -1) {
			document.getElementById("nav-events-tab").classList.add("active");
			metaType = "events";
		} else if (_.indexOf(actionsAlerts, action) !== -1) {
			document.getElementById("nav-alerts-tab").classList.add("active");
			metaType = "alerts";
		} else if (_.indexOf(actionsStatus, action) !== -1) {
			document.getElementById("nav-status-tab").classList.add("active");
			metaType = "status";
		} else if (_.indexOf(actionsMessages, action) !== -1) {
			document.getElementById("nav-messages-tab").classList.add("active");
			metaType = "messages";
		} else if (_.indexOf(actionsChat, action) !== -1) {
			document.getElementById("nav-chat-tab").classList.add("active");
			metaType = "chat";
		} else if (_.indexOf(actionsActivity, action) !== -1) {
			document.getElementById("nav-activity-tab").classList.add("active");
			metaType = "activity";
		} else if (_.indexOf(optionsActions, action) !== -1) {
			document.getElementById("nav-options-tab").classList.add("active");
			isNotificationItem = false;
			metaType = "options";
		} else {
			isNotificationItem = false;
			showMetaPanel = false;
		}

		if (metaType !== null) {
			metaPanel.querySelectorAll(".meta-" + metaType).forEach(function (meta) {
				meta.classList.add("is-visible");
			});
		}
	}

	if (showMetaPanel) {
		metaPanel.classList.add("is-visible");
	} else {
		metaPanel.classList.remove("is-visible");
	}

	if (showNotificationItems && id !== null && (isNotificationItem || metaType === "options")) {
		notificationTabs.classList.add("is-visible");

		var counts = null;
		if (type === "asset") {
			counts = getNotificationCounts(getAssetDataGroupForCurrentViewMode(), id);
		} else if (type === "trip") {
			counts = getNotificationCounts(trkDataGroups.JOURNEY_HISTORY, id);
		} else if (type === "group") {
			counts = getNotificationCountsForGroup(getAssetDataGroupForCurrentViewMode(), id);
		} else if (type === "journey") {
			counts = getNotificationCountsForGroup(trkDataGroups.JOURNEY_HISTORY, id);
		} else if (type === "shared-view") {
			counts = getNotificationCounts(trkDataGroups.SHARED_VIEW_HISTORY, id);
			console.log(counts);
		}
		if (counts !== null) {
			updateNotificationsInSecondaryPanel(type + "s", id, counts);
		}
	} else {
		notificationTabs.classList.remove("is-visible");
	}

	var panelTitle = document.getElementById("panel-secondary-title");
	panelTitle.textContent = title;

	if (domNode !== null) {
		var dialogDom = document.getElementById("dialog-functions");
		var existingDialog = dialogDom.querySelector(".dialog");
		if (existingDialog !== domNode) {
			if (existingDialog !== null) {
				document.getElementById("detached-dialogs").appendChild(existingDialog);
			}
			dialogDom.appendChild(domNode);
		}

		// breadcrumbs
		var breadcrumbs = [];
		if (id !== null) {
			// when inside of a dialog, add breadcrumbs leading back to the item in general
			switch (type + "s") {
				case "assets":
					breadcrumbs.push({
						label: strings.ASSET,
						callback: itemSettingsClick,
						attributes: { "data-asset-id": id },
					});
					break;
				case "groups":
					breadcrumbs.push({
						label: strings.ASSET_GROUP,
						callback: itemSettingsClick,
						attributes: { "data-group-id": id, "data-group-for": "assets" },
					});
					break;
				case "fences":
					breadcrumbs.push({
						label: strings.GEOFENCE,
						callback: itemSettingsClick,
						attributes: { "data-fence-id": id },
					});
					break;
				case "fence-groups":
					breadcrumbs.push({
						label: strings.GEOFENCE_GROUP,
						callback: itemSettingsClick,
						attributes: { "data-group-id": id, "data-group-for": "fences" },
					});
					break;
				case "places":
					breadcrumbs.push({
						label: strings.PLACE,
						callback: itemSettingsClick,
						attributes: { "data-place-id": id },
					});
					break;
				case "place-groups":
					breadcrumbs.push({
						label: strings.PLACE_GROUP,
						callback: itemSettingsClick,
						attributes: { "data-group-id": id, "data-group-for": "places" },
					});
					break;
				case "journeys":
					breadcrumbs.push({
						label: strings.JOURNEY,
						callback: itemSettingsClick,
						attributes: { "data-journey-id": id },
					});
					break;
				case "trips":
					breadcrumbs.push({
						label: strings.TRIP,
						callback: itemSettingsClick,
						attributes: { "data-trip-id": id },
					});
					break;
				case "shared-views":
					breadcrumbs.push({
						label: strings.SHARED_VIEW,
						callback: itemSettingsClick,
						attributes: { "data-shared-view-id": id },
					});
					break;
			}
		}
		breadcrumbs.push({ label: title, callback: function () {} });
		var breadcrumbDom = document.getElementById("dialog-breadcrumbs");
		var breadcrumbTitle = breadcrumbDom.querySelector(".panel-title");
		breadcrumbTitle.innerHTML = "";
		breadcrumbTitle.appendChild(createSecondaryBreadcrumb(breadcrumbs));

		// enable back button to go back to item settings
		var backButton = document.getElementById("panel-dialog-back");
		backButton.removeAttribute("data-asset-id");
		backButton.removeAttribute("data-group-id");
		backButton.removeAttribute("data-group-for");
		backButton.removeAttribute("data-fence-id");
		backButton.removeAttribute("data-place-id");
		backButton.removeAttribute("data-trip-id");
		backButton.removeAttribute("data-shared-view-id");
		if (id !== null && !isNotificationItem) {
			// show breadcrumbs
			breadcrumbDom.classList.add("is-visible");
			if (type.endsWith("group")) {
				backButton.setAttribute("data-group-id", id);
				const groupFor = type === "place-group" ? "places" : type === "fence-group" ? "fences" : "assets";
				backButton.setAttribute("data-group-for", groupFor);
			} else {
				backButton.setAttribute("data-" + type + "-id", id);
			}
		} else {
			// hide breadcrumbs
			breadcrumbDom.classList.remove("is-visible");
		}

		//// panel filters shown/hidden here - should move to a more declarative way of doing this
		//var notificationOptions = document.getElementById('panel-options-notifications');
		//if (notificationOptions !== null) {
		//    var notificationDialogs = [
		//        domNodes.dialogs.assetMessages,
		//        domNodes.dialogs.assetStatus,
		//        domNodes.dialogs.assetEvents,
		//        domNodes.dialogs.assetAlerts,
		//        domNodes.dialogs.assetPositions
		//    ];
		//    if (notificationDialogs.indexOf(domNode) !== -1) {
		//        notificationOptions.classList.add('is-visible');
		//    } else {
		//        notificationOptions.classList.remove('is-visible');
		//    }
		//}
		resetParametersForms(domNode);

		// clear dialog statuses
		dialogDom.querySelectorAll(".dialog-status").forEach(function (status) {
			clearStatusMessage(status);
		});
	}

	domNodes.simpleBars.secondary.recalculate(); // todo: this should occur after all mutations
}

function resetParametersForms(node) {
	node.querySelectorAll(".parameters-form").forEach(function (form) {
		$(".parameter-toggle input:checkbox", form).prop("checked", false);
		$(".parameter-toggle input:checkbox", form)
			.parents(".parameter-toggle")
			.next(".parameter-options")
			.find("input,select,label")
			.prop("disabled", true)
			.addClass("disabled");
	});
}

function populateItemSelector(action, currentItem, type) {
	var selector = document.getElementById("panel-secondary-header");
	//selector.classList.add('d-flex');
	var $selector = $(selector);
	selector.setAttribute("data-group-for", type);
	var items = [];
	var allItems = [];
	switch (type) {
		case "assets":
			allItems = trkData.assets;
			switch (action) {
				case "send-command":
					items = _.filter(trkData.assets, function (asset) {
						return isSendCommandEnabledForAsset(asset);
					});
					break;
				case "send-waypoint":
					items = _.filter(trkData.assets, function (asset) {
						return isWaypointEnabledForAsset(asset);
					});
					break;
				case "send-message":
					items = _.filter(trkData.assets, function (asset) {
						return isMessagingEnabledForAsset(asset);
					});
					break;
				case "view-logs-service-meters":
					items = _.filter(trkData.assets, function (asset) {
						return isServiceMeterEnabledForAsset(asset);
					});
					break;
				case "view-logs-garmin-forms":
					items = _.filter(trkData.assets, function (asset) {
						return isGarminFormsEnabledForAsset(asset);
					});
					break;
				case "set-output":
					items = _.filter(trkData.assets, function (asset) {
						return isOutputEnabledForAsset(asset);
					});
					break;
				case "set-driver":
					items = trkData.assets;
					break;
				case "route-asset":
				case "set-waypoint":
				case "add-position":
				case "view-logs-message":
				case "view-logs-io":
				case "view-logs-waypoint": // todo: does this apply to set waypoint? if not, it should be same restriction as send-waypoint
				case "view-logs-driver":
				case "edit-asset":
				case "asset-positions":
				case "asset-events":
				case "asset-status":
				case "asset-messages":
				case "asset-chat":
				case "asset-options":
				case "asset-alerts":
				case "asset-activity":
					items = trkData.assets;
					break;
				case "acknowledge-alert":
					items = _.filter(trkData.assets, function (asset) {
						return (
							_.filter(trkData.alerts, function (alert) {
								return alert.AssetId === asset.Id;
							}).length > 0
						);
					});
					break;
				default:
					console.warn("No asset action selector filter defined for:" + action);
					break;
			}
			break;
		case "groups":
			allItems = trkData.groups;
			switch (action) {
				case "send-message":
					items = _.filter(trkData.groups, function (group) {
						return isMessagingEnabledForAssetGroup(group);
					});
					break;
				case "group-events":
				case "group-status":
				case "group-messages":
				case "group-positions":
				case "group-options":
				case "group-alerts":
				case "group-chat":
				case "group-activity":
					items = trkData.groups;
					break;
				case "edit-group":
					items = _.filter(trkData.groups, function (group) {
						return !group.IsDefault;
					});
					break;
				default:
					console.warn("No group action selector filter defined for:" + action);
					break;
			}
			break;
		case "fence-groups":
		case "place-groups":
			allItems = type === "fence-groups" ? trkData.fenceGroups : trkData.placeGroups;
			switch (action) {
				case "group-options":
				case "edit-group":
					items = _.filter(allItems, function (group) {
						return !group.Id.startsWith("all-");
					});
					break;
				default:
					console.warn("No " + type + " action selector filter defined for:" + action);
					break;
			}
			break;
		case "fences":
			allItems = trkData.fences;
			switch (action) {
				case "edit-fence":
				case "fence-options":
					items = trkData.fences;
					break;
				default:
					console.warn("No fence action selector filter defined for:" + action);
					break;
			}
			break;
		case "places":
			allItems = trkData.places;
			switch (action) {
				case "route-asset":
				case "edit-place":
				case "place-options":
					items = trkData.places;
					break;
				default:
					console.warn("No place action selector filter defined for:" + action);
					break;
			}
			break;
		case "trips":
			switch (action) {
				case "trip-positions":
				case "trip-events":
				case "trip-status":
				case "trip-messages":
				case "trip-options":
				case "trip-alerts":
				case "trip-chat":
				case "trip-activity":
					var allTrips = [];
					trkData.journeys.forEach(function (journey) {
						allTrips = allTrips.concat(journey.Trips);
					});
					items = allTrips;
					allItems = allTrips;
			}
			break;
		case "journeys":
			items = trkData.journeys;
			allItems = trkData.journeys;
			break;
		case "shared-views":
			items = trkData.sharedViews;
			allItems = trkData.sharedViews;
			break;
		default:
			console.warn("Unknown item action selector type:" + type);
			break;
	}

	const options = selector.querySelector("#panel-secondary-item-options");
	options.innerHTML = "";

	let isNext = true,
		isPrev = true;
	var selectorOptions = document.createDocumentFragment();
	var indexFirstEnabled = null;
	var indexLastEnabled = 0;
	var selectedIndex = 0;
	allItems.forEach(function (val, ind, list) {
		var opt = document.createElement("option");
		opt.value = val.Id;
		opt.textContent = (ind + 1).toString() + ": " + val.Name;
		opt.disabled = _.indexOf(items, val) === -1;
		if (currentItem.Id === val.Id) {
			selectedIndex = ind;
			opt.setAttribute("selected", "selected");
		}
		var visibleOption = opt.cloneNode(true); // why?
		visibleOption.textContent = val.Name;
		options.appendChild(visibleOption);
		selectorOptions.appendChild(opt);
		if (!opt.disabled) {
			if (indexFirstEnabled === null) {
				indexFirstEnabled = ind;
			}
			indexLastEnabled = ind;
		}
	});

	isNext = selectedIndex < indexLastEnabled;
	isPrev = selectedIndex > indexFirstEnabled;

	//select.appendChild(selectorOptions);
	$selector.find("a").removeClass("disabled");
	$selector.find("button").removeClass("disabled").prop("disabled", false);
	if (!isNext) {
		$selector.find("a.item-next").addClass("disabled");
		$selector.find("button.item-next").addClass("disabled").prop("disabled", true);
	}
	if (!isPrev) {
		$selector.find("a.item-prev").addClass("disabled");
		$selector.find("button.item-prev").addClass("disabled").prop("disabled", true);
	}
}

function populateMetaInformation(type, item) {
	// photo for geofence, place, asset, trip
	var metaPanels = [
		"meta-asset",
		"meta-group",
		"meta-trip",
		"meta-journey",
		"meta-fence",
		"meta-place",
		"meta-shared-view",
		"meta-place-group",
		"meta-fence-group",
	];
	var activePanel = "meta-" + type;
	var metaPanel = document.getElementById("panel-secondary-meta");
	//var metaRight = metaPanel.querySelector('.meta-right');
	if (type === "asset" || type === "shared-view") {
		//metaRight.classList.add('is-visible');
		metaPanel.classList.add("show-right");
	} else {
		//metaRight.classList.remove('is-visible');
		metaPanel.classList.remove("show-right");
	}
	switch (type) {
		case "asset":
			// notes, photo, uniqueid, device type
			var notes = document.getElementById("meta-asset-notes");
			//if (item.PhotoType != '') {
			//    metaRight.classList.add('is-visible');
			//    metaPanel.classList.add('show-right');
			//} else {
			//    metaRight.classList.remove('is-visible');
			//    metaPanel.classList.remove('show-right');
			//}
			if (item.Notes !== null && item.Notes !== "") {
				notes.querySelector(".meta-item").textContent = item.Notes;
				//notes.classList.add('is-visible');
			} else {
				notes.querySelector(".meta-item").textContent = strings.GROUP_NONE;
				//notes.classList.remove('is-visible');
			}
			var device = findDeviceById(item.DeviceId);
			var deviceName = document.getElementById("meta-asset-device");
			if (options.hideDeviceName) {
				deviceName.classList.remove("is-visible");
			} else {
				deviceName.querySelector(".meta-item").textContent = device.Name;
				deviceName.classList.add("is-visible");
			}
			var uniqueId = document.getElementById("meta-asset-uniqueid");
			uniqueId.querySelector(".meta-item").textContent = item.UniqueId;
			uniqueId.querySelector(".meta-header").textContent =
				$("#ddlEditAssetDeviceTypes option[value=" + device.UniqueIdType + "]").text() + ":";
			break;
		case "group":
			break;
		case "fence":
			// description
			var description = document.getElementById("meta-group-description");
			if (item.Description !== null && item.Description !== "") {
				description.querySelector(".meta-item").textContent = item.Description;
				description.classList.add("is-visible");
			} else {
				description.classList.remove("is-visible");
			}
			break;
		case "place":
			// description, contact
			var description = document.getElementById("meta-place-description");
			if (item.Description !== null && item.Description !== "") {
				description.querySelector(".meta-item").textContent = item.Description;
				description.classList.add("is-visible");
			} else {
				description.classList.remove("is-visible");
			}
			var contact = document.getElementById("meta-place-contact");
			if (item.Contact !== null && item.Contact !== "") {
				contact.querySelector(".meta-item").textContent = item.Contact;
				contact.classList.add("is-visible");
			} else {
				contact.classList.remove("is-visible");
			}
			break;
		case "shared-view":
			var status = document.getElementById("meta-shared-view-status").querySelector(".meta-item");
			if (item.IsEnabled) {
				status.textContent = strings.ENABLED;
			} else {
				status.textContent = strings.DISABLED;
			}

			var expires = document.getElementById("meta-shared-view-expiration").querySelector(".meta-item");
			if (item.ExpiresOnEpoch === null) {
				expires.textContent = strings.NEVER;
			} else {
				expires.textContent = moment(item.ExpiresOnEpoch).format(user.shortDateFormat);
			}

			var availability = document.getElementById("meta-shared-view-availability").querySelector(".meta-item");
			if (item.IsPublic) {
				availability.textContent = strings.PUBLIC;
			} else {
				availability.textContent = strings.PRIVATE;
			}

			var messaging = document.getElementById("meta-shared-view-messaging").querySelector(".meta-item");
			if (item.IsMessagingEnabled) {
				messaging.textContent = strings.ENABLED;
			} else {
				messaging.textContent = strings.DISABLED;
			}

			// link
			//var link = document.getElementById('meta-shared-view-link');
			var linkButton = document.getElementById("meta-shared-view-link");
			var linkLink = document.getElementById("meta-shared-view-link-link");
			var linkUrl = item.Link;
			linkLink.textContent = linkUrl;
			linkLink.setAttribute("href", linkUrl);
			linkButton.setAttribute("data-link", linkUrl);

			// share links
			document.getElementById("shared-view-share-email").querySelector("a").setAttribute("data-share-view-id", item.Id);
			document
				.getElementById("shared-view-share-twitter")
				.querySelector("a")
				.setAttribute(
					"href",
					"https://twitter.com/intent/tweet?url=" +
						encodeURIComponent(item.Link) +
						"&text=" +
						encodeURIComponent(item.Name) +
						". " +
						encodeURIComponent(item.Description)
				);
			document
				.getElementById("shared-view-share-linkedin")
				.querySelector("a")
				.setAttribute(
					"href",
					"https://www.linkedin.com/shareArticle?mini=true&url=" +
						encodeURIComponent(item.Link) +
						"&title=" +
						encodeURIComponent(item.Name) +
						"&summary=" +
						encodeURIComponent(item.Description) +
						"&source=" +
						encodeURIComponent(productTitle)
				);
			document
				.getElementById("shared-view-share-facebook")
				.querySelector("a")
				.setAttribute("href", "https://www.facebook.com/sharer.php?u=" + encodeURIComponent(item.Link));

			// description
			var description = document.getElementById("meta-shared-view-description");
			if (item.Description !== null && item.Description !== "") {
				description.querySelector(".meta-item").textContent = item.Description;
				description.classList.add("is-visible");
			} else {
				description.querySelector(".meta-item").textContent = "";
				description.classList.remove("is-visible");
			}

			break;
		case "journey":
			break;
		case "trip":
			break;
	}

	metaPanels.forEach(function (panel) {
		metaPanel.querySelectorAll("." + panel).forEach(function (item) {
			if (item !== null) {
				if (activePanel !== null && activePanel === panel) {
					item.classList.add("is-visible");
				} else {
					item.classList.remove("is-visible");
				}
			}
		});
	});
}

export function clearStatusMessage(elem) {
	elem.textContent = "";
	elem.classList.remove("alert-danger");
	elem.classList.remove("alert-success");
	elem.classList.remove("is-visible");
}

function createSecondaryBreadcrumb(breadcrumbs) {
	// asset / action / grouping
	// add item / asset
	var nav = document.createElement("nav");
	nav.setAttribute("aria-label", "breadcrumb");
	var navList = document.createElement("ol");
	navList.className = "breadcrumb p-0 m-0";
	breadcrumbs.forEach(function (breadcrumb, index, list) {
		var navItem = document.createElement("li");
		var className = "breadcrumb-item";
		var isCurrent = false;
		if (index === list.length - 1) {
			className += " active";
			navItem.setAttribute("aria-current", "page");
			isCurrent = true;
		}
		navItem.className = className;
		if (!isCurrent) {
			var navItemLink = document.createElement("a");
			navItemLink.setAttribute("href", "#");
			Object.entries(breadcrumb.attributes).forEach(function ([key, val]) {
				navItemLink.setAttribute(key, val);
			});
			navItemLink.textContent = breadcrumb.label;
			navItemLink.callback = breadcrumb.callback;
			navItem.appendChild(navItemLink);
		} else {
			navItem.textContent = breadcrumb.label;
		}
		navList.appendChild(navItem);
	});
	nav.appendChild(navList);
	return nav;
}
