// Monolithic data structure to hold application state.
// See also state.js and user.js

const trkData = {
	softwarePackages: null,
	wizards: {
		sharedView: null,
	},
	search: {
		assets: null,
		places: null,
		fences: null,
		sharedViews: null,
		liveActivity: null,
		historyActivity: null,
	},
	pending: {
		live: false,
		events: false,
	},
	currentHeight: null,
	previousZoom: null,
	visible: {
		assets: [],
		groups: [],
		places: [],
		fences: [],
		trips: [],
	},

	assets: [],
	assetsById: {},
	markers: [],
	markersByAssetId: {},
	fenceMarkers: [],
	groups: [],
	groupsById: {},
	devices: [],
	devicesById: {},
	gatewayAccounts: [],
	fences: [],
	fenceGroups: [],
	fenceGroupUsers: [],
	fencesById: {},
	places: [],
	placeGroups: [],
	placeGroupUsers: [],
	placesById: {},
	journeys: [],
	journeysById: {},
	sharedViews: [],
	sharedViewsById: {},
	users: null,
	attributes: [],
	attributeGroups: [],
	assetUsers: [],
	assetForms: [],
	fenceUsers: [],
	assetGroupUsers: [],
	placeUsers: [],
	placeMarkers: [],
	live: {
		isInitialized: false,
		latestPosition: null,
		latestPositions: [],
		latestPositionsByAssetId: {},
		positions: [],
		normalizedPositions: [],
		positionsByAssetId: {},
		normalizedPositionsByAssetId: {},
		events: [],
		normalizedEvents: [],
		eventIds: [],
		normalizedEventIds: {},
		normalizedEventsByAssetId: {},
		markers: [],
		markersByAssetId: {},
		markersByPositionId: {},
		messageCounts: [],
		messageCountsByAssetId: {},
		messages: [],
		normalizedMessages: [],
		messagesByAssetId: {},
		normalizedMessagesByAssetId: {},
		eventFilters: ["all"],
		unreadNotifications: {
			positions: [],
			alerts: [],
			events: [],
			status: [],
			messages: [],
		},
		notificationTimesByAssetId: {},
		mapLinesByAssetId: {},
	},
	history: {
		isLimited: false,
		isLoadedLimitedData: false,
		limitedData: null,
		latestPosition: null,
		positions: [],
		normalizedPositions: [],
		positionsByAssetId: {},
		normalizedPositionsByAssetId: {},
		events: [],
		normalizedEvents: [],
		normalizedEventIds: {},
		normalizedEventsByAssetId: {},
		markers: [],
		markersByAssetId: {},
		markersByPositionId: {},
		messageCounts: [],
		messageCountsByAssetId: {},
		assetIdsWithResults: {},
		messages: [],
		normalizedMessages: [],
		messagesByAssetId: {},
		normalizedMessagesByAssetId: {},
		fromDate: null,
		toDate: null,
		eventFilters: ["all"],
		mapLinesByAssetId: {},
		markerClustersByAssetId: {},
	},
	activityById: {},
	activityByAssetId: {},
	positionsById: {},
	positionsByAssetId: {},
	eventsById: {},
	messagesById: {},
	trips: {
		positions: [],
		positionsByTripId: {},
		normalizedPositionsByTripId: {},
		events: [],
		eventsByTripId: {},
		normalizedEvents: [],
		normalizedEventsByTripId: {},
		normalizedEventIds: {},
		markers: [],
		markersByTripId: {},
		markersByPositionId: {}, // multiple trips can include the same position
		messageCounts: [],
		messageCountsByTripId: {},
		messages: [],
		normalizedMessages: [],
		messagesByTripId: {},
		normalizedMessagesByTripId: {},
		tripIdsWithResults: {},
		mapLinesByTripId: {},
		markerClustersByTripId: {},
	},
	sharedView: {
		isLimited: false,
		isLoadedLimitedData: false,
		limitedData: null,
		current: null,
		temp: null,
		events: [],
		positions: [],
		positionsByAssetId: {},
		normalizedPositions: [],
		normalizedPositionsByAssetId: {},
		normalizedEvents: [],
		//normalizedEventsBySharedViewId: {},
		markers: [],
		//markersBySharedViewId: {},
		markersByPositionId: {},
		messageCounts: {
			FromMobile: 0,
			ToMobile: 0,
			FromMobileChats: 0,
			ToMobileChats: 0,
			FromMobileDevice: 0,
			ToMobileDevice: 0,
		},
		//messageCountsBySharedViewId: {},
		isMessagesLoaded: false,
		normalizedMessages: [],
		//normalizedMessagesBySharedViewId: {},
		mapLinesByAssetId: {},
		markerClustersByAssetId: {},
		trips: {
			markers: [],
		},
		placeMarkers: [],
		fenceMarkers: [],
		priorMapType: null,
		priorIsSatelliteLabelOverlayEnabled: true,
		// separate cached containers from trkData on purpose
		positionsById: {},
		eventsById: {},
		messagesById: {},
		activityById: {},
		fromDate: null,
		toDate: null,
	},
	sharedViewUndo: null,
	behaviorAnalysis: {
		positions: [],
		events: [],
		markers: [],
	},
	followAssets: {
		positions: [],
		events: [],
		markers: [],
	},
	snapToRoadsQueue: [],
	tileLoadedQueue: [],
	waypoints: [],
	events: [],
	pendingEvents: [],
	alerts: [],
	eventFilters: ["all"],
	eventTypes: [],
	lastEventId: null,
	lastAlertId: null,
	queryingNotifications: false,
	lastNotificationId: null,
	quickMessages: [],
	messaging: {
		inbox: null,
		outbox: null,
	},
	validation: {
		addGroup: null,
		editAsset: null,
		editGroup: null,
		geofence: null,
		//place: null,
		dPlusQuery: null,
		dPlusInterval: null,
		idpAvlGeofence: null,
		idpAvlParameters: null,
		idpCoreParameters: null,
		idpIoParameters: null,
		idpMetersSet: null,
	},
	radar: {
		isActive: false,
		timer: null,
	},
	radarAustralia: {
		isActive: false,
		timer: null,
		basetime: null,
		issuetime: null,
	},
	worldIR: {
		isActive: false,
		timer: null,
	},
	maritime: { isActive: false },
	oil: { isActive: false },
	clouds: { isActive: false },
	weather: {
		geoJSON: null,
		gettingData: false,
		layer: null,
		isActive: false,
		request: null,
	},
	iridium: { isActive: false },
	iridiumnext: { isActive: false },
	globalstar: { isActive: false },
	inmarsat: { isActive: false },
	orbcomm: { isActive: false },
	geostationary: { isActive: false },
	traffic: { isActive: false },
	activeLayers: [],
	ruler: {
		isOpen: false,
		points: [],
		polygon: null,
	},
	routing: {
		isOpen: false,
		start: null,
		end: null,
		waypoints: [],
		isUsingMap: false,
		mapClickDestination: "waypoint",
	},
	zoom: {
		isActive: false,
		manager: null,
	},
	drawing: {
		isActive: false,
		manager: null,
	},
	searchPositionResults: null,
	searchPlaceResults: null,
	searchRouteResults: null,
	playback: {
		assetId: null,
		index: 0,
		positions: [],
	},
	satelliteMarkers: [],
	satelliteOrbits: [],
	satelliteIndex: [],
	mapLayers: {
		other: null,
		normal: null,
		sharedView: null,
	},
	isSatelliteLabelOverlayEnabled: true,
	routeLine: null,
	routeLineEncoded: null,
	routeLinesEncoded: null,
	routePolygon: null,
	systemNotifications: [],
	// TODO optimize these as lookup tables instead of arrays
	MESSAGES_TEXT: [0, 10, 11],
	MESSAGE_IDP_COMMAND_RESPONSE: 76,
	MSG_IDP_UPDATER_TERMINAL_INFO_RESPONSE: 165,
	MSG_IDP_UPDATER_STATE_RESPONSE: 166,
	tooltips: [],
	MAP_TYPES: {
		roadlight: 4,
		roaddark: 5,
		road: 0,
		satellite: 1,
		satellitealt: 6,
		openstreetmap: 3,
		terrain: 2,
	},
};

export default trkData;
