import strings from "./strings.js";
import trkData from "./data.js";
import state from "./state.js";
import options from "./options.js";
import { convertToLatLngPreference } from "./preferences.js";
import domNodes from "./domNodes.js";
import log from "./log.js";
import { map } from "./map-base.js";
import { markerUnhover } from "./marker-click.js";
import { createMarkerPath } from "./marker-path.js";
import { openDialogPanel } from "./panel-nav.js";
import { startChoosingMapLocation, stopChoosingMapLocation, updateChosenLocation } from "./map-chooselocation.js";
import { convertHexToSortable, convertNamedColorToHex } from "./color.js";
import { sortItemsByMode, resortItemGroup, sortByName, toggleItemSorting, sortGroups } from "./item-sorting.js";
import { sortModes } from "./const.js";
import { handleAjaxFormSubmission } from "./ajax.js";
import { isItemIncluded } from "./polyfills.js";
import { findGroupById, updateGroupVisibilityStatus } from "./asset-group.js";
import { assignItemToGroups } from "./common-group.js";
import { addGroupToGroupListInItemEditDialog } from "./group-list.js";
import { changePrimaryButtonLabel } from "./modal.js";
import { includeRowIfNotNull, createAccordionCard, createDialogTitleFragment } from "./dom-util.js";
import { getAttributesForType } from "./attributes.js";
import { uploadFile } from "./ajax.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import { addPlaceMarker, enablePlaceMarkerDraggable } from "./place-marker.js";
import { populateCustomAttributes } from "./attributes.js";
import user, { displayPreferencesAdd, displayPreferencesRemove } from "./user.js";
import { updateItemListingIcon } from "./item-listing.js";
import templates from "./templates.js";
import { closeSecondaryPanel } from "./panel.js";
import { markerClick } from "./marker-click.js";
import { updateItemVisibilityStatus } from "./asset-select.js";

import $ from "jquery";
import $j from "jquery";
import L from "leaflet";
import _ from "lodash";
import { el, text, setChildren } from "redom"; // https://redom.js.org/

/*global JsSearch */
// import JsSearch from '../js-search.js';

export function initPlaces() {
	$(domNodes.infoDialogs.mapItemInformation).on("click", "a.location", addOrUpdatePlaceClick);

	$(domNodes.dialogs.addPlace).on("click", "#RemovePlacePhoto", function (e) {
		e.preventDefault();
		$(this).next().val("true");
		$(this).parent().parent().hide();
	});
	$(domNodes.dialogs.addPlace).on("click", "#AddPlace", function (e) {
		e.preventDefault();

		var isFormValid = $(trkData.validation.placeAdd.currentForm).valid();
		if (!isFormValid) {
			trkData.validation.placeAdd.focusInvalid();
			return;
		}

		// submit position to pending
		var btn = this;
		var status = document.getElementById("add-place-status");

		var id = $("#hfPlaceId").val();
		var name = $("#txtPlaceName").val();
		var description = $("#txtPlaceDescription").val();
		var contact = $("#txtPlaceContact").val();
		var uniqueKey = $("#txtPlaceUniqueKey").val();
		var color = $("#form-add-place").find("input[name=rbEditPlaceColor]:checked").val();
		var lat = $("#hfPlaceLat").val();
		var lng = $("#hfPlaceLng").val();
		var userIds = new Array();
		$("#place-users input[name=EditPlaceUserIds]:checked").each(function (index, elem) {
			userIds.push($(this).val());
		});
		var attributes = getAttributesForType(2, "EditPlaceAttribute");
		var removePhoto = $("#edit-place-remove-photo").val() === "true";
		var groupIds = new Array();
		$j("#edit-place-groups-container input[name=EditGroupIds]:checked").each(function (index, elem) {
			groupIds.push($j(this).val());
		});

		if (state.isChoosingPlace) {
			var dataPost = {
				Name: name,
				Description: description,
				Contact: contact,
				Color: color,
				UniqueKey: uniqueKey,
				Location: { Lat: lat, Lng: lng },
				UserIds: userIds,
				Attributes: attributes,
				GroupIds: groupIds,
			};

			handleAjaxFormSubmission(
				"AddPlace",
				dataPost,
				btn,
				status,
				strings.MSG_ADD_PLACE_SUCCESS,
				strings.MSG_ADD_PLACE_ERROR,
				function (result) {
					try {
						// add place to map
						var place = result.Place;
						processNewPlace(place);

						if (trkData.placeUsers !== null) {
							trkData.placeUsers.push({ PlaceId: place.Id, UserIds: [] });
						}
						_.each(userIds, function (userId) {
							addUserToPlace(userId, place.Id);
						});

						uploadFile("fileEditPlacePhoto", "/Home/UploadPlacePhoto", { placeId: place.Id }, function (res) {
							if (res == "" || res == "empty") {
								// plugin does not handle empty result properly
								return;
							}
							res = JSON.parse(res);

							if (res.success == true) {
								if (res.type != null && res.type != "") {
									var item = findPlaceById(place.Id);
									item.PhotoType = res.type;
									if (
										domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
										parseInt(domNodes.panels.secondary.getAttribute("data-item-id")) === place.Id
									) {
										$j("#edit-place-photo")
											.find("img")
											.attr(
												"src",
												"/uploads/images/places/" + place.Id + "_thumb." + res.type + "?rnd=" + new Date().getTime()
											);
										$j("#edit-place-photo a").attr("href", "/uploads/images/places/" + place.Id + "." + res.type);
										$j("#edit-place-photo").show();
									}
									$("#fileEditPlacePhoto").val("").next(".custom-file-label").text(strings.NO_FILE_SELECTED);
								}
							}
						});

						trkData.validation.placeAdd.resetForm();
						trkData.validation.placeAdd.currentForm.reset();
					} catch (e) {
						console.error(e);
						throw e;
					}
				}
			);
		} else {
			var dataPost = {
				Id: id,
				Name: name,
				Description: description,
				Contact: contact,
				Color: color,
				UniqueKey: uniqueKey,
				Location: { Lat: lat, Lng: lng },
				UserIds: userIds,
				Attributes: attributes,
				RemovePhoto: removePhoto,
				GroupIds: groupIds,
			};

			handleAjaxFormSubmission(
				"UpdatePlace",
				dataPost,
				btn,
				status,
				strings.MSG_EDIT_PLACE_SUCCESS,
				strings.MSG_EDIT_PLACE_ERROR,
				function (result) {
					try {
						var place = result.Place;
						updatePlace(place);
						var previousUserIds = findPlaceUsersByPlaceId(id);
						if (previousUserIds != null) {
							if (previousUserIds.toString() != userIds.toString()) {
								let value;
								for (var i = 0; i < previousUserIds.length; i++) {
									value = previousUserIds[i];
									if ($j.inArray(value, userIds) == -1) {
										removeUserFromPlace(value, id);
										i--;
									}
								}

								$j.each(userIds, function (index, value) {
									if ($j.inArray(value, previousUserIds) == -1) {
										addUserToPlace(value, id);
									}
								});
							}
						}

						if (removePhoto) {
							var oldPlace = findPlaceById(place.Id);
							oldPlace.PhotoType = null;
						}

						uploadFile("fileEditPlacePhoto", "/Home/UploadPlacePhoto", { placeId: place.Id }, function (res) {
							if (res == "" || res == "empty") {
								// plugin does not handle empty result properly
								return;
							}
							res = JSON.parse(res);

							if (res.success == true) {
								if (res.type != null && res.type != "") {
									var item = findPlaceById(place.Id);
									item.PhotoType = res.type;
									if (
										domNodes.panels.secondary.getAttribute("data-group-for") === "dialog" &&
										parseInt(domNodes.panels.secondary.getAttribute("data-item-id")) === place.Id
									) {
										$j("#edit-place-photo")
											.find("img")
											.attr(
												"src",
												"/uploads/images/places/" + place.Id + "_thumb." + res.type + "?rnd=" + new Date().getTime()
											);
										$j("#edit-place-photo a").attr("href", "/uploads/images/places/" + place.Id + "." + res.type);
										$j("#edit-place-photo").show();
									}
									$("#fileEditPlacePhoto").val("").next(".custom-file-label").text(strings.NO_FILE_SELECTED);
								}
							}
						});
					} catch (e) {
						console.error(e);
						throw e;
					}
				}
			);
		}
	});
	$(domNodes.dialogs.addPlace).on("click", "#AddPlaceCancel", function (e) {
		e.preventDefault();
		trkData.validation.placeAdd.resetForm();
		trkData.validation.placeAdd.currentForm.reset();

		// if we're editing the place, cancel = dialog close
		if (state.isChoosingPlace) {
			$("#add-place-search").addClass("is-visible");
			$("#form-add-place").removeClass("is-visible");

			// remove the marker from the map
			stopChoosingMapLocation(state.mapClickHandlers.PLACE);
			startChoosingMapLocation(state.mapClickHandlers.PLACE);
		} else {
			document.getElementById("panel-dialog-back").click();
		}
	});

	$(domNodes.dialogs.addPlace).on("click", "#btnAddPlaceLatLng", function (e) {
		e.preventDefault();

		if (!state.isChoosingPlace) {
			return;
		}

		var isFormValid = $(trkData.validation.placeFind.currentForm).valid();
		if (!isFormValid) {
			trkData.validation.placeFind.focusInvalid();
			return;
		}

		var lat = $("#txtAddPlaceLat").val();
		var lng = $("#txtAddPlaceLng").val();

		var latLng = L.latLng(lat, lng);
		if (isNaN(latLng.lat) || isNaN(latLng.lng)) {
			return;
		}

		updateChosenLocation(latLng, state.mapClickHandlers.PLACE);
		map.panTo(latLng);
	});
}

export function findPlaceByLatLng(latLng) {
	var item = _.find(trkData.places, function (itm) {
		return itm.Location.Lat === latLng.lat && itm.Location.Lng === latLng.lng;
	});
	return item === undefined ? null : item;
}

export function findPlaceByUniqueKey(uniqueKey) {
	var item = _.find(trkData.places, { UniqueKey: uniqueKey });
	return item === undefined ? null : item;
}

export function findPlaceById(id) {
	var item = _.find(trkData.places, { Id: parseInt(id) });
	return item === undefined ? null : item;
}

export function populatePlaceInformation(place) {
	var info = [];

	if (place.Description !== null && place.Description !== "") {
		var desc = el("div.map-item-description", place.Description);
		info.push(desc);
	}

	var itemPhoto = null;
	if (place.PhotoType != null && place.PhotoType != "") {
		itemPhoto = el(
			"a",
			{ href: "/uploads/images/places/" + place.Id + "." + place.PhotoType, target: "_blank" },
			el("img", { src: "/uploads/images/places/" + place.Id + "_thumb." + place.PhotoType })
		);
	}

	// location table
	info.push(
		el(
			"table.map-item-details",
			el(
				"tbody",
				el("tr", [
					el("td.field", strings.LAT_LNG),
					el("td", convertToLatLngPreference(place.Location.Lat, place.Location.Lng, place.Grid)),
				])
			)
		)
	);

	var placeInfo = [];
	var generalinformation = _.compact([
		includeRowIfNotNull(strings.CONTACT, place.Contact),
		includeRowIfNotNull(strings.PHOTO, itemPhoto),
	]);
	if (generalinformation.length > 0) {
		placeInfo.push(
			createAccordionCard(
				"general",
				"place-information-accordion",
				strings.GENERAL_FIELDS,
				el("table", el("tbody", generalinformation))
			)
		);
	}

	placeInfo = _.compact(placeInfo.concat(populateCustomAttributes(place.Attributes, 2, "place-information-accordion")));
	if (placeInfo.length > 0) {
		info.push(el("#place-information-accordion.position-accordion.mt-1", placeInfo));
	}

	return el(".markercontent", info);
}

function showPlaceInformationOnMap(marker, place, isHover) {
	console.warn("showPlaceInformationOnMap");
	if (place === undefined || place === null) {
		return;
	}

	if (isHover === undefined || isHover === null) {
		isHover = false;
	}

	var isMarkerSelected = !isHover;

	// if there is already marker selected, and this is not it, do not show the information
	if (state.selectedMarker !== null) {
		if (state.selectedMarker !== marker) {
			return;
		}
		isMarkerSelected = true;
	}

	var latLng = marker.getLatLng();
	var dialog = $(domNodes.infoDialogs.mapItemInformation);
	dialog.data("placeId", place.Id);

	dialog.empty();
	dialog.append(populatePlaceInformation(place));

	// set zoom
	if (map.getZoom() < options.defaultZoom) {
		map.setView(latLng, options.defaultZoom);
	} else {
		map.panTo(latLng);
	}

	// titlebar
	dialog.dialog("option", "title", createDialogTitleFragment(place.Name));
	var dialogTitleBar = $("div.ui-dialog-titlebar", dialog.parent());
	var titleIcon = "url(" + createMarkerPath("Generic", place.Color, null, null, null, false, null, false, false) + ")";
	dialogTitleBar[0].style.backgroundImage = titleIcon;

	// place dialog
	var icon = marker._icon;
	var at = "right center";
	if (icon === undefined || icon === null) {
		// if there's no icon for this location then center it on the map
		// todo: place it at the appropriate location on the map instead of in the center by showing its marker
		icon = map._container;
		at = "center center";
	}

	// only move the dialog if its not already open
	if (!dialog.dialog("isOpen")) {
		dialog.dialog("option", "position", {
			my: "left center",
			at: at,
			of: icon,
			collision: "flipfit",
			within: document.getElementById("map"),
			using: function (param1, param2) {
				$(this).css(param1);
			},
		});
		dialog.dialog("open"); // dialog must be opened away from the marker so it can be moved without triggering
	} else {
		// dialog was already open but may have been resized due to content changes
		var parent = dialog.closest(".ui-dialog");
		parent.position({
			my: "left center",
			at: at,
			of: icon,
			collision: "flipfit",
			within: document.getElementById("map"),
			using: function (param1, param2) {
				// if the dialog is currently in the same horizontal position as before
				// then we will assume the user has not manually moved it and it can be
				// repositioned programmatically to fit centered vertically
				if (dialog.closest(".ui-dialog").position().left == param1.left) {
					// just move the dialog vertically
					$(this).css(param1);
				}
			},
		});
	}

	if (!isMarkerSelected) {
		dialog.dialog({ dialogClass: "no-close" });
	} else {
		dialog.dialog({ dialogClass: "" });
	}

	dialog.dialog("moveToTop");
	dialog.off("dialogclose");

	dialog.on("dialogclose", function (event, ui) {
		// todo: bug here when selecting locations on the map
		// previous, temporary dialog is closed
		// should only mark as selected/unselected if a permanent dialog is opened

		var isHover = $j(this).data("isHover");
		if (isHover) {
			return;
		}
		log("dialog close");
		marker.data.selected = false;
		markerUnhover(marker); // todo: fix this recursive bug

		state.selectedMarker = null;
	});
}

export function addOrUpdatePlaceClick(e) {
	console.warn("addOrUpdatePlaceClick");
	if (e !== null && e !== undefined) {
		e.preventDefault();
	}
	// open place marker, if it exists, otherwise new (Garmin Waypoint)
	var location = $(this).data("location");
	if (location !== undefined) {
		var name = $(this).data("name");
		var latlng = L.latLng(location.Lat, location.Lng);
		var place = findPlaceByLatLng(latlng);
		if (place == null) {
			openPlaceDialog(null);
			$("#txtPlaceName").val(name);
			updateChosenLocation(latlng, state.mapClickHandlers.PLACE);
		} else {
			showPlaceInformationOnMap(latlng, place);
		}
	} else {
		var lat = this.getAttribute("data-lat");
		var lng = this.getAttribute("data-lng");
		//var positionId = this.getAttribute('data-position-id');
		//var assetId = this.getAttribute('data-asset-id');
		var latlng = L.latLng(lat, lng);
		var place = findPlaceByLatLng(latlng);
		if (place == null) {
			openPlaceDialog(null);
			updateChosenLocation(latlng, state.mapClickHandlers.PLACE);
		} else {
			showPlaceInformationOnMap(latlng, place);
		}
	}
}

export function openPlaceDialog(place) {
	trkData.validation.placeFind.resetForm();
	trkData.validation.placeFind.currentForm.reset();
	trkData.validation.placeSearch.resetForm();
	trkData.validation.placeSearch.currentForm.reset();
	trkData.validation.placeAdd.resetForm();
	trkData.validation.placeAdd.currentForm.reset();

	var dialog = domNodes.dialogs.addPlace;
	var dialogTitle = place !== null ? strings.EDIT_PLACE : strings.ADD_PLACE;

	var buttonText = place !== null ? strings.SAVE_CHANGES : strings.CREATE_PLACE;
	changePrimaryButtonLabel(domNodes.dialogs.addPlace, buttonText);

	openDialogPanel(
		dialog,
		dialogTitle,
		place,
		false,
		function () {
			state.isChoosingPlace = false;
			stopChoosingMapLocation(state.mapClickHandlers.PLACE);

			// disable marker dragging
			if (place !== null) {
				var updatedPlace = findPlaceById(place.Id);
				var location = updatedPlace.Location;
				if (
					location.marker !== undefined &&
					location.marker !== null &&
					location.marker.data !== undefined &&
					location.marker.data.isMoved === true
				) {
					location.marker.draggable = false;
					// restore the marker to the original location, if not saved
					location.marker.geometry = [updatedPlace.Location.Lat, updatedPlace.Location.Lng];
				}
			}
		},
		"place",
		place !== null ? "edit-place" : null,
		place !== null ? openPlaceDialog : null
	);

	$("#edit-place-photo").hide();
	$("#edit-place-remove-photo").val("false");
	if (place == null) {
		// new place
		state.isChoosingPlace = true;
		startChoosingMapLocation(state.mapClickHandlers.PLACE);
		$("#accordion-edit-place-main-content").collapse("show");
		$("#add-place-search").addClass("is-visible");
		$("#form-add-place").removeClass("is-visible");
		$("#rbEditPlaceColor-red").prop("checked", true);
	} else {
		// editing existing place
		$("#add-place-search").removeClass("is-visible");
		$("#form-add-place").addClass("is-visible");

		// pan to current location and make place draggable
		var location = place.Location;
		if (location.marker !== undefined && location.marker !== null) {
			enablePlaceMarkerDraggable(location.marker);
		}

		var loc = L.latLng(location.Lat, location.Lng);
		map.panTo(loc);

		$("#add-place-location").text(convertToLatLngPreference(loc.lat, loc.lng, location.Grid));
		if (place.PhotoType != null && place.PhotoType != "") {
			$("#edit-place-photo img").attr(
				"src",
				"/uploads/images/places/" + place.Id + "_thumb." + place.PhotoType + "?rnd=" + new Date().getTime()
			);
			$("#edit-place-photo a").attr("href", "/uploads/images/places/" + place.Id + "." + place.PhotoType);
			$("#edit-place-photo").show();
		}
		$("#txtPlaceContact").val(place.Contact);
		$("#txtPlaceUniqueKey").val(place.UniqueKey);
		$("#txtPlaceName").val(place.Name);
		$("#txtPlaceDescription").val(place.Description);
		$("#rbEditPlaceColor-" + place.Color).prop("checked", true);
		$("#hfPlaceLat").val(place.Location.Lat);
		$("#hfPlaceLng").val(place.Location.Lng);
		$("#hfPlaceId").val(place.Id);

		if (place.Attributes != null) {
			for (var i = 0; i < place.Attributes.length; i++) {
				var attr = place.Attributes[i];
				$("#EditPlaceAttribute" + attr.Id).val(attr.Value);
			}
		}
	}

	// populate groups, along with selecting groups this item currently belongs to
	var cont = $j("#edit-place-groups-container");
	var groupContainer = document.getElementById("edit-place-groups-container");
	cont.empty();
	for (let group of sortGroups(trkData.placeGroups)) {
		addGroupToGroupListInItemEditDialog(group, 0, groupContainer, place);
	}

	$("#accordion-edit-place-users-head button").prop("disabled", !user.isAdmin);
	if (user.isAdmin) {
		var cont = document.getElementById("place-users");
		// populate users
		var inputs = [];
		for (var k = 0; k < trkData.users.length; k++) {
			const user = trkData.users[k];
			var isChecked = false;
			if (place != null) {
				for (var i = 0; i < trkData.placeUsers.length; i++) {
					if (trkData.placeUsers[i].PlaceId != place.Id) {
						continue;
					}

					for (var j = 0; j < trkData.placeUsers[i].UserIds.length; j++) {
						if (trkData.placeUsers[i].UserIds[j] == user.Id) {
							isChecked = true;
							break;
						}
					}
					break;
				}
			}
			var checkbox = el("input.custom-control-input", {
				type: "checkbox",
				id: "EditPlaceUser" + k,
				name: "EditPlaceUserIds",
				value: user.Id,
				checked: isChecked,
			});
			if (isChecked) {
				checkbox.setAttribute("checked", "checked");
			}
			inputs.push(
				el("div.custom-control.custom-checkbox", [
					checkbox,
					text(" "),
					el("label.custom-control-label", { for: "EditPlaceUser" + k }, user.Name + " (" + user.Username + ")"),
				])
			);
		}
		setChildren(cont, inputs);
	}
}

function updatePlace(place) {
	if (trkData.places === null) {
		return;
	}

	var oldPlace = findPlaceById(place.Id);
	assignItemToGroups(oldPlace.Id, place.GroupIds, "places");

	// replace place in trkData.places
	for (var i = 0; i < trkData.places.length; i++) {
		if (trkData.places[i].Id == place.Id) {
			trkData.places.splice(i, 1, place);
		}
	}

	trkData.places = trkData.places.sort(sortByName);

	var lis = domNodes.places[place.Id];
	if (lis !== undefined) {
		_.each(lis, function (li) {
			var name = li.querySelector(".place-name");
			name.textContent = place.Name;
			var description = li.querySelector(".place-description");
			description.textContent = place.Description;
		});
	}

	place.ColorSorted = convertHexToSortable(convertNamedColorToHex(place.Color));
	if (oldPlace.Color != place.Color) {
		// update color and paths
		var isActive = !isItemIncluded(user.displayPreferences.hiddenPlaces, place.Id);
		updatePlaceListingIcon(place.Id, place.Color, isActive);
	}

	// update icon on the map as the location may have moved
	// remove old marker, add new marker
	var wasDraggable = false;
	var oldMarker = null;
	if (trkData.placeMarkers != null) {
		for (var i = trkData.placeMarkers.length - 1; i >= 0; i -= 1) {
			var placeMarker = trkData.placeMarkers[i];
			var placeId = placeMarker.data.placeId;
			if (placeId == place.Id) {
				oldMarker = placeMarker;

				wasDraggable = placeMarker.draggable;

				removeItemFromMap(placeMarker);
				trkData.placeMarkers.splice(i, 1);
			}
		}
	}
	var point = [place.Location.Lat, place.Location.Lng];
	var newPlaceMarker = addPlaceMarker(point, place.Location, place);
	if (wasDraggable) {
		enablePlaceMarkerDraggable(place.Location.marker);
	}

	// if place is highlighted, refresh it
	if (state.selectedMarker === oldMarker) {
		markerClick(newPlaceMarker, "place", point, false); // convenience, but really should just reload contents and not pan/zoom
	}

	resortItemGroup("all-places");
	indexPlacesForSearch();
}

export function processNewPlace(place) {
	if (trkData.places == null) {
		return;
	}

	place.ColorSorted = convertHexToSortable(convertNamedColorToHex(place.Color));
	trkData.places.push(place);
	trkData.placesById[place.Id] = place;
	addPlace(place);
	togglePlaceActive(place.Id, true, true);

	document.getElementById("no-places").classList.remove("is-visible");
	document.getElementById("places-all").classList.add("is-visible");
	document.getElementById("filter-places").querySelector(".filter-box").classList.add("is-visible");
}

function addPlace(place) {
	var groups = _.map(place.GroupIds ? place.GroupIds : [], function (groupId) {
		return findGroupById(groupId, "places");
	});
	groups.push(findGroupById("all-places", "places"));

	var itemNode = createPlaceNode(place);
	domNodes.places[place.Id] = [];
	_.each(groups, function (group) {
		const nodeCpy = itemNode.cloneNode(true);
		domNodes.places[place.Id].push(nodeCpy);
		var groupContentsNode = domNodes.groupContents[group.Id].querySelector("ul.group-list-list");

		const groupItems = sortItemsByMode("places", trkData.places, group.Id, "places");
		if (groupItems.length > 1) {
			var itemIndex = _.indexOf(groupItems, place);
			var subgroups = group !== null ? groups.length : 0;
			groupContentsNode.insertBefore(nodeCpy, groupContentsNode.children[itemIndex + subgroups]);
		} else {
			groupContentsNode.appendChild(nodeCpy);
		}
		updateGroupVisibilityStatus(group.Id, "places");
	});

	var point = [place.Location.Lat, place.Location.Lng];
	addPlaceMarker(point, place.Location, place);
	toggleItemSorting("places", user.displayPreferences.sortMode.places === sortModes.CUSTOM);

	document.getElementById("no-places").classList.remove("is-visible");
	document.getElementById("places-all").classList.add("is-visible");
	document.getElementById("filter-places").querySelector(".filter-box").classList.add("is-visible");
}

export function togglePlaceActive(placeId, makePlaceActive, updateUI) {
	var isCurrentlyActive = !isItemIncluded(user.displayPreferences.hiddenPlaces, placeId);
	if (makePlaceActive == null) makePlaceActive = !isCurrentlyActive;

	if (isCurrentlyActive == makePlaceActive) return;

	var place = findPlaceById(placeId);
	let color = place.Color;

	if (trkData.placeMarkers != null) {
		for (var i = 0; i < trkData.placeMarkers.length; i++) {
			var markerPlaceId = trkData.placeMarkers[i].data.placeId;
			if (markerPlaceId == placeId) {
				if (makePlaceActive) {
					addItemToMap(trkData.placeMarkers[i]);
				} else {
					removeItemFromMap(trkData.placeMarkers[i]);
				}
			}
		}
	}

	// change icon and set preferences
	if (makePlaceActive) {
		updatePlaceListingIcon(placeId, color, true);
		displayPreferencesRemove("hiddenPlaces", placeId);
	} else {
		updatePlaceListingIcon(placeId, color, false);
		displayPreferencesAdd("hiddenPlaces", placeId);
	}

	// ensure checkbox is proper
	updateItemVisibilityStatus(placeId, "places", makePlaceActive);
	if (updateUI) {
		_.each(place.GroupIds, function (assetGroupId) {
			updateGroupVisibilityStatus(assetGroupId, "places");
		});
		updateGroupVisibilityStatus("all-places");
	}
}

function findPlaceUsersByPlaceId(id) {
	if (trkData.placeUsers == null) return null;
	for (var i = 0, len = trkData.placeUsers.length; i < len; i++) {
		if (trkData.placeUsers[i].PlaceId == id) {
			return trkData.placeUsers[i].UserIds;
		}
	}
}

function addUserToPlace(userId, placeId) {
	if (trkData.placeUsers == null) return;
	for (var i = 0; i < trkData.placeUsers.length; i++) {
		if (trkData.placeUsers[i].PlaceId == placeId) {
			trkData.placeUsers[i].UserIds.push(userId);
			break;
		}
	}
}

function removeUserFromPlace(userId, placeId) {
	if (trkData.placeUsers == null) return;
	for (var i = 0; i < trkData.placeUsers.length; i++) {
		if (trkData.placeUsers[i].PlaceId == placeId) {
			for (var j = 0; j < trkData.placeUsers[i].UserIds.length; j++) {
				if (userId == trkData.placeUsers[i].UserIds[j]) {
					trkData.placeUsers[i].UserIds.splice(j, 1);
					break;
				}
			}
			break;
		}
	}
}

function updatePlaceListingIcon(id, color, isActive) {
	updateItemListingIcon(id, color, isActive, "Generic", domNodes.places);
}

export function indexPlacesForSearch() {
	trkData.search.places = new JsSearch.Search("Id");
	trkData.search.places.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
	var attributes = ["Name", "Description", "UniqueKey", "Contact"];
	// todo: location?
	_.each(attributes, function (attribute) {
		trkData.search.places.addIndex(attribute);
	});
	trkData.search.places.addDocuments(trkData.places);
	log("Places indexed for search filtering.");
}

export function createPlaceNode(place) {
	var color = "red";
	if (place.Color != null) {
		color = place.Color;
	}

	var isActive = !isItemIncluded(user.displayPreferences.hiddenPlaces, place.Id);
	if (isActive) {
		trkData.visible.places.push(place.Id);
	}
	var alpha = isActive ? null : 50;
	var imagePath = createMarkerPath("Generic", color, null, alpha, null, true);
	////var li = $j('<li class="places-item" data-place-id="' + place.Id + '" style="background-image: url(' + imagePath + '); background-position: 10px -6px; background-repeat: no-repeat;">' +
	////    '<input type="checkbox" class="showhide" data-place-id="' + place.Id + '" id="place-' + place.Id + '" checked="checked" /><span class="place-name" for="place-' + place.Id + '">' + place.Name + '</span>' +
	////    '<div class="edit-place"><a href="#"><span class="t-icon t-icon-context"></span></a></div>' +
	////    '</li>');

	var placeDto = {
		id: place.Id,
		name: place.Name,
		color: place.Color,
		description: place.Description,
		icon: imagePath,
		isVisible: !isItemIncluded(user.displayPreferences.hiddenPlaces, place.Id),
		visibilityClass: !isItemIncluded(user.displayPreferences.hiddenPlaces, place.Id) ? "active" : "disabled",
	};

	var fragment = templates.place(placeDto);
	return fragment.childNodes[0];

	// var placeLi = document.createElement("li");
	// placeLi.className = "places-item group-item" + (isActive ? " active" : " disabled");
	// placeLi.setAttribute("data-place-id", place.Id);
	// placeLi.style.cssText = "background-image: url(" + imagePath + ");"; // background-position: 10px -6px; background-repeat: no-repeat;';
	// var placeShowHide = document.createElement("input");
	// placeShowHide.setAttribute("type", "checkbox");
	// placeShowHide.className = "showhide";
	// placeShowHide.setAttribute("data-place-id", place.Id);
	// placeShowHide.setAttribute("id", "place-" + place.Id);
	// if (isActive) {
	// 	placeShowHide.setAttribute("checked", "checked");
	// }
	// var placeName = document.createElement("span");
	// placeName.className = "place-name";
	// placeName.setAttribute("for", "place-" + place.Id);
	// placeName.textContent = place.Name;
	// //var placeEdit = document.createElement('div');
	// //placeEdit.className = 'edit-place';
	// var placeEditLink = document.createElement("a");
	// placeEditLink.setAttribute("href", "#");
	// placeEditLink.className = "edit-place t-icon t-icon-context";
	// //var placeEditIcon = document.createElement('span');
	// //placeEditIcon.className = 't-icon t-icon-context';
	// //placeEditLink.appendChild(placeEditIcon);
	// //placeEdit.appendChild(placeEditLink);
	//
	// placeLi.appendChild(placeShowHide);
	// placeLi.appendChild(placeName);
	// //placeLi.appendChild(placeEdit);
	// placeLi.appendChild(placeEditLink);
	//
	// return placeLi;
}

export function deletePlace(place) {
	if (
		domNodes.infoDialogs.mapItemInformation.data !== undefined &&
		domNodes.infoDialogs.mapItemInformation.data !== null &&
		domNodes.infoDialogs.mapItemInformation.data.placeId === place.Id &&
		$(domNodes.infoDialogs.mapItemInformation).dialog("isOpen")
	) {
		$(domNodes.infoDialogs.mapItemInformation).dialog("close");
	}

	_.each(domNodes.places[place.Id], function (placeNode) {
		placeNode.parentNode.removeChild(placeNode);
	});
	var panel = domNodes.panels.secondary;
	if (panel.getAttribute("data-group-for") === "places" && parseInt(panel.getAttribute("data-item-id")) === place.Id) {
		closeSecondaryPanel();
	}

	if (trkData.placeUsers != null) {
		for (var i = trkData.placeUsers.length - 1; i >= 0; i -= 1) {
			if (trkData.placeUsers[i].PlaceId == place.Id) {
				trkData.placeUsers.splice(i, 1);
			}
		}
	}

	// remove place marker
	if (trkData.placeMarkers != null) {
		for (var i = trkData.placeMarkers.length - 1; i >= 0; i -= 1) {
			//var placeId = $j.data(trkData.placeMarkers[i], 'placeId');
			var placeId = trkData.placeMarkers[i].data.placeId;
			if (placeId == place.Id) {
				removeItemFromMap(trkData.placeMarkers[i]);
				trkData.placeMarkers.splice(i, 1);
			}
		}
	}

	// remove from trkData.places
	for (var i = 0; i < trkData.places.length; i++) {
		if (trkData.places[i].Id == place.Id) trkData.places.splice(i, 1);
	}

	// if last fence, show no places
	if (trkData.places.length == 0) {
		document.getElementById("no-places").classList.add("is-visible");
		document.getElementById("places-all").classList.remove("is-visible");
		document.getElementById("filter-places").querySelector(".filter-box").classList.remove("is-visible");
	}
	updateGroupVisibilityStatus("all-places");
	indexPlacesForSearch();
}