import strings from "./strings.js";
import options from "./options.js";
import domNodes from "./domNodes.js";
import trkData from "./data.js";
import user from "./user.js";
import state from "./state.js";
import { changePrimaryButtonLabel } from "./modal.js";
import { openDialogPanel } from "./panel-nav.js";
import { populateGroupList } from "./group-list.js";
import { sortGroups } from "./item-sorting.js";
import { populateCheckboxList } from "./dom-util.js";
import { populateGroupDialog } from "./asset-group.js";
import { displayPreferencesRemove } from "./user.js";
import { updateGroupVisibilityStatus, createGroupColorStyles, addItemToGroup } from "./asset-group.js";
import { findPlaceById } from "./place.js";
import { findFenceById } from "./fence.js";

import $ from "jquery";
import _ from "lodash";

/** For place and fence groups */
export function openItemGroupDialog(group, groupFor = group.Type) {
    const groups = groupFor === "places" ? trkData.placeGroups
        : groupFor === "fences" ? trkData.fenceGroups
        : [];
    const groupUsers = groupFor === "places" ? trkData.placeGroupUsers
        : groupFor === "fences" ? trkData.fenceGroupUsers
        : [];
    const groupIdFieldInGroupUsers = groupFor === "places" ? 'PlaceGroupId'
        : groupFor === "fences" ? 'FenceGroupId'
        : [];
    const items = groupFor === "places" ? trkData.places
        : groupFor === "fences" ? trkData.fences
        : [];

	if (options.enabledFeatures.indexOf("UI_GROUPS") === -1) {
		return;
	}
    const itemType = groupFor.substring(0, groupFor.length-1); // e.g. "place" or "fence"

	const dialog = domNodes.dialogs.editGroup;
	const isEditing = !!group;
	state.groupDialog.isEditing = isEditing;
	state.groupDialog.type = itemType;

    const itemStrings = groupFor === "places" ? { name: strings.PLACES, edit: strings.EDIT_PLACE_GROUP, add: strings.ADD_PLACE_GROUP }
        : groupFor === "fences" ? { name: strings.GEOFENCES, edit: strings.EDIT_GEOFENCE_GROUP, add: strings.ADD_GEOFENCE_GROUP }
        : {}; 

	const buttonText = isEditing ? strings.SAVE_CHANGES : strings.CREATE_GROUP;
	changePrimaryButtonLabel(dialog, buttonText);

	if (!isEditing) {
		$("#accordion-edit-asset-group-main-content").collapse("show");
	}

	$(".section-asset-group").hide();
	$("#edit-group-item-name").text(itemStrings.name);

	trkData.validation.addGroup.resetForm();
	trkData.validation.addGroup.currentForm.reset();

	populateGroupList(sortGroups(groups));
	$("#edit-asset-group-accordion .primary-card button").removeClass("disabled");
	const dialogTitle = isEditing ? itemStrings.edit : itemStrings.add;
	if (isEditing) {
		$("#asset-group-parent").hide();
		$(dialog).data("groupId", group.Id);
	} else {
		$("#asset-group-parent").show();
	}

	if (!user.isAdmin) {
		$("#accordion-edit-asset-group-users-head button").addClass("disabled");
	}

	// checkbox lists...
	var userList = user.isAdmin ? trkData.users : [];
	populateCheckboxList(
		"edit-asset-group-users-list",
		userList,
		"EditAssetGroupUserIds",
		function (item) {
			if (group === null) {
				return false;
			}
			var itemUsers = groupUsers.find(gu => gu[groupIdFieldInGroupUsers] === group.Id);
			if (itemUsers === undefined) {
				return false;
			}
			return itemUsers.UserIds.indexOf(item.Id) >= 0;
		},
		function (item) {
			return item.Name;
		},
		"users",
		function (item) {
			return item.Username;
		}
	);

	populateCheckboxList(
		"edit-asset-group-assets-list",
		items,
		"EditAssetGroupAssetIds",
		function (item) {
			if (group === null) {
				return false;
			}
			return item.GroupIds.indexOf(group.Id) !== -1;
		},
		function (item) {
			return item.Name;
		},
		groupFor,
		function (item) {
			return item.Description;
		}
	);

	if (group !== null) {
		populateGroupDialog(group);
	}

	openDialogPanel(
		domNodes.dialogs.editGroup,
		dialogTitle,
		group,
		false,
		null,
		itemType + "-group",
		isEditing ? "edit-group" : null,
		isEditing ? openItemGroupDialog : null
	);
	document.getElementById("txtGroupName").focus();
}

/** For place and fence groups */
export function assignItemToGroups(itemId, groupIds, groupFor) {
    const item = 
        groupFor === "places" ? findPlaceById(itemId)
        : groupFor === "fences" ? findFenceById(itemId)
        : null;
	if (item === null) {
		return;
	}
    const itemType = groupFor.substring(0, groupFor.length-1); // e.g. "place" or "fence"

	const groupsRemoved = _.difference(item.GroupIds, groupIds);
	_.each(groupsRemoved, function (groupId) {
		domNodes.groupContents[groupId].querySelector('.' + itemType + '-' + itemId).remove();
	});

	const groupsAdded = _.difference(groupIds, item.GroupIds);
	_.each(groupsAdded, function (groupId) {
		addItemToGroup(item, groupId, groupFor);
	});

	item.GroupIds = groupIds;

	_.each(_.concat(groupsRemoved, groupsAdded), function (groupId) {
		updateGroupVisibilityStatus(groupId, groupFor);
	});
}

/** For place and fence groups */
export function deleteItemGroup(deleted) {
	// remove group from UI
	domNodes.groups[deleted.Id].remove();
	delete domNodes.groups[deleted.Id];
	delete domNodes.groupColors[deleted.Id];
	delete domNodes.groupContents[deleted.Id];

    const [items, groups, groupUsers] = 
        deleted.Type === "places" ? [trkData.places, trkData.placeGroups, trkData.placeGroupUsers]
        : deleted.Type === "fences" ? [trkData.fences, trkData.fenceGroups, trkData.fenceGroupUsers]
        : [[], [], []];

	// remove group from trkData.groups
	const idx = groups.findIndex(g => g.Id === deleted.Id);
	if (idx >=0 ) {
		groups.splice(idx, 1);
	}

	var modifiedGroupIds = [];
	_.each(groups, function (group) {
		if (_.indexOf(group.GroupIds, deleted.Id)) {
			group.GroupIds = _.without(group.GroupIds, deleted.Id);
			modifiedGroupIds.push(group.Id);
		}
	});

	if (groupUsers != null) {
        const idx = groupUsers.findIndex(gu => gu.AssetGroupId === deleted.Id);
        if (idx >= 0) groupUsers.splice(idx, 1);
	}

	for (let item of items) {
        const idx = item.GroupIds.findIndex(gId => gId === deleted.Id);
        if (idx >= 0) item.GroupIds.splice(idx, 1);
	}

	// remove group expanded preference
	displayPreferencesRemove("expandedGroups", deleted.Id);

	// update group status for parent groups
	_.each(modifiedGroupIds, function (groupId) {
		updateGroupVisibilityStatus(groupId, deleted.Type);
	});
}

/** For place and fence groups */
export function updateItemGroup(updated) {
	domNodes.groupColors[updated.Id] = updated.Color;

	var li = domNodes.groups[updated.Id];
	if (li !== undefined) {
		li.style.borderColor = updated.Color;
		var name = li.querySelector(".group-name");
		name.textContent = updated.Name;
	}

	createGroupColorStyles();
}
