import trkData from "./data.js";
import state from "./state.js";
import options from "./options.js";
import user from "./user.js";
import domNodes from "./domNodes.js";
import { mapModes, panels } from "./const.js";
import { changeActivePanel } from "./panel.js";
import { switchMapMode } from "./map-base.js";
import { updateGroupVisibilityStatus } from "./asset-group.js";
import { wrapUrl } from "./wrapurl.js";
import { intervals } from "./timers.js";
import { setUrlHistoryForActivePanel } from "./panel-browser-history.js";
import { openSharedViewDialog } from "./shared-view-dialog.js";
import { openAssetGroupDialog } from "./asset-group.js";
import { openPlaceDialog } from "./place.js";
import { updateActiveAssetInformation } from "./assets-active.js";
import { openEditAssetDialog } from "./asset-edit-dialog.js";
import { openGeofenceDialog } from "./fence.js";
import { toggleAssetActive } from "./asset-select.js";
import { queryVisibleTrips } from "./trips.js";
import { queryActiveAssets } from "./assets-active.js";
import { openItemGroupDialog } from "./common-group.js";

import _ from "lodash";

export function initWindowLoad() {
	// TODO handle url actions better
	var preventPanelChange = false;
	var postLoadAction = null;
	var keepPrimaryPanelHidden = window.screen.width <= 768;
	if (window.location.hash !== "") {
		preventPanelChange = true;
		var hash = window.location.hash;
		if (hash.substring(0, 1) === "#") {
			hash = hash.substring(1);
		}
		var hashItems = hash.split("/");
		var activePanel = hash;
		if (hashItems.length > 1) {
			options.defaultMode = hashItems[0];
			activePanel = hashItems[1];
			if (hashItems.length > 2) {
				postLoadAction = hashItems[2];
			}
		}
		switch (activePanel) {
			case "journeys":
				changeActivePanel(panels.JOURNEYS, keepPrimaryPanelHidden);
				break;
			case "places":
				changeActivePanel(panels.PLACES, keepPrimaryPanelHidden);
				break;
			case "fences":
				changeActivePanel(panels.GEOFENCES, keepPrimaryPanelHidden);
				break;
			case "shared-views":
				changeActivePanel(panels.SHARED_VIEWS, keepPrimaryPanelHidden);
				break;
			case "live":
			case "history":
			case "assets":
				changeActivePanel(panels.ASSETS, keepPrimaryPanelHidden);
				break;
			//case '#panel-live':
			//    options.defaultMode = 'live';
			//    changeActivePanel(panels.LIVE);
			//    break;
			//case '#panel-history':
			//    options.defaultMode = 'history';
			//    changeActivePanel(panels.HISTORY);
			//    break;
			case "action-add-asset":
			case "action-add-group":
			case "action-add-fence":
			case "action-add-place":
			case "action-add-shared-view":
			case "action-add-fencegroup":
			case "action-add-placegroup":
				preventPanelChange = false;
				postLoadAction = activePanel;
				break;
			default:
				preventPanelChange = false;
				break;
		}
	}

	var deferreds = [];

	if (
		(user.isAnonymous &&
			options.showAssetId == "" &&
			options.showAssetUniqueId == "" &&
			options.defaultMode !== "live") ||
		options.defaultMode === "history"
	) {
		// anonymous users not highlighting an asset start with history view
		// as well as anyone with the history default mode preference

		deferreds.push(switchMapMode(mapModes.HISTORY, null, true)); // promise

		// automatically requery the history for embedded views
		// todo: this will instead be follow asset mode with a specific asset and date range?
		if (user.isAnonymous) {
			intervals.history = setInterval(requeryHistory, 1800000);
		}
	} else if (options.showSharedViewId !== null) {
		//changeActivePanel(panels.SHARED_VIEWS, keepPrimaryPanelHidden);
		//var sharedView = findSharedViewById(options.showSharedViewId);
		//selectSharedView(sharedView);
		//preventPanelChange = true;
		//postLoadAction = null;
		//// we always have to choose a map mode for normal view
		//var isSharedViewMapModeLive = sharedView.Preferences.DefaultMode === mapModes.LIVE;
		//switchMapMode(isSharedViewMapModeLive, null, !isSharedViewMapModeLive, preventPanelChange);
	} else if (options.showPositionId != "") {
		// loading a history view to highlight a specific position
		if (options.showAssetId != "") {
			// make all other assets inactive
			for (var i = 0; i < trkData.assets.length; i++) {
				var makeActive = trkData.assets[i].Id == options.showAssetId;
				toggleAssetActive(trkData.assets[i].Id, makeActive, false);
			}

			_.each(trkData.groups, function (group) {
				updateGroupVisibilityStatus(group.Id);
			});
			updateGroupVisibilityStatus("all-assets");
			updateActiveAssetInformation();
		}
		deferreds.push(switchMapMode(mapModes.HISTORY, null, true)); // promise
	} else {
		deferreds.push(switchMapMode(mapModes.LIVE, null, false)); // promise
	}

	if (!preventPanelChange) {
		changeActivePanel(state.activePanel, keepPrimaryPanelHidden);
	}

	deferreds.push(queryVisibleTrips());

	// TODO handle url actions better
	Promise.all(deferreds).then(function () {
		if (options.isCompact) {
			// this need to be done after all data load or history view change needs to check for first load
			domNodes.mapTools.container.classList.add("is-closed");
			domNodes.mapMode.container.classList.add("is-minimized");
			domNodes.mapMode.dateRange.classList.remove("is-visible");
		}

		if (postLoadAction !== null) {
			switch (postLoadAction) {
				case "add":
					switch (state.activePanel) {
						case panels.ASSETS:
							openEditAssetDialog(null);
							setUrlHistoryForActivePanel("add");
							break;
						case panels.FENCES:
							openGeofenceDialog(null);
							setUrlHistoryForActivePanel("add");
							break;
						case panels.PLACES:
							openPlaceDialog(null);
							setUrlHistoryForActivePanel("add");
							break;
						case panels.JOURNEYS:
							break;
					}
					break;
				case "add-group":
					openAssetGroupDialog(null);
					setUrlHistoryForActivePanel("add-group");
					break;
				case "action-add-asset":
					openEditAssetDialog(null);
					setUrlHistoryForActivePanel("add");
					break;
				case "action-add-group":
					openAssetGroupDialog(null);
					setUrlHistoryForActivePanel("add-group");
					break;
				case "action-add-fence":
					openGeofenceDialog(null);
					setUrlHistoryForActivePanel("add");
					break;
				case "action-add-fencegroup":
				case "add-fencegroup":
					openItemGroupDialog(null, "fences");
					setUrlHistoryForActivePanel("add-fencegroup");
					break;
				case "action-add-place":
					openPlaceDialog(null);
					setUrlHistoryForActivePanel("add");
					break;
				case "action-add-placegroup":
				case "add-placegroup":
					openItemGroupDialog(null, "places");
					setUrlHistoryForActivePanel("add-placegroup");
					break;
				case "action-add-shared-view":
					openSharedViewDialog(null);
					setUrlHistoryForActivePanel("add");
					break;
			}
		}

		state.isFirstLoad = false;

		fetch(wrapUrl("/api/ui/ml"), {
			method: "POST",
			body: JSON.stringify({ Uid: user.id }),
			headers: new Headers({
				"Content-Type": "application/json; charset=utf-8",
			}),
		});
	});
}

function requeryHistory() {
	if (state.activeMapMode === mapModes.LIVE || trkData.history.toDate !== "") {
		clearInterval(intervals.history);
		return;
	}

	queryActiveAssets();
}
