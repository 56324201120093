import trkData from "./data.js";
import options from "./options.js";
import user from "./user.js";

import { EVENTS_STATUS, EVENTS_TEXT_MESSAGE, EVENTS_EMERGENCY, EVENTS_ALERT } from "./asset-events.js";

export function getDisplayFilterForEventType(type) {
	switch (type) {
		case "activity":
			return function (item) {
				// basically a duplicate of the below filters, but I don't see a way around it
				if (item.Chat !== undefined) {
					var isChatDisabled = user.isAnonymous && !options.allowAnonymousMessaging;
					return !isChatDisabled && item.Chat !== undefined;
				} else if (item.Event !== undefined) {
					if (item.Event.Hide) {
						return false;
					}
					var hideAlertTriggeredEvents = options.hideAlertTriggeredEvents || user.isAnonymous;
					var hideEmergencyEvents = options.hideEmergencyEvents || user.isAnonymous;
					var isAlertEvent = EVENTS_ALERT.indexOf(item.Event.Type) !== -1;
					if (isAlertEvent && hideAlertTriggeredEvents) {
						return false;
					}
					var isEmergencyEvent = EVENTS_EMERGENCY.indexOf(item.Event.Type) !== -1;
					if (isEmergencyEvent && hideEmergencyEvents) {
						return false;
					}
					if (item.Event.Type === 268) {
						// SL Summary
						return false;
					}
					if (EVENTS_TEXT_MESSAGE.indexOf(item.Event.Type) !== -1) {
						return false;
					}
				}
				return true;
			};
		case "chat":
			return function (item) {
				var isChatDisabled = user.isAnonymous && !options.allowAnonymousMessaging;
				return !isChatDisabled && item.Chat !== undefined;
			};
		case "messages":
			return function (item) {
				return item.Chat === undefined && item.Message !== undefined;
			};
		case "positions":
			return function (item) {
				return item.Position !== undefined;
			};
		case "status":
			return function (item) {
				return !item.Event.Hide && EVENTS_STATUS.indexOf(item.Event.Type) !== -1;
			};
		case "alerts":
			return function (item) {
				if (item.Event.Hide) {
					return false;
				}
				var hideAlertTriggeredEvents = options.hideAlertTriggeredEvents || user.isAnonymous;
				var hideEmergencyEvents = options.hideEmergencyEvents || user.isAnonymous;
				var isAlertEvent = EVENTS_ALERT.indexOf(item.Event.Type) !== -1;
				if (isAlertEvent && hideAlertTriggeredEvents) {
					return false;
				}
				var isEmergencyEvent = EVENTS_EMERGENCY.indexOf(item.Event.Type) !== -1;
				if (isEmergencyEvent && hideEmergencyEvents) {
					return false;
				}
				return isAlertEvent || isEmergencyEvent;
			};
		case "events":
		default:
			return function (item) {
				return (
					!item.Event.Hide &&
					item.Event.Type !== 268 && // SL Summary
					EVENTS_STATUS.indexOf(item.Event.Type) === -1 &&
					EVENTS_ALERT.indexOf(item.Event.Type) === -1 &&
					EVENTS_EMERGENCY.indexOf(item.Event.Type) === -1 &&
					EVENTS_TEXT_MESSAGE.indexOf(item.Event.Type) === -1
				); // because it will be a chat item?
			};
	}
}
