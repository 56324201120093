import { createMarkerPath } from "./marker-path.js";
import { map } from "./map-base.js";
import {convertNamedColorToArray} from "./color.js";

import GleoSymbol from "./gleo/src/symbols/Symbol.mjs";
import HeadingTriangle from "./gleo/src/symbols/HeadingTriangle.mjs";
import TintedSprite from "./gleo/src/symbols/TintedSprite.mjs";
import bouncify from "./gleo/src/symboldecorators/bouncify.mjs";
// import HTMLPin from "./gleo/src/pin/HTMLPin.mjs";
// import LatLng from "./gleo/src/geometry/LatLng.mjs";

import parseColour from "./gleo/src/3rd-party/css-colour-parser.mjs";

import L from "leaflet";

const BouncySprite = bouncify(TintedSprite);

/**
 * Similar to a Gleo MultiSymbol, but specific to assets. Groups together:
 * - A sprite for the asset "at rest", i.e. not bouncing
 * - A sprite for the asset when selected, i.e. bouncing
 * - A sprite for the asset's event/status
 * - A HeadingTriangle for the heading, only for some asset types
 * - Functionality to get a popup/tooltip HTML anchor
 *
 */

export default class AssetSprite extends GleoSymbol {
	#spriteBase;
	#spriteBouncing;
	#headingTriangle;
	#spriteStatus;

	#bouncing = false;
	#tintBase = false;

	#assetId;
	#isFirst;
	#isLast;
	#heading;

	constructor(geom,
				type = "Generic",
				colour = "red",
				heading = undefined,	/// A number from 0 to 360 (inclusive). 0 means no arrow; otherwise degrees clockwise from north. Use 360 for north.
				alpha = 255,
				id = undefined,	/// TODO
				evtType = undefined,
				isFirst = undefined,	/// TODO
				isLast = undefined,	/// TODO
	) {
		super();

		// Sanitize parameter - some other parts of the system expect an empty string
		if (heading === "") {
			heading = undefined;
		}

		this.#assetId = id;
		this.#heading = heading;
		this.#isFirst = isFirst;
		this.#isLast = isLast;
		this.geometry = geom;

		if (evtType) {

			let statusSpriteOffset;

			// See also the EVENTS_* constants in asset-events.js

			switch(evtType) {

				case 1: // "Start": green circle - no longer used?
				case 2: // "Stop": red circle - no longer used?
					break;

				case 133:	// "Low Battery"
					statusSpriteOffset = [80, 160];
					break;

				case 274:	// Eye alert, image response
				case 275:	// Eye alert, immediate image
					statusSpriteOffset = [0, 160];	// Camera, grey lens
					break;

				case 272:	// Eye alert, driver fatigue
				case 273:	// Eye alert, fatigue image
					statusSpriteOffset = [240,80];	// Camera, red lens
					break;

				case 9:	// "Emergency"
					statusSpriteOffset = [0,80];	// Red exclamation sign
					break;

				case 14:	// "Alert triggered" -
				case 126:	// "Help"
					statusSpriteOffset = [0,0];	// Yellow exclamation sign
					break;

				case 7:	// "Enter fence"
					statusSpriteOffset = [80,80];
					break;

				case 8:	// "Exit fence"
					statusSpriteOffset = [160,80];
					break;

				case 10:	// "Reset" - on/off symbol
					statusSpriteOffset = [0,240];
					break;

				case 54:	// "Driver ID"
				case 375:	// "Encrypted"
					statusSpriteOffset = [240,0];	// Yellow padlock
					break;

				case 121:	// "Driver ID Failed"
					statusSpriteOffset = [160,0];	// Red padlock
					break;

				case 122:	// "Driver Logoff"
				case 376:	// "Unencrypted"
					statusSpriteOffset = [320,0];	// Yellow padlock, open
					break;

				case 50:	// "Stop ETA":
					statusSpriteOffset = [240, 240];	// Stop sign with clock hands
					break;

				case 49:	// "Stop status":
					statusSpriteOffset = [320, 240];	// Stop sign with interrogation
					break;

				case 120:	// "Quick message"
				case 123:	// "Quick message response"
					statusSpriteOffset = [240, 160];	// Speech bubble, slanted
					break;

				case 51:	// "Text message"
					statusSpriteOffset = [160, 160];	// Speech bubble, straight
					break;

				case 58:	// "Refuel"
					statusSpriteOffset = [320, 160];	// Fuel pump
					break;

				case 42:	// "Service meter" - Grey wrench
					statusSpriteOffset = [80,240];
					break;

				case 15:	// "Check-in" - Green checkmark
					statusSpriteOffset = [80,0];
					break;

				case 5:	// "Speeding start"
					statusSpriteOffset = [160,320];	// Red speedometer
					break;

				case 45: // "Towing start"
					statusSpriteOffset = [320,320];	// Yellow speedometer
					break;

				case 32: // "Idling start"
				case 48: // "Moving stop"
					statusSpriteOffset = [320,400];	// Grey speedometer
					break;

				case 6: // "Speeding stop"
				case 46: // "Towing end"
				case 98: // "Idling stop"
				case 47: // "Moving start"
					statusSpriteOffset = [240,320];	// Green speedometer
					break;

				case 21: 	// "Ignition on"
					statusSpriteOffset = [0,320];	// Green engine block
					break;

				case 22: 	// "Ignition off"
					statusSpriteOffset = [80,320];	// Grey engine block
					break;

				case 35:	// "Antenna cut start"
					statusSpriteOffset = [80,480];	// Antenna crossed out
					break;

				case 129:	// "Antenna cut end"
					statusSpriteOffset = [0,480];	// Antenna _not_ crossed out
					break;

				case 36:	// "GPS jamming start"
					statusSpriteOffset = [240,400];	// circle-crosshair, crossed out
					break;

				case 37:	// "GPS jamming end"
					statusSpriteOffset = [160,400];	// circle-crosshair, _not_ crossed out
					break;

				case 38:	// "Cell jamming start"
					statusSpriteOffset = [80,400];	// cell reception bars, crossed out
					break;

				case 39:	// "Cell jamming end"
					statusSpriteOffset = [0,400];	// cell reception bars, _not_ crossed out
					break;

				default:
					// noop
			}

			if (statusSpriteOffset) {
				this.#spriteStatus = new TintedSprite(geom, {
					image: "/content/images/markers/status-spritesheet.png",
					spriteAnchor: [0, 80],
					// spriteAnchor: [0, 120],
					offset: [6,6],
					spriteSize: [80, 80],
					spriteStart: statusSpriteOffset,
					tint: [255,255,255,255],
					spriteScale: 0.125
				});
			}

		}

		this.replaceIcon(type, colour, alpha);

	}

	fire(evType, detail) {
		// Hook up propagation of synthetic click events
		if (evType === "click") {
			this.#spriteBase.fire("click");
		} else {
			return super.fire(evType, detail);
		}
	}

	addTo(target) {
		target.multiAdd(this.symbols);
		this.target = target;
		return this;
	}

	remove() {
		this.symbols.forEach((s) => s.remove());
		this.target = undefined;
		return this;
	}

	// addTo(target) {
	// 	// target.multiAdd(this.#symbols);
	// 	this.#spriteBase.addTo(target);
	// 	this.#target = target;
	// 	return this;
	// }
 //
	// remove() {
	// 	// this.#symbols.forEach((s) => s.remove());
	// 	this.#spriteBase.remove();
	// 	this.#spriteBouncing.remove();
	// 	this.#spriteHeading.remove();
	// 	this.#bouncing = false;
	// 	this.#target = undefined;
	// 	return this;
	// }

	get geometry() {
		return super.geometry;
	}
	set geometry(geom) {
		super.geometry = geom;
		this.#spriteBase && (this.#spriteBase.geometry = geom);
		this.#spriteBouncing && (this.#spriteBouncing.geometry = geom);
		this.#headingTriangle && (this.#headingTriangle.geometry = geom);
	}

	isActive() {
		return this.#spriteBase.isActive();
	}


	get offset() {
		return this.#spriteBase.offset;
	}

	set offset(o){
		// const [oldX, oldY] = this.#spriteBase.offset;
		this.#spriteBase.offset = o;
		this.#spriteBouncing && (this.#spriteBouncing.offset = o);
		this.#headingTriangle && (this.#headingTriangle.offset = o);

		if (this.#spriteStatus) {
			// Status offset from base sprite is always [6, 6]
			// If it was different, it would have to be cached or recalculated
			// from the old base offset.
			this.#spriteStatus.offset = [o[0] + 6,o[1] + 6];
		}

	}

	get tint() {
		return this.#spriteBase.tint;
	}

	set tint(t) {
		if (this.#tintBase) {
			this.#spriteBase.tint = t;
			this.#spriteBouncing.tint = t;
		}
		if (this.#headingTriangle) {
			this.#headingTriangle.fillColour = t;
		}
	}


	// For duplicating the image when part of a cluster
	get spriteBase() {
		return this.#spriteBase;
	}

	// Compat with Leaflet bouncing markers
	isBouncing() {
		return this.#bouncing;
	}

	setBouncingOptions() {
		return this;
	}

	toggleBouncing() {
		const target = this.#spriteBase._inAcetate?.platina;
		if (target) {
			this.#spriteBase.remove();
			this.#spriteBouncing.addTo(target);
		}
		this.symbols = [this.#spriteBouncing];
		if (this.#headingTriangle) {
			this.symbols.push(this.#headingTriangle);
		}
		if (this.#spriteStatus) {
			this.symbols.push(this.#spriteStatus);
		}
		this.#bouncing = true;
		return this;
	}
	stopBouncing() {
		const target = this.#spriteBouncing._inAcetate?.platina;
		if (target) {
			this.#spriteBouncing.remove();
			this.#spriteBase.addTo(target);
		}
		this.symbols = [this.#spriteBase];
		if (this.#headingTriangle) {
			this.symbols.push(this.#headingTriangle);
		}
		if (this.#spriteStatus) {
			this.symbols.push(this.#spriteStatus);
		}
		this.#bouncing = false;
		return this;
	}

	// Compat with Leaflet bouncing markers
	getLatLng() {
		return this.geometry.asLatLng();
	}


	// Event handling as per Gleo MultiSymbol
	on(evtype, handler) {
		this.#spriteBase.on(evtype, handler);
		this.#spriteBouncing.on(evtype, handler);
		return this;
	}
	off(evtype, handler) {
		this.#spriteBase.off(evtype, handler);
		this.#spriteBouncing.off(evtype, handler);
		return this;
	}
	once(evtype, handler) {
		this.#spriteBase.once(evtype, handler);
		this.#spriteBouncing.once(evtype, handler);
		return this;
	}
	fire(evtype, detail) {
		const target =
			this.#spriteBouncing?._inAcetate
			? this.#spriteBouncing
			: this.#spriteBase;

		target && target.fire(evtype, detail);

		return this;
	}

	// addEventListener(evtype, handler) {
	// 	this.#symbols.forEach((s) => s.addEventListener(evtype, handler));
	// 	return this;
	// }
	// removeEventListener(evtype, handler) {
	// 	this.#symbols.forEach((s) => s.removeEventListener(evtype, handler));
	// 	return this;
	// }
	// dispatchEvent(evtype, handler) {
	// 	this.#symbols.forEach((s) => s.dispatchEvent(evtype, handler));
	// 	return this;
	// }


	// For compatibility with Leaflet functionality that depends on
	// getting a reference to the DOM element for the L.Marker.
	// i.e. for the bootstrap tooltips/popups
	// We'll assume that there'll be only one tooltip/popup at any given time,
	// and the approach will be to maintain just one L.DivIcon for the entire class,
	// resetting its geometry as needed.

	static #popupAnchor;

	static get popupAnchor() {
		if (this.#popupAnchor ) {
			return this.#popupAnchor;
		}
		this.#popupAnchor = L.marker([0,0], {icon: L.divIcon(), opacity: 0}).addTo(map);
		const el = this.#popupAnchor.getElement();
		el.style.pointerEvents = 'none';
		el.style.width = 0;
		el.style.height = 0;
		el.style.border = "none";
		el.style.background = "transparent";

		return this.#popupAnchor;
	}

	getElement() {
		const [lng, lat] = this.geometry.toCRS("EPSG:4326").coords;

		const anchor = this.constructor.popupAnchor;
		// set width/height based on baseSprite size for offsets/positioning
		anchor.setIcon(L.divIcon({ iconSize: this.#spriteBase._spriteSize }));
		anchor.setLatLng([lat, lng]);
		const anchorEl = anchor.getElement();
		//anchorEl.style.marginLeft = `${this.offset[0] + 10}px`;
		//anchorEl.style.marginTop = `${-this.offset[1]}px`;
		return anchorEl;
	}


	/// Replaces the base icon and colour. This will re-request the sprite image
	/// (specially if it's set to a user-uploaded one) and re-tint all components.
	replaceIcon(type, colour, alpha) {
		const target =
			this.#spriteBouncing?._inAcetate?.platina ||
			this.#spriteBase?._inAcetate?.platina;

		let originalColour = colour;
		colour = parseColour(convertNamedColorToArray(colour));
		if (colour[3] == 255 && alpha !== undefined) {
			colour[3] = alpha;
		}

		// The base sprite is tinted only when its icon is *not* a user-uploaded
		// image.
		//this.#tintBase = type !== 'Upload';
		this.#tintBase = false;

		// this.geometry = geom;
		const imagePath = createMarkerPath(
			type,	// Asset class
			originalColour,		// Colour, handled by tint
			null, 	// Course, handled by heading triangle
			null, 	// Alpha, handled by tint
			this.#assetId, 	// Asset ID
			false,
			'',	// Event type, handled by status sprite
			this.#isFirst,
			this.#isLast
		);

		if (!this.#spriteBase) {
			this.#spriteBase = new TintedSprite(this.geometry, {
				image: imagePath,
				spriteAnchor: [18, 18],
				spriteSize: [36, 36],
				tint: this.#tintBase ? colour : [255, 255, 255, alpha] ,
				interactive: true,
				cursor: 'pointer',
				offset: [0,0],
			});
		} else {
			this.#spriteBase.tint = this.#tintBase ? colour : [255, 255, 255, alpha];
			this.#spriteBase.replaceImage({
				image: imagePath,
				spriteAnchor: [18, 18],
				spriteSize: [36, 36],
			}, true);
		}

		if (this.#heading !== undefined) {
			// TODO: replace triangle's colour instead of removing+readding
			if (this.#headingTriangle?._inAcetate) {
				this.#headingTriangle?.remove();
			}
			this.#headingTriangle = new HeadingTriangle(this.geometry, {
				distance: 10,
				length: 6,
				width: 11,
				borderWidth: .9,
				feather: .7,
				fillColour: colour,
				borderColour: [0,0,0, alpha],
				yaw: this.#heading,
				offset: this.offset,
			});

			/// TODO: If heading is between 40 and 70, and there's an event
			/// icon, move the event icon to the bottom-right.
		}

		if (!this.#spriteBouncing) {
			this.#spriteBouncing = new BouncySprite(this.geometry, {
				image: imagePath,
				spriteAnchor: [18, 18],
				spriteSize: [36, 36],
				tint: this.#tintBase ? colour : [255, 255, 255, alpha],
				interactive: true,
				cursor: 'pointer',
				bounceHeight: 15,
				offset: this.offset,
			});
		} else {
			this.#spriteBouncing.tint = this.#tintBase ? colour : [255, 255, 255, alpha];
			this.#spriteBouncing.replaceImage({
				image: imagePath,
				spriteAnchor: [18, 18],
				spriteSize: [36, 36],
			}, true);
		}

		// MultiSymbol compat
		this.symbols = this.#bouncing ? [this.#spriteBouncing] : [this.#spriteBase];
		if (this.#headingTriangle) {
			this.symbols.push(this.#headingTriangle);
		}
		if (this.#spriteStatus) {
			this.symbols.push(this.#spriteStatus);
		}

		// Hook up dragging events
		this.#spriteBase.on("dragstart", this.#onDragStart.bind(this));
		this.#spriteBase.on("drag", this.#onDrag.bind(this));
		this.#spriteBase.on("dragend", this.#onDragEnd.bind(this));
		this.#spriteBouncing.on("dragstart", this.#onDragStart.bind(this));
		this.#spriteBouncing.on("drag", this.#onDrag.bind(this));
		this.#spriteBouncing.on("dragend", this.#onDragEnd.bind(this));

		if (target) {
			target.multiAdd(this.symbols);
		}
	}


	// Dragging logic: rely on the base & bouncing sprites.
	get draggable() {
		return this.#spriteBase.draggable;
	}

	set draggable(d) {
		if (this.#spriteBase.draggable && !d) {
			this.fire("dragdisable");
		} else if (!this.#spriteBase.draggable && d) {
			this.fire("dragenable");
		}
		this.#spriteBase.draggable = this.#spriteBouncing.draggable = d;
	}

	#onDragStart() {
		// Disable *leaflet* map dragging - the sprite only has access to
		// the gleo platina.
		map.dragging.disable();
	}
	#onDrag(ev) {
		// Whenever the underlying base/bouncing sprite is dragged, update
		// the geometry of all sprites + heading triangle
		this.geometry = ev.target.geometry;

		/// Move the anchor element as well, since the leaflet tooltip
		/// might be visible while dragging. But this won't update a jQuery
		/// tooltip / bsTooltip.
		const anchor = this.constructor.popupAnchor;
		anchor.setLatLng(this.geometry.asLatLng());
	}
	#onDragEnd() {
		// Re-enable Leaflet map dragging, idem as onDragStart.
		map.dragging.enable();
	}

}

