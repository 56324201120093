import { viewModes, panels } from "./const.js";

// Monolithic data structure to hold application state.
// See also data.js and user.js

const state = {
	isSafari: !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/),
	isMobile: /iPhone|iPad|iPod|Android|Blackberry|Fennec|Mobi/i.test(navigator.userAgent),
	liveFollow: {
		isActive: false,
		asset: null,
		assets: [],
		groups: [],
	},
	isAddingAsset: false,
	isEditingGeofence: false,
	isEditingAssetGroup: false,
	groupDialog: {
		isEditing: false,
		type: null, // 'asset' | 'fence' | 'place'
	},
	isInLiveMode: true,
	activeMode: null,
	activeMapMode: null,
	activeViewMode: viewModes.NORMAL,
	hasQueriedHistory: false,
	hasQueriedLive: false,
	portalLoadedEpoch: null,
	activePanel: panels.ASSETS,
	mapClickQueue: [],
	mapClickHandlers: {
		PLACE: 0,
		POSITION: 1,
		POSITION_ADD: 2,
		RULER: 3,
		ROUTING: 4,
		GEOFENCE: 5,
	},
	isChoosingPosition: false,
	isAddingPosition: false,
	isChoosingPlace: false,
	isFirstLoad: true,
	isInPlaybackMode: false,
	openWindow: null,
	openPanels: {
		primary: true,
		secondary: false,
	},
	selectedMarker: null,
	chosenLocation: null,
	chosenLocations: [],
	isSnapToRoadsQueueRunning: false,
	isSnapToRoadsQueueProcessing: false,
	isPreviewingBuffer: false,
	bounds: null,
	boundsItems: [],
	zoomTimeout: null,
	userLocation: {
		position: null,
		marker: null,
		circle: null,
		watch: null,
		zoomToPosition: false,
		options: {
			enableHighAccuracy: true,
			maximumAge: 60 * 1000 * 5,
			timeout: 30 * 1000,
		},
	},
	promises: {
		getGroupsAndAssets: null,
		getAssetUsers: null,
		getLatestPositions: null,
		getNotificationsAndAlerts: null,
	},
	currentMapType: null,
	nextFocus: null,
};

export default state;
