Proxy ||= function(target) { return target; }; // For the browsers that don't support Proxy

const strings = new Proxy({
	MSG_MESSAGE_SENDING_ERROR: "",
	MSG_MESSAGE_SENT_SUCCESS: "",
	MSG_MESSAGE_SENT_ERROR: "",
	MSG_ADD_GROUP_ERROR: "",
	MSG_QUERY_GROUPS_ERROR: "",
	MSG_QUERY_USERS_ERROR: "",
	MSG_SINGLE_ASSET_ERROR: "",
	MSG_ASSET_QUERY_ERROR: "",
	MSG_ADD_ASSET_ERROR: "",
	MSG_ADD_ASSET_SUCCESS: "",
	MSG_EDIT_ASSET_SUCCESS: "",
	MSG_EDIT_ASSET_ERROR: "",
	MSG_EDIT_ASSET_FAILURE: "",
	MSG_EDIT_GROUP_SUCCESS: "",
	MSG_EDIT_GROUP_ERROR: "",
	MSG_EDIT_GROUP_FAILURE: "",
	MSG_DELETE_GROUP_ERROR: "",
	MSG_DELETE_ASSET_ERROR: "",
	MSG_ADD_GEOFENCE_NO_SEGMENTS: "",
	MSG_ADD_GEOFENCE_ERROR: "",
	MSG_EDIT_GEOFENCE_ERROR: "",
	MSG_DELETE_GEOFENCE_ERROR: "",
	MSG_INTERVAL_ERROR: "",
	MSG_SET_OUTPUT_PIN_ERROR: "",
	MSG_DPLUS_INTERVAL_ERROR: "",
	MSG_DPLUS_INTERVAL_SUCCESS: "",
	MSG_DPLUS_REPORT_ERROR: "",
	MSG_DPLUS_REPORT_SUCCESS: "",
	MSG_ADD_POSITION_SUCCESS: "",
	MSG_ADD_POSITION_ERROR: "",
	MSG_ADD_FILLUP_SUCCESS: "",
	MSG_ADD_FILLUP_ERROR: "",
	MSG_DELETE_FILLUP_ERROR: "",
	MSG_REFUEL_HISTORY_ERROR: "",
	MSG_LOAD_POSITION_ERROR: "",
	MSG_CONFIRM_NOTIFICATION_ERROR: "",
	MSG_DELETE_PLACE_ERROR: "",
	DELETE: "",
	CANCEL: "",
	FUEL_IMPERIAL: "",
	FUEL_LITRES: "",
	FUEL_US: "",
	ASSET_COLOR: "",
	ASSET_UNIQUEID: "",
	MSG_VALID_IMEI: "",
	MSG_ASSETS_AND_POSITIONS: "",
	MSG_ASSETS_SHOWN_LIVE: "",
	ADD_GEOFENCE: "",
	EDIT_ASSET: "",
	MSG_DELETE_GROUP_CONFIRM: "",
	VEHICLE_FIELDS: "",
	SOFTWARE_VERSION: "",
	VEHICLE_MAKE_AND_MODEL: "",
	VEHICLE_PURCHASE_DATE: "",
	VEHICLE_VIN: "",
	VESSEL_FIELDS: "",
	VESSEL_CLASS: "",
	VESSEL_TONNAGE: "",
	VESSEL_FLAG_REGISTRY: "",
	VESSEL_IMO_NUMBER: "",
	VESSEL_CALL_SIGN: "",
	VESSEL_NAME: "",
	NOTES: "",
	POSITION_INDEX: "",
	POSITION_NEXT: "",
	POSITION_PREV: "",
	PLAYBACK_STOP: "",
	PLAYBACK_PLAY: "",
	EXTRA: "",
	FIRST: "",
	LAST: "",
	HOURS: "",
	DAYS: "",
	WEEKS: "",
	MONTHS: "",
	UNKNOWN: "",
	NEVER: "",
	SAVE_CHANGES: ""
}, {
    get(target, prop) {
		if (prop in target) {
			return target[prop];
		}
		console.warn(`String key not found: ${prop}`);
        return prop;
    }
});


export default strings;

// Adds the overrides to the `strings` structure. Called from outside tracking.js
// when the language of the user is known. This usually adds lots of new keys to
// the structure, and therefore it's not possible to `export` individual strings.
export function overrideStrings(overrides) {
	Object.assign(strings, overrides);
}
