import strings from "./strings.js";
import state from "./state.js";
import user from "./user.js";
import domNodes from "./domNodes.js";
import { viewModes } from "./const.js";
import { EVENTS_STATUS, EVENTS_EMERGENCY, EVENTS_ALERT } from "./asset-events.js";

import $ from "jquery";
import _ from "lodash";
import { setChildren } from "redom"; // https://redom.js.org/

export function renderListItem(item, index) {
	// TODO filter out text message events that are not Chats before this is called
	var li = undefined;
	if (item.IsChat !== undefined) {
		li = renderMessageListItem(item, index); // TODO different chat rendering
	} else if (item.MessageId !== undefined) {
		li = renderMessageListItem(item, index);
	} else if (item.EventId !== undefined) {
		li = renderEventListItem(item, index);
	} else if (item.PositionId !== undefined) {
		li = renderPositionListItem(item, index);
	} else if (item.Items !== undefined) {
		li = renderActivityListItem(item, index);
	}
	return li;
}

function renderMessageListItem(item, index) {
	if (item === undefined) {
		return undefined;
	}

	var dataSource = state.activeViewMode === viewModes.SHARED_VIEW ? domNodes.sharedView : domNodes;
	var existing = dataSource.messageListingById[item.MessageId];
	if (existing !== undefined) {
		setListItemIndex(existing, index);
		return existing;
	}

	var li = document.createElement("li");
	li.className = item.IsFromMobile ? "from-mobile" : "to-mobile";
	var isHidden = item.Position !== undefined && item.Position !== null && item.Position.IsHidden;
	if (isHidden) {
		li.classList.add("is-hidden");
	}

	var header = renderListItemHeader(item.EventName, item.Position, "messages", item.AssetId, index);

	//// info button to view raw message, if available
	//if (!item.IsFromMobile && item.RawText !== undefined && item.RawText !== null && item.RawText !== '') {
	//    var infoLink = document.createElement('a');
	//    infoLink.className = 'message-info';
	//    infoLink.setAttribute('href', '#');
	//    infoLink.setAttribute('title', item.RawText);

	//    var infoIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
	//    var infoIconContents = document.createElementNS('http://www.w3.org/2000/svg', 'use');
	//    infoIconContents.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '/content/svg/tracking.svg?v=15#info-circle');
	//    infoIcon.appendChild(infoIconContents);
	//    infoLink.appendChild(infoIcon);
	//    header.appendChild(infoLink);
	//    $(infoLink).bsTooltip();
	//}

	var contents = document.createElement("div");
	contents.className = "list-contents";

	var row = renderListItemCommonMeta(item, true, true, true);
	contents.appendChild(row);

	// details - if alert, have this be toggleable
	var details = document.createElement("div");
	details.className = "event-details break-text";
	if (item.Details !== null && item.Details !== undefined) {
		details.classList.add("is-inline");
		setChildren(details, item.Details);
	}

	contents.appendChild(details);
	li.appendChild(header);
	li.appendChild(contents);

	dataSource.messageListingById[item.MessageId] = li;
	return li;
}

function renderEventListItem(item, index) {
	if (item === undefined) {
		return undefined;
	}
	var dataSource = state.activeViewMode === viewModes.SHARED_VIEW ? domNodes.sharedView : domNodes;
	var existing = dataSource.eventListingById[item.EventId];
	if (existing !== undefined) {
		setListItemIndex(existing, index);
		return existing;
	}

	var isEmergencyAlert = false;
	var listItemGroup = "events";
	if (EVENTS_STATUS.indexOf(item.Type) !== -1) {
		listItemGroup = "status";
	} else if (EVENTS_ALERT.indexOf(item.Type) !== -1) {
		listItemGroup = "alerts";
	} else if (EVENTS_EMERGENCY.indexOf(item.Type) !== -1) {
		listItemGroup = "alerts";
		isEmergencyAlert = true;
	} else {
		listItemGroup = "events";
	}

	var isHidden = item.Position !== undefined && item.Position !== null && item.Position.IsHidden;
	var li = document.createElement("li");
	if (isEmergencyAlert) {
		li.classList.add("is-emergency");
	}
	if (isHidden) {
		li.classList.add("is-hidden");
	}

	var header = renderListItemHeader(item.EventName, item.Position, listItemGroup, item.AssetId, index);

	var contents = document.createElement("div");
	contents.className = "list-contents";
	// asset icon if group listing

	var row = renderListItemCommonMeta(item, true, true, true);
	contents.appendChild(row);

	// details header (for alerts and grouped events/activity)
	var eventDetailsHeader = renderListItemDetailsHeader(item, false);
	if (eventDetailsHeader !== undefined) {
		contents.appendChild(eventDetailsHeader);
	}
	var eventDetails = renderListItemDetails(item, false);
	if (eventDetails !== undefined) {
		contents.appendChild(eventDetails);
	}

	li.appendChild(header);
	li.appendChild(contents);

	dataSource.eventListingById[item.EventId] = li;
	return li;
}

function renderPositionListItem(item, index) {
	if (item === undefined) {
		return undefined;
	}

	var dataSource = state.activeViewMode === viewModes.SHARED_VIEW ? domNodes.sharedView : domNodes;
	var existing = dataSource.positionListingById[item.PositionId];
	if (existing !== undefined) {
		setListItemIndex(existing, index);
		return existing;
	}

	var li = document.createElement("li");
	var isHidden = item.Position !== undefined && item.Position !== null && item.Position.IsHidden;
	if (isHidden) {
		li.classList.add("is-hidden");
	}

	var hasAddress = item.Position.Address !== null;
	var itemName = hasAddress ? item.Position.Address : item.Position.LatLng;
	var header = renderListItemHeader(itemName, item.Position, "positions", item.AssetId, index);

	var contents = document.createElement("div");
	contents.className = "list-contents";

	var row = renderListItemCommonMeta(item, false, hasAddress, true);
	contents.appendChild(row);

	li.appendChild(header);
	li.appendChild(contents);

	dataSource.positionListingById[item.PositionId] = li;
	return li;
}

function renderActivityListItem(groupItem, index) {
	// special handling of activity list items which are grouped by Epoch and formatted differently
	if (groupItem === undefined) {
		return undefined;
	}

	var dataSource = state.activeViewMode === viewModes.SHARED_VIEW ? domNodes.sharedView : domNodes;
	var existing = dataSource.activityListingById[groupItem.EpochGroup];
	if (existing !== undefined) {
		setListItemIndex(existing, index);
		return existing;
	}

	var firstItem = _.first(groupItem.Items);
	var itemWithPosition = _.find(groupItem.Items, function (item) {
		return item.Position !== undefined && item.Position !== null;
	});

	var li = document.createElement("li");
	var position = undefined;
	if (itemWithPosition !== undefined) {
		position = itemWithPosition.Position;
	}
	var isHidden = position !== undefined && position !== null && position.IsHidden;
	if (isHidden) {
		li.classList.add("is-hidden");
	}

	var header = renderListItemHeader(firstItem.Time, position, "activity", groupItem.AssetId, index);
	li.appendChild(header);

	var contents = document.createElement("div");
	contents.className = "list-contents";

	// common items for the position, but what if we don't have a position?
	// and we don't need time!
	var isGrouped = true;
	var showAddress = true;
	var showLatLng = true;
	var showTime = false;
	var row = renderListItemCommonMeta(
		itemWithPosition !== undefined ? itemWithPosition : firstItem,
		showAddress,
		showLatLng,
		showTime
	);
	if (row !== undefined) {
		contents.appendChild(row);
	}

	if (itemWithPosition !== undefined) {
		// additional position details
		var positionDetails = renderListItemPositionDetails(itemWithPosition);
		if (positionDetails !== undefined) {
			contents.appendChild(positionDetails);
		}
	}

	_.each(groupItem.Items, function (item) {
		var eventDetailsHeader = renderListItemDetailsHeader(item, isGrouped);
		if (eventDetailsHeader !== undefined) {
			contents.appendChild(eventDetailsHeader);
		}
		var eventDetails = renderListItemDetails(item, isGrouped);
		if (eventDetails !== undefined) {
			contents.appendChild(eventDetails);
		}
	});
	li.appendChild(contents);

	dataSource.activityListingById[groupItem.EpochGroup] = li;
	return li;
}
function renderListItemHeader(name, position, type, assetId, index) {
	var header = document.createElement("div");
	header.className = "list-header";

	var typeIcon = renderListItemIcon(type);
	header.appendChild(typeIcon);

	if (position !== undefined && position !== null) {
		var listItemTypeNameLink = document.createElement("a");
		listItemTypeNameLink.textContent = name;
		listItemTypeNameLink.className = "event-name location";
		listItemTypeNameLink.setAttribute("href", "#");
		listItemTypeNameLink.setAttribute("data-marker", position.Id);
		listItemTypeNameLink.setAttribute("data-time", position.Time);
		listItemTypeNameLink.setAttribute("data-asset", assetId);
		listItemTypeNameLink.setAttribute("data-hidden", position.IsHidden);
		header.appendChild(listItemTypeNameLink);
	} else {
		var listItemTypeName = document.createElement("span");
		listItemTypeName.textContent = name;
		listItemTypeName.className = "event-name";
		header.appendChild(listItemTypeName);
	}

	if (position !== undefined && position !== null) {
		if (user.canEditAssets) {
			var mapVisibility = document.createElement("a");
			mapVisibility.className = "map-toggle";
			mapVisibility.setAttribute("href", "#");
			mapVisibility.setAttribute("data-marker", position.Id);
			mapVisibility.setAttribute("data-asset", assetId);
			mapVisibility.setAttribute("data-hidden", position.IsHidden);
			mapVisibility.title = position.IsHidden ? strings.SHOW_ON_MAP : strings.HIDE_ON_MAP;

			var mapVisibilityIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
			var mapVisibilityIconContents = document.createElementNS("http://www.w3.org/2000/svg", "use");
			mapVisibilityIconContents.setAttributeNS(
				"http://www.w3.org/1999/xlink",
				"href",
				"/content/svg/tracking.svg?v=15#" + (position.IsHidden ? "invisible" : "visible")
			);
			mapVisibilityIcon.appendChild(mapVisibilityIconContents);
			mapVisibility.appendChild(mapVisibilityIcon);
			header.appendChild(mapVisibility);
		}

		var mapLink = document.createElement("a");
		mapLink.className = "location map-hilight";
		mapLink.setAttribute("href", "#");
		mapLink.setAttribute("data-marker", position.Id);
		mapLink.setAttribute("data-time", position.Time);
		mapLink.setAttribute("data-asset", assetId);

		var mapIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		var mapIconContents = document.createElementNS("http://www.w3.org/2000/svg", "use");
		mapIconContents.setAttributeNS(
			"http://www.w3.org/1999/xlink",
			"href",
			"/content/svg/tracking.svg?v=15#globe-americas"
		);
		mapIcon.appendChild(mapIconContents);
		mapLink.appendChild(mapIcon);
		header.appendChild(mapLink);
	}

	// position in list
	var itemIndex = document.createElement("span");
	itemIndex.className = "item-index";
	itemIndex.textContent = "#" + (index + 1);
	header.appendChild(itemIndex);
	return header;
}

function renderListItemCommonMeta(item, includeAddress, includeLatLng, includeTime) {
	if (item === undefined) {
		return undefined;
	}
	var row = document.createElement("div");
	row.className = "list-item-row";

	var assetIcon = document.createElement("div");
	assetIcon.className = "item-icon item-asset-name";
	assetIcon.style.backgroundImage = "url(" + item.IconUrl + ")";
	row.appendChild(assetIcon);

	var meta = document.createElement("div");
	meta.className = "item-metadata";

	// details
	var assetLine = document.createElement("div");
	assetLine.className = "item-asset-name";

	var assetNameLabel = document.createElement("span");
	assetNameLabel.className = "item-label";
	assetNameLabel.textContent = strings.ASSET + ": ";

	var assetName = document.createElement("span");
	assetName.className = "event-asset-name";
	assetName.textContent = item.AssetName;
	assetLine.appendChild(assetNameLabel);
	assetLine.appendChild(assetName);
	meta.appendChild(assetLine);

	//if (includeLatLng && item.Position !== undefined && item.Position !== null) {
	//    // lat/lng
	//    var latLngLine = document.createElement('div');
	//    var latLngLabel = document.createElement('span');
	//    latLngLabel.className = 'item-label';
	//    latLngLabel.textContent = strings.LAT_LNG + ': ';
	//    latLngLine.appendChild(latLngLabel);

	//    var latLngLink = document.createElement('a');
	//    latLngLink.setAttribute('href', '#');
	//    latLngLink.className = 'event-latlng location';
	//    latLngLink.setAttribute('data-marker', item.Position.Id);
	//    latLngLink.setAttribute('data-time', item.Position.Time);
	//    latLngLink.setAttribute('data-asset', item.AssetId);
	//    latLngLink.textContent = item.Position.LatLng;
	//    latLngLine.appendChild(latLngLink);
	//    meta.appendChild(latLngLine);
	//}

	if (includeAddress && item.Position !== undefined && item.Position !== null && item.Position.Address !== null) {
		var addressLine = document.createElement("div");
		var addressLabel = document.createElement("span");
		addressLabel.className = "item-label";
		addressLabel.textContent = strings.ADDRESS + ": ";
		var address = document.createElement("a");
		address.setAttribute("href", "#");
		address.className = "event-location location";
		address.setAttribute("data-marker", item.Position.Id);
		address.setAttribute("data-time", item.Position.Time);
		address.setAttribute("data-asset", item.AssetId);
		address.textContent = item.Position.Address;
		addressLine.appendChild(addressLabel);
		addressLine.appendChild(address);
		meta.appendChild(addressLine);
	}

	if (includeLatLng && item.Position !== undefined && item.Position !== null && item.Position.LatLng !== null) {
		var latLngLine = document.createElement("div");
		var latLngLabel = document.createElement("span");
		latLngLabel.className = "item-label";
		latLngLabel.textContent = strings.LAT_LNG + ": ";
		latLngLine.appendChild(latLngLabel);

		if (!includeAddress || item.Position.Address === null) {
			var latLngLink = document.createElement("a");
			latLngLink.setAttribute("href", "#");
			latLngLink.className = "event-latlng location";
			latLngLink.setAttribute("data-marker", item.Position.Id);
			latLngLink.setAttribute("data-time", item.Position.Time);
			latLngLink.setAttribute("data-asset", item.AssetId);
			latLngLink.textContent = item.Position.LatLng;
			latLngLine.appendChild(latLngLink);
		} else {
			var latLngValue = document.createElement("span");
			latLngValue.className = "event-latlng";
			latLngValue.textContent = item.Position.LatLng;
			latLngLine.appendChild(latLngValue);
		}
		meta.appendChild(latLngLine);
	}

	// time
	if (includeTime) {
		var timeLine = document.createElement("div");
		var timeLabel = document.createElement("span");
		timeLabel.className = "item-label";
		timeLabel.textContent = strings.TIME + ": ";
		timeLine.appendChild(timeLabel);
		var timeValue = document.createElement("span");
		timeValue.className = "item-time index";
		timeValue.textContent = item.Time;
		timeLine.appendChild(timeValue);
		//timeValue.setAttribute('data-epoch', position.epoch);

		var epochValue = document.createElement("span");
		epochValue.className = "hidden item-epoch";
		epochValue.textContent = item.Epoch;
		timeLine.appendChild(epochValue);
		meta.appendChild(timeLine);
	}

	// position-specific
	if (item.EventTypes !== undefined && item.EventTypes !== null && item.EventTypes !== "") {
		var typesLine = document.createElement("div");
		var typesLabel = document.createElement("span");
		typesLabel.className = "item-label";
		typesLabel.textContent = strings.TYPE + ": ";
		typesLine.appendChild(typesLabel);
		var typesValue = document.createElement("span");
		typesValue.className = "item-types index";
		typesValue.textContent = item.EventTypes;
		typesLine.appendChild(typesValue);
		meta.appendChild(typesLine);
	}

	// message-specific
	if (item.Source !== undefined && item.Source !== null) {
		var sourceLine = document.createElement("div");
		var sourceLabel = document.createElement("span");
		sourceLabel.className = "item-label";
		sourceLabel.textContent = strings.DATA_SOURCE + ": ";
		sourceLine.appendChild(sourceLabel);
		var sourceValue = document.createElement("span");
		sourceValue.className = "item-source";
		sourceValue.textContent = item.Source;
		sourceLine.appendChild(sourceValue);
		meta.appendChild(sourceLine);
	}

	if (item.Method !== undefined && item.Method !== null) {
		var methodLine = document.createElement("div");
		var methodLabel = document.createElement("span");
		methodLabel.className = "item-label";
		methodLabel.textContent = strings.METHOD + ": ";
		methodLine.appendChild(methodLabel);
		var methodValue = document.createElement("span");
		methodValue.className = "item-method";
		methodValue.textContent = item.Method;
		methodLine.appendChild(methodValue);
		meta.appendChild(methodLine);
	}

	if (item.IsFromMobile !== undefined && !item.IsFromMobile) {
		// && item.Status !== undefined && item.Status !== null) {
		var statusLine = document.createElement("div");
		var statusLabel = document.createElement("span");
		statusLabel.className = "item-label";
		statusLabel.textContent = strings.STATUS + ": ";
		statusLine.appendChild(statusLabel);
		if (item.IsError) {
			var statusValue = document.createElement("a");
			statusValue.className = "item-status error";
			statusValue.setAttribute("href", "#");
			statusValue.setAttribute("data-message-id", item.Id);
			if (item.Response !== null) {
				statusValue.setAttribute("title", item.Response);
				$(statusValue).bsTooltip();
			}
			statusValue.textContent = item.Status;
			statusLine.appendChild(statusValue);
		} else {
			var statusValue = document.createElement("span");
			statusValue.className = "item-status";
			statusValue.textContent = item.Status;
			statusLine.appendChild(statusValue);
		}
		meta.appendChild(statusLine);
	}

	// other info
	row.appendChild(meta);
	return row;
}

function renderListItemDetails(item, isGrouped) {
	var alert = item.Alert;
	if (alert === null) {
		alert = undefined;
	}
	if (
		(item.Details === undefined && alert === undefined) ||
		(item.Details === null && alert === undefined) ||
		(item.Details === "" && alert === undefined)
	) {
		return undefined;
	}
	var details = document.createElement("div");
	details.id = "event-details-" + item.Id;
	details.className = "event-details break-text";
	if (item.Details !== null) {
		details.classList.add("is-inline");
		setChildren(details, item.Details);
	}

	if (isGrouped || alert !== undefined) {
		// will have a header to expand/contract this section
		details.classList.add("collapse");
		details.classList.remove("is-inline");
	}

	if (alert !== undefined) {
		// alert triggered/no longer triggered event
		if (item.Type === 14) {
			// alert triggered
			if (alert.Acknowledged === 0) {
				// add alert acknowledgement form elements
				var form = document.createElement("form");
				form.id = "asset-alert-acknowledge-" + item.EventId;
				form.setAttribute("data-alert-id", alert.Id);
				form.setAttribute("data-asset-id", item.AssetId);
				form.setAttribute("data-event-id", item.EventId);

				var status = document.createElement("div");
				status.id = "asset-alert-acknowledge-status-" + item.EventId;
				status.className = "dialog-status alert toggle-content";

				var resolution = document.createElement("div");
				resolution.className = "alert-resolution form-group";
				var resolutionLabel = document.createElement("label");
				resolutionLabel.setAttribute("for", "asset-alert-resolution-" + item.EventId);
				resolutionLabel.textContent = strings.RESOLUTION;
				resolution.appendChild(resolutionLabel);
				var resolutionText = document.createElement("textarea");
				resolutionText.id = "asset-alert-resolution-" + item.EventId;
				resolutionText.className = "form-control required";
				resolution.appendChild(resolutionText);

				var textAcknowledge = alert.LabelAcknowledge !== null ? alert.LabelAcknowledge : strings.ACKNOWLEDGE;
				var textAcknowledgeAlt =
					alert.LabelAcknowledgeAlt !== null ? alert.LabelAcknowledgeAlt : strings.ACKNOWLEDGE_ALT;
				var acknowledge = document.createElement("div");
				acknowledge.className = "alert-acknowledge dialog-buttons";
				var ackButton = document.createElement("button");
				ackButton.className = "btn btn-primary alert-acknowledge";
				ackButton.textContent = textAcknowledge;
				var altAckButton = document.createElement("button");
				altAckButton.className = "btn btn-secondary alert-acknowledge-alt";
				altAckButton.textContent = textAcknowledgeAlt;
				acknowledge.appendChild(ackButton);
				acknowledge.appendChild(altAckButton);

				form.appendChild(status);
				form.appendChild(resolution);
				form.appendChild(acknowledge);
				details.appendChild(form);
			} else {
				// include acknowledged by details
				var resolution = document.createElement("div");
				resolution.className = "alert-resolution form-group";
				var resolutionLabel = document.createElement("label");
				resolutionLabel.setAttribute("for", "asset-alert-resolution-" + item.EventId);
				resolutionLabel.textContent = strings.RESOLUTION;
				resolution.appendChild(resolutionLabel);
				var resolutionText = document.createElement("span");
				resolutionText.id = "asset-alert-resolution-" + item.EventId;
				resolutionText.className = "form-control-plaintext";
				resolutionText.textContent = alert.AcknowledgedText;
				resolution.appendChild(resolutionText);

				var status = document.createElement("div");
				status.className = "alert-status form-group";
				var statusLabel = document.createElement("label");
				statusLabel.setAttribute("for", "asset-alert-status-" + item.EventId);
				statusLabel.textContent = strings.STATUS;
				status.appendChild(statusLabel);
				var statusText = document.createElement("span");
				statusText.id = "asset-alert-status-" + item.EventId;
				statusText.className = "form-control-plaintext";
				statusText.textContent = strings.ACKNOWLEDGE_STATUS.replace("{Status}", alert.AcknowledgedStatus)
					.replace("{Time}", alert.AcknowledgedOn)
					.replace("{User}", alert.AcknowledgedBy);
				status.appendChild(statusText);

				details.appendChild(resolution);
				details.appendChild(status);
			}
		}
	}
	return details;
}

function renderListItemDetailsHeader(item, isGrouped) {
	var alert = item.Alert;
	if (item.EventName !== undefined && (isGrouped || (alert !== undefined && alert !== null))) {
		var hasDetails = item.Details !== undefined && item.Detials !== null && item.Details !== "";
		var hasAlert = alert !== undefined && alert !== null;

		var eventDetailsHeader = document.createElement("div");
		eventDetailsHeader.className = "event-details-header";

		var isCollapse = hasDetails || hasAlert;
		if (isCollapse) {
			// for collapsible panels use buttons and data-toggle
			var headerButton = document.createElement("button");
			headerButton.className = "btn btn-link";
			headerButton.setAttribute("data-toggle", "collapse");
			headerButton.setAttribute("data-target", "#event-details-" + item.Id);
			headerButton.setAttribute("aria-expanded", "false");

			// if grouped, include event type icon
			if (isGrouped) {
				var itemType = getListItemGroupForEvent(item);
				var typeIcon = renderListItemIcon(itemType);
				if (typeIcon !== undefined) {
					//typeIcon.className = 'list-item-icon';
					typeIcon.setAttributeNS("http://www.w3.org/1999/xlink", "class", "list-item-icon");
					headerButton.appendChild(typeIcon);
				}
			}

			var eventDetailsTitle = document.createElement("span");
			eventDetailsTitle.className = "mr-auto";
			eventDetailsTitle.textContent = !isGrouped ? strings.DETAILS : item.EventName;
			headerButton.appendChild(eventDetailsTitle);

			if (item.Type === 14) {
				// alert triggered
				var alertAcknowledgedIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
				alertAcknowledgedIcon.classList.add("list-item-action");
				alertAcknowledgedIcon.classList.add("alert-acknowledge-icon");
				if (alert.Acknowledged === 0) {
					if (alert.RequiresAcknowledgement) {
						alertAcknowledgedIcon.classList.add("requires-acknowledgement");
					} else {
						alertAcknowledgedIcon.classList.add("pending-acknowledgement");
					}
				} else {
					alertAcknowledgedIcon.classList.add("is-acknowledged");
				}
				alertAcknowledgedIcon.setAttribute("data-event-id", item.EventId);
				var alertAcknowledgedTitle = document.createElementNS("http://www.w3.org/2000/svg", "title");
				alertAcknowledgedTitle.textContent = strings.ACKNOWLEDGE_ALERT;
				alertAcknowledgedIcon.appendChild(alertAcknowledgedTitle);

				var alertAcknowledgedIconType = document.createElementNS("http://www.w3.org/2000/svg", "use");
				if (alert.Acknowledged === 0) {
					alertAcknowledgedIconType.setAttributeNS(
						"http://www.w3.org/1999/xlink",
						"href",
						"/content/svg/tracking.svg?v=15#times-circle-solid"
					);
				} else {
					alertAcknowledgedIconType.setAttributeNS(
						"http://www.w3.org/1999/xlink",
						"href",
						"/content/svg/tracking.svg?v=15#check-circle-solid"
					);
				}

				alertAcknowledgedIcon.appendChild(alertAcknowledgedIconType);
				headerButton.appendChild(alertAcknowledgedIcon);
			}

			var infoExpandIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
			infoExpandIcon.setAttribute("class", "list-item-action list-item-details");
			var infoExpandIconTitle = document.createElementNS("http://www.w3.org/2000/svg", "title");
			infoExpandIconTitle.textContent = strings.DETAILS;
			infoExpandIcon.appendChild(infoExpandIconTitle);
			var infoExpandIconContents = document.createElementNS("http://www.w3.org/2000/svg", "use");
			infoExpandIconContents.setAttributeNS(
				"http://www.w3.org/1999/xlink",
				"href",
				"/content/svg/tracking.svg?v=15#expand"
			);
			infoExpandIcon.appendChild(infoExpandIconContents);
			headerButton.appendChild(infoExpandIcon);

			eventDetailsHeader.appendChild(headerButton);
		} else {
			// if grouped, include event type icon
			if (isGrouped) {
				var itemType = getListItemGroupForEvent(item);
				var typeIcon = renderListItemIcon(itemType);
				if (typeIcon !== undefined) {
					eventDetailsHeader.appendChild(typeIcon);
				}
			}

			var eventDetailsTitle = document.createElement("span");
			eventDetailsTitle.textContent = item.EventName;
			eventDetailsHeader.appendChild(eventDetailsTitle);
		}
		return eventDetailsHeader;
	} else {
		return undefined;
	}
}

function setListItemIndex(listItem, index) {
	var indexDom = listItem.querySelector(".item-index");
	if (indexDom !== null) {
		indexDom.textContent = "#" + (index + 1);
	}
}

function getListItemGroupForEvent(item) {
	// TODO store this on the mapped item instead
	var itemType = "positions";
	if (item.IsChat === true) {
		itemType = "chat";
	} else if (item.MessageId !== undefined) {
		itemType = "messages";
	} else if (item.EventId !== undefined) {
		itemType = "events";
		if (EVENTS_STATUS.indexOf(item.Type) !== -1) {
			itemType = "status";
		} else if (EVENTS_ALERT.indexOf(item.Type) !== -1) {
			itemType = "alerts";
		} else if (EVENTS_EMERGENCY.indexOf(item.Type) !== -1) {
			itemType = "alerts";
		} else {
			itemType = "events";
		}
	}
	return itemType;
}

function renderListItemIcon(type) {
	var iconTypes = {
		positions: "map-marker-alt",
		events: "exclamation-square",
		status: "exclamation-circle",
		alerts: "exclamation-triangle",
		chat: "comments-alt",
		messages: "code",
		activity: "clock",
	};
	var typeIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
	var typeIconContents = document.createElementNS("http://www.w3.org/2000/svg", "use");
	typeIconContents.setAttributeNS(
		"http://www.w3.org/1999/xlink",
		"href",
		"/content/svg/tracking.svg?v=15#" + iconTypes[type]
	);
	typeIcon.appendChild(typeIconContents);
	return typeIcon;
}

function renderListItemPositionDetails(item) {
	if (item === undefined || item.Position === undefined) {
		return undefined;
	}

	var items = [
		renderDetailsItem(strings.SPEED, item.Position.Speed),
		renderDetailsItem(strings.ALTITUDE, item.Position.Altitude),
		renderDetailsItem(strings.HEADING, item.Position.Heading),
		renderDetailsItem(strings.ACCURACY, item.Position.Accuracy),
		renderDetailsItem(strings.DATA_SOURCE, item.Position.Source),
		renderDetailsItem(strings.STATUS, item.Position.Status),
		renderDetailsItem(strings.GEOFENCES, item.Position.InsideFences),
		renderDetailsItem(strings.ODOMETER, item.Position.Odometer),
	];
	var actualItems = _.filter(items, function (row) {
		return row !== undefined;
	});
	if (actualItems.length === 0) {
		return undefined;
	}

	var itemDetails = document.createDocumentFragment();
	var header = document.createElement("div");
	header.className = "event-details-header";

	var headerButton = document.createElement("button");
	headerButton.className = "btn btn-link";
	headerButton.setAttribute("data-toggle", "collapse");
	headerButton.setAttribute("data-target", "#position-details-" + item.Id);
	headerButton.setAttribute("aria-expanded", "false");

	headerButton.appendChild(renderListItemIcon("positions"));

	var headerTitle = document.createElement("span");
	headerTitle.className = "mr-auto";
	headerTitle.textContent = strings.DETAILS;
	headerButton.appendChild(headerTitle);

	var infoExpandIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
	infoExpandIcon.setAttribute("class", "list-item-action list-item-details");
	var infoExpandIconTitle = document.createElementNS("http://www.w3.org/2000/svg", "title");
	infoExpandIconTitle.textContent = strings.DETAILS;
	infoExpandIcon.appendChild(infoExpandIconTitle);
	var infoExpandIconContents = document.createElementNS("http://www.w3.org/2000/svg", "use");
	infoExpandIconContents.setAttributeNS(
		"http://www.w3.org/1999/xlink",
		"href",
		"/content/svg/tracking.svg?v=15#expand"
	);
	infoExpandIcon.appendChild(infoExpandIconContents);
	headerButton.appendChild(infoExpandIcon);

	header.appendChild(headerButton);

	var details = document.createElement("div");
	details.id = "position-details-" + item.Id;
	details.className = "event-details break-text collapse";

	var meta = document.createElement("div");
	meta.className = "item-metadata";

	_.each(actualItems, function (rowItem) {
		meta.appendChild(rowItem);
	});

	details.appendChild(meta);

	itemDetails.appendChild(header);
	itemDetails.appendChild(details);
	return itemDetails;
}

function renderDetailsItem(label, text) {
	if (text === undefined || text === null || text === "") {
		return undefined;
	}
	var itemLine = document.createElement("div");

	var itemLabel = document.createElement("span");
	itemLabel.className = "item-label";
	itemLabel.textContent = label + ": ";

	var itemText = document.createElement("span");
	itemText.textContent = text;
	itemLine.appendChild(itemLabel);
	itemLine.appendChild(itemText);
	return itemLine;
}
