import strings from "./strings.js";
import trkData from "./data.js";
import user from "./user.js";
import domNodes from "./domNodes.js";
import { findGroupById, createAssetNode } from "./asset-group.js";
import { isItemIncluded } from "./polyfills.js";
import { toggleGroupExpandedIcon } from "./asset-group.js";
import { sortItemsByMode } from "./item-sorting.js";
import { updateGroupVisibilityStatus, createGroupNode } from "./asset-group.js";
import { findAssetById } from "./assets.js";
import { createFenceNode } from "./fence.js";
import { saveItemCustomSortOrder } from "./item-sorting.js";
import { createSharedViewNode } from "./shared-view.js";
import { createPlaceNode } from "./place.js";
import { createTripNode } from "./trips.js";
import { resizeApp } from "./window-layout.js";

import $ from "jquery";
import $j from "jquery";
import _ from "lodash";

function populateSidePanelItems(data) {
	var itemNodes = {};
	var groupNodes = {};

	var groupItems = {}; // for all groups: groupId -> { groups: {*full child group object*}[], assets: {*full asset object*}[] }

	// initialize groups
	_.each(data.groups, function (group) {
		groupItems[group.Id] = { groups: [], items: [] };
	});

	// assign subgroups to groups
	_.each(data.groups, function (group) {
		if (group.ParentGroupId !== null && groupItems[group.ParentGroupId] !== undefined) {
			groupItems[group.ParentGroupId].groups.push(group);
			var parentGroup = data.groups.find(g => g.Id === group.ParentGroupId);
			if (parentGroup !== null) {
				parentGroup.GroupIds.push(group.Id);
			}
		}

		// render baseline group for dom
		groupNodes[group.Id] = createGroupNode(group);
	});

	// initialize group nodes cache and set colors for stylesheet
	_.each(groupNodes, function (group, key, list) {
		domNodes.groups[key] = groupNodes[key];
		var actualGroup = data.groups.find(g => g.Id === key);
		if (actualGroup !== null) {
			domNodes.groupColors[key] = actualGroup.Color;
		}
	});

	groupItems["all-" + data.itemType].items = sortItemsByMode(data.itemType, data.items, "all-" + data.itemType, data.itemType);
	var primaryGroups = sortItemsByMode(
		data.itemType,
		_.filter(data.groups, function (group) {
			return group.ParentGroupId === null && !group.IsDefault;
		}),
		data.itemType + "-root",
		"groups"
	);
	var primaryGroups = _.concat(
		_.filter(data.groups, function (group) {
			return group.IsDefault;
		}),
		primaryGroups
	)

	// assign items to groups
	_.each(data.items, function (item, index, list) {
		_.each(item.GroupIds, function (groupId, ind, li) {
			if (groupItems[groupId] !== undefined) {
				groupItems[groupId].items.push(item);
			}
		});

		// render baseline item for dom
		itemNodes[item.Id] = data.createItemNode(item);
	});

	// sort items and subgroups within groups
	_.each(groupItems, function (value, key, list) {
		list[key].items = sortItemsByMode(data.itemType, value.items, key, data.itemType);
		list[key].groups = sortItemsByMode(data.itemType, value.groups, key, "groups");
	});

	// console.log(groupItems);

	_.each(groupItems, function (group, key, list) {
		var groupContentsNode = groupNodes[key].querySelector(".group-contents");
		var groupContentsItems = groupContentsNode.querySelector("ul.group-list-list");
		var groupData = groupItems[key];

		// add subgroup nodes to group dom
		_.each(groupData.groups, function (subGroup, index, list) {
			// groups can only be assigned to one parent group, so do not clone their nodes
			groupContentsItems.appendChild(groupNodes[subGroup.Id]);
		});

		// add items nodes to group dom
		_.each(groupData.items, function (item, index, list) {
			// items can be added to multiple groups, so they must be cloned
			var groupItem = itemNodes[item.Id].cloneNode(true);

			groupContentsItems.appendChild(groupItem);
			if (data.domNodesItems[item.Id] === undefined) {
				data.domNodesItems[item.Id] = [];
			}
			data.domNodesItems[item.Id].push(groupItem);
		});

		// cache group content nodes so they can be detached/reattached to the dom
		// based on whether the group is expanded/visible
		domNodes.groupContents[key] = groupContentsNode;

		if (!isItemIncluded(user.displayPreferences.expandedGroups, key)) {
			// only render group contents when the group is visible (expanded)
			groupContentsNode.parentNode.removeChild(groupContentsNode);
			toggleGroupExpandedIcon(key, false);
		} else {
			toggleGroupExpandedIcon(key, true);
		}
	});

	// toggle active groups within fragment
	// by default groups are inactive unless they contain at least one active asset
	// this isn't done on creation as it would be pretty tricky and not worth the effort
	_.each(groupItems, function (group, key, list) {
		updateGroupVisibilityStatus(key, data.itemType);
	});

	var assetsGroupsFragment = document.createDocumentFragment();
	assetsGroupsFragment.appendChild(groupNodes["all-" + data.itemType]);
	_.each(primaryGroups, function (value, key, list) {
		assetsGroupsFragment.appendChild(groupNodes[value.Id]);
	});

	if (data.items.length === 0) {
		document.getElementById("no-" + data.itemType).classList.add("is-visible");
		document.getElementById(data.itemType + "-all").classList.remove("is-visible");
		document.getElementById("filter-" + data.itemType).querySelector(".filter-box").classList.remove("is-visible");
	} else {
		document.getElementById("no-" + data.itemType).classList.remove("is-visible");
		document.getElementById(data.itemType + "-all").classList.add("is-visible");
		document.getElementById("filter-" + data.itemType).querySelector(".filter-box").classList.add("is-visible");
	}

	var itemGroupsContainer = document.getElementById(data.itemType + "-all");
	itemGroupsContainer.appendChild(assetsGroupsFragment);
	// root item group sorting
	$(itemGroupsContainer).sortable({
		axis: "y",
		items: "> li.group",
		handle: "> .group-header .group-drag",
		update: function (event, ui) {
			saveItemCustomSortOrder();
		},
	});

	// only initialize the sorting when the group contents are rendered
	_.each(domNodes.groupContents, function (groupContentsNode, key, list) {
		if (groupContentsNode.parentNode != null)
		{
			initializeOrRefreshGroupSortable(key);
		}
	});
}

export function initializeOrRefreshGroupSortable(groupId) {
	// refresh the sortable so it can be aware of new items being added to the container
	if (domNodes.groupContents[groupId] == null) {
		console.log("unknown group contents for id", groupId);
		return;
	}
	var groupContentsNode = domNodes.groupContents[groupId];
	var groupContentsItems = groupContentsNode.querySelector("ul.group-list-list");
	if (groupContentsNode.classList.contains('ui-sortable')) {
		// already initialized, just refresh for newly added item(s)
		// subgroup ordering
		$(groupContentsItems).sortable("refresh");
		// group items ordering
		$(groupContentsNode).sortable("refresh");
	} else {
		// subgroup ordering
		$(groupContentsItems).sortable({
			axis: 'y',
			items: '> li.group',
			handle: '> .group-header .group-drag',
			update: function (event, ui) {
				saveItemCustomSortOrder();
			},
		});

		// group items ordering
		$(groupContentsNode).sortable({
			axis: 'y',
			items: '> .group-list-list > li.group-item',
			handle: '.item-drag',
			update: function (event, ui) {
				saveItemCustomSortOrder();
			},
		});

		domNodes.groups[groupId].setAttribute("data-sort-initialized", true);
	}
}

function populateSidePanelJourneys() {
	// journeys - just like asset groups
	if (trkData.journeys.length === 0) {
		// no journeys, add to journeys-all?
		var noExtrasNode = document.createElement("li");
		noExtrasNode.className = "toggle-content no-items is-visible";
		noExtrasNode.setAttribute("id", "no-journeys");
		var noExtrasText = document.createElement("span");
		noExtrasText.textContent = strings.NO_JOURNEYS;
		noExtrasNode.appendChild(noExtrasText);
		document.getElementById("journeys-all").appendChild(noExtrasNode);
		//document.getElementById('filter-journeys').querySelector('.filter-box').classList.remove('is-visible');
	}
	_.each(trkData.journeys, function (item) {
		var journeyAsset = findAssetById(item.AssetId);
		if (journeyAsset === null) {
			return;
		}
		var groupId = "journey-" + item.Id;
		var extraGroupNode = createGroupNode({ Id: groupId, Name: item.Name, Color: item.Color, Type: "journeys" });
		domNodes.groups[groupId] = extraGroupNode;

		var extraGroupContentsNode = extraGroupNode.querySelector(".group-contents");
		var extraGroupContentsItems = extraGroupContentsNode.querySelector("ul.group-list-list");
		var noExtrasNode = document.createElement("li");
		noExtrasNode.className = "toggle-content no-items";
		if (item.Trips.length === 0) {
			noExtrasNode.className += " is-visible";
			//document.getElementById('filter-journeys').querySelector('.filter-box').classList.remove('is-visible');
		}
		noExtrasNode.setAttribute("id", "no-" + groupId);
		var noExtrasText = document.createElement("span");
		noExtrasText.textContent = strings.NO_TRIPS;
		noExtrasNode.appendChild(noExtrasText);
		extraGroupContentsItems.appendChild(noExtrasNode);

		var journey = item;
		_.each(item.Trips, function (item, index, list) {
			var extraNode = createTripNode(journey, item, journeyAsset);
			domNodes.trips[item.Id] = extraNode;
			extraGroupContentsItems.appendChild(extraNode);
		});

		var extraAllItems = extraGroupNode.getElementsByClassName("journeys-item").length;
		var extraActiveItems = extraGroupNode.getElementsByClassName("journeys-item active").length;
		if (extraActiveItems > 0) {
			var extraVisibility = extraGroupNode.querySelector(".showhide");

			if (extraAllItems !== extraActiveItems) {
				extraVisibility
					.querySelector("use")
					.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#indeterminate");
				extraVisibility.classList.add("indeterminate");
			} else {
				extraVisibility
					.querySelector("use")
					.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#visible");
				extraVisibility.classList.add("active");
			}
			extraGroupNode.classList.add("active");
		}
		domNodes.groupContents[groupId] = extraGroupContentsNode;
		if (item.Color !== undefined && item.Color !== null) {
			domNodes.groupColors[groupId] = item.Color;
		}
		if (!isItemIncluded(user.displayPreferences.expandedGroups, groupId)) {
			// only render group contents when the group is visible (expanded)
			extraGroupContentsNode.parentNode.removeChild(extraGroupContentsNode);
			toggleGroupExpandedIcon(groupId, false);
		} else {
			toggleGroupExpandedIcon(groupId, true);
		}

		var allJourneys = document.getElementById("journeys-all");
		allJourneys.appendChild(extraGroupNode);

		updateGroupVisibilityStatus(groupId);
	});
}

function populateSidePanelExtraGroups() {
	var extraGroups = [{
		type: "shared-views",
		createNodeFunction: createSharedViewNode,
		name: strings.ALL_SHARED_VIEWS,
		color: null,
		emptyText: strings.NO_SHARED_VIEWS,
		noItemsText: strings.NO_SHARED_VIEWS_HINT,
		items: sortItemsByMode("shared-views", trkData.sharedViews, "all-shared-views", "shared-views"),
		nodeList: domNodes.sharedViews,
	}];

	_.each(extraGroups, function (group, index, list) {
		var groupId = "all-" + group.type;
		var extraGroupNode = createGroupNode({ Id: groupId, Name: group.name, Color: group.color, Type: group.type });
		domNodes.groups[groupId] = extraGroupNode;
		var extraGroupContentsNode = extraGroupNode.querySelector(".group-contents");
		var extraGroupContentsItems = extraGroupContentsNode.querySelector("ul.group-list-list");
		var noExtrasNode = document.createElement("li");
		noExtrasNode.className = "toggle-content no-items";
		if (group.items.length === 0) {
			noExtrasNode.className += " is-visible";
			document
				.getElementById("filter-" + group.type)
				.querySelector(".filter-box")
				.classList.remove("is-visible");
		} else {
			document
				.getElementById("filter-" + group.type)
				.querySelector(".filter-box")
				.classList.add("is-visible");
		}
		noExtrasNode.setAttribute("id", "no-" + groupId);

		if (group.type == "shared-views" && group.items.length === 0) {
			document.getElementById("no-shared-views").classList.add("is-visible");
			document.getElementById("shared-views-all").classList.remove("is-visible");
			//document.getElementById('filter-shared-views').classList.remove('is-visible');
		}

		var noExtrasText = document.createElement("span");
		noExtrasText.textContent = group.emptyText;
		noExtrasNode.appendChild(noExtrasText);
		extraGroupContentsItems.appendChild(noExtrasNode);

		_.each(group.items, function (item, index, list) {
			var extraNode = group.createNodeFunction(item);
			group.nodeList[item.Id] = extraNode;
			extraGroupContentsItems.appendChild(extraNode);
		});

		var extraAllItems = extraGroupNode.getElementsByClassName(group.type + "-item").length;
		var extraActiveItems = extraGroupNode.getElementsByClassName(group.type + "-item active").length;
		if (extraActiveItems > 0) {
			var extraVisibility = extraGroupNode.querySelector(".showhide");

			if (extraAllItems !== extraActiveItems) {
				extraVisibility
					.querySelector("use")
					.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#indeterminate");
				extraVisibility.classList.add("indeterminate");
			} else {
				extraVisibility
					.querySelector("use")
					.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#visible");
				extraVisibility.classList.add("active");
			}
			extraGroupNode.classList.add("active");
		}
		domNodes.groupContents[groupId] = extraGroupContentsNode;
		if (group.color !== undefined && group.color !== null) {
			domNodes.groupColors[groupId] = group.color;
		}
		if (!isItemIncluded(user.displayPreferences.expandedGroups, "all-" + group.type)) {
			// only render group contents when the group is visible (expanded)
			extraGroupContentsNode.parentNode.removeChild(extraGroupContentsNode);
			toggleGroupExpandedIcon(groupId, false);
		} else {
			toggleGroupExpandedIcon(groupId, true);
		}

		// insert into separate panel/container
		var container = document.getElementById(group.type + "-all");
		container.appendChild(extraGroupNode);

		updateGroupVisibilityStatus(groupId);

		$(extraGroupContentsNode).sortable({
			axis: "y",
			items: "> .group-list-list > li.group-item",
			handle: ".item-drag",
			update: function (event, ui) {
				saveItemCustomSortOrder();
			},
		});
	});
}

export function populateSidePanel() {
	populateSidePanelItems({
		itemType: "assets",
		groups: trkData.groups,
		items: trkData.assets,
		createItemNode: createAssetNode,
		domNodesItems: domNodes.assets
	});
	populateSidePanelItems({
		itemType: "places",
		groups: trkData.placeGroups,
		items: trkData.places,
		createItemNode: createPlaceNode,
		domNodesItems: domNodes.places
	});
	populateSidePanelItems({
		itemType: "fences",
		groups: trkData.fenceGroups,
		items: trkData.fences,
		createItemNode: createFenceNode,
		domNodesItems: domNodes.fences
	});
	populateSidePanelJourneys();
	populateSidePanelExtraGroups();
}

export function hideSidePanel() {
	$j("#topbar-left").css("left", "-25em");
	$j("#topbar-right").css("margin-left", "0");
	$j("#panel").hide();
	$j("#map").css("left", 0).css("margin-right", 0);
	$j("#map_tools,#map_tools_bottom,#map_panels").css("margin-left", 0);
	$j("#panel-expand").show();
	$j("#ds-v .shade, #event-panel,#event-panel-container").css("left", "0");
	$j("#panel-contract").hide();
	resizeApp(true);
}

export function showSidePanel() {
	$j("#topbar-left").css("left", "0");
	$j("#topbar-right").css("margin-left", "25em");
	$j("#panel").show();
	$j("#map").css("left", "25em").css("margin-right", "25em");
	$j("#map_tools,#map_tools_bottom,#map_panels").css("margin-left", "25em");
	$j("#panel-contract").show();
	$j("#ds-v .shade, #event-panel,#event-panel-container").css("left", "25em");
	$j("#panel-expand").hide();
	resizeApp(true);
}
