import strings from "./strings.js";
import trkData from "./data.js";
import domNodes from "./domNodes.js";
import state from "./state.js";
import { map } from "./map-base.js";
import { addItemToMap, removeItemFromMap } from "./map-items.js";
import user from "./user.js";
import { viewModes, trkDataGroups } from "./const.js";
import { resizeApp } from "./window-layout.js";
import { findAssetById, normalizeAssetData, findAssetIdsUnderGroup } from "./assets.js";
import options from "./options.js";
import { sortModes } from "./const.js";
import { moveOrOpenDialogRelativeTo } from "./modal-dialog.js";
import { loadDialogButtons, closeButton } from "./modal-dialog-buttons.js";
import { setMapBounds } from "./map-bounds.js";
import { convertHexToSortable, convertNamedColorToHex, getItemHexColor } from "./color.js";
import { createMarkerPath } from "./marker-path.js";
import { sortItemsByMode, resortItemGroup, toggleItemSorting } from "./item-sorting.js";
import { closeSecondaryPanel } from "./panel.js";
import { handleLimitedDataResult } from "./limited-data.js";
import { changeMapType } from "./map-maptype.js";
import { updateAssetFunctionBadges } from "./badges.js";
import { createMarkerCluster } from "./marker-cluster.js";
import { initSharedViewDialog, openSharedViewShareDialog } from "./shared-view-dialog.js";
import { handleAjaxFormSubmission } from "./ajax.js";
import { addPositionMarkerToPoint } from "./marker.js";
import { restrictAllChildren, unrestrictDirectChildren } from "./dom-util.js";
import { productTitle } from "./entry_point.js";
import { showResultLimitsIfApplicable } from "./item-listing.js";
import { findGroupById, updateGroupVisibilityStatus } from "./asset-group.js";
import { updateActiveAssetInformation } from "./assets-active.js";
import { createPositionLinesForAsset } from "./geometry-create.js";
import { getSvgIconForItemType, createDialogTitleFragment } from "./dom-util.js";
import { updateMapModeDateRange } from "./map-ui.js";
import { highlightActiveItemInPrimaryPanel } from "./panel.js";
import { openSharedViewSettingsPanel } from "./panel-settings.js";
import templates from "./templates.js";
import { goToWizardStep } from "./shared-view-dialog.js";
import {
	populateSharedViewInformation,
	populateSharedViewSubmissionFromForm,
	getSharedViewSelectionOptions,
} from "./shared-view-populate.js";
import { createQuickActionsForSharedView } from "./quick-actions.js";
import { updateMapModePanel } from "./map-ui.js";
import log from "./log.js";

import $ from "jquery";
import _ from "lodash";
import L from "leaflet";
import moment from "moment"; // https://www.npmjs.com/package/moment

/*global JsSearch, MapToolbar */
// import JsSearch from '../js-search.js';

export function initSharedView() {
	var sharedViewShareButtons = [
		{
			buttonType: "primary",
			svgIcon: "envelope-solid",
			text: strings.SEND_INVITE,
			id: "SharedViewShareButton",
			click: function () {
				var isValid = $(trkData.validation.sharedViewShare.currentForm).valid();
				if (!isValid) {
					trkData.validation.sharedViewShare.focusInvalid();
					return;
				}

				var dialog = domNodes.dialogs.sharedViewShare;
				var status = dialog.querySelector(".dialog-status");
				var btn = this;

				var id = parseInt(domNodes.panels.secondary.getAttribute("data-item-id"));
				var email = document.getElementById("SharedViewShareEmail").value;
				var subject = document.getElementById("SharedViewShareSubject").value;
				var body = document.getElementById("SharedViewShareBody").value;
				var includePassword = document.getElementById("SharedViewShareIncludePasswordYes").checked;

				var data = {
					id: id,
					email: email,
					subject: subject,
					body: body,
					includePassword: includePassword,
				};

				handleAjaxFormSubmission(
					"SendSharedViewInvites",
					data,
					btn,
					status,
					strings.MSG_SHARE_SHARED_VIEW_SUCCESS,
					strings.MSG_SHARE_SHARED_VIEW_ERROR,
					function (result) {
						// reload form defaults
						trkData.validation.sharedViewShare.resetForm();
						trkData.validation.sharedViewShare.currentForm.reset();

						var sharedView = findSharedViewById(id);
						if (sharedView !== null) {
							querySharedViewInvites(sharedView);
						}
					}
				);
			},
		},
		closeButton,
	];

	loadDialogButtons(domNodes.dialogs.sharedViewShare, sharedViewShareButtons);

	loadDialogButtons(domNodes.dialogs.sharedViewStatistics, [closeButton]);
	$("#shared-view-visitor-activity").dataTable({
		destroy: true,
		filter: false,
		info: false,
		jQueryUI: false,
		autoWidth: false,
		lengthChange: false,
		paging: false,
		pageLength: 3,
		deferRender: true,
		order: [[2, "desc"]],
		columnDefs: [
			{
				targets: "_all",
				render: $.fn.dataTable.render.text(),
			},
		],
		columns: [
			{}, // name
			{}, // visits
			{}, // last visit
		],
		language: strings.DATATABLE,
		drawCallback: function (oSettings) {},
		initComplete: function (oSettings, json) {
			$("#shared-view-visitor-activity").DataTable().clear();
		},
	});

	var sharedViewButtons = [
		{
			buttonType: "primary",
			text: strings.CREATE_SHARED_VIEW,
			id: "UpdateSharedViewButton",
			click: function () {},
		},
		closeButton,
	];

	trkData.wizards.sharedView = domNodes.dialogs.sharedView.querySelector(".step-wizard");
	trkData.wizards.sharedView.steps = [];
	_.each(trkData.wizards.sharedView.querySelectorAll(".wizard-step"), function (item) {
		trkData.wizards.sharedView.steps.push({
			id: item.getAttribute("data-step-id"),
			step: parseInt(item.getAttribute("data-step-step")),
			title: item.getAttribute("data-step-title"),
			icon: item.getAttribute("data-step-icon"),
			form: $(item.querySelector("form")).validate({
				ignore: ":hidden:not(#SharedViewShareOne)",
				showErrors: function (errorMap, errorList) {
					if (errorMap.SharedViewShareOne !== undefined) {
						// turn it red
						document.getElementById("SharedViewShareOneHint").classList.add("invalid-feedback");
					} else {
						this.defaultShowErrors();
					}
				},
				errorPlacement: function (error, element) {
					// error = error element
					if (element.id !== "SharedViewShareOne" && element.id !== "SharedViewDataTimeframeRelativeNumber") {
						error.addClass("invalid-feedback");
						error.insertAfter(element);
					}
				},
				unhighlight: function (element, errorClass, validClass) {
					console.log("unhighlight", element, errorClass, validClass);
					if (element.id === "SharedViewShareOne") {
						document.getElementById("SharedViewShareOneHint").classList.remove("invalid-feedback");
					} else {
						$(element).removeClass(errorClass).addClass(validClass);
					}
				},
			}), // this should happen after jquery validation initialization
		});
	});
	trkData.wizards.sharedView.steps = _.sortBy(trkData.wizards.sharedView.steps, "step");

	loadDialogButtons(domNodes.dialogs.sharedView, sharedViewButtons);
	$(domNodes.dialogs.sharedView).on("click", "input[name=SharedViewIsPublic]", function (e) {
		var isPrivate = document.getElementById("SharedViewIsPublicNo").checked;
		if (isPrivate) {
			document.getElementById("SharedViewPrivatePassword").classList.add("is-visible");
		} else {
			document.getElementById("SharedViewPrivatePassword").classList.remove("is-visible");
		}
	});
	$(domNodes.dialogs.sharedView).on("click", "#ShareViewShowAll", function (e) {
		e.preventDefault();
		closeSecondaryPanel();
	});
	$(domNodes.dialogs.sharedView).on("click", "#ShareViewEdit", function (e) {
		e.preventDefault();
		if (trkData.sharedView.current !== null) {
			openSharedViewSettingsPanel(trkData.sharedView.current);
		}
	});
	$(domNodes.infoDialogs.sharedViewInformation).on("click", "#SharedViewMapInformationShare", function (e) {
		e.preventDefault();
		if (trkData.sharedView.current !== null) {
			openSharedViewSettingsPanel(findSharedViewById(trkData.sharedView.current.Id));
		}
	});
	$(domNodes.dialogs.sharedView).on("click", "#ShareViewCompleteEmail", function (e) {
		e.preventDefault();
		if (trkData.sharedView.current !== null) {
			openSharedViewShareDialog(findSharedViewById(trkData.sharedView.current.Id));
		}
	});
	$(domNodes.dialogs.sharedView).on("click", "input[name=SharedViewDoesExpire]", function (e) {
		var doesExpire = document.getElementById("SharedViewDoesExpireYes").checked;
		if (doesExpire) {
			document.getElementById("SharedViewExpireDate").classList.add("is-visible");
			document.getElementById("SharedViewExpiresOn").disabled = false;
		} else {
			document.getElementById("SharedViewExpireDate").classList.remove("is-visible");
			document.getElementById("SharedViewExpiresOn").disabled = true;
		}
	});
	$(domNodes.dialogs.sharedView).on("change", "#SharedViewMapType", function (e) {
		var editing = trkData.sharedView.temp;
		if (editing !== null) {
			var prior = cloneSharedView(editing);
			editing.Preferences.MapType = parseInt(this.value);
			compareSharedViews(editing, prior);
		}
	});

	//$(domNodes.dialogs.sharedView).on('change', '#SharedViewDefaultMode', function(e) {
	//    if (parseInt(this.value) === mapModes.HISTORY) {
	//        document.getElementById('SharedViewModeHistory').classList.add('is-visible');
	//    } else {
	//        document.getElementById('SharedViewModeHistory').classList.remove('is-visible');
	//    }
	//});

	$(domNodes.dialogs.sharedView).on("click", "input[name=SharedViewPositionConsolidation]", function (e) {
		var isPositionsConsolidated = document.getElementById("SharedViewPositionConsolidationYes").checked;
		var editing = trkData.sharedView.temp;
		if (editing !== null) {
			var prior = cloneSharedView(editing);
			editing.Preferences.PositionConsolidation = isPositionsConsolidated;
			compareSharedViews(editing, prior);
		}
	});
	$(domNodes.dialogs.sharedView).on("click", "input[name=SharedViewRemoveRoads]", function (e) {
		var isRemoveRoads = document.getElementById("SharedViewRemoveRoadsYes").checked;
		var editing = trkData.sharedView.temp;
		if (editing !== null) {
			var prior = cloneSharedView(editing);
			editing.Preferences.RemoveRoads = isRemoveRoads;
			compareSharedViews(editing, prior);
		}
	});
	$(domNodes.dialogs.sharedView).on("click", "input[name=SharedViewIsMessagingEnabled]", function (e) {
		var isMessagingEnabled = document.getElementById("SharedViewIsMessagingEnabledYes").checked;
		var editing = trkData.sharedView.temp;
		if (editing !== null) {
			var prior = cloneSharedView(editing);
			editing.IsMessagingEnabled = isMessagingEnabled;
			compareSharedViews(editing, prior);
		}
	});

	function sharedViewDateChange() {
		console.log("sharedViewDateChange");
		var editing = trkData.sharedView.temp;
		if (editing === null) {
			return;
		}

		var prior = cloneSharedView(editing);
		var isRelative = document.getElementById("SharedViewDataTimeframeRelative").checked;
		var isDataLimited = document.getElementById("SharedViewDataTimeframeCustom").checked;

		if (isRelative) {
			editing.IsTimeframeRelative = true;
			editing.RelativeTimeframeNumber = document.getElementById("SharedViewDataTimeframeRelativeNumber").value;
			editing.RelativeTimeframeType = document.getElementById("SharedViewDataTimeframeRelativeType").value;
			editing.FromDateEpoch = null;
			editing.ToDateEpoch = null;
		} else {
			editing.IsTimeframeRelative = false;
			editing.RelativeTimeframeNumber = null;
			editing.RelativeTimeframeType = null;
			if (isDataLimited) {
				var from = $(document.getElementById("SharedViewFrom")).datetimepicker("getDate");
				if (from !== null) {
					from = from.getTime();
				}
				var to = $(document.getElementById("SharedViewTo")).datetimepicker("getDate");
				if (to !== null) {
					to = to.getTime();
				}
				editing.FromDateEpoch = from;
				editing.ToDateEpoch = to;
			} else {
				editing.FromDateEpoch = null;
				editing.ToDateEpoch = null;
			}
		}
		compareSharedViews(editing, prior);
	}
	$(domNodes.dialogs.sharedView).on("click", "input[name=SharedViewDataTimeframe]", function (e) {
		// enable/disable inputs based on which radio is selected
		var isRelative = document.getElementById("SharedViewDataTimeframeRelative").checked;
		if (isRelative) {
			document.getElementById("SharedViewRelativeDateRange").classList.add("is-visible");
			document.getElementById("SharedViewDataTimeframeRelativeNumber").disabled = false;
			document.getElementById("SharedViewDataTimeframeRelativeType").disabled = false;
			document.getElementById("SharedViewDateRange").classList.remove("is-visible");
			document.getElementById("SharedViewFrom").disabled = true;
			document.getElementById("SharedViewTo").disabled = true;
		} else {
			document.getElementById("SharedViewRelativeDateRange").classList.remove("is-visible");
			document.getElementById("SharedViewDataTimeframeRelativeNumber").disabled = true;
			document.getElementById("SharedViewDataTimeframeRelativeType").disabled = true;
			var isLimited = document.getElementById("SharedViewDataTimeframeCustom").checked;
			if (isLimited) {
				document.getElementById("SharedViewDateRange").classList.add("is-visible");
				document.getElementById("SharedViewFrom").disabled = false;
				document.getElementById("SharedViewTo").disabled = false;
			} else {
				document.getElementById("SharedViewDateRange").classList.remove("is-visible");
				document.getElementById("SharedViewFrom").disabled = true;
				document.getElementById("SharedViewTo").disabled = true;
			}
		}
		sharedViewDateChange();
	});
	$(domNodes.dialogs.sharedView).on(
		"change",
		"#SharedViewFrom,#SharedViewTo,#SharedViewDataTimeframeRelativeNumber,#SharedViewDataTimeframeRelativeType",
		sharedViewDateChange
	);
	$(domNodes.dialogs.sharedView).on(
		"change",
		"#SharedViewPermissionsGroups,#SharedViewPermissionsAssets,#SharedViewPermissionsGeofences,#SharedViewPermissionsPlaces,#SharedViewPermissionsDrivers",
		function (e) {
			var collapse = document.getElementById(this.getAttribute("aria-controls"));
			if (this.checked) {
				$(collapse).collapse("show");
			} else {
				$(collapse).collapse("hide");
			}
			var editing = trkData.sharedView.temp;
			if (editing !== null) {
				var permissions = getSharedViewSelectionOptions();
				var prior = cloneSharedView(editing);
				editing.AssetIds = permissions.assetIds;
				editing.FenceIds = permissions.fenceIds;
				editing.AssetGroupIds = permissions.assetGroupIds;
				editing.PlaceIds = permissions.placeIds;
				editing.DriverIds = permissions.driverIds;
				compareSharedViews(editing, prior);
			}
		}
	);
	$(domNodes.dialogs.sharedView).on("click", "input[name=SharedViewAssetGroupIds]", function (e) {
		var cont = document.getElementById("shared-view-groups-list");
		if (this.checked) {
			restrictAllChildren(this, cont);
		} else {
			unrestrictDirectChildren(this, cont);
		}
	});
	$(domNodes.dialogs.sharedView).on(
		"change",
		"input[name=SharedViewAssetGroupIds],input[name=SharedViewAssetIds],input[name=SharedViewPlaceIds],input[name=SharedViewFenceIds],input[name=SharedViewDriverIds]",
		function (e) {
			// this is happening before the input checkbox is checked
			//
			console.log("permissions item click");
			var editing = trkData.sharedView.temp;
			if (editing !== null) {
				var permissions = getSharedViewSelectionOptions();
				var prior = cloneSharedView(trkData.sharedView.temp);
				editing.AssetIds = permissions.assetIds;
				editing.FenceIds = permissions.fenceIds;
				editing.AssetGroupIds = permissions.assetGroupIds;
				editing.PlaceIds = permissions.placeIds;
				editing.DriverIds = permissions.driverIds;
				compareSharedViews(editing, prior);
			}
		}
	);

	initSharedViewDialog();

	$(domNodes.dialogs.sharedView).on("click", ".wizard-buttons .cancel", function (e) {
		e.preventDefault();
		_.each(trkData.wizards.sharedView.steps, function (step) {
			if (step.form !== undefined) {
				step.form.resetForm();
				step.form.currentForm.reset();
			}
		});
		closeSecondaryPanel();
	});
	$(domNodes.dialogs.sharedView).on("click", ".cancel", function (e) {
		e.preventDefault();

		_.each(trkData.wizards.sharedView.steps, function (step) {
			if (step.form !== undefined) {
				step.form.resetForm();
				step.form.currentForm.reset();
			}
		});
		document.getElementById("panel-dialog-back").click();
	});
	$("#SharedViewExpiresOn").datetimepicker({
		dateFormat: user.shortDateFormat,
		hour: 0,
		minute: 0,
		minDate: options.dateRangeMin === null ? null : new Date(options.dateRangeMin),
		maxDate: options.dateRangeMax === null ? null : new Date(options.dateRangeMax),
	});
	$("#SharedViewFrom").datetimepicker({
		dateFormat: user.shortDateFormat,
		hour: 0,
		minute: 0,
		minDate: options.dateRangeMin === null ? null : new Date(options.dateRangeMin),
		maxDate: options.dateRangeMax === null ? null : new Date(options.dateRangeMax),
		onSelect: function (selectedDate) {
			// when a date is selected here, set the min date for the to picker
			var current = $("#SharedViewTo").val();
			$("#SharedViewTo").datetimepicker("option", "minDate", $(this).datetimepicker("getDate"));
			$("#SharedViewTo").val(current);
		},
	});
	$("#SharedViewTo").datetimepicker({
		dateFormat: user.shortDateFormat,
		hour: 23,
		minute: 59,
		minDate: options.dateRangeMin === null ? null : new Date(options.dateRangeMin),
		maxDate: options.dateRangeMax === null ? null : new Date(options.dateRangeMax),
		onSelect: function (selectedDate) {
			// when a date is selected here, set the max date for the from picker
			var current = $("#SharedViewFrom").val();
			$("#SharedViewFrom").datetimepicker("option", "maxDate", $(this).datetimepicker("getDate"));
			$("#SharedViewFrom").val(current);
		},
	});

	$(domNodes.dialogs.sharedView).on("click", "#UpdateSharedViewButton", function (e) {
		var step = trkData.wizards.sharedView.currentStep;
		var isValid = $(step.form.currentForm).valid();
		if (!isValid) {
			step.form.focusInvalid();
			return;
		}

		var dialog = domNodes.dialogs.sharedView;
		var status = dialog.querySelector(".dialog-status");
		var btn = this;

		// really should just be updating the current step, but probably ok to do it all
		var data = populateSharedViewSubmissionFromForm();

		handleAjaxFormSubmission(
			"UpdateSharedView",
			data,
			btn,
			status,
			strings.MSG_EDIT_SHARED_VIEW_SUCCESS,
			strings.MSG_EDIT_SHARED_VIEW_ERROR,
			function (result) {
				updateSharedView(result.SharedView);

				trkData.sharedView.temp = result.SharedView;
				trkData.sharedView.current = result.SharedView;
				showSharedViewInformationOnMap(result.SharedView, false);
			}
		);
	});

	$(domNodes.dialogs.sharedView).on("click", "#SharedViewWizardSave", function (e) {
		e.preventDefault();

		var isFormValid = true;
		var invalidStep = null;
		_.each(trkData.wizards.sharedView.steps, function (step) {
			if (step.form !== undefined) {
				var isValid = $(step.form.currentForm).valid();
				if (!isValid) {
					isFormValid = false;
					invalidStep = step;
				}
			}
			return;
		});

		if (!isFormValid) {
			invalidStep.form.focusInvalid();
			return;
		}

		var dialog = domNodes.dialogs.sharedView;
		var status = dialog.querySelector(".dialog-status");
		var btn = this;
		var data = populateSharedViewSubmissionFromForm();
		delete data.id;
		handleAjaxFormSubmission(
			"AddSharedView",
			data,
			btn,
			status,
			null,
			strings.MSG_CREATE_SHARED_VIEW_ERROR,
			function (result) {
				var sharedView = result.SharedView;
				addSharedView(sharedView);

				trkData.sharedView.current = sharedView;
				highlightActiveItemInPrimaryPanel("shared-views", sharedView.Id);

				// show success step
				goToWizardStep(trkData.wizards.sharedView, "success");

				// populate share links
				dialog.querySelector(".share-view-share.email").setAttribute("data-share-view-id", sharedView.Id);
				dialog
					.querySelector(".share-view-share.twitter")
					.setAttribute(
						"href",
						"https://twitter.com/intent/tweet?url=" +
							encodeURIComponent(sharedView.Link) +
							"&text=" +
							encodeURIComponent(sharedView.Name) +
							". " +
							encodeURIComponent(sharedView.Description)
					);
				dialog
					.querySelector(".share-view-share.linkedin")
					.setAttribute(
						"href",
						"https://www.linkedin.com/shareArticle?mini=true&url=" +
							encodeURIComponent(sharedView.Link) +
							"&title=" +
							encodeURIComponent(sharedView.Name) +
							"&summary=" +
							encodeURIComponent(sharedView.Description) +
							"&source=" +
							encodeURIComponent(productTitle)
					);
				dialog
					.querySelector(".share-view-share.facebook")
					.setAttribute("href", "https://www.facebook.com/sharer.php?u=" + encodeURIComponent(sharedView.Link));
				dialog.querySelector("#ShareViewCopyLink").setAttribute("data-share-view-id", sharedView.Id);
				dialog.querySelector("#ShareViewCopyLink").setAttribute("data-link", sharedView.Link);
				document.getElementById("ShareViewCopyLinkLink").textContent = sharedView.Link;
				document.getElementById("ShareViewCopyLinkLink").href = sharedView.Link;
			}
		);
	});
}

export function findSharedViewById(id) {
	var item = _.find(trkData.sharedViews, { Id: parseInt(id) });
	return item === undefined ? null : item;
}

export function initializeSharedViewInformationDialog() {
	domNodes.infoDialogs.sharedViewInformation.parentNode.classList.add("default-state");
	let $dialog = $(domNodes.infoDialogs.sharedViewInformation);
	$dialog.dialog("option", "title", createDialogTitleFragment(strings.EDIT_MODE + " - " + strings.SHARED_VIEWS));
	var dialogPanel = document.getElementById("dialog-shared-view-information");
	var dialogTitleName = dialogPanel.querySelector("div.ui-dialog-titlebar");

	dialogTitleName.classList.add("has-svg-icon");
	dialogTitleName.style.backgroundImage = null;

	dialogTitleName
		.querySelector("use")
		.setAttributeNS("http://www.w3.org/1999/xlink", "href", getSvgIconForItemType("shared-views"));
	dialogTitleName
		.querySelector("svg")
		.setAttribute("style", "color: #bbbbbb; --color-primary: #bbbbbb; --color-secondary: #bbbbbb");
}

export function deselectSharedView() {
	console.log("shared view de-selected. none active.");
	_.each(domNodes.sharedViews, clearSettingsNode);
	domNodes.panels.secondary.removeAttribute("data-chat-enabled");
	clearSharedViewData(true);
	trkData.sharedView.current = null;
	trkData.sharedView.temp = null;

	initializeSharedViewInformationDialog();

	var $dialog = $(domNodes.infoDialogs.sharedViewInformation);
	if (!$dialog.dialog("isOpen")) {
		moveOrOpenDialogRelativeTo($dialog, map._container, "center center");
		$dialog.off("dialogclose");
	}

	//$(domNodes.infoDialogs.sharedViewInformation).dialog('close');
	// resetting map type to prior
	trkData.isSatelliteLabelOverlayEnabled = trkData.sharedView.priorIsSatelliteLabelOverlayEnabled;
	changeMapType(trkData.sharedView.priorMapType);
}

export function selectSharedView(sharedView) {
	if (sharedView === null) {
		deselectSharedView();
		return;
	}

	domNodes.infoDialogs.sharedViewInformation.parentNode.classList.remove("default-state");

	if (trkData.sharedView.current !== null) {
		// clear out previous shared view
		console.log("selected shared view changed?"); // handled by other close callback?
	}

	if (trkData.sharedView.current === sharedView) {
		// nothing's changed
		console.log("selected shared view did not change.");
		return;
	}

	// deselect previous
	_.each(domNodes.sharedViews, clearSettingsNode);
	console.log("selected shared view changed");
	highlightActiveItemInPrimaryPanel("shared-views", sharedView.Id);
	trkData.sharedView.current = sharedView;
	clearSharedViewData(true);
	querySharedViewData(sharedView, null, false);

	// if secondary panel is opened, hide the sharedviewinformation dialog
	showSharedViewInformationOnMap(sharedView, !state.openPanels.secondary);
}

function showSharedViewInformationOnMap(sharedView, doOpen) {
	if (sharedView === undefined || sharedView === null) {
		return;
	}

	domNodes.infoDialogs.sharedViewInformation.parentNode.classList.remove("default-state");
	var dialog = domNodes.infoDialogs.sharedViewInformation;
	var $dialog = $(dialog);
	var content = populateSharedViewInformation(sharedView);
	createQuickActionsForSharedView(sharedView);
	dialog.setAttribute("data-shared-view-id", sharedView.Id);
	$dialog.data.sharedViewId = sharedView.Id;

	var contentArea = dialog.querySelector(".content-selected");

	while (contentArea.firstChild) {
		contentArea.removeChild(contentArea.firstChild);
	}
	contentArea.appendChild(content);

	$dialog.dialog("option", "title", createDialogTitleFragment(sharedView.Name, strings.SHARED_VIEWS));
	var dialogPanel = document.getElementById("dialog-shared-view-information");
	var dialogTitleName = dialogPanel.querySelector("div.ui-dialog-titlebar");

	dialogTitleName.classList.add("has-svg-icon");
	dialogTitleName.style.backgroundImage = null;

	dialogTitleName
		.querySelector("use")
		.setAttributeNS("http://www.w3.org/1999/xlink", "href", getSvgIconForItemType("shared-views", sharedView));
	dialogTitleName
		.querySelector("svg")
		.setAttribute(
			"style",
			"color: " +
				sharedView.Color +
				"; --color-primary: " +
				sharedView.Color +
				"; --color-secondary: " +
				sharedView.Color
		);

	if (doOpen) {
		moveOrOpenDialogRelativeTo($dialog, map._container, "center center");
		$dialog.unbind("dialogclose");
	}
}

export function clearSettingsNode(node) {
	if (node.classList.contains("settings-active")) {
		node.classList.remove("settings-active");
		var settingsIcon = node.querySelector(".item-settings");
		if (settingsIcon !== null) {
			var use = settingsIcon.querySelector("use");
			use.setAttributeNS(
				"http://www.w3.org/1999/xlink",
				"href",
				use.getAttributeNS("http://www.w3.org/1999/xlink", "href").replace("-solid", "")
			);
		}
	}
}

export function clearSharedViewData(clearLimitedData) {
	console.log("clearSharedViewData");
	_.each(trkData.sharedView.markers, function (item) {
		removeItemFromMap(item, null, viewModes.SHARED_VIEW);
	});
	_.each(trkData.sharedView.mapLinesByAssetId, function (item) {
		removeItemFromMap(item, null, viewModes.SHARED_VIEW);
	});
	_.each(trkData.sharedView.markerClustersByAssetId, function (item) {
		removeItemFromMap(item, null, viewModes.SHARED_VIEW);
	});
	_.each(trkData.sharedView.fenceMarkers, function (item) {
		removeItemFromMap(item, null, viewModes.SHARED_VIEW);
	});
	_.each(trkData.sharedView.placeMarkers, function (item) {
		removeItemFromMap(item, null, viewModes.SHARED_VIEW);
	});

	trkData.sharedView.markers = [];
	trkData.sharedView.fenceMarkers = [];
	trkData.sharedView.placeMarkers = [];
	trkData.sharedView.events = [];
	trkData.sharedView.normalizedEvents = [];
	trkData.sharedView.isMessagesLoaded = false;
	trkData.sharedView.normalizedMessages = [];
	trkData.sharedView.normalizedEventIds = {};
	trkData.sharedView.markerClustersByAssetId = {};
	trkData.sharedView.mapLinesByAssetId = {};
	trkData.sharedView.positionsByAssetId = {};
	trkData.sharedView.markersByAssetId = {};
	trkData.sharedView.normalizedPositions = [];
	trkData.sharedView.normalizedPositionsByAssetId = {};
	trkData.sharedView.messageCountsByAssetId = {};
	trkData.sharedView.fromDate = null;
	trkData.sharedView.toDate = null;
	trkData.sharedView.isDateFiltered = false;
	trkData.sharedView.isLimited = false;
	trkData.sharedView.isLoadedLimitedData = false;
	trkData.sharedView.messageCounts = {
		FromMobile: 0,
		ToMobile: 0,
		FromMobileChats: 0,
		ToMobileChats: 0,
		FromMobileDevice: 0,
		ToMobileDevice: 0,
	};
	if (clearLimitedData) {
		trkData.sharedView.limitedData = null;
		updateMapModePanel();
		updateActiveAssetInformation(viewModes.SHARED_VIEW);
	} else {
		showResultLimitsIfApplicable({ IsLimited: false, IncludesLimitedData: false }, false);
	}
}

export function querySharedViewData(sharedView, dateFilter, loadLimitedData) {
	console.log("query shared view", sharedView);
	var fromUtc = null;
	var fromUtcEpoch = null;
	var toUtc = null;
	var toUtcEpoch = null;

	// override shared view's date ranges to show data that otherwise would exceed display limits
	var isDateFiltered = false;
	if (dateFilter !== undefined && dateFilter !== null) {
		fromUtc = dateFilter.fromUtc;
		toUtc = dateFilter.toUtc;
		isDateFiltered = true;
	} else {
		if (sharedView.IsTimeframeRelative) {
			var momentType = sharedView.RelativeTimeframeType === "m" ? "M" : sharedView.RelativeTimeframeType;
			var fromLocal = moment().subtract(sharedView.RelativeTimeframeNumber, momentType).subtract(user.tickOffset, "ms");
			//fromUtcEpoch = moment.utc()
			fromUtc = moment.utc().subtract(sharedView.RelativeTimeframeNumber, momentType).format(user.dateFormat);
			document.getElementById("txtDateFrom").value = fromLocal.format(user.dateFormat); // utc to local
			document.getElementById("txtDateTo").value = "";
		} else {
			if (sharedView.FromDateEpoch !== null) {
				var fromLocal = moment(sharedView.FromDateEpoch);
				fromUtc = moment.utc(sharedView.FromDateEpoch).format(user.dateFormat);
				document.getElementById("txtDateFrom").value = fromLocal
					.subtract(user.tickOffset, "ms")
					.format(user.dateFormat); // utc to local
			} else {
				document.getElementById("txtDateFrom").value = "";
			}
			if (sharedView.ToDateEpoch !== null) {
				var toLocal = moment(sharedView.ToDateEpoch);
				toUtc = moment.utc(sharedView.ToDateEpoch).format(user.dateFormat);
				document.getElementById("txtDateTo").value = toLocal.subtract(user.tickOffset, "ms").format(user.dateFormat); // utc to local
			} else {
				document.getElementById("txtDateTo").value = "";
			}
		}
	}

	var assetIds = sharedView.AssetIds.slice(0);
	var assetGroupIds = sharedView.AssetGroupIds;
	_.each(assetGroupIds, function (assetGroupId) {
		var group = findGroupById(assetGroupId);
		if (group !== null) {
			var groupAssetIds = findAssetIdsUnderGroup(group);
			assetIds = assetIds.concat(groupAssetIds);
		}
	});
	var sharedViewId = null;
	if (sharedView !== null) {
		sharedViewId = sharedView.Id;
	}

	var data = {
		sharedViewId: sharedViewId,
		fromDate: fromUtc, // UTC
		toDate: toUtc, // UTC
		isMessagingEnabled: sharedView.IsMessagingEnabled,
		assetIds: _.uniq(assetIds),
		loadLimitedData: loadLimitedData,
		isMobile: state.isMobile,
		format: user.dateFormat,
		lang: user.dateCulture,
	};

	// set some dates in the map mode panel
	trkData.sharedView.fromDate = fromUtc;
	trkData.sharedView.toDate = toUtc;

	return handleAjaxFormSubmission(
		"GetSharedViewData",
		data,
		null,
		null,
		null,
		strings.MSG_QUERY_SHARED_VIEW_ERROR,
		function (result) {
			if (trkData.sharedView.current !== sharedView && trkData.sharedView.temp !== sharedView) {
				// if the results came back after the shared view has changed, discard them
				return;
			}

			if (result.IsLimited === true && result.IncludesLimitedData === false) {
				document.getElementById("txtDateFrom").value = fromUtc; // isn't this utc?
				document.getElementById("txtDateTo").value = toUtc; // isn't this utc?
				showResultLimitsIfApplicable(result, false);
				return;
			}

			// clear previous results
			clearSharedViewData(!isDateFiltered); // TODO clear limitedData here under specific circumstances?

			trkData.sharedView.isDateFiltered = isDateFiltered;
			trkData.sharedView.isLimited = result.IsLimited;
			trkData.sharedView.isLoadedLimitedData = result.IncludesLimitedData;

			_.each(result.Assets, function (assetResult) {
				processSharedViewData(sharedView.Id, assetResult, sharedView.Preferences.PositionConsolidation);
			});

			trkData.sharedView.events = result.Events;
			trkData.sharedView.messageCounts = {
				FromMobile: 0,
				FromMobileChats: 0,
				FromMobileDevice: 0,
				ToMobile: 0,
				ToMobileChats: 0,
				ToMobileDevice: 0,
			};

			_.each(trkData.sharedView.messageCountsByAssetId, function (item) {
				trkData.sharedView.messageCounts.FromMobile += item.FromMobile;
				trkData.sharedView.messageCounts.FromMobileChats += item.FromMobileChats;
				trkData.sharedView.messageCounts.FromMobileDevice += item.FromMobileDevice;
				trkData.sharedView.messageCounts.ToMobile += item.ToMobile;
				trkData.sharedView.messageCounts.ToMobileChats += item.ToMobileChats;
				trkData.sharedView.messageCounts.ToMobileDevice += item.ToMobileDevice;
			});

			var normalizedSharedViewEvents = [];
			_.each(result.Events, function (item) {
				if (trkData.sharedView.eventsById[item.Id] === undefined) {
					trkData.sharedView.eventsById[item.Id] = normalizeAssetData(item.AssetId, "event", item);
				}
				normalizedSharedViewEvents.push(trkData.sharedView.eventsById[item.Id]);
				trkData.sharedView.normalizedEvents.push(trkData.sharedView.eventsById[item.Id]);
				trkData.sharedView.normalizedEventIds[item.Id] = true;
			});

			trkData.sharedView.normalizedEvents = normalizedSharedViewEvents;

			// data manipulation/loading completed by this step

			// add geofences and places
			addFenceAndPlaceMarkersToSharedView(sharedView.PlaceIds, sharedView.FenceIds);

			// map type
			trkData.isSatelliteLabelOverlayEnabled = !sharedView.Preferences.RemoveRoads;
			changeMapType(
				_.findKey(trkData.MAP_TYPES, function (item) {
					return item === sharedView.Preferences.MapType;
				})
			);

			updateAssetFunctionBadges(trkDataGroups.SHARED_VIEW_HISTORY, sharedView.Id);
			showResultLimitsIfApplicable(result, isDateFiltered);

			if (
				(trkData.sharedView.isLimited && trkData.sharedView.isLoadedLimitedData) ||
				trkData.sharedView.limitedData !== null
			) {
				handleLimitedDataResult(viewModes.SHARED_VIEW, fromUtc, toUtc, result.Counts, result.Limit);
			} else {
				trkData.sharedView.limitedData = null;
			}
			updateActiveAssetInformation(viewModes.SHARED_VIEW);
			updateMapModeDateRange();

			resizeApp(true);
			setMapBounds();
		}
	);
}

export const throttledQuerySharedViewData = _.throttle(querySharedViewData, 250, { leading: false });

function processSharedViewData(sharedViewId, data, clusterMarkers) {
	//console.log('processSharedViewData ' + sharedViewId + ', cluster? ' + clusterMarkers);
	var asset = findAssetById(data.Id);
	if (asset === null) {
		return;
	}

	var sharedView = findSharedViewById(sharedViewId);

	_.each(data.Positions, function (position) {
		// we probably shouldn't check data.positions
		if (trkData.sharedView.positionsById[position.Id] === undefined) {
			trkData.sharedView.positionsById[position.Id] = normalizeAssetData(asset.Id, "position", position);
		}

		if (trkData.sharedView.normalizedPositionsByAssetId[asset.Id] === undefined) {
			trkData.sharedView.normalizedPositionsByAssetId[asset.Id] = [];
		}
		trkData.sharedView.normalizedPositionsByAssetId[asset.Id].push(trkData.sharedView.positionsById[position.Id]);
		trkData.sharedView.normalizedPositions.push(trkData.sharedView.positionsById[position.Id]);
	});

	trkData.sharedView.positionsByAssetId[asset.Id] = data;

	if (data.MessageCounts !== null) {
		trkData.sharedView.messageCountsByAssetId[asset.Id] = data.MessageCounts;
	}

	var visiblePositions = _.filter(data.Positions, function (item) {
		return !item.IsHidden;
	});
	var lastVisiblePositionId = null;
	var firstVisiblePositionId = null;
	if (visiblePositions.length > 0) {
		lastVisiblePositionId = visiblePositions[0].Id;
		firstVisiblePositionId = visiblePositions[visiblePositions.length - 1].Id;
	}
	//console.log('Asset ' + asset.Id + ' visible positions: ' + visiblePositions.length);
	if (data.Positions.length > 0) {
		if (clusterMarkers && trkData.sharedView.markerClustersByAssetId[asset.Id] === undefined) {
			trkData.sharedView.markerClustersByAssetId[asset.Id] = createMarkerCluster(asset, null, sharedViewId);
		}

		let alpha = 255;
		const alphaIncrement = sharedView.Preferences.PositionAlpha
			? 185 / visiblePositions.length
			: 0;
		// positions are sorted from newest->last
		var totalAssetPositions = visiblePositions.length;
		var markersForCluster = [];
		for (var j = 0; j < data.Positions.length; j++) {
			// add position to map and position filters
			var position = data.Positions[j];
			var isFirst = totalAssetPositions > 1 && position.Id === firstVisiblePositionId;
			var isLast = totalAssetPositions > 1 && position.Id === lastVisiblePositionId;
			var marker = addPositionMarkerToPoint(
				[position.Lat, position.Lng],
				false,
				position,
				asset,
				alpha,
				clusterMarkers,
				isFirst,
				isLast,
				trkDataGroups.SHARED_VIEW_HISTORY,
				null,
				sharedViewId
			);
			if (!position.IsHidden) {
				markersForCluster.push(marker);
			}
			alpha -= alphaIncrement;
		}

		trkData.sharedView.markersByAssetId = _.groupBy(trkData.sharedView.markers, function (marker) {
			return marker.data.assetId;
		});

		if (clusterMarkers && trkData.sharedView.markerClustersByAssetId[asset.Id] !== undefined) {
			trkData.sharedView.markerClustersByAssetId[asset.Id].empty();
			trkData.sharedView.markerClustersByAssetId[asset.Id].multiAdd(markersForCluster);
			addItemToMap(trkData.sharedView.markerClustersByAssetId[asset.Id], null, viewModes.SHARED_VIEW);
		}

		createPositionLinesForAsset(asset, visiblePositions, viewModes.SHARED_VIEW);
	}
}

function addFenceAndPlaceMarkersToSharedView(placeIds, fenceIds) {
	var fenceIdLookup = _.keyBy(fenceIds);
	var placeIdLookup = _.keyBy(placeIds);
	var fenceMarkers = _.filter(trkData.fenceMarkers, function (item) {
		return fenceIdLookup[item.data.fenceId] !== undefined;
	});
	var placeMarkers = _.filter(trkData.placeMarkers, function (item) {
		return placeIdLookup[item.data.placeId] !== undefined;
	});
	trkData.sharedView.fenceMarkers = fenceMarkers;
	trkData.sharedView.placeMarkers = placeMarkers;
	_.each(fenceMarkers, function (item) {
		addItemToMap(item, null, viewModes.SHARED_VIEW);
	});
	_.each(placeMarkers, function (item) {
		addItemToMap(item, null, viewModes.SHARED_VIEW);
	});
}

export function cloneSharedView(sharedView) {
	var clonedPreferences = _.clone(sharedView.Preferences);
	var cloned = _.clone(sharedView);
	cloned.Preferences = clonedPreferences;
	return cloned;
}

function compareSharedViews(newView, oldView) {
	console.log("compareSharedViews", newView, oldView);
	// first check all properties that would require a re-processing
	var isReprocessing = false;
	var changeMade = false;
	if (
		newView.IsMessagingEnabled !== oldView.IsMessagingEnabled ||
		newView.FromDateEpoch !== oldView.FromDateEpoch ||
		newView.ToDateEpoch !== oldView.ToDateEpoch ||
		!_.isEqual(newView.AssetIds, oldView.AssetIds) ||
		!_.isEqual(newView.AssetGroupIds, oldView.AssetGroupIds) ||
		newView.IsTimeframeRelative !== oldView.IsTimeframeRelative ||
		newView.RelativeTimeframeNumber !== oldView.RelativeTimeframeNumber ||
		newView.RelativeTimeframeType !== oldView.RelativeTimeframeType
	) {
		isReprocessing = true;
		//querySharedViewData(newView, null, false);
		throttledQuerySharedViewData(newView, null, false);
		console.log("change made");
		return;
	}

	// add/remove fences/places
	if (!_.isEqual(newView.FenceIds, oldView.FenceIds) || !_.isEqual(newView.PlaceIds, oldView.PlaceIds)) {
		_.each(trkData.sharedView.fenceMarkers, function (item) {
			removeItemFromMap(item, null, viewModes.SHARED_VIEW);
		});
		_.each(trkData.sharedView.placeMarkers, function (item) {
			removeItemFromMap(item, null, viewModes.SHARED_VIEW);
		});
		addFenceAndPlaceMarkersToSharedView(newView.PlaceIds, newView.FenceIds);
		changeMade = true;
	}

	// all properties that can just be modified on the view go here
	if (
		newView.Preferences.MapType !== oldView.Preferences.MapType ||
		newView.Preferences.RemoveRoads !== oldView.Preferences.RemoveRoads
	) {
		trkData.isSatelliteLabelOverlayEnabled = !newView.Preferences.RemoveRoads;
		changeMapType(
			_.findKey(trkData.MAP_TYPES, function (item) {
				return item === newView.Preferences.MapType;
			})
		);
		changeMade = true;
	}

	if (newView.Preferences.PositionConsolidation !== oldView.Preferences.PositionConsolidation) {
		if (!newView.Preferences.PositionConsolidation) {
			// remove clusters and show visible markers
			_.each(trkData.sharedView.markerClustersByAssetId, function (item) {
				item.empty();
			});
			_.each(trkData.sharedView.markers, function (item) {
				if (!item.data.isHidden) {
					addItemToMap(item, null, viewModes.SHARED_VIEW);
				}
			});
		} else {
			// remove visible markers, adding them to appropriate clusters (were they ever created?)
			_.each(trkData.sharedView.markersByAssetId, function (item, assetId) {
				_.each(item, function (marker) {
					removeItemFromMap(marker, null, viewModes.SHARED_VIEW);
				});

				if (trkData.sharedView.markerClustersByAssetId[assetId] === undefined) {
					var asset = findAssetById(assetId);
					trkData.sharedView.markerClustersByAssetId[assetId] = createMarkerCluster(asset, null, newView.Id);
				}

				trkData.sharedView.markerClustersByAssetId[assetId].empty();
				trkData.sharedView.markerClustersByAssetId[assetId].multiAdd(item);
				addItemToMap(trkData.sharedView.markerClustersByAssetId[assetId], null, viewModes.SHARED_VIEW);
			});
		}
		changeMade = true;
	}

	if (changeMade) {
		console.log("changes made");
	}
}

export function getSharedViewLink(sharedView) {
	// TODO remove this method
	return window.location.protocol + "//" + window.location.host + "/s/" + sharedView.UniqueKey;
}

function updateSharedView(sharedView) {
	var oldSharedView = findSharedViewById(sharedView.Id);
	for (var i = 0; i < trkData.sharedViews.length; i++) {
		if (trkData.sharedViews[i].Id == sharedView.Id) {
			trkData.sharedViews.splice(i, 1, sharedView);
		}
	}

	var li = domNodes.sharedViews[sharedView.Id];
	if (li !== undefined) {
		var name = li.querySelector(".shared-view-name");
		name.textContent = sharedView.Name;
		var description = li.querySelector(".shared-view-description");
		description.textContent = getSharedViewNodeDescription(sharedView);
	}

	sharedView.ColorSorted = convertHexToSortable(convertNamedColorToHex(sharedView.Color));
	sharedView.Link = getSharedViewLink(sharedView); // TODO remove this
	if (oldSharedView.Color !== sharedView.Color || oldSharedView.IsEnabled !== sharedView.IsEnabled) {
		updateSharedViewListingIcon(sharedView);
	}
	// TODO title and icon of secondary panel, if open, applies to all item types too

	// update options panel if opened
	if (
		domNodes.panels.secondary.getAttribute("data-group-for") === "shared-views" &&
		parseInt(domNodes.panels.secondary.getAttribute("data-item-id")) === sharedView.Id
	) {
		openSharedViewSettingsPanel(sharedView);
	}

	resortItemGroup("all-shared-views");
	indexSharedViewsForSearch();
}

function getSharedViewNodeDescription(sharedView) {
	var description = strings.ALL_TIME;
	if (sharedView.FromDateEpoch !== null || sharedView.ToDateEpoch !== null) {
		var from = moment(sharedView.FromDateEpoch);
		var to = moment(sharedView.ToDateEpoch);
		if (from.isValid() && to.isValid()) {
			description = from.format(user.shortDateFormat) + " - " + to.format(user.shortDateFormat);
		} else if (from.isValid()) {
			description = from.format(user.shortDateFormat) + " - ";
		} else if (to.isValid()) {
			description = " - " + to.format(user.shortDateFormat);
		}
	} else if (sharedView.IsTimeframeRelative === true) {
		var timeFrames = {
			"h": strings.HOURS,
			"m": strings.MONTHS,
			"d": strings.DAYS,
			"w": strings.WEEKS,
		};
		description = sharedView.RelativeTimeframeNumber + " " + timeFrames[sharedView.RelativeTimeframeType];
	}
	return description;
}

export function createSharedViewNode(sharedView) {
	if (sharedView === undefined || sharedView === null) {
		return null;
	}

	var color = getItemHexColor(sharedView);
	var description = getSharedViewNodeDescription(sharedView);
	var imagePath = createMarkerPath("SharedView", color, null, null, null, false);
	var sharedViewDto = {
		id: sharedView.Id,
		name: sharedView.Name,
		color: sharedView.Color,
		description: description,
		icon: imagePath,
		visibilityClass: sharedView.IsEnabled ? "active" : "disabled",
	};
	var fragment = templates.sharedView(sharedViewDto);
	return fragment.childNodes[0];
}

export function checkForShareViewChange(sharedView, wasEditing) {
	return function () {
		console.log("checkForShareViewChange - dialog closed", wasEditing, sharedView);
		var currentSharedViewId = domNodes.panels.secondary.getAttribute("data-item-id");
		if (wasEditing === true && trkData.sharedView.temp !== null && trkData.sharedView.current !== null) {
			// closed out of editing
			console.log("- canceled shared view temp edit");
			compareSharedViews(trkData.sharedView.current, trkData.sharedView.temp);
			trkData.sharedView.temp = null;
			// may have also closed secondary panel
		} else if (wasEditing === true && trkData.sharedView.temp !== null) {
			console.log("- canceled out of new shared view");
			deselectSharedView();
		} else if (currentSharedViewId !== null) {
			var newSharedViewId = parseInt(currentSharedViewId);
			if (
				(sharedView === null && currentSharedViewId === null) ||
				(sharedView !== null && newSharedViewId !== sharedView.Id)
			) {
				console.log("- change in shared view", newSharedViewId, sharedView.Id);
				clearSharedViewData(true);
				trkData.sharedView.current = null;
				$(domNodes.infoDialogs.sharedViewInformation).dialog("close");
				if (newSharedViewId === null) {
					// resetting map type to prior
					trkData.isSatelliteLabelOverlayEnabled = trkData.sharedView.priorIsSatelliteLabelOverlayEnabled;
					changeMapType(trkData.sharedView.priorMapType);
				}
			} else {
				console.log("- no change in shared view");
			}
		}

		if (currentSharedViewId === null) {
			// user has closed secondary panel.. re-show shared view information
			console.log("- reshow shared view info");
			showSharedViewInformationOnMap(trkData.sharedView.current, true);
		}
	};
}

function addSharedView(sharedView, groupId) {
	sharedView.ColorSorted = convertHexToSortable(sharedView.Color);
	sharedView.Link = getSharedViewLink(sharedView); // TODO remove this
	trkData.sharedViews.push(sharedView);

	var group = findGroupById(groupId);
	if (group === null) {
		groupId = "all-shared-views";
	}

	var itemNode = createSharedViewNode(sharedView);
	domNodes.sharedViews[sharedView.Id] = itemNode;
	var groupContentsNode = domNodes.groupContents[groupId].querySelector("ul.group-list-list");

	var groupItems = sortItemsByMode("shared-views", trkData.sharedViews, groupId, "shared-views");

	// add it where it belongs instead of at the end
	if (groupItems.length > 1) {
		var itemIndex = _.indexOf(groupItems, sharedView);
		var subgroups = group !== null ? group.GroupIds.length : 0;
		groupContentsNode.insertBefore(itemNode, groupContentsNode.children[itemIndex + subgroups + 1]); // +1 due to the no-items first node
	} else {
		groupContentsNode.appendChild(itemNode);
	}

	updateGroupVisibilityStatus(groupId);
	toggleItemSorting("shared-views", user.displayPreferences.sortMode["shared-views"] === sortModes.CUSTOM);
	document.getElementById("no-shared-views").classList.remove("is-visible");
	document.getElementById("shared-views-all").classList.add("is-visible");
	document.getElementById("filter-shared-views").querySelector(".filter-box").classList.add("is-visible");
	groupContentsNode.querySelector(".no-items").classList.remove("is-visible");
}

export function querySharedViewInvites(sharedView) {
	// get existing invites list
	// maybe only if not already loaded?
	var data = {
		id: sharedView.Id,
	};

	var itemList = document.getElementById("shared-with-items");
	itemList.setAttribute("data-shared-view-id", sharedView.Id);
	// clear previous data
	while (itemList.firstChild) {
		itemList.removeChild(itemList.firstChild);
	}
	return handleAjaxFormSubmission(
		"GetSharedViewInvites",
		data,
		null,
		null,
		null,
		strings.MSG_GET_SHARED_VIEW_INVITES_ERROR,
		function (result) {
			if (parseInt(itemList.getAttribute("data-shared-view-id")) !== sharedView.Id) {
				return;
			}
			var items = document.createDocumentFragment();
			if (result.Invites.length === 0) {
				document.getElementById("shared-with-none").classList.add("is-visible");
			} else {
				document.getElementById("shared-with-none").classList.remove("is-visible");
			}
			_.each(result.Invites, function (invite) {
				var li = document.createElement("li");
				li.className = "list-group-item";
				var head = document.createElement("div");
				head.className = "list-group-item-header";
				var email = document.createElement("a");
				email.href = "mailto:" + invite.Email;
				email.textContent = invite.Email;
				var time = document.createElement("span");
				time.textContent = invite.SharedOn;

				head.appendChild(email);
				head.appendChild(time);
				li.appendChild(head);

				var link = document.createElement("a");
				link.href = sharedView.Link + "/" + invite.UniqueKey;
				link.textContent = sharedView.Link + "/" + invite.UniqueKey;
				link.setAttribute("target", "_blank");
				link.setAttribute("rel", "nofollow");
				li.appendChild(link);
				items.appendChild(li);
			});
			itemList.appendChild(items);
		}
	);
}

function updateSharedViewListingIcon(sharedView) {
	var color = getItemHexColor(sharedView);
	var isActive = sharedView.IsEnabled;
	var svgIcon = getSvgIconForItemType("shared-views", sharedView);
	var items = domNodes.sharedViews[sharedView.Id];
	if (!_.isArray(items)) {
		items = [items];
	}
	_.each(items, function (itemNode) {
		if (isActive) {
			itemNode.classList.remove("disabled");
			itemNode.classList.add("active");
		} else {
			itemNode.classList.add("disabled");
			itemNode.classList.remove("active");
		}
		var itemIcon = itemNode.querySelector(".list-item-icon");
		itemIcon.querySelector("use").setAttributeNS("http://www.w3.org/1999/xlink", "href", svgIcon);
		itemIcon.style["color"] = color;
	});
}

export function indexSharedViewsForSearch() {
	trkData.search.sharedViews = new JsSearch.Search("Id");
	trkData.search.sharedViews.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
	var attributes = ["Name", "Description", "UniqueKey"];
	// todo: asset or group names?
	_.each(attributes, function (attribute) {
		trkData.search.sharedViews.addIndex(attribute);
	});
	trkData.search.sharedViews.addDocuments(trkData.sharedViews);
	log("Shared Views indexed for search filtering.");
}

export function changeSharedViewStatus(sharedView) {
	var data = {
		id: sharedView.Id,
		isEnabled: !sharedView.IsEnabled,
	};

	handleAjaxFormSubmission(
		"ChangeSharedViewStatus",
		data,
		null,
		null,
		strings.MSG_EDIT_SHARED_VIEW_SUCCESS,
		strings.MSG_EDIT_SHARED_VIEW_ERROR,
		function (result) {
			updateSharedView(result.SharedView);
			showSharedViewInformationOnMap(result.SharedView, !state.openPanels.secondary);
		}
	);
}
