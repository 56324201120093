import domNodes from "./domNodes.js";
import { mapModes } from "./const.js";
import trkData from "./data.js";
import { getDisplayFilterForEventType } from "./display-filter.js";
import { openDialogPanel } from "./panel-nav.js";
import state from "./state.js";
import strings from "./strings.js";
import { createListing, defaultListItemSort } from "./item-listing.js";
import { wrapUrl } from "./wrapurl.js";
import { toggleLoadingMessage } from "./ajax.js";
import { handleWebServiceError } from "./ajax.js";

import _ from "lodash";
import $j from "jquery";

export function updateAssetState(asset, state) {
	//var priorStatus = mapAssetStateToStatusState(asset.State);
	var priorStatus = null;
	var newStatus = mapAssetStateToStatusState(state);
	asset.State = state;

	_.each(domNodes.assets[asset.Id], function (assetNode) {
		if (state.activeMapMode === mapModes.LIVE) {
			updateAssetStatusIconNodes(assetNode.querySelector(".asset-indicators"), newStatus, priorStatus, asset.Id);
		} else {
			// TODO: this seems bad, detach/reattach instead so they don't have to be recreated?
			// store in domNodes.assets[asset.Id].statuses or such
			var status = assetNode.querySelectorAll(".status");
			_.each(status, function (item) {
				item.parentNode.removeChild(item);
			});
		}
	});
}

function createAssetStatusIconNode(title, iconClass, type) {
	// <svg class="status notify-class"><title>Title</title><use href="/content/svg/tracking.svg?v=15#type"></use></svg>
	var notifyType = "notify-" + iconClass;
	var icon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
	icon.classList.add("status");
	if (type !== undefined && type !== null) {
		icon.classList.add("status-" + type);
		icon.setAttributeNS("http://www.w3.org/2000/svg", "data-status", iconClass);
	}
	icon.classList.add(notifyType);
	var iconTitle = document.createElementNS("http://www.w3.org/2000/svg", "title");
	iconTitle.textContent = title;
	icon.appendChild(iconTitle);
	var iconType = document.createElementNS("http://www.w3.org/2000/svg", "use");
	iconType.setAttributeNS("http://www.w3.org/1999/xlink", "href", "/content/svg/tracking.svg?v=15#" + notifyType);
	icon.appendChild(iconType);
	return icon;
}

function updateAssetStatusIconNodes(node, status, priorStatus, assetId) {
	var statusTitles = statusTitles;
	var newIcons = document.createDocumentFragment();
	var itemsAdded = false;
	// if was null, and now not null it must be added
	// else if was not null and now null it must be removed
	// else if not the same value then it must be updated
	// todo: re-add LPM interval to title

	// prior state is really just what is visible

	// TODO: detach/reattach nodes instead of removing/recreating?

	var statusTypes = ["movement", "engine", "satellite", "cellular", "power"];
	_.each(statusTypes, function (statusType) {
		// don't necessarily like that we're doing a 5x query per node here
		// other method of state storage instead of DOM?
		var priorStatusNode = node.querySelector(".status-" + statusType);
		if (priorStatusNode === null && status[statusType] !== null) {
			// new icon for state
			newIcons.appendChild(createAssetStatusIconNode(statusTitles[status[statusType]], status[statusType], statusType));
			itemsAdded = true;
		} else if (priorStatusNode !== null && status[statusType] === null) {
			// icon no longer known for state, so remove it
			var iconNode = node.querySelector(".status-" + statusType);
			if (iconNode !== null) {
				iconNode.parentNode.removeChild(iconNode);
			}
		} else if (
			priorStatusNode !== null &&
			priorStatusNode.getAttributeNS("http://www.w3.org/2000/svg", "data-status") !== status[statusType]
		) {
			// state of icon has changed, update it
			var updateNode = node.querySelector(".status-" + statusType);
			if (updateNode !== null) {
				// remove prior status classes
				_.each(updateNode.classList, function (classItem) {
					if (classItem.indexOf("notify-") !== -1) {
						updateNode.classList.remove(classItem);
					}
				});
				updateNode.classList.add("notify-" + status[statusType]);
				updateNode.setAttributeNS("http://www.w3.org/2000/svg", "data-status", status[statusType]);
				updateNode
					.querySelector("use")
					.setAttributeNS(
						"http://www.w3.org/1999/xlink",
						"href",
						"/content/svg/tracking.svg?v=15#" + "notify-" + status[statusType]
					);
				updateNode.querySelector("title").textContent = statusTitles[status[statusType]];
			}
		}
	});

	if (itemsAdded) {
		node.appendChild(newIcons);
	}
}

function mapAssetStateToStatusState(state) {
	var status = {
		movement: null,
		engine: null,
		satellite: null,
		cellular: null,
		power: null,
	};

	// movement status
	if (state.IsSpeeding === true) {
		status.movement = "speeding";
	} else if (state.IsTowing === true) {
		status.movement = "towing";
	} else if (state.IsMoving === true) {
		status.movement = "moving";
	} else if (state.IsMoving === false || (state.IsMoving === null && state.IsTowing === false)) {
		status.movement = "stationary";
	}

	// engine status
	if (state.IsImmobilized === true) {
		status.engine = "immobilized";
	} else if (state.IsIdling === true) {
		status.engine = "idling";
	} else if (state.IsIgnitionOn === true) {
		status.engine = "ignition-on";
	} else if (state.IsIgnitionOn === false) {
		status.engine = "ignition-off";
	}

	// sat signal status
	if (state.IsAntennaCut === true) {
		status.satellite = "antenna-cut";
	} else if (state.IsGpsJammed === true) {
		status.satellite = "gps-jammed";
	} else if (state.IsInLowPowerMode === true) {
		status.satellite = "low-power-mode";
	}

	// cell signal status
	if (state.IsCellJammed === true) {
		status.cellular = "cell-jammed";
	}

	// power status
	if (state.IsOnBackupPower === true) {
		status.power = "backup-power";
	}

	return status;
}

export function createIconForAssetEvent(_asset, eventType) {
	var text = _.find(trkData.eventTypes, { Id: eventType }).Text;
	switch (eventType) {
		case 21: // ignition on
			return createAssetStatusIconNode(text, "ignition-on");
		case 22: // ignition off
			return createAssetStatusIconNode(text, "ignition-off");
		case 47: // moving start
		case 1: // start
			return createAssetStatusIconNode(text, "moving");
		case 48: // moving stop
		case 2: // stop
			return createAssetStatusIconNode(text, "stationary");
		case 32: // 98 // idling
			return createAssetStatusIconNode(text, "idling");
		case 5: // 6
			return createAssetStatusIconNode(text, "speeding");
		case 3: // power on
			return createAssetStatusIconNode(text, "power-on");
		case 4: // power off
			return createAssetStatusIconNode(text, "power-off");
		case 45: // 46  // towing start
			return createAssetStatusIconNode(text, "towing");
		case 20: // 19  // on main power
			return createAssetStatusIconNode(text, "backup-power");
		case 35: // 129 // antenna cut start
			return createAssetStatusIconNode(text, "antenna-cut");
		case 36: // 37  // gps jamming start
			return createAssetStatusIconNode(text, "gps-jammed");
		case 38: // 39  // cell jamming start
			return createAssetStatusIconNode(text, "cell-jammed");
		case 133: // low battery
			return createAssetStatusIconNode(text, "low-battery");
		case 14: // alert triggered
			return createAssetStatusIconNode(text, "alert");
		case 16: // alert no longer triggered
			return createAssetStatusIconNode(text, "alert-cancel");
		case 15: // check-in
			return createAssetStatusIconNode(text, "check-in");
		default:
			return null;
	}
}

export function openStatusForAsset(asset) {
	var dataSource =
		state.activeMapMode === mapModes.LIVE
			? trkData.live.normalizedEventsByAssetId
			: trkData.history.normalizedEventsByAssetId;
	var events = _.sortBy(
		_.filter(dataSource[asset.Id], getDisplayFilterForEventType("status")),
		defaultListItemSort
	).reverse();
	createListing(events, "status");
	openDialogPanel(
		domNodes.dialogs.assetStatus,
		strings.STATUS,
		asset,
		false,
		null,
		"asset",
		"asset-status",
		openStatusForAsset
	);
}

export function loadAssetStatus(asset) {
	if (asset == null) return;
	var btn = $j("#RefreshAssetStatus");
	var data = { assetId: asset.Id };
	btn.addClass("disabled").prop("disabled", true);
	toggleLoadingMessage(true, "asset-status");
	return $j.ajax({
		type: "POST",
		url: wrapUrl("/services/GPSService.asmx/GetAssetStatus"),
		data: JSON.stringify(data),
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		success: function (msg) {
			btn.removeClass("disabled").prop("disabled", false);
			if (msg.d) {
				var result = msg.d;
				if (result.Success === true) {
					$j('#edit-asset-current-status input[value=""]').prop("checked", true);
					$j("input[name=rbEditAssetMoving][value=" + result.Status.IsMoving + "]").prop("checked", true);
					$j("input[name=rbEditAssetIgnitionOn][value=" + result.Status.IsIgnitionOn + "]").prop("checked", true);
					$j("input[name=rbEditAssetIdling][value=" + result.Status.IsIdling + "]").prop("checked", true);
					$j("input[name=rbEditAssetSpeeding][value=" + result.Status.IsSpeeding + "]").prop("checked", true);
					$j("input[name=rbEditAssetDwelling][value=" + result.Status.IsDwelling + "]").prop("checked", true);
					$j("input[name=rbEditAssetTowing][value=" + result.Status.IsTowing + "]").prop("checked", true);
					$j("input[name=rbEditAssetInLowPowerMode][value=" + result.Status.IsInLowPowerMode + "]").prop(
						"checked",
						true
					);
					$j("input[name=rbEditAssetOnBackupPower][value=" + result.Status.IsOnBackupPower + "]").prop("checked", true);
					$j("input[name=rbEditAssetAntennaCut][value=" + result.Status.IsAntennaCut + "]").prop("checked", true);
					$j("input[name=rbEditAssetGpsJammed][value=" + result.Status.IsGpsJammed + "]").prop("checked", true);
					$j("input[name=rbEditAssetCellJammed][value=" + result.Status.IsCellJammed + "]").prop("checked", true);
					$j("input[name=rbEditAssetImmobilized][value=" + result.Status.IsImmobilized + "]").prop("checked", true);
					$j("#EditAssetShockLogAlarmCount").text(result.Status.ShockLogAlarmCount);
					updateAssetState(asset, result.Status);
				} else {
					handleWebServiceError(strings.MSG_GET_STATUS_ERROR);
				}
			}
			toggleLoadingMessage(false, "asset-status");
		},
		error: function (xhr, status, error) {
			handleWebServiceError(strings.MSG_GET_STATUS_ERROR);
			toggleLoadingMessage(false, "asset-status");
			btn.removeClass("disabled").prop("disabled", false);
		},
	});
}
