<li class="group" id="group-{{id}}" data-group-id="{{id}}" data-group-for="{{type}}" style="border-color: {{color}};">
    <div class="d-flex flex-row align-items-center group-header">
        <svg class="list-item-action showhide" data-group-id="{{id}}"><use xlink:href="/content/svg/tracking.svg?v=15#visible"></use></svg>
        <div class="item-group-toggle">
            <svg class="list-item-action group-toggle"><use xlink:href="/content/svg/tracking.svg?v=15#folder-solid"></use></svg>
            <div class="item-count">{{assets.length}}</div>
        </div>
        <div class="group-info expand mr-auto">
            <span class="group-name group-color">{{name}}</span>
            <div class="asset-indicators d-flex align-items-center">
                <svg class="notifications" title="@ViewRes.HomeStrings.Positions"><use xlink:href="/content/svg/notifications.svg?v=2#{{notifications.symbol}}"></use></svg>
            </div>
        </div>
        <svg class="list-item-action group-drag disabled"><use xlink:href="/content/svg/tracking.svg?v=15#sort"></use></svg>
        <svg class="list-item-action item-settings"><use xlink:href="/content/svg/tracking.svg?v=15#group-settings"></use></svg>
    </div>
    <div class="group-contents">
        <ul class="group-list-list"></ul>
    </div>
</li>
