import { map } from "./map-base.js";

import Clusterer from "./gleo/src/loaders/Clusterer.mjs";
import TintedSprite from "./gleo/src/symbols/TintedSprite.mjs";
import TextLabel from "./gleo/src/symbols/TextLabel.mjs";
import Geometry from "./gleo/src/geometry/Geometry.mjs";

import L from "leaflet";

// This is the default behaviour of the Gleo defaultOnClusterClick(), but
// adapted for leaflet-gleo.
function onClusterClick(ev, clusterer, items, bbox) {

	const { minX, minY, maxX, maxY } = bbox;

	// Reproject bbox into epsg4326
	const crs = ev.target.symbols[0]._inAcetate.platina.crs;
	const sw = new Geometry(crs, [minX, minY]).asLatLng();
	const ne = new Geometry(crs, [maxX, maxY]).asLatLng();
	const bounds = L.latLngBounds([sw, ne]);
	map.fitBounds(bounds, { maxZoom: 17 });
}

// Note: Loading TextLabel font faces is async.
const fontfaceURL = new URL("/fonts/open-sans-v34-latin_vietnamese-regular", document.URL).toString();
TextLabel.addFontFace("Open Sans", `
local('Open Sans Regular'),
local('OpenSans-Regular'),
url('${fontfaceURL}.woff2') format('woff2'),
url('${fontfaceURL}.woff') format('woff')
`);

function clusterSymbolizer(symbols) {
	const geom = symbols[0].geometry;
	const base = symbols[0].spriteBase;

	return [
		new TintedSprite(geom, {
			image: base.image,
			tint: base.tint,
		}),
		// new CircleStroke(geom, { radius: 16, colour: "#3388ff80", width: 4, cursor: 'pointer' }),
		// new CircleFill(geom, {
		// 	radius: 14,
		// 	colour: "#3388ffc0" ,
		// 	cursor: "pointer",
		// }),
		// drop shadow
		new TextLabel(geom, {
			str: symbols.length,
			align: "center",
			baseline: "middle",
			cache: true,
			interactive: false,
			colour: 'white',
			//outlineWidth: 0.15,
			//outlineColour: [255, 255, 255, 255],
			offset: [1, -19],
			font: "14px 'Open Sans'"
		}),
		// cluster label
		new TextLabel(geom, {
			str: symbols.length,
			align: "center",
			baseline: "middle",
			cache: true,
			interactive: false,
			outlineWidth: 0.15,
			outlineColour: [255, 255, 255, 255],
			offset: [0, -18],
			font: "14px 'Open Sans'"
		}),
		// new L.Marker(geom.coords, { icon: L.divIcon({html: symbols.length})})
	];
}

export function createMarkerCluster(asset, trip, sharedViewId) {
	return new Clusterer({
		scaleLimit: 1,
		distance: 72,
		clusterSymbolizer: clusterSymbolizer,
		onClusterClick: onClusterClick,
		outlineColour: "white",
		outlineWidth: 0.5
	});
}
